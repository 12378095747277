import { useQuery, useQueryClient } from "react-query";
import { apiGet } from "../../../../helpers/requests.helper";
import { Simulator } from "../../../../types/simulator.types";
import { useGetAllOrganizations } from "../Organizations/Organizations.api";
import { useGetAllSchools } from "../../../../api/admin/allSchools";
import { isAdminUser } from "../../../../helpers/universal.helper";

export function useGetAllSimulators(refetchInterval?: number) {
  const queryClient = useQueryClient();
  const organizationsQuery = useGetAllOrganizations();
  const schoolsQuery = useGetAllSchools();
  return useQuery(
    ["get-all-simulators"],
    async () => {
      const data = await apiGet("/all-simulators");
      const list: Simulator[] = data.Simulators.filter((s: Simulator) => {
        const school = schoolsQuery.data?.map[s.SchoolID];
        const organization = school
          ? organizationsQuery.data?.map[s?.OrganizationID]
          : undefined;
        return s.IsActivated && school?.Active && organization?.Active;
      });

      const map: Record<string, Simulator> = {};
      list.forEach((sim: Simulator) => {
        map[sim.InstallationID] = sim;
      });
      return { list, map };
    },
    {
      enabled:
        isAdminUser() && organizationsQuery.isSuccess && schoolsQuery.isSuccess,
      refetchOnMount: false,
      onSuccess: () => queryClient.refetchQueries("get-simulators-hardware"),
      refetchInterval,
      refetchIntervalInBackground: !!refetchInterval,
    }
  );
}

export function useGetSimulatorsPerSchool() {
  const allSimulatorsQuery = useGetAllSimulators();
  if (!allSimulatorsQuery.isSuccess) return null;
  const simulatorsPerSchool: Record<number, Simulator[]> = {};
  allSimulatorsQuery.data.list.forEach((s) => {
    simulatorsPerSchool[s.SchoolID] = simulatorsPerSchool[s.SchoolID]
      ? [...simulatorsPerSchool[s.SchoolID], s]
      : [s];
  });
  return simulatorsPerSchool;
}

type SimulatorHardware = {
  ID: number;
  SimulatorID: number;
  CraneController: string;
  RightJoystick: string;
  LeftJoystick: string;
  TurnIndicator: string;
  WheelLoaderController: string;
  SteeringWheel: string;
  DisplayCount: number;
  CPU: string;
  CPUCount: number;
  SystemMemoryMb: number;
  GPU: string;
  GPUVersion: string;
  SetupFrom: string;
  SetupTo: string | null;
  VRLastActiveTime: string | null;
  DisplayController: "NVIDIA" | "Windows";
};

export function useGetSimulatorsHardware() {
  return useQuery(
    ["get-simulators-hardware"],
    async () => {
      const map: Record<string, SimulatorHardware> = await apiGet(
        "/get-simulators-hardware"
      );
      const list: SimulatorHardware[] = [];
      Object.keys(map).forEach((key) => list.push(map[key]));
      return { list, map };
    },
    {
      refetchOnMount: false,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
}

export type SchoolLocation = {
  Address: string;
  Latitude: number;
  Longitude: number;
  ExpirationTime: string;
  LastUpdatedTime: string;
  Id: number;
  Name: string;
  SimulatorCount: number;
};

export type SchoolLocationResponse = {
  Locations: SchoolLocation[];
  SchoolsFailedGeoCode: number[];
  SchoolsMissingAddress: number[];
  SchoolsNoGeolocationFound: number[];
};

export function useSchoolLocationInfo() {
  return useQuery<SchoolLocationResponse, Error>(
    ["school-location-info"],
    async () => {
      return await apiGet("/school-location-info");
    },
    {
      refetchOnMount: false,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
}
