import { Image, Title, Text, Stack, Button } from "@mantine/core"
import { useTranslation } from "react-i18next";
import { localStorageGet } from "../../../../../../helpers/localstorage.helper";
import clappingHands from "./assets/svg/clapping-hands.svg";

interface CompletedModalProps {
    onClose: () => void;
}

function CompletedModal(props: CompletedModalProps) {
    const { t } = useTranslation();
    return (
        <>
            <Stack align="center" py="xl">
                <Image src={clappingHands} width={45} />
                <Stack spacing="xs" align="center">
                    <Title order={2}>
                        {t("academy.presentation.completedModal.title", { name: localStorageGet("first_name") })}
                    </Title>
                    <Text>
                        {t("academy.presentation.completedModal.subtitle")}
                    </Text>
                </Stack>
            </Stack>
            <Stack mt="xl">
                <Button variant="light" onClick={props.onClose}>
                    {t("common:buttons.close")}
                </Button>
            </Stack>
        </>

    )
}

export default CompletedModal