import { get } from "lodash";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions, { setError, setLoading, setSuccess } from "../../../actions";
import { apiUnauthenticatedPost } from "../../../helpers/requests.helper";
import { RootState } from "../../../reducers";
import { SelectPassword, ConfirmationActivationStudent, ConfirmationActivationTeacher, ConfirmationPasswordResetStudent, ConfirmationPasswordResetTeacher, InvalidToken } from "./ActivateAccount.components";
import { useTranslation } from 'react-i18next';

function ActivateAcccount() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loadingState = useSelector((state: RootState) => state.loading);
    const successState = useSelector((state: RootState) => state.success);

    const [token, setToken] = useState("");
    const [invalidToken, setInvalidToken] = useState(false);

    const [forgot, setForgot] = useState(false);
    const [isTeacher, setIsTeacher] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordScore, setPasswordScore] = useState(0);

    const onClickLicenceAgreement = () => dispatch(actions.modals.setIsOpen("licenceAgreement", true));

    const loading = get(loadingState, "ACTIVATE_FORGOT", false);
    const success = get(successState, "ACTIVATE_FORGOT", false);

    if (window.location.search.substr(1) === "forgot" && !forgot)
        setForgot(true);

    useEffect(() => {
        setToken(window.location.search.substr(1));
        dispatch(actions.auth.logout());
        async function checkToken() {
            try {
                let result = await apiUnauthenticatedPost("/check-password-reset-token", { Token: token })
                setForgot(!result.IsActivation);
                setIsTeacher(result.IsTeacher);
                setFirstName(result.FirstName);
                setLastName(result.LastName);
                setInvalidToken(false);
            } catch (e) {
                dispatch(setError("INVALID_TOKEN"));
                setInvalidToken(true);
            }
        }
        if (token.length) checkToken();
    }, [dispatch, token]);

    const onChangePasswordField = (event: FormEvent<Element>) => {
        const target = event.target as HTMLInputElement;
        setPassword(target.value);
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (passwordScore > 1) {
            try {
                dispatch(setLoading("ACTIVATE_FORGOT"));
                let result = await apiUnauthenticatedPost("/perform-password-reset", { Token: token, Password: password })
                dispatch(setSuccess("ACTIVATE_FORGOT"));
                setForgot(!result.IsActivation);
                setIsTeacher(result.IsTeacher);
                setFirstName(result.FirstName);
            } catch (e) {
                dispatch(setError("ACTIVATE_FORGOT"))
            }
        }
    }

    if (invalidToken) return <InvalidToken />;
    if (success) {
        if (forgot) return isTeacher ? <ConfirmationPasswordResetTeacher /> : <ConfirmationPasswordResetStudent name={`${firstName}`} />;
        return isTeacher ? <ConfirmationActivationTeacher name={`${firstName}`} /> : <ConfirmationActivationStudent name={`${firstName}`} />;
    }

    const title = forgot ? t('activateAccount.selectPassword.title.forgot') : t('activateAccount.selectPassword.title.activate');
    const buttonText = forgot ? t('activateAccount.selectPassword.buttonText.forgot') : t('activateAccount.selectPassword.buttonText.activate');
    const buttonTextLoading = forgot ? t('activateAccount.selectPassword.buttonLoadingText.forgot') : t('activateAccount.selectPassword.buttonLoadingText.activate');

    return <SelectPassword
        title={title}
        buttonText={buttonText}
        buttonTextLoading={buttonTextLoading}
        onSubmit={handleSubmit}
        name={`${firstName} ${lastName}`}
        password={password}
        setPasswordScore={setPasswordScore}
        onChangePassword={onChangePasswordField}
        onClickLicenseAgreement={onClickLicenceAgreement}
        score={passwordScore}
        loading={loading}
    />;

}

export default ActivateAcccount;