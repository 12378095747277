import { Collapse, Menu } from "@mantine/core";
import { IconAward, IconBug, IconBuildingWarehouse, IconCar, IconChartCircles, IconEdit, IconFileInvoice, IconMap, IconMessage, IconReportAnalytics, IconSchool, IconTrophy } from "@tabler/icons";
import { ReactNode, useEffect, useRef, useState } from "react";
import { BarChart, ChevronDown, Users } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ADMIN_BILLING_PATH, ADMIN_CONTESTS_PATH, ADMIN_PATH, ADMIN_TEACHER_LECTURES_PATH, ADMIN_TEACHER_LECTURES_STATS_PATH, TICKETS_PATH, COURSE_EDITOR_STATS_PATH, EXERCISE_COMMENTS_PATH, GLOBAL_TOPLIST_PATH, GLOBAL_USERS_PATH, MAP_PATH, ORGANIZATIONS_PATH, RUN_DATA_PATH, SCHOOLS_PATH, STATS_SIMULATORS_PATH, } from "../../../../navigation/Routes";
import { RootState } from "../../../../reducers";
import { ViewConfigKey } from "../../../../types/user.types";
import { SubMenuSimulatorsIcon } from "../../../pages/Admin/Admin/Admin.components";
import { IconOrganization } from "../../Misc/Symbols.components";

function AdminMenuItems() {
    const [adminMenuOpen, setAdminMenuOpen] = useState(false);
    const [hovering, setHovering] = useState(false);
    const ref = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (hovering) {
            ref.current = setTimeout(() => setAdminMenuOpen(true), 300);
        } else {
            if (ref.current) clearTimeout(ref.current);
        }
    }, [hovering]);

    return (
        <>
            <Menu.Item
                icon={<IconReportAnalytics size={14} />}
                rightSection={<ChevronDown size={14} />}
                component={Link}
                to={`${ADMIN_PATH}`}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            >
                Admin
            </Menu.Item>
            <Collapse
                in={adminMenuOpen ?? false}
                sx={(theme) => ({
                    backgroundColor: theme.colors.bluegray[9]
                })}
            >
                <AdminMenuItem
                    url={ADMIN_PATH}
                    label="Statistik"
                    icon={<BarChart width={15} color="white" />}
                />
                <AdminMenuItem
                    url={STATS_SIMULATORS_PATH}
                    label="Simulatorer"
                    icon={<SubMenuSimulatorsIcon color="white" />}
                />
                <AdminMenuItem
                    url={GLOBAL_USERS_PATH}
                    label="Användare"
                    icon={<Users width={12} strokeWidth={2} color="white" />}
                />
                <AdminMenuItem
                    url={ORGANIZATIONS_PATH}
                    label="Organisationer"
                    icon={<IconOrganization color="white" width={12} />}
                />
                <AdminMenuItem
                    url={SCHOOLS_PATH}
                    label="Skolor"
                    icon={<IconBuildingWarehouse color="white" width={12} />}
                />
                <AdminMenuItem
                    url={MAP_PATH}
                    label="Kartan"
                    icon={<IconMap width={15} strokeWidth={2.1} color="white" />}
                />
                <AdminMenuItem
                    url={GLOBAL_TOPLIST_PATH}
                    label="Topplistan"
                    icon={<IconTrophy width={15} strokeWidth={2.1} color="white" />}
                />
                <AdminMenuItem
                    url={TICKETS_PATH}
                    label="Ärenden"
                    icon={<IconBug color="white" width={15} />}
                />
                <AdminMenuItem
                    url={RUN_DATA_PATH}
                    label="Kördata"
                    icon={<IconCar width={15} color="white" />}
                />
                <AdminMenuItem
                    url={COURSE_EDITOR_STATS_PATH}
                    label="Skapade kurser"
                    icon={<IconEdit width={12} color="white" />}
                />
                <AdminMenuItem
                    url={ADMIN_TEACHER_LECTURES_PATH}
                    label="Lärarutbildningar"
                    icon={<IconSchool className="icon" width={15} color="white" />}
                />
                <AdminMenuItem
                    url={ADMIN_TEACHER_LECTURES_STATS_PATH}
                    label="Academy stats"
                    icon={<IconChartCircles className="icon" width={15} color="white" />}
                />
                <AdminMenuItem
                    url={ADMIN_CONTESTS_PATH}
                    label="Tävlingar"
                    icon={<IconAward className="icon" width={15} color="white" />}
                />
                <AdminMenuItem
                    url={EXERCISE_COMMENTS_PATH}
                    label="Övningskommentarer"
                    icon={<IconMessage className="icon" width={15} color="white" />}
                />
                <AdminMenuItem
                    url={ADMIN_BILLING_PATH}
                    label="Fakturering"
                    icon={<IconFileInvoice className="icon" width={15} color="white" />}
                    requiredViewConfig="ShowBillingPage"
                />
            </Collapse>
        </>
    )
}

export default AdminMenuItems;

interface AdminMenuItemProps {
    icon: ReactNode;
    url: string;
    label: string;
    requiredViewConfig?: ViewConfigKey;
}

function AdminMenuItem(props: AdminMenuItemProps) {
    const permissionsState = useSelector((state: RootState) => state.permissions);
    const viewConfig = permissionsState.loggedInUserViewConfig;
    const isAllowed = !!props.requiredViewConfig ? viewConfig[props.requiredViewConfig] : true;
    return isAllowed ? (
        <Menu.Item
            icon={props.icon}
            component={Link}
            to={props.url}
            className="admin-item"
        >
            {props.label}
        </Menu.Item>
    ) : null;


}
