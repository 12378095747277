import { Button, Col, createStyles, Divider, Grid, Group, Loader, Title, useMantineTheme } from '@mantine/core'
import { ChevronLeft, Maximize } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { fetchLang } from '../../../../../../helpers/universal.helper'
import { Lecture } from '../../../../../../types/lecture.types'
import { useLectures, useProgressForLecture } from '../../../../Admin/TeacherLectures/TeacherLectures.api'
import LectureProgressRing from '../../../components/LectureProgressRing'

const useStyles = createStyles((theme, _params, getRef) => ({
    button: {
        backgroundColor: theme.colors.bluegray[0],
        color: theme.colors.bluegray[5],
        height: 40,
        ':hover': {
            backgroundColor: theme.colors.bluegray[1],
        }
    }
}));

interface PresentationHeaderProps {
    toggleFullscreen: () => Promise<void>;
    backButtonLink: string;
}

function PresentationHeader(props: PresentationHeaderProps) {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { lectureId } = useParams<Record<string, string>>();
    const lecturesQuery = useLectures();
    const lecture: Lecture | undefined = lecturesQuery.data?.map[lectureId];
    const progress = useProgressForLecture(Number(lectureId));
    const { classes } = useStyles();

    if (lecturesQuery.isLoading || lecturesQuery.isIdle)
        return (
            <Loader />
        )

    if (lecturesQuery.isError || !lecture)
        return (
            <>Error</>
        );

    return (
        <Group sx={{ height: "100%" }}>
            <Grid columns={3} sx={{ width: "100%", height: "100%" }} align="center" m={0}>
                <Col span="auto" sx={{ height: "100%", }} p={0}>
                    <Group sx={{ height: "100%" }} noWrap>
                        <Button
                            component={Link}
                            to={props.backButtonLink}
                            variant="light"
                            className={classes.button}
                            leftIcon={<ChevronLeft color={theme.colors.bluegray[5]} />}
                        >
                            {t("common:buttons.back")}
                        </Button>
                        <LectureProgressRing
                            size={37}
                            thickness={6}
                            progress={progress}
                            hideText
                        />
                        <Divider my={10} size="sm" color="bluegray.3" orientation="vertical" />

                        <Title order={3} sx={(theme) => ({ color: theme.colors.bluegray[6] })}>
                            {lecture?.Names[fetchLang()] || t("common:misc.untitled")}
                        </Title>
                    </Group>
                </Col>
                <Col span="content" sx={{ height: "100%" }} p={0}>
                    <Group sx={{ height: "100%" }} position="right">
                        <Button
                            onClick={props.toggleFullscreen}
                            className={classes.button}
                            pl={15}
                            leftIcon={<Maximize color={theme.colors.bluegray[5]} width={18} strokeWidth={2} />}
                        >
                            {t("academy.presentation.fullscreen.enter")}
                        </Button>
                    </Group>
                </Col>
            </Grid>
        </Group>
    )
}

export default PresentationHeader