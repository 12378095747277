import { Button, Center, Group, Loader, ScrollArea, Stack, Switch, Text } from "@mantine/core";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useGetAllSchools } from "../../../../../api/admin/allSchools";
import { Course } from "../../../../../types/course.types";
import CourseCard from "../../../../global/Cards/Course/CourseCard";
import { useGetAllOrganizations } from "../../../Admin/Organizations/Organizations.api";
import { useCourseAccessControls, useCourseRestrictionMutation } from "../../Courses.api";
import OrganizationAccess from "./components/OrganizationAccess";

interface CourseAccessProps {
    course: Course;
    onClose: () => void;
}
function CourseAccess(props: CourseAccessProps) {
    const organizationsQuery = useGetAllOrganizations();
    const schoolsQuery = useGetAllSchools();
    const courseAccessQuery = useCourseAccessControls(props.course.UUID);

    const mutation = useCourseRestrictionMutation();

    const [schoolsWithAccess, setSchoolsWithAccess] = useState<number[]>(courseAccessQuery.data?.Schools ?? []);
    const isEdited = !isEqual(courseAccessQuery.data?.Schools ?? [], schoolsWithAccess);

    useEffect(() => {
        if (courseAccessQuery.isSuccess) setSchoolsWithAccess(courseAccessQuery.data.Schools)
    }, [courseAccessQuery.isSuccess, courseAccessQuery.data]);

    const restrictedAccess = schoolsWithAccess.length > 0;

    function onSave() {
        mutation.mutate({
            courseUuid: props.course.UUID,
            schoolIds: schoolsWithAccess
        })
    }

    function toggleRestrictedAccess() {
        if (!restrictedAccess) {
            setSchoolsWithAccess([Number(localStorage.getItem("school_id"))])
        } else {
            setSchoolsWithAccess([]);
        }
    }

    if (courseAccessQuery.isLoading || organizationsQuery.isLoading || schoolsQuery.isLoading) return (<Loader />)

    return (
        <Stack>
            <Center
                sx={(theme) => ({
                    borderRadius: 5,
                    backgroundColor: theme.colors.bluegray[3],
                    pointerEvents: "none"
                })}
                p="md"
            >
                <CourseCard course={props.course} />
            </Center>
            <Switch checked={restrictedAccess} onChange={toggleRestrictedAccess} label="Begränsa till utvalda skolor" />
            {restrictedAccess && (
                <ScrollArea h={700}>
                    {organizationsQuery.data?.list.map(organization => (
                        <OrganizationAccess
                            key={organization.ID}
                            organization={organization}
                            publicCourseUuid={props.course.PublicUUID}
                            createdBySchoolId={props.course.OwnerId}
                            schoolsWithAccess={schoolsWithAccess}
                            addSchoolIds={(ids: number[]) => setSchoolsWithAccess(curr => [...curr, ...ids])}
                            removeSchoolIds={(ids: number[]) => setSchoolsWithAccess(curr => [...curr.filter(cid => !ids.some(id => id === cid))])}
                        />
                    ))}
                </ScrollArea>
            )}
            <Group position="right">
                <div>
                    {mutation.isError && (<Text weight="bold" size="sm" color="red.8">Något gick fel, försök igen</Text>)}
                    {mutation.isSuccess && (<Text weight="bold" size="sm" color="green.7">Sparat!</Text>)}
                </div>
                <Group position="right">
                    <Button variant="light" onClick={props.onClose}>Avbryt</Button>
                    <Button onClick={onSave} loading={mutation.isLoading} disabled={!isEdited}>
                        {mutation.isLoading ? "Sparar..." : "Spara"}
                    </Button>
                </Group>
            </Group>
        </Stack>
    );
}

export default CourseAccess
