import styled from "styled-components";
import lakeLinear from "../../../resources/illustrations/lake-linear.svg";

export const Wrapper = styled.div`
    padding-top: 50px;
    display: flex;
    align-items: center;
    min-height: 100vh;
    button, a button {
        margin-top: 20px;
    }
    h1 {
        margin-top: 10px;
    }
    form {
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            max-width: 350px;
        }
    }
    > div {
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            width: 50%;
        }
        :nth-child(2) {
            display: none;
            @media (min-width: ${props => props.theme.breakpoints.mobile}) {
                display: initial;
            }
        }
    }
    .contactPerson {
        margin-top: 30px;
    }
`;

export const VehicleWrapper = styled.div`
    background-image: url(${lakeLinear});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
`;

export const Checks = styled.div`
    margin: 20px 0;
    .check {
        display: flex;
        margin-bottom: 10px;
        img {
            width: 13px;
            height: 17px;
            margin-top: 2px;
            margin-right: 10px;
        }
    }
`;
