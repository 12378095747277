import { localStorageGet } from "../../../helpers/localstorage.helper";
import { IllustrationCourses, IllustrationAddMembers, IllustrationCreateNew, IllustrationSimulator } from "../../global/Misc/Symbols.components";
import { Logo } from "../Login/Login.components";
import { Section, WelcomeWrapper } from "./Welcome.styled.components";
import { useTranslation } from 'react-i18next';
import { Button } from "../../global/Misc/Misc.components";

interface WelcomeGreetingProps {
    onClickOK: () => void;
}

export const WelcomeGreeting = (props: WelcomeGreetingProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Logo />
            <WelcomeWrapper>
                <div className="top">
                    <h1 className="huge">{t('welcome.title', { name: localStorageGet("first_name") })}</h1>
                    <h2>{t('welcome.description')}</h2>
                </div>
                <Section>
                    <IllustrationCreateNew />
                    <div className="content">
                        <h2>{t('welcome.sections.one.title')}</h2>
                        <p className="description">{t('welcome.sections.one.description')}</p>
                    </div>
                </Section>
                <Section>
                    <IllustrationAddMembers />
                    <div className="content">
                        <h2>{t('welcome.sections.two.title')}</h2>
                        <p className="description">{t('welcome.sections.two.description')}</p>
                    </div>
                </Section>
                <Section>
                    <IllustrationCourses />
                    <div className="content">
                        <h2>{t('welcome.sections.three.title')}</h2>
                        <p className="description">{t('welcome.sections.three.description')}</p>
                    </div>
                </Section>
                <Section>
                    <IllustrationSimulator />
                    <div className="content">
                        <h2>{t('welcome.sections.four.title')}</h2>
                        <p className="description">{t('welcome.sections.four.description')}</p>
                    </div>
                </Section>
                <Button onClick={props.onClickOK}>{t('welcome.button')}</Button>
            </WelcomeWrapper>
        </>
    );
}
