import { Aside, ScrollArea, Text, Stack, Transition, Card, Title, Button, useMantineTheme } from "@mantine/core";
import { IconPlayerPlay } from "@tabler/icons";
import { Link } from "react-router-dom";
import { localStorageGet } from "../../../../helpers/localstorage.helper";
import { ACADEMY_PATH } from "../../../../navigation/Routes";
import { Lecture } from "../../../../types/lecture.types";
import { IconClap } from "../../../global/Misc/Symbols.components";
import AsideListItem from "./components/AsideListItem";

interface AcademyAsideProps {
    width: number;
    allChecked?: boolean;
    lecture: Lecture;
    currentId: number;
}

function AcademyAside(props: AcademyAsideProps) {
    const theme = useMantineTheme();

    return (
        <Aside
            width={{ base: props.width }}
            pb={50}
            height="100%"
            sx={(theme) => ({
                backgroundColor: theme.white,
            })}
            withBorder={false}
        >
            <ScrollArea>
                <Stack
                    spacing={2}
                    p={2}
                    sx={(theme) => ({
                        backgroundColor: theme.colors.bluegray[0]
                    })}
                >
                    {props.lecture.Segments?.list.map((segment, index) => (
                        <AsideListItem currentId={props.currentId} segment={segment} key={segment.UUID} index={index} />
                    ))}
                </Stack>
                <Transition
                    mounted={props.allChecked ?? false}
                    transition="fade"
                    duration={400}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <Stack align="center" spacing="xs" mt={45} style={styles}>
                            <Card
                                sx={{
                                    width: "90%",
                                }}
                                py={60}
                            >
                                <Stack spacing="xs" align="center">
                                    <IconClap color={theme.colors.green[6]} />
                                    <Title order={2}>{localStorageGet("first_name")}, bra jobbat!</Title>
                                    <Text mx={60} color="bluegray.6" align="center">Nu är denna avklarad, hoppa på nästa.</Text>
                                    <Stack spacing="xs" mt="xl" align="center">
                                        <Button variant="light" leftIcon={<IconPlayerPlay width={15} />}>
                                            10 tips för handledning
                                        </Button>
                                        <Button
                                            component={Link}
                                            to={ACADEMY_PATH}
                                            variant="subtle"
                                            sx={(theme) => ({ color: theme.colors.dark[5] })}
                                            size="sm"
                                        >
                                            Avsluta
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Stack>
                    )}
                </Transition>
            </ScrollArea>
        </Aside>
    )
}

export default AcademyAside;
