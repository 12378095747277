import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiGet, apiPut } from "../../../helpers/requests.helper";
import { fetchLang, isAdminUserOnSkillsterOrganization } from "../../../helpers/universal.helper";

interface SetCourseRestrictionMutation {
  courseUuid: string;
  schoolIds: number[];
}

export function useCourseRestrictionMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    "set-access-controls",
    (mutation: SetCourseRestrictionMutation) => {
      return apiPut("/course/set-access-controls", {
        UUID: mutation.courseUuid,
        Schools: mutation.schoolIds,
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["courses"]);
      },
    }
  );
}

interface CourseAccessControlsResponse {
  CourseID: number;
  PublicCourseUUID: string;
  Schools: number[];
}

export function useCourseAccessControls(courseUuid: string) {
  return useQuery<CourseAccessControlsResponse, Error>(["course-access-control", courseUuid], () => {
    return apiGet("/course/access-controls", {
      language: fetchLang(),
      uuid: courseUuid,
    });
  });
}

type CourseAccess = {
  SchoolID: number;
  PublicCourseUUID: string;
};

export function useAllCourseAccesses() {
  const enabled = isAdminUserOnSkillsterOrganization();
  return useQuery<CourseAccess[], Error>(
    ["all-access-controls"],
    () => {
      return apiGet("/course/all-access-controls", {});
    },
    { enabled }
  );
}
