import { MantineTheme, MantineThemeOverride } from "@mantine/core";

const textInputStyles = (theme: MantineTheme) => ({
  root: {
    "mantine-TextInput-error": {
      color: "black",
    },
  },
  input: {
    height: 40,
    borderWidth: 2,
    borderColor: theme.fn.lighten(theme.colors.bluegray[4], 0.3),
    ":hover": {
      ":not(:focus)": {
        borderColor: theme.colors.bluegray[5],
      },
    },
    "&.mantine-TextInput-invalid": {
      borderColor: theme.colors.orange[2],
      backgroundColor: theme.colors.orange[0],
    },
    "::placeholder": {
      fontWeight: 600,
    },
    ":disabled": {
      backgroundColor: theme.colors.bluegray[3],
      color: theme.colors.dark[9],
      opacity: 1,
    },
  },
  defaultVariant: {
    border: `2px solid ${theme.colors.bluegray[3]}`,
  },
  filledVariant: {
    borderWidth: 2,
    backgroundColor: theme.colors.bluegray[1],
  },
  label: {
    color: theme.colors.bluegray[5],
    fontWeight: 700,
  },
});

export const skillsterMantineTheme: MantineThemeOverride = {
  components: {
    Progress: {
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.bluegray[0],
        },
        bar: {
          backgroundColor: theme.colors.blue[5],
        },
      }),
    },
    Drawer: {
      styles: (theme) => ({
        title: {
          fontFamily: theme.headings.fontFamily,
        },
      }),
    },
    Textarea: {
      styles: (theme) => ({
        input: {
          borderWidth: 2,
          borderColor: theme.fn.lighten(theme.colors.bluegray[4], 0.3),
          ":hover": {
            ":not(:focus)": {
              borderColor: theme.colors.bluegray[5],
            },
          },
          "&.mantine-TextArea-invalid": {
            borderColor: theme.colors.orange[2],
            backgroundColor: theme.colors.orange[0],
          },
          "::placeholder": {
            fontWeight: 600,
          },
          ":disabled": {
            backgroundColor: theme.colors.bluegray[3],
          },
        },
      }),
    },
    TextInput: {
      styles: textInputStyles,
    },
    DatePicker: {
      styles: textInputStyles,
    },
    NumberInput: {
      styles: textInputStyles,
    },
    PasswordInput: {
      styles: textInputStyles,
    },
    // PasswordInput: {
    //     styles: (theme) => ({
    // root: {
    //     'mantine-TextInput-error': {
    //         color: "black"
    //     }
    // },
    // input: {
    //     height: 40,
    //     '&.mantine-PasswordInput-invalid': {
    //         borderColor: theme.colors.orange[2],
    //         backgroundColor: theme.colors.orange[0]
    //     },
    // },
    // defaultVariant: {
    //     border: `1px solid ${theme.colors.bluegray[3]}`,
    // },
    // filledVariant: {
    //     backgroundColor: theme.colors.bluegray[1],
    // },
    // label: {
    //     color: theme.colors.bluegray[5],
    //     fontWeight: 700
    // }
    //     })
    // },
    Select: {
      styles: (theme) => ({
        input: {
          minHeight: 40,
          border: `1px solid ${theme.colors.bluegray[3]}`,
        },
        hovered: {
          backgroundColor: theme.colors.bluegray[0],
        },
      }),
    },
    MultiSelect: {
      styles: (theme) => ({
        input: {
          minHeight: 40,
          border: `1px solid ${theme.colors.bluegray[3]}`,
        },
        hovered: {
          backgroundColor: theme.colors.bluegray[0],
        },
        value: {
          backgroundColor: theme.colors.bluegray[0],
          fontWeight: 600,
        },
      }),
    },
    Pagination: {
      styles: (theme) => ({
        item: {
          border: `1px solid ${theme.colors.bluegray[3]}`,
          ":hover:not(.mantine-Pagination-active)": {
            backgroundColor: theme.colors.blue[0],
          },
        },
      }),
    },
    SegmentedControl: {
      styles: (theme) => ({
        label: {
          fontFamily: theme.headings.fontFamily,
          fontWeight: "bold",
          fontSize: theme.fontSizes.md,
          color: theme.colors.bluegray[5],
        },
        labelActive: {
          color: theme.colors.bluegray[0],
        },
        root: {
          backgroundColor: theme.colors.bluegray[1],
        },
      }),
    },
    RangeCalendar: {
      styles: (theme) => ({
        day: {
          fontWeight: 600,
        },
        input: {
          minHeight: 40,
          border: `1px solid ${theme.colors.bluegray[3]}`,
        },
        dropdown: {
          border: `1px solid ${theme.colors.bluegray[3]}`,
        },
        calendarHeaderLevel: {
          fontFamily: theme.headings.fontFamily,
          fontSize: theme.headings.sizes.h5.fontSize,
        },
        weekend: {
          color: `${theme.colors.gray[7]}!important`,
        },
      }),
    },
    Menu: {
      styles: (theme) => ({
        dropdown: {
          border: 0,
        },
        item: {
          fontWeight: "bold",
          color: theme.colors.blue[6],
          ":hover": {
            color: theme.colors.blue[5],
            backgroundColor: theme.colors.bluegray[0],
          },
          // fontFamily: theme.headings.fontFamily,
          // fontSize: theme.fontSizes.md,
          // color: theme.colors.dark[4],
          // ':hover': {
          //     backgroundColor: theme.colors.bluegray[0]
          // }
        },
      }),
    },
    Divider: {
      styles: (theme) => ({
        label: {
          fontSize: theme.fontSizes.sm,
        },
      }),
    },
    Notification: {
      styles: (theme) => ({
        root: {
          border: 0,
          paddingLeft: 0,
          boxShadow: theme.shadows.xl,
        },
        icon: {
          marginRight: 14,
          backgroundColor: `${theme.colors.blue[5]}!important`,
        },
        body: {},
        title: {
          fontFamily: theme.fontFamily,
          fontSize: 14,
          // fontWeight: theme.headings.fontWeight,
          color: theme.colors.bluegray[6],
          marginBottom: 0,
        },
        description: {
          fontFamily: theme.fontFamily,
          fontSize: 14,
          color: theme.colors.bluegray[5],
          fontWeight: 600,
        },
      }),
    },
    Button: {
      styles: (theme) => ({
        root: {
          fontFamily: "Rajdhani",
          fontWeight: 800,
          fontSize: 14,
        },
        filled: {
          ":disabled": {
            backgroundColor: `${theme.colors.bluegray[1]}!important`,
            color: `${theme.colors.bluegray[5]}!important`,
          },
        },
        default: {
          borderColor: theme.colors.bluegray[3],
          color: theme.colors.blue[5],
          ":hover": {
            backgroundColor: theme.colors.bluegray[0],
          },
        },
        subtle: {
          ".mantine-Button-label": {
            textDecoration: "underline",
          },
        },
        light: {
          backgroundColor: theme.colors.bluegray[0],
          ":hover": {
            backgroundColor: theme.colors.blue[1],
          },
        },
        white: {
          ":hover": {
            backgroundColor: theme.colors.blue[0],
          },
        },
        leftIcon: {
          marginLeft: -4,
        },
      }),
    },
    Anchor: {
      styles: (theme) => ({
        root: {
          color: theme.colors.blue[5],
          ":hover": {
            textDecoration: "underline",
          },
        },
      }),
    },
    Modal: {
      defaultProps: {
        exitTransitionDuration: 200,
      },
      styles: () => ({
        title: {
          fontFamily: "Rajdhani",
          fontWeight: 700,
          fontSize: 22,
        },
      }),
    },
    Title: {
      styles: () => ({
        root: {
          // fontWeight: "bold",
        },
      }),
    },
    Checkbox: {
      styles: (theme) => ({
        input: {
          borderWidth: 2,
          cursor: "pointer",
          borderColor: theme.colors.bluegray[4],
        },
        label: {
          cursor: "pointer",
          fontWeight: "bold",
          color: theme.colors.bluegray[6],
        },
      }),
    },
    Switch: {
      styles: (theme) => ({
        root: {},
        input: {
          cursor: "pointer",
          "&:checked": {
            backgroundColor: theme.colors.blue[5],
            borderColor: theme.colors.blue[5],
          },
        },
        label: {
          cursor: "pointer",
          fontFamily: theme.headings.fontFamily,
          // fontWeight: theme.headings.fontWeight,
          fontSize: theme.headings.sizes.h6.fontSize,
          color: theme.colors.bluegray[6],
          paddingLeft: 10,
        },
      }),
    },
    Popover: {
      styles: () => ({
        dropdown: {
          // padding: 0,
          border: 0,
        },
        arrow: {},
      }),
    },
    Skeleton: {
      styles: (theme) => ({
        visible: {
          "::after": {
            backgroundColor: theme.colors.blue[0],
          },
        },
      }),
    },
    Tooltip: {
      styles: (theme) => ({
        tooltip: {
          backgroundColor: "white",
          color: theme.colors.bluegray[6],
          boxShadow: theme.shadows.md,
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: theme.fontSizes.sm,
        },
      }),
    },
  },
  headings: {
    fontFamily: "Rajdhani",
    sizes: {
      h1: { fontSize: 34, lineHeight: "40px" },
      h2: { fontSize: 26, lineHeight: "31px" },
      h3: { fontSize: 22, lineHeight: "26px" },
      h4: { fontSize: 18, lineHeight: "21px" },
      h5: { fontSize: 16, lineHeight: "19px" },
      h6: { fontSize: 14, lineHeight: "17px" },
    },
  },
  fontFamily: "Open Sans",
  fontSizes: {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 18,
  },
  colors: {
    blue: [
      "#DEE6F7",
      "#C2D1F5",
      "#9CB7F1",
      "#769CED",
      "#5991F2",
      "#3B86F7",
      "#3670D6",
      "#3059B4",
      "#2C4988",
      "#28385C",
    ],
    green: [
      "#D2F1E5",
      "#A2EFD9",
      "#72E7C5",
      "#42DFB0",
      "#21DBB0",
      "#00D6AF",
      "#08C79A",
      "#0FB885",
      "#128F6A",
      "#14654E",
    ],
    pink: [
      "#FFE0E4",
      "#FAE0E0",
      "#F7D2D2",
      "#F3C3C3",
      "#F9A2A9",
      "#FF808F",
      "#F16A7F",
      "#E3546E",
      "#A83A4C",
      "#6C2029",
    ],
    yellow: [
      "#F6F4C2",
      "#F6EEA6",
      "#F4EB99",
      "#F1E78B",
      "#F6E761",
      "#FAE636",
      "#DBC81C",
      "#BBA902",
      "#9E8A01",
      "#806B00",
    ],
    grape: [
      "#F1E8FC",
      "#EBE1F7",
      "#D5C6F5",
      "#BEABF3",
      "#BB96F8",
      "#B781FC",
      "#965DDF",
      "#7538C2",
      "#5C2D98",
      "#43216D",
    ],
    bluegray: [
      "#EFF3F8",
      "#E7EDF5",
      "#E1E7F1",
      "#DAE1EC",
      "#A6B0C2",
      "#717F98",
      "#545F73",
      "#373E4D",
      "#303646",
      "#282E3F",
    ],
    gray: [
      "#EEEEEE",
      "#D6D6D6",
      "#C5C5C5",
      "#B4B4B4",
      "#969696",
      "#777777",
      "#676767",
      "#575757",
      "#454545",
      "#333333",
    ],
    orange: [
      "#F7EFDE",
      "#F5E4C2",
      "#F1D59C",
      "#EDC576",
      "#F2BF59",
      "#F7B83B",
      "#D6A136",
      "#B48830",
      "#88692C",
      "#5C4B28",
    ],
    dark: [
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5C5F66",
      "#373A40",
      "#2C2E33",
      "#25262B",
      "#1A1B1E",
      "#141517",
      "#101113",
    ],
    purple: [
      "#F1E8FC",
      "#EBE1F7",
      "#D5C6F5",
      "#BEABF3",
      "#BB96F8",
      "#B781FC",
      "#965DDF",
      "#7538C2",
      "#5C2D98",
      "#43216D",
    ],
  },
};
