import { Box, Grid, Group } from "@mantine/core";

interface GroupRowColumnsProps {
    columns: [JSX.Element, JSX.Element, JSX.Element, JSX.Element, JSX.Element, JSX.Element];
}

function UsersRowColumns(props: GroupRowColumnsProps) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    width: 60,
                }}
                px="xs"
            >
                <Group noWrap>{props.columns[0]}</Group>
            </Box>
            <Grid columns={54} align="center" p={8} px="xs" sx={{ flexGrow: 1, }}>
                <Grid.Col span={13}>
                    {props.columns[1]}
                </Grid.Col>
                <Grid.Col span={11}>
                    {props.columns[2]}
                </Grid.Col>
                <Grid.Col span={9}>
                    {props.columns[3]}
                </Grid.Col>
                <Grid.Col span={7}>
                    {props.columns[4]}
                </Grid.Col>
                <Grid.Col span={14}>
                    {props.columns[5]}
                </Grid.Col>
            </Grid>
        </Box>
    )
}

export default UsersRowColumns;