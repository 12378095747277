import { Anchor, Box, Button, Center, Group, MultiSelect, Overlay, Paper, Popover, Stack, Text, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { IconCopy, IconDots, IconLock, IconPencil, IconShare, IconUserPlus, IconX } from "@tabler/icons";
import { useEffect, useState } from "react";
import { Repeat, User } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useGetAllSchools } from "../../../../api/admin/allSchools";
import { useDeleteCourseMutation, useDuplicateCourseMutation } from "../../../../api/courses";
import { useGetUsersFromSchool } from "../../../../api/usersFromSchool";
import { localStorageGet } from "../../../../helpers/localstorage.helper";
import { dateObjectToReadableWithYear, safariDateFix } from "../../../../helpers/time.helper";
import { fetchLang, isAdminUser, isAdminUserOnSkillsterOrganization } from "../../../../helpers/universal.helper";
import { COURSES_CUSTOM_PATH, COURSE_EDITOR_PATH } from "../../../../navigation/Routes";
import { dropdownTransition } from "../../../../resources/styles/animations";
import { Course } from "../../../../types/course.types";
import { Lock } from "../../../../types/editor.types";
import CourseAccess from "../../../pages/Courses/components/CourseAccess";
import { useAddCategoryToCourseMutation, useGetCourseCategories, useRemoveCategoryFromCourseMutation } from "../../CourseCategories/CourseCategories.api";
import CatalogCourseCard from "./CourseCardCatalog";
import CopyToSchool from "./components/CopyToSchool";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

export const IndividualCourseSymbol = () => {
    const theme = useMantineTheme();
    return (
        <Tooltip label="Detta är en extrakurs" openDelay={500}>
            <Box
                sx={(theme) => ({
                    width: 26,
                    height: 26,
                    borderRadius: "100%",
                    backgroundColor: theme.colors.bluegray[0],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                })}
            >
                <User color={theme.colors.blue[5]} strokeWidth={3} width={14} />
            </Box>
        </Tooltip>
    )
}


interface DropdownMenuProps {
    course: Course;
    setHover: (hovered: boolean) => void;
    setMenuOpen: (open: boolean) => void;
    open?: boolean;
    lock?: Lock;
}
export const DropdownMenu = (props: DropdownMenuProps) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { hovered, ref } = useHover();

    const modals = useModals();
    const usersQuery = useGetUsersFromSchool();
    const organizationState = useSelector((state: RootState) => state.organization);
    const { schools } = organizationState;
    useGetAllSchools();

    const deleteCourseMutation = useDeleteCourseMutation();
    const duplicateCourseMutation = useDuplicateCourseMutation();

    const onDuplicate = () => duplicateCourseMutation.mutate({ uuid: props.course.UUID, publicUuid: props.course.PublicUUID });

    const locked = props.lock && (props.lock.CreatedBy.toString() !== localStorageGet("user_id"));
    const createdByUser = usersQuery.isSuccess ? usersQuery.data.map[props.course.PublishedBy] : undefined;

    function onOpenAccess() {
        modals.openModal({
            title: "Välj access",
            children: <CourseAccess course={props.course} onClose={() => modals.closeAll()} />
        });
    }

    const onDelete = () => deleteCourseMutation.mutate({ uuid: props.course.UUID, publicUuid: props.course.PublicUUID });

    const openConfirmModal = () => {
        const id = modals.openModal({
            styles: ({
                title: {
                    fontSize: theme.headings.sizes.h3.fontSize,
                }
            }),
            withCloseButton: false,
            size: 300,
            padding: "sm",
            children: (
                <Stack>

                    <Stack
                        sx={(theme) => ({
                            borderRadius: theme.radius.sm,
                            backgroundColor: theme.colors.bluegray[1]
                        })}
                        pb="sm"
                    >
                        <Group position="center" py="sm" sx={(theme) => ({ position: "relative" })}>
                            <Overlay opacity={0} zIndex={5} />
                            <Stack>
                                <Paper shadow="xl">
                                    <CatalogCourseCard disableDropdown course={props.course} locks={[]} customCourse={!props.course.Native} />
                                </Paper>
                            </Stack>
                        </Group>
                    </Stack>

                    <Stack spacing="xs">
                        <Title order={3} sx={{ textAlign: "center" }}>{t("courses.deleteCourse.confirmationModal.title")}</Title>
                        {createdByUser ? (
                            <Center>
                                <Text size="sm" sx={(theme) => ({ color: theme.colors.bluegray[5] })}>
                                    {t("courses.deleteCourse.confirmationModal.description", { name: `${createdByUser.FirstName} ${createdByUser.LastName}`, date: dateObjectToReadableWithYear(new Date(safariDateFix(props.course.Published))) })}
                                </Text>
                            </Center>
                        ) : null}
                    </Stack>

                    <Stack spacing="xs">
                        <Button
                            variant="filled"
                            sx={(theme) => ({ backgroundColor: theme.colors.red[6], ':hover': { backgroundColor: theme.colors.red[9] } })}
                            onClick={() => { onDelete(); modals.closeModal(id); }}
                            loading={deleteCourseMutation.isLoading}
                        >
                            {t("common:buttons.delete")}
                        </Button>
                        <Button variant="subtle" onClick={() => modals.closeModal("openConfirmModal")}>{t("common:buttons.cancel")}</Button>
                    </Stack>

                </Stack>
            ),
            centered: true,
        });
    }
    const openCopyToSchool = (course: Course) => {
        const id = modals.openModal({
            styles: ({
                title: {
                    fontSize: theme.headings.sizes.h3.fontSize,
                }
            }),
            withCloseButton: false,
            size: 300,
            padding: "sm",
            children: (
                <CopyToSchool course={course} onClose={() => modals.closeModal(id)} />
            ),
            centered: true,
        });
    }

    const openCategoryModal = () => {
        modals.openModal({
            styles: ({
                title: {
                    fontSize: theme.headings.sizes.h3.fontSize,
                }
            }),
            withCloseButton: false,
            size: 300,
            padding: "sm",
            children: (
                <CategoriesModalContent course={props.course} />
            ),
            centered: true,
        });
    }

    const [opened, setOpened] = useState(false);

    useEffect(() => props.setHover(hovered), [hovered]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => props.setMenuOpen(opened), [opened]); // eslint-disable-line react-hooks/exhaustive-deps

    if (duplicateCourseMutation.isSuccess)
        return (
            <Redirect to={{
                pathname: COURSES_CUSTOM_PATH,
                state: {
                    scrollToFreeCourses: !props.course.IsLinear
                }
            }} />
        )

    return (

        <Popover
            transition={dropdownTransition}
            opened={props.open}
            onChange={props.setMenuOpen}
            withinPortal
            shadow="md"
            position="bottom-end"
        >
            <Popover.Target>
                <Box
                    ref={ref}
                    onClick={() => setOpened((o) => !o)}
                    sx={(theme) => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: 25,
                        witdh: 25,
                        height: 25,
                        backgroundColor: theme.colors.bluegray[0],
                        borderRadius: "100%",
                        ':hover': {
                            backgroundColor: props.course.Native ? theme.colors.bluegray[2] : "white",
                        }
                    })}
                >
                    <IconDots color={theme.colors.blue[5]} width={14} strokeWidth={2.5} />
                </Box>
            </Popover.Target>
            <Popover.Dropdown p={0} sx={{ overflow: "hidden" }} onClick={() => props.setMenuOpen(false)}>
                <Stack spacing={0}>
                    {(locked && props.lock) ? (
                        <>
                            <Overlay radius="sm" zIndex={2} opacity={0.5} color={"#000"} blur={1} />
                            <Group sx={{ zIndex: 3, position: "absolute", width: "100%", height: "100%", cursor: "not-allowed" }} position="center" px={0}>
                                <Stack align="center">
                                    <IconLock color="white" />
                                    <Title order={4} sx={{ cursor: "not-allowed", color: "white" }} px={20}>
                                        {
                                            usersQuery.data?.map[props.lock?.CreatedBy] ?
                                                t("courses.isEditingTooltip", { name: usersQuery.data?.map[props.lock?.CreatedBy].FirstName }) :
                                                t("courseEditor.courseIsLocked.title")
                                        }
                                    </Title>
                                </Stack>
                            </Group>
                        </>
                    ) : null}
                    <Group
                        spacing="xs"
                        px="md"
                        py="xs"
                        sx={(theme) => ({
                            cursor: "pointer",
                            ':hover': {
                                backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                            }
                        })}
                        onClick={onDuplicate}
                    >
                        <Box
                            sx={(theme) => ({
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: 25,
                                witdh: 25,
                                height: 25,
                                backgroundColor: theme.colors.blue[0],
                                borderRadius: "100%",
                            })}
                        >
                            <IconCopy color={theme.colors.blue[5]} width={14} strokeWidth={2.5} />
                        </Box>
                        <Text size="sm" weight="bold" color="bluegray.8">{t("courses.dropDown.duplicate")}</Text>
                    </Group>
                    {(props.course.Native && isAdminUser()) ? (
                        <Group
                            spacing="xs"
                            px="md"
                            py="xs"
                            sx={(theme) => ({
                                cursor: "pointer",
                                ':hover': {
                                    backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                                }
                            })}
                            onClick={onOpenAccess}
                        >
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: 25,
                                    witdh: 25,
                                    height: 25,
                                    backgroundColor: theme.colors.blue[0],
                                    borderRadius: "100%",
                                })}
                            >
                                <IconUserPlus color={theme.colors.blue[5]} width={12} strokeWidth={3.2} />
                            </Box>
                            <Text size="sm" weight="bold" color="bluegray.8">{t("courses.dropDown.access")}</Text>
                        </Group>
                    ) : null}
                    {(!props.course.Native && schools.length > 1) ? (
                        <Group
                            spacing="xs"
                            px="md"
                            py="xs"
                            sx={(theme) => ({
                                cursor: "pointer",
                                ':hover': {
                                    backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                                }
                            })}
                            onClick={() => openCopyToSchool(props.course)}
                        >
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: 25,
                                    witdh: 25,
                                    height: 25,
                                    backgroundColor: theme.colors.blue[0],
                                    borderRadius: "100%",
                                })}
                            >
                                <IconShare color={theme.colors.blue[5]} width={12} strokeWidth={3.2} />
                            </Box>
                            <Text size="sm" weight="bold" color="bluegray.8">
                                {t("courses.dropDown.copyToSchool")}
                            </Text>
                        </Group>
                    ) : null}
                    {!props.course.Native ?
                        (
                            <Anchor
                                component={Link}
                                to={`${COURSE_EDITOR_PATH}/${props.course.PublicUUID}`}
                            >
                                <Group
                                    spacing="xs"
                                    px="md"
                                    py="xs"
                                    sx={(theme) => ({
                                        cursor: "pointer",
                                        ':hover': {
                                            backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                                        }
                                    })}
                                >
                                    <Box
                                        sx={(theme) => ({
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minWidth: 25,
                                            witdh: 25,
                                            height: 25,
                                            backgroundColor: theme.colors.blue[0],
                                            borderRadius: "100%",
                                        })}
                                    >
                                        <IconPencil color={theme.colors.blue[5]} width={15} strokeWidth={2.8} />
                                    </Box>
                                    <Text size="sm" weight="bold" color="bluegray.8">{t("courses.dropDown.edit")}</Text>
                                </Group>
                            </Anchor>
                        ) : null}

                    {!props.course.Native ? (
                        <Group
                            spacing="xs"
                            px="md"
                            py="xs"
                            sx={(theme) => ({
                                cursor: "pointer",
                                ':hover': {
                                    backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                                }
                            })}
                            onClick={openConfirmModal}
                        >
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: 25,
                                    witdh: 25,
                                    height: 25,
                                    backgroundColor: theme.colors.red[0],
                                    borderRadius: "100%",
                                })}
                            >
                                <IconX color={theme.colors.red[5]} width={12} strokeWidth={3.2} />
                            </Box>
                            <Text size="sm" weight="bold" color="bluegray.8">{t("courses.dropDown.delete")}</Text>
                        </Group>
                    ) : null}

                    {(isAdminUserOnSkillsterOrganization() && props.course.Native) ? (
                        <Group
                            spacing="xs"
                            px="md"
                            py="xs"
                            sx={(theme) => ({
                                cursor: "pointer",
                                ':hover': {
                                    backgroundColor: theme.fn.lighten(theme.colors.blue[1], .9)
                                }
                            })}
                            onClick={openCategoryModal}
                        >
                            <Box
                                sx={(theme) => ({
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: 25,
                                    witdh: 25,
                                    height: 25,
                                    backgroundColor: theme.colors.blue[0],
                                    borderRadius: "100%",
                                })}
                            >
                                <Repeat color={theme.colors.blue[5]} width={12} strokeWidth={3.2} />
                            </Box>
                            <Text size="sm" weight="bold" color="bluegray.8">Kategorier</Text>
                        </Group>
                    ) : null}
                </Stack>
            </Popover.Dropdown>
        </Popover>
    )
}

interface CategoriesModalContentProps {
    course: Course;
}

function CategoriesModalContent(props: CategoriesModalContentProps) {

    const { t } = useTranslation();
    const modals = useModals();
    const [categories, setCategories] = useState<string[]>([]);
    const [initialCategories, setInitialCategories] = useState<string[] | null>(null);
    const deleteCourseMutation = useDeleteCourseMutation();
    const addCategoryToCourseMutation = useAddCategoryToCourseMutation();
    const removeCategoryFromCourseMutation = useRemoveCategoryFromCourseMutation();

    const courseCategoriesQuery = useGetCourseCategories();

    useEffect(() => {
        if (courseCategoriesQuery.isSuccess) {
            setCategories(
                (courseCategoriesQuery.data?.list.filter(c => c.Courses.some(id => id === props.course.PublicUUID)) ?? []).map(ca => ca.ID.toString())
            );
            if (initialCategories === null)
                setInitialCategories(
                    (courseCategoriesQuery.data?.list.filter(c => c.Courses.some(id => id === props.course.PublicUUID)) ?? []).map(ca => ca.ID.toString())
                );
        }
    }, [courseCategoriesQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    function onSave() {
        const toRemove: string[] = [];
        const toAdd: string[] = [];
        initialCategories?.forEach(initial => {
            if (!categories.some(cat => cat === initial)) {
                toRemove.push(initial)
            }
        })
        categories?.forEach((newCat: string) => {
            if (!initialCategories?.some(cat => cat === newCat)) {
                toAdd.push(newCat)
            }
        });
        toRemove.forEach(id => removeCategoryFromCourseMutation.mutate({ id: Number(id), publicCourseUuid: props.course.PublicUUID }))
        toAdd.forEach(id => addCategoryToCourseMutation.mutate({ id: Number(id), publicCourseUuid: props.course.PublicUUID }))
        modals.closeAll();
    }

    return (
        <Stack>

            <Stack
                sx={(theme) => ({
                    borderRadius: theme.radius.sm,
                    backgroundColor: theme.colors.bluegray[1]
                })}
                pb="sm"
            >
                <Group position="center" py="sm" sx={(theme) => ({ position: "relative" })}>
                    <Overlay opacity={0} zIndex={5} />
                    <Stack>
                        <Paper shadow="xl">
                            <CatalogCourseCard disableDropdown course={props.course} locks={[]} customCourse={!props.course.Native} />
                        </Paper>
                    </Stack>
                </Group>
            </Stack>

            <Stack spacing="xs">
                <Title order={3} sx={{ textAlign: "center" }}>Välj kategorier</Title>
            </Stack>

            <MultiSelect
                data={
                    courseCategoriesQuery.data?.list.map(c => ({
                        label: c.Names.find(n => n.Language === fetchLang())?.Value ?? "",
                        value: c.ID.toString(),
                    })) ?? []
                }
                value={categories}
                onChange={(val) => setCategories(val)}
            />

            <Stack spacing="xs">
                <Button
                    variant="filled"
                    onClick={onSave}
                    loading={deleteCourseMutation.isLoading}
                >
                    {t("common:buttons.save")}
                </Button>
                <Button variant="subtle" onClick={() => modals.closeModal("openConfirmModal")}>{t("common:buttons.cancel")}</Button>
            </Stack>

        </Stack>
    );

}
