import { useSelector } from 'react-redux';
import {
    Route,
    RouteProps,
} from 'react-router-dom';
import PageNotFound from '../components/pages/PageNotFound/PageNotFound';
import NotAllowed from '../components/pages/NotAllowed/NotAllowed';
import { RootState } from '../reducers';
import { ViewConfig } from '../types/user.types';
import { isAdminUser } from '../helpers/universal.helper';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

export const AdminRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isAdminUser() ? (
                    <Component {...routeProps} />
                ) : (
                    <Route component={PageNotFound} />
                )
            }
        />
    );
};

interface PermissionRouteProps extends RouteProps {
    component: any;
    requiredPermission: keyof ViewConfig;
}

export const PermissionRoute = (props: PermissionRouteProps) => {
    const { component: Component, ...rest } = props;
    const permissionsState = useSelector((state: RootState) => state.permissions);
    const viewConfig = permissionsState.loggedInUserViewConfig;
    const isAllowed = viewConfig[props.requiredPermission];

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isAllowed ? (
                    <Component {...routeProps} />
                ) : (
                    <Route component={NotAllowed} />
                )
            }
        />
    );
};

