import { Group, NativeSelect } from "@mantine/core";
import { ChangeEvent } from "react";
import { SortOrder } from "../../../types/sort.types";
import { OrderArrow } from "../Misc/Misc.components";

interface MobileSorterProps {
    data: { label: string, value: string }[];
    label: string;
    order: SortOrder;
    handleChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    handleReverseOrder: () => void;
    my?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const MobileSorter = (props: MobileSorterProps) => {
    return (
        <Group spacing="xs" position="right" my={props.my}>
            <OrderArrow
                order={props.order}
                active={true}
                width={12}
                onClick={props.handleReverseOrder}
            />
            <NativeSelect
                aria-label={props.label}
                data={props.data}
                onChange={props.handleChange}
            />
        </Group>
    )
}