import styled from "styled-components";

export const StatsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-auto-rows: 100px; */
    gap: 10px;
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: ${props => props.theme.border.radius.medium};
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding: 20px;
    }
`;

export const BigNumberWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ChartLoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
`;
