import styled from "styled-components";

export const FooterWrapper = styled.div`
    background-color: ${props => props.theme.colors.comp4.light};
    padding: 50px 0 120px 0;
    margin-top: 30px;
    font-family: "Nunito sans", sans-serif;
    .content {
        min-height: 150px;
        display: flex;
        flex-direction: column;
        @media (min-width: ${props => props.theme.breakpoints.medium}) {
            flex-direction: row;
        }
        .col1 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: ${props => props.theme.colors.comp4.normal};
            flex: 1;
            .logo {
                width: 150px;
            }
            .copyright {
                font-size: 12px;
                margin: 10px 0 30px 0;
            }
            .address {
                display: flex;
                flex-direction: column;
                a {
                    display: block;
                    font-family: "Open sans", sans-serif;
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
        }
        .col2 {
            flex: 2.5;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .menuItems {
                display: flex;
                flex-direction: column;
                margin: 30px 0;
                @media (min-width: ${props => props.theme.breakpoints.medium}) {
                    margin: 0;
                    flex-direction: row;
                    height: 40px;
                    padding-right: 120px;
                    align-items: center;
                }
                justify-content: space-between;
            }
        }
        .col3 {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            strong {
                color: ${props => props.theme.colors.comp4.normal};
            }
            .cookies {
                display: flex;
                color: ${props => props.theme.colors.comp4.normal};
            }
            > div {
                margin-top: 30px;
                @media (min-width: ${props => props.theme.breakpoints.medium}) {
                    margin: 0;
                    justify-content: flex-end;
                }
            }
        }
    }
`;

export const ContactPersonWrapper = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
        width: 52px;
        height: 52px;
        min-width: 52px;
        min-height: 52px;
    }
    .contactInfo {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        line-height: 20px;
        .phone {
            font-weight: 600;
        }
    }
`;