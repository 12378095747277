import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
} from "../../../../helpers/requests.helper";
import {
  Lecture,
  Segment,
  ServerLecture,
  ServerName,
  ServerSegment,
} from "../../../../types/lecture.types";
import {
  convertLectureToServerLecture,
  convertServerLectureToLecture,
} from "./helpers/lecture.helper";

export function useLectures() {
  const queryClient = useQueryClient();
  return useQuery(
    "all-lectures",
    async () => {
      const data: ServerLecture[] = await apiGet("/lecture/get-all");
      const list: Lecture[] = data.map((l) => convertServerLectureToLecture(l));
      const map: Record<string, Lecture> = {};
      list.forEach((l) => {
        map[l.ID] = l;
      });
      return { list, map };
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-lecture-user-progress"),
    }
  );
}

type LectureProgressResponse = Record<
  number,
  {
    UserID: number;
    LectureID: number;
    LectureSegmentID: number;
    CompletionTime: string;
  }[]
>;

export function useLectureProgress() {
  return useQuery<LectureProgressResponse, Error>(
    ["get-lecture-user-progress"],
    async () => {
      const data = await apiGet("/lecture/get-user-progress");
      return data;
    }
  );
}

export function useTotalAcademyProgress() {
  const lectureProgressQuery = useLectureProgress();
  const lecturesQuery = useLectures();

  if (
    lectureProgressQuery.isLoading ||
    lectureProgressQuery.isIdle ||
    lecturesQuery.isLoading ||
    lecturesQuery.isIdle
  )
    return {
      data: { progress: 0 },
      isLoading: true,
      isError: false,
    };

  if (lectureProgressQuery.isError || lecturesQuery.isError)
    return {
      data: { progress: 0 },
      isLoading: false,
      isError: true,
    };

  const totalSegmentsCount = lecturesQuery.data.list.reduce(
    (acc: number, curr: Lecture) => acc + curr.Segments.list.length,
    0
  );

  const totalCompleted = Object.keys(lectureProgressQuery.data).reduce(
    (acc: number, curr: string) => {
      const lecture = lectureProgressQuery.data[Number(curr)];
      return acc + lecture.length;
    },
    0
  );

  const progress = Math.floor((100 * totalCompleted) / totalSegmentsCount);
  return {
    data: { progress },
    isLoading: false,
    isError: false,
  };
}

export function useCreateLecture() {
  return useMutation<Lecture>(() => {
    return apiPost("/lecture/create", {});
  });
}

interface DeleteLectureMutation {
  Id: number;
}

export function useDeleteLectureMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: DeleteLectureMutation) => {
      return apiDelete("/lecture/delete", { Id: mutation.Id });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export function useCreateSegment(LectureID: number) {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return apiPost("/lecture/create-segment", { LectureID });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface AddSegmentProgressMutation {
  segmentID: number;
}

export function useAddSegmentProgress() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: AddSegmentProgressMutation) => {
      return apiPost("/lecture/add-user-progress", {
        LectureSegmentID: mutation.segmentID,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-lecture-user-progress"),
    }
  );
}

export interface RemoveSegmentProgressMutation {
  segmentID: number;
}

export function useRemoveSegmentProgress() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: RemoveSegmentProgressMutation) => {
      return apiDelete("/lecture/delete-user-progress", {
        LectureSegmentID: mutation.segmentID,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-lecture-user-progress"),
    }
  );
}

interface CreateSegmentWithImageProps {
  lectureId: number;
  base64File: string;
  language: string;
}

export function useCreateSegmentWithImage() {
  const queryClient = useQueryClient();
  const uploadSegmentImageMutation = useUploadSegmentImage();
  return useMutation(
    (mutation: CreateSegmentWithImageProps) => {
      return apiPost("/lecture/create-segment", {
        LectureID: mutation.lectureId,
      });
    },
    {
      onSuccess: (data: Segment, mutation) => {
        queryClient.refetchQueries("all-lectures");
        uploadSegmentImageMutation.mutate({
          segmentId: data.ID,
          language: mutation.language,
          imageBase64: mutation.base64File,
        });
      },
    }
  );
}

export interface UpdateLectureMutation {
  ID: number;
  EstimatedTime: number;
  Visibility: number;
  Version: number;
  Names: ServerName[];
  Descriptions: ServerName[];
}

export function useUpdateLecture() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: UpdateLectureMutation) => {
      return apiPut("/lecture/update", {
        ID: mutation.ID,
        EstimatedTime: mutation.EstimatedTime,
        Visibility: mutation.Visibility, // 0 = bara admin, 1 = de med permissions kan se, 2 = alla kan se
        Version: mutation.Version,
        Names: mutation.Names,
        Descriptions: mutation.Descriptions,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface UpdateSegmentMutation {
  segment: Segment;
}

export function useUpdateSegment() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: UpdateSegmentMutation) => {
      const serverSegment: ServerSegment = {
        ...mutation.segment,
        Names: Object.keys(mutation.segment.Names).map((lang) => ({
          Data: mutation.segment.Names[lang],
          Language: lang,
        })),
        Images: Object.keys(mutation.segment.Names).map((lang) => ({
          Data: mutation.segment.Names[lang],
          Language: lang,
        })),
      };
      return apiPut("/lecture/update-segment", serverSegment);
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface UpdateSegmentsOrderMutation {
  lecture: Lecture;
}

export function useUpdateSegmentsOrder() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: UpdateSegmentsOrderMutation) => {
      return apiPut(
        "/lecture/update-segments-order",
        convertLectureToServerLecture(mutation.lecture)
      );
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface DeleteSegmentMutation {
  id: number;
}

export function useDeleteSegment() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: DeleteSegmentMutation) => {
      return apiDelete("/lecture/delete-segment", { ID: mutation.id });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface UploadLectureImageProps {
  lectureId: number;
  imageBase64: string;
}

export function useUploadLectureImage() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: UploadLectureImageProps) => {
      return apiPost("/lecture/upload-image", {
        LectureID: mutation.lectureId,
        ImageData: mutation.imageBase64,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface RemoveLectureImageProps {
  lectureId: number;
}

export function useRemoveLectureImage() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: RemoveLectureImageProps) => {
      return apiDelete("/lecture/delete-image", {
        LectureID: mutation.lectureId,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export interface UploadSegmentImageProps {
  segmentId: number;
  language: string;
  imageBase64: string;
}

export function useUploadSegmentImage() {
  const queryClient = useQueryClient();
  return useMutation(
    (mutation: UploadSegmentImageProps) => {
      return apiPost("/lecture/upload-segment-image", {
        SegmentID: mutation.segmentId,
        Language: mutation.language,
        ImageData: mutation.imageBase64,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("all-lectures"),
    }
  );
}

export function useProgressForLecture(lectureId: number) {
  const lecturesQuery = useLectures();
  const progressQuery = useLectureProgress();

  const totalCount =
    lecturesQuery.data?.map[lectureId].Segments.list?.length ?? 1;
  const completed = progressQuery.data?.[lectureId]?.length ?? 0;

  return Math.floor((100 * completed) / totalCount);
}

export function useSegmentIsCompleted(lectureId: number, segmentId: number) {
  const progressQuery = useLectureProgress();
  return progressQuery.data?.[lectureId]?.some(
    (s) => s.LectureSegmentID === segmentId
  );
}
