
import { useQuery } from "react-query";
import { apiGet } from "../../helpers/requests.helper";
import { fetchLang, isAdminUser } from "../../helpers/universal.helper";
import { School } from "../../types/school.types";

export function useGetAllSchools(include_inactive = false) {
  return useQuery(
    ["all-schools", include_inactive],
    async () => {
      const data = await apiGet("/all-schools", { language: fetchLang(), include_inactive });
      const list: School[] = data;
      const map: Record<string, School> = {};
      list.forEach((s) => (map[s.ID] = s));
      return { list, map };
    },
    { enabled: isAdminUser() }
  );
}