import { Stack, StackProps, keyframes } from "@mantine/core";
import { ReactNode } from "react";
import { getNthChildObjectStylingList } from "../../../../helpers/universal.helper";

const animateListCards = keyframes({
    "0%": {
        opacity: 0,
        transform: "translateY(-10px)"
    },
    "50%": {
        opacity: 1,
    },
    "100%": {
        transform: "translateY(0px)"
    }
});


interface AnimatedStackProps {
    children: ReactNode;
    disabled?: boolean;
}

export function AnimatedStack(props: AnimatedStackProps & StackProps) {
    return (
        <Stack
            {...props}
            sx={[{
                '.animate-in': props.disabled ? undefined : {
                    animation: `${animateListCards} 150ms ease-in-out`,
                    animationFillMode: "backwards",
                }
            },
            ...getNthChildObjectStylingList(),
            ]}
        >
            {props.children}
        </Stack>
    )
}