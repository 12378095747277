import { createGlobalStyle } from 'styled-components';
import theme from "../themes/teacher";
import { jello, pulse, shake, zoomIn, zoomInCard, slideDownCard, slideDownCardCollapsed, zoomInDown, fadeInDown } from './animations';

interface GlobalStylesProps {
    isLoginScreen?: boolean;
}

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
    * {
        margin: 0;
        padding: 0;
    }
    :not(input, textarea) {
        user-select: none;
        @media (min-width: ${theme.breakpoints.mobile}) {
            user-select: auto;
        }
    }
    *, *::after, *::before {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #root {
        width: 100%;
        height: 100%;
    }
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
          props.isLoginScreen ? "white" : theme.colors.comp4.lighter};
        color: ${theme.colors.comp5.darker};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: "Open Sans", "sans-serif";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        @media (max-width: ${theme.breakpoints.mobile}) {
            *::-webkit-scrollbar {
               width: 0px;
            }
        }
    }

    h1, h2, h3, h4 {
        font-family: "Nunito Sans", "sans-serif";
        font-style: normal;
        font-weight: 800;
    }
    
    h1 {
        font-size: 24px;
        line-height: 33px;
        &.huge {
            font-size: 40px;
            line-height: 40px;
            font-weight: 900;
            margin-bottom: 20px;
            @media (min-width: ${theme.breakpoints.mobile}) {
                font-size: 68px;
                line-height: 68px;
            }
        }
    }

    h2 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 16px;
        line-height: 20px;
    }

    h4 {
        font-size: 14px;
        line-height: 20px;
    }

    strong {
        font-weight: bold;
        &.semiBold {
            font-weight: 600;
        }
    }

    em {
        color: ${theme.colors.comp4.normal};
        font-style: normal;
    }

    .smallText {
        font-weight: 600;
        font-size: 12px;
        font-family: Open Sans;
        color: ${theme.colors.comp4.normal};
    }

    .row-number {
        position: absolute;
        left: -30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 20px;
        text-align: right;
        color: ${theme.colors.comp4.normal};
        font-family: monospace;
        font-weight: bold;
    }

    a {
        color: ${theme.colors.primary.normal};
        text-decoration: none;
        cursor: pointer;
        &.underscore {
            text-decoration: underline;
        }
    }
    .link {
        color: ${theme.colors.primary.normal};
        font-weight: bold;
        cursor: pointer;
        :hover {
            text-decoration: underline;
        }
    }
    .bold {
        font-weight: bold;
    }
    label:not(.mantine-Switch-label, .mantine-SegmentedControl-label, .mantine-TextInput-label, .mantine-Checkbox-label, .mantine-Chips-label) {
        font-family: "Open Sans", "sans-serif";
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: ${theme.colors.comp4.normal};
        cursor: inherit;
    }

    .desktop-only {
        @media (max-width: ${theme.breakpoints.mobile}) {
            display: none;
        }
    }

    .textButton {
        display: block;
        position: relative;
        background: transparent;
        box-shadow: none;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.warning {
            color: ${theme.colors.comp1.dark};
            border-bottom: 1px solid ${theme.colors.comp1.dark};
        }
    }
        
    .mobile {
        @media (min-width: ${theme.breakpoints.mobile}) {
            display: none;
        }
    }
    .desktop {
        display: none;
        @media (min-width: ${theme.breakpoints.mobile}) {
            display: initial;
        }
    }

    .floatRight {
        margin-left: auto;    
    }

    .description {
        margin-bottom: 15px;
        color: ${theme.colors.comp5.dark};
    }

    .fadeZoom-enter {
        opacity: 0;
        transform: scale(0.95);
    }
    .fadeZoom-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 200ms, transform 200ms;
    }
    .fadeZoom-exit {
        opacity: 1;
    }
    .fadeZoom-exit-active {
        opacity: 0;
        transform: scale(0.95);
        transition: opacity 200ms, transform 200ms;
    }

    .fadeSlideUp-enter {
        opacity: 0;
        transform: translateY(10px);
    }
    .fadeSlideUp-enter-active {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 200ms, transform 200ms;
    }
    .fadeSlideUp-exit {
        opacity: 1;
    }
    .fadeSlideUp-exit-active {
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 200ms, transform 200ms;
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        opacity: 1;
        transition: opacity 200ms;
        &.fast {
            transition: opacity 100ms;
        }
    }
    .fade-exit {
        opacity: 1;
    }
    .fade-exit-active {
        opacity: 0;
        transition: opacity 200ms;
        &.fast {
            transition: opacity 100ms;
        }
    }
    
    .fade-fast-enter {
        opacity: 0;
    }
    .fade-fast-enter-active {
        opacity: 1;
        transition: opacity 50ms;
    }
    .fade-fast-exit {
        opacity: 1;
    }
    .fade-fast-exit-active {
        opacity: 0;
        transition: opacity 50ms;
    }
    
    .grow-enter {
        max-height: 0;
    }
    .grow-enter-active {
        max-height: 100px;
        transition: max-height 300ms ease-in;
    }
    .grow-exit {
        max-height: 100px;
    }
    .grow-exit-active {
        max-height: 0;
        transition: max-height 300ms ease-out;
    }

    .shake {
        animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

    .zoomIn {
        animation: ${zoomIn} 0.82s;
    }

    .zoomInCard {
        animation: ${zoomInCard} 2s;
    }

    .slideDownCard {
        animation: ${slideDownCard} 2s;
    }

    .slideDownCardCollapsed {
        animation: ${slideDownCardCollapsed} 2s;
    }

    .fadeInEntrance {
        animation: ${fadeInDown} 600ms;
    }

    .fadeInDown-enter {
        opacity: 0;
        transform: translateY(-20px);
    }
    .fadeInDown-enter-active {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 100ms, transform 200ms;
    }
    .fadeInDown-exit {
        opacity: 1;
    }
    .fadeInDown-exit-active {
        transform: translateY(-20px);
        opacity: 0;
        transition: opacity 100ms, transform 200ms;
    }

    .jello {
        animation: ${jello} 0.82s;
    }

    .pulse {
        animation: ${pulse} 0.82s;
        animation-iteration-count: infinite;
    }

    .zoomInDown {
        animation: ${zoomInDown} 0.82s;
    }

    .infinite {
        animation-iteration-count: infinite;
    }

    .titles {
        margin-bottom: 7px;
        display: none;
        @media (min-width: ${theme.breakpoints.mobile}) {
            display: flex;
        }
        label {
            cursor: pointer;
            user-select: none;
            color: ${theme.colors.comp4.normal};
            &.active {
                color: ${theme.colors.primary.normal};
            }
        }
        .col1, .col2, .col3, .col4, .col5 {
            display: flex;
            align-items: center;
            img {
                margin-left: 6px;
                margin-bottom: 3px;
            }
        }
    }

    .card {
        display: flex; 
        align-items: center;
        border-radius: ${theme.border.radius.small};
        margin-bottom: 6px;
        color: ${theme.colors.comp5.darker};
        cursor: pointer;
        :hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.normal}, ${
  theme.boxShadow
};
        }
        &.dragging {
            background-color: ${theme.colors.comp2.lighter};
        }
        &.no-hover {
            cursor: initial;
            :hover {
                box-shadow: none;
            }
        }
        &.column {
            flex-direction: column;
            align-items: flex-start;
        }
        &.selected {
            box-shadow: 0 0 0 2px ${theme.colors.primary.normal};
            background-color: ${theme.colors.primary.lighter};
        }
    }

    .section {
        margin-bottom: 25px;
        &.small {
            margin-bottom: 10px;
        }
        &.large {
            margin-bottom: 35px;
        }
    }


    .hideOnPrint {
        @media print {
            display: none!important;
        }
    }

    .avoidPageBreak {
        page-break-inside: avoid;
    }

    .missing {
        color: rgba(0,0,0,.3);
    }

`;
