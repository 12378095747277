import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { RichTextEditor } from "@mantine/tiptap";

// define your extension array
const extensions = [
  StarterKit,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Highlight,
  Underline,
];

interface TextEditorProps {
  initialContent?: string;
  placeHolder?: string;
  topBar?: React.ReactNode;
  onUpdate: (val: string) => void;
}

const TextEditor = (props: TextEditorProps) => {
  const editorExtensions = props.placeHolder
    ? [...extensions, Placeholder.configure({ placeholder: props.placeHolder })]
    : [...extensions];

  const editor = useEditor({
    extensions: editorExtensions,
    content: props.initialContent,
    autofocus: true,
    onUpdate: (e) => {
      props.onUpdate(e.editor.getHTML());
    },
  });

  return (
    <RichTextEditor editor={editor}>
      {props.topBar}
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content
        className="ticket-message-content"
        sx={{
          blockquote: {
            padding: "0 0 0 8px",
            borderLeftWidth: 2,
            ".ProseMirror-trailingBreak": {
              display: "none",
            },
            fontSize: 15,
          },
          ".tiptap": {
            minHeight: 150,
          },
        }}
      />
    </RichTextEditor>
  );
};

export default TextEditor;
