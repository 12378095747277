import { combineReducers } from "@reduxjs/toolkit";
import produce from "immer";
import { generateListReducer } from "../actions/generate-list-actions";
import { AggregationList } from "../components/pages/Admin/RunData/api/RunData.api";
import { START_CACHE_BASE_PATH } from "../helpers/strings.helper";
import { convertDateToYYYMMDD, jan1stThisYear } from "../helpers/time.helper";
import { ContactFormFields } from "../types/contactForm.types";
import {
  Course,
  CourseAccessClipboard,
  Exercise,
  ExerciseVariant,
  Lesson,
} from "../types/course.types";
import { Lock } from "../types/editor.types";
import { Organization } from "../types/organization.types";
import {
  ALL_SIMULATORS_SET_SIMULATORS,
  ASSESSMENTS_SET,
  CHANGE_ORGANIZATION,
  CHANGE_SCHOOL,
  CLEAR_CLASSES_FETCH_CONTROLLER,
  CLEAR_COURSES_FETCH_CONTROLLER,
  CLEAR_EXERCISES_FETCH_CONTROLLER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  CLEAR_LESSONS_FETCH_CONTROLLER,
  CLEAR_SIMULATORS_FETCH_CONTROLLER,
  CLEAR_STORE,
  CLEAR_USERS_FETCH_CONTROLLER,
  CONTACT_FORM_SET_FEEDBACK_FROM,
  CONTACT_FORM_SET_FEEDBACK_MESSAGE,
  COURSE_ACCESS_ADD_TO_CLIPBOARD,
  COURSE_ACCESS_CLEAR_CLIPBOARD,
  COURSE_ACCESS_CLEAR_MODAL,
  COURSE_ACCESS_SET_ACCESSES,
  COURSE_ACCESS_SET_ACCESS_MODAL,
  COURSE_CATEGORIES_SET_SELECTED_CATEGORY,
  EMAILS_SET_ACTIVATION_TRANSLATIONS,
  EMAILS_SET_PASSWORD_RESET_TRANSLATIONS,
  EXERCISE_NUMBERING_SET,
  EXERCISE_VARIANTS_SET_VARIANTS,
  GLOBAL_USERS_SET_META_DATA,
  GLOBAL_USERS_SET_ORDER_BY,
  HOME_PAGE_CLEAR,
  HOME_PAGE_SET_ACTIVE_PRESET,
  HOME_PAGE_SET_DATE_RANGE,
  HOME_PAGE_SET_DATE_RANGE_ON_DATA,
  HOME_PAGE_SET_FROM_DATE,
  HOME_PAGE_SET_LATEST_EXERCISES,
  HOME_PAGE_SET_LATEST_RUNS,
  HOME_PAGE_SET_OUTLIERS,
  HOME_PAGE_SET_SCHOOL_MINI_STATS,
  HOME_PAGE_SET_SCHOOL_RUNTIME,
  HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME,
  HOME_PAGE_SET_TO_DATE,
  IMPORT_USERS_ADD_NEW,
  IMPORT_USERS_CLEAR,
  IMPORT_USERS_DELETE_USER,
  IMPORT_USERS_SET_RESULT,
  IMPORT_USERS_SET_USERS,
  IMPORT_USERS_UPDATE_USER,
  LATEST_APPLICATION_VERSION,
  LOCKS_ADD_LOCK,
  LOCKS_SET_LOCKS,
  LOGIN,
  LOGIN_SET_EMAIL,
  LOGIN_SET_PASSWORD,
  LOGOUT,
  MOBILE_MENU_SET_IS_OPEN,
  MODAL_CLEAR_ALL,
  MODAL_SET_OPEN,
  PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG,
  PERMISSIONS_SET_SELECTED_USER_PERMISSIONS,
  PREVIOUS_SEARCHES_ADD_ENTRY,
  PREVIOUS_SEARCHES_REMOVE_ENTRY,
  PREVIOUS_SEARCHES_SET_ALL,
  REVERSE_ORDER_SCHOOL_CLASSES,
  REVERSE_ORDER_USERS,
  RUN_STATS_SET_DATA,
  RUN_STATS_SET_FROM_DATE,
  RUN_STATS_SET_SELECTED_CALENDAR_MONTH,
  RUN_STATS_SET_TO_DATE,
  SELECTED_COURSE_SET_PUBLIC_UUID,
  SET_FILTER,
  SET_HAS_SEEN_WELCOME_SCREEN,
  SET_IS_CHECKING_SESSION,
  SET_NO_SCHOOLS_IN_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_IS_OPEN,
  SET_RELEASE_NOTES,
  SET_SCHOOLS,
  SET_SCHOOLS_IS_OPEN,
  SET_SELECTED_ORGANIZATION,
  SET_SELECTED_SCHOOL,
  SIMULATORS_INITIAL_ORDER_CLEAR,
  SIMULATORS_INITIAL_ORDER_SET,
  SORT_CLASS_HISTORY,
  SORT_DRIVING_RECORD,
  SORT_ORGANIZATIONS,
  SORT_SCHOOL_CLASSES,
  SORT_USERS,
  STATS_SET_USERS,
  STATS_SET_USERS_PER_DAY,
  STAY_LOGGED_IN,
} from "../types/redux.types";
import { AccessModal, School, SchoolClass } from "../types/school.types";
import { Simulator } from "../types/simulator.types";
import { OrderBy, SortOrder } from "../types/sort.types";
import {
  Assessment,
  ImportResult,
  ImportUser,
  Outlier,
  RunTemp,
  SelectedUserPermissions,
  User,
  ViewConfig,
} from "../types/user.types";
import { courseEditorReducer } from "./course-editor";
import {
  extraDataValuesReducer,
  newSchoolClassReducer,
  selectedSchoolClassReducer,
} from "./school-class";
import { supportReducer } from "./support";
import {
  drivingRecordReducer,
  newUserReducer,
  selectedUserReducer,
} from "./user";

class AssessmentsState {
  assessments: Assessment[] = [];
}

const assessmentsReducer = function (
  state = new AssessmentsState(),
  action: any
): AssessmentsState {
  switch (action.type) {
    case ASSESSMENTS_SET:
      return {
        ...state,
        assessments: action.assessments,
      };
  }
  return state;
};

class PermissionsState {
  loggedInUserViewConfig: ViewConfig = new ViewConfig();
  selectedUserPermissions: SelectedUserPermissions =
    new SelectedUserPermissions();
}

const permissionsReducer = function (
  state = new PermissionsState(),
  action: any
): PermissionsState {
  switch (action.type) {
    case PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG:
      return {
        ...state,
        loggedInUserViewConfig: action.viewConfig,
      };
    case PERMISSIONS_SET_SELECTED_USER_PERMISSIONS:
      return {
        ...state,
        selectedUserPermissions: {
          userID: action.userID,
          permissions: action.permissions,
        },
      };
  }
  return state;
};

class LocksState {
  list: Lock[] = [];
  map: { [UUID: string]: Lock } = {};
}

const locksReducer = function (
  state = new LocksState(),
  action: any
): LocksState {
  switch (action.type) {
    case LOCKS_SET_LOCKS: {
      const map: { [UUID: string]: Lock } = {};
      action.locks.forEach((lock: Lock) => {
        map[lock.UUID] = lock;
      });
      const list = action.locks;
      return {
        list,
        map,
      };
    }
    case LOCKS_ADD_LOCK: {
      const updatedState = produce(state, (draft) => {
        draft.map[action.lock.UUID] = action.lock;
        const lockListIndex = draft.list.findIndex(
          (l: Lock) => l.ResourceKey === action.lock.ResourceKey
        );
        if (lockListIndex === -1) {
          draft.list.push(action.lock);
        } else {
          draft.list.splice(lockListIndex, 1, action.lock);
        }
      });
      return updatedState;
    }
  }
  return state;
};

class ExerciseVariantsState {
  list: ExerciseVariant[] = [];
  map: { [variantUUID: string]: ExerciseVariant } = {};
}

const exerciseVariantsReducer = function (
  state = new ExerciseVariantsState(),
  action: any
): ExerciseVariantsState {
  switch (action.type) {
    case EXERCISE_VARIANTS_SET_VARIANTS:
      const list: ExerciseVariant[] = action.variants.map(
        (ev: ExerciseVariant) => ev
      );
      const map: { [variantUUID: string]: ExerciseVariant } = {};
      list.forEach((ev: ExerciseVariant) => (map[ev.VariantUUID] = ev));
      return {
        ...state,
        list,
        map,
      };
  }
  return state;
};
class HomePageReducerState {
  schoolRuntimePerDay: { day: Date; runtime: number }[] = [];
  dateRangeRequested: { from: Date; to?: Date } = {
    from: jan1stThisYear,
    to: new Date(),
  };
  dateRangeOnData: { from: Date; to?: Date } = {
    from: jan1stThisYear,
    to: undefined,
  };
  schoolMiniStats: {
    totalDistance: number;
    totalFuel: number;
    totalRuntime: number;
  } = { totalDistance: 0, totalFuel: 0, totalRuntime: 0 };
  outliers: Outlier[] = [];
  schoolClassesRunTime: { schoolClassID: number; runtime: number }[] = [];
  latestRuns: RunTemp[] = [];
  latestExercises: Exercise[] = [];
  activePreset: 1 | 2 | 3 | undefined = 3;
}

const homePageReducer = function (
  state = new HomePageReducerState(),
  action: any
): HomePageReducerState {
  switch (action.type) {
    case HOME_PAGE_SET_SCHOOL_RUNTIME:
      return {
        ...state,
        schoolRuntimePerDay: action.runtime,
      };
    case HOME_PAGE_SET_DATE_RANGE:
      return {
        ...state,
        dateRangeRequested: action.dateRange,
      };
    case HOME_PAGE_SET_OUTLIERS:
      return {
        ...state,
        outliers: action.outliers,
      };
    case HOME_PAGE_SET_FROM_DATE:
      return {
        ...state,
        dateRangeRequested: { from: action.from, to: undefined },
      };
    case HOME_PAGE_SET_TO_DATE:
      return {
        ...state,
        dateRangeRequested: {
          from: state.dateRangeRequested.from,
          to: action.to,
        },
      };
    case HOME_PAGE_SET_DATE_RANGE_ON_DATA:
      return {
        ...state,
        dateRangeOnData: action.dateRange,
      };
    case HOME_PAGE_SET_ACTIVE_PRESET:
      return {
        ...state,
        activePreset: action.preset,
      };
    case HOME_PAGE_SET_SCHOOL_MINI_STATS: {
      return {
        ...state,
        schoolMiniStats: {
          totalDistance: action.totalDistance,
          totalFuel: action.totalFuel,
          totalRuntime: action.totalRuntime,
        },
      };
    }
    case HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME:
      return {
        ...state,
        schoolClassesRunTime: action.data,
      };
    case HOME_PAGE_SET_LATEST_RUNS:
      return {
        ...state,
        latestRuns: action.latestRuns,
      };
    case HOME_PAGE_SET_LATEST_EXERCISES:
      return {
        ...state,
        latestExercises: action.exercises,
      };
    case HOME_PAGE_CLEAR: {
      return new HomePageReducerState();
    }
  }
  return state;
};

class CourseAccessState {
  accessModal: AccessModal = new AccessModal();
  courseAccesses?: { [publicCourseUUID: string]: number[] };
  clipboard?: CourseAccessClipboard;
}

const courseAccessReducer = function (
  state = new CourseAccessState(),
  action: any
): CourseAccessState {
  switch (action.type) {
    case COURSE_ACCESS_SET_ACCESS_MODAL:
      return {
        ...state,
        accessModal: action.accessModal,
      };
    case COURSE_ACCESS_SET_ACCESSES: {
      return {
        ...state,
        courseAccesses: action.courseAccesses,
      };
    }
    case COURSE_ACCESS_CLEAR_MODAL:
      return {
        ...state,
        accessModal: new AccessModal(),
      };
    case COURSE_ACCESS_ADD_TO_CLIPBOARD:
      return {
        ...state,
        clipboard: {
          allowedOrganiatons: action.clipboard.allowedOrganiatons,
          originCoursePublicUuid: action.clipboard.originCoursePublicUuid,
        },
      };
    case COURSE_ACCESS_CLEAR_CLIPBOARD:
      return {
        ...state,
        clipboard: undefined,
      };
  }
  return state;
};
class StatsState {
  usersPerDay: any = [];
  users: {
    lastDay: number;
    lastWeek: number;
    lastMonth: number;
    lastYear: number;
  } = { lastDay: 0, lastWeek: 0, lastMonth: 0, lastYear: 0 };
}

const statsReducer = function (
  state = new StatsState(),
  action: any
): StatsState {
  switch (action.type) {
    case STATS_SET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          [action.timeSpan]: [action.users],
        },
      };
    case STATS_SET_USERS_PER_DAY:
      return {
        ...state,
        usersPerDay: action.usersPerDay,
      };
  }
  return state;
};

class ReleaseNotesState {
  releaseNotes?: JSX.Element;
}

const releaseNotesReducer = function (
  state = new ReleaseNotesState(),
  action: any
): ReleaseNotesState {
  switch (action.type) {
    case SET_RELEASE_NOTES:
      return {
        ...state,
        releaseNotes: action.releaseNotes,
      };
  }
  return state;
};

type PreviousSearchEntry = {
  type: string;
  label: string;
  path: string;
  clickCount: number;
};
class PreviousSearchesState {
  entries: Record<string, PreviousSearchEntry> = {};
}

const previousSearchesReducer = function (
  state = new PreviousSearchesState(),
  action: any
): PreviousSearchesState {
  const PREVIOUS_SEARCH_CACHE_KEY = `${START_CACHE_BASE_PATH}${action.userId}`;
  switch (action.type) {
    case PREVIOUS_SEARCHES_ADD_ENTRY: {
      if (!Object.keys(state.entries).length) {
        const entries = {
          [action.entry.path]: { ...action.entry, clickCount: 1 },
        };
        localStorage.setItem(
          PREVIOUS_SEARCH_CACHE_KEY,
          JSON.stringify(entries)
        );
        return {
          ...state,
          entries,
        };
      }
      if (state.entries[action.entry.path]) {
        const entries = {
          ...state.entries,
          [action.entry.path]: {
            ...action.entry,
            clickCount: action.entry.clickCount + 1,
          },
        };
        localStorage.setItem(
          PREVIOUS_SEARCH_CACHE_KEY,
          JSON.stringify(entries)
        );
        return {
          ...state,
          entries,
        };
      }

      const entries = {
        ...state.entries,
        [action.entry.path]: {
          ...action.entry,
          clickCount: 1,
        },
      };

      localStorage.setItem(PREVIOUS_SEARCH_CACHE_KEY, JSON.stringify(entries));

      return {
        ...state,
        entries,
      };
    }
    case PREVIOUS_SEARCHES_REMOVE_ENTRY: {
      const entries = {
        ...state.entries,
      };

      delete entries[action.path];
      localStorage.setItem(PREVIOUS_SEARCH_CACHE_KEY, JSON.stringify(entries));

      return {
        ...state,
        entries,
      };
    }
    case PREVIOUS_SEARCHES_SET_ALL: {
      localStorage.setItem(
        PREVIOUS_SEARCH_CACHE_KEY,
        JSON.stringify(action.entries)
      );
      return {
        ...state,
        entries: action.entries,
      };
    }
  }
  return state;
};

const modalsReducer = function (state = {}, action: any) {
  switch (action.type) {
    case MODAL_SET_OPEN: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case MODAL_CLEAR_ALL:
      return {};
  }
  return state;
};

class ContactFormsState {
  feedback: ContactFormFields = new ContactFormFields();
}

const contactFormsReducer = function (
  state = new ContactFormsState(),
  action: any
): ContactFormsState {
  switch (action.type) {
    case CONTACT_FORM_SET_FEEDBACK_FROM:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          from: action.from,
        },
      };
    case CONTACT_FORM_SET_FEEDBACK_MESSAGE:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          message: action.message,
        },
      };
  }
  return state;
};

class FetchControllerState {
  courses: AbortController = new AbortController();
  classes: AbortController = new AbortController();
  users: AbortController = new AbortController();
  simulators: AbortController = new AbortController();
  lessons: AbortController = new AbortController();
  exercises: AbortController = new AbortController();
}

const fetchControllerReducer = function (
  state = new FetchControllerState(),
  action: any
): FetchControllerState {
  switch (action.type) {
    case CLEAR_COURSES_FETCH_CONTROLLER:
      return {
        ...state,
        courses: new AbortController(),
      };
    case CLEAR_CLASSES_FETCH_CONTROLLER:
      return {
        ...state,
        classes: new AbortController(),
      };
    case CLEAR_USERS_FETCH_CONTROLLER:
      return {
        ...state,
        users: new AbortController(),
      };
    case CLEAR_SIMULATORS_FETCH_CONTROLLER:
      return {
        ...state,
        simulators: new AbortController(),
      };
    case CLEAR_LESSONS_FETCH_CONTROLLER:
      return {
        ...state,
        lessons: new AbortController(),
      };
    case CLEAR_EXERCISES_FETCH_CONTROLLER:
      return {
        ...state,
        exercises: new AbortController(),
      };
  }
  return state;
};

class OrganizationState {
  organizations: Organization[] = [];
  selectedOrganization = new Organization();
  organizationsIsOpen: boolean = false;
  schoolsIsOpen: boolean = false;
  schools: School[] = [];
  selectedSchool: School = new School();
  noSchoolsInOrganization: boolean = false;
}

const organizationReducer = function (
  state = new OrganizationState(),
  action: any
): OrganizationState {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.organizations,
      };
    case SET_SELECTED_ORGANIZATION:
      localStorage.setItem("organization_id", action.organization.ID);
      return {
        ...state,
        selectedOrganization: action.organization,
      };
    case CHANGE_SCHOOL:
      localStorage.setItem("school_id", action.schoolID);
      return state;
    case CHANGE_ORGANIZATION:
      localStorage.setItem("organization_id", action.organizationID);
      return state;
    case SET_SCHOOLS:
      return {
        ...state,
        schools: action.schools,
      };
    case SET_SELECTED_SCHOOL:
      return {
        ...state,
        selectedSchool: action.school,
      };
    case SET_ORGANIZATIONS_IS_OPEN:
      return {
        ...state,
        organizationsIsOpen: action.status,
      };
    case SET_SCHOOLS_IS_OPEN:
      return {
        ...state,
        schoolsIsOpen: action.status,
      };
    case SET_NO_SCHOOLS_IN_ORGANIZATION:
      return {
        ...state,
        noSchoolsInOrganization: action.status,
      };
  }
  return state;
};

class AuthState {
  isLoggedIn = false;
  isCheckingSession = true;
  loading = false;
  wrongCred = false;
  email: string = "";
  password: string = "";
  forgotPassword = false;
  hasSeenWelcomeScreen = true;
}

const authReducer = function (state = new AuthState(), action: any): AuthState {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem("status", "logged in");
      localStorage.setItem("authentication_token", action.token);
      localStorage.setItem("school_id", action.user.SchoolID);
      localStorage.setItem("user_id", action.user.ID);
      localStorage.setItem("first_name", action.user.FirstName);
      localStorage.setItem("last_name", action.user.LastName);
      localStorage.setItem("email", action.user.Email);
      localStorage.setItem("role", action.user.Role);
      localStorage.setItem("organization_id", action.user.OrganizationID);
      return {
        ...state,
        isLoggedIn: true,
        isCheckingSession: false,
      };
    case STAY_LOGGED_IN:
      localStorage.setItem("status", "logged in");
      localStorage.setItem("authentication_token", action.token);
      localStorage.setItem("user_id", action.user.ID);
      localStorage.setItem("first_name", action.user.FirstName);
      localStorage.setItem("last_name", action.user.LastName);
      localStorage.setItem("email", action.user.Email);
      localStorage.setItem("role", action.user.Role);
      if (!localStorage.getItem("organization_id"))
        localStorage.setItem("organization_id", action.user.OrganizationID);
      return {
        ...state,
        isLoggedIn: true,
        isCheckingSession: false,
      };

    case LOGOUT:
      localStorage.setItem("status", "logged out");
      localStorage.removeItem("authentication_token");
      localStorage.removeItem("organization_id");
      localStorage.removeItem("school_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("first_name");
      localStorage.removeItem("last_name");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      return {
        ...state,
        isLoggedIn: false,
        isCheckingSession: false,
      };
    case LOGIN_SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case LOGIN_SET_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case SET_IS_CHECKING_SESSION:
      return {
        ...state,
        isCheckingSession: action.status,
      };
    case SET_HAS_SEEN_WELCOME_SCREEN:
      return {
        ...state,
        hasSeenWelcomeScreen: action.status,
      };
  }
  return state;
};

class ApplicationVersionState {
  latestVersion: string = "2020.6.0";
}

function applicationVersionReducer(
  state = new ApplicationVersionState(),
  action: any
): ApplicationVersionState {
  switch (action.type) {
    case LATEST_APPLICATION_VERSION:
      return {
        ...state,
        latestVersion: action.version,
      };
  }
  return state;
}

const filterReducer = function (state = {}, action: any) {
  switch (action.type) {
    case SET_FILTER: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case CLEAR_FILTER:
      return {
        ...state,
        [action.key]: "",
      };
    case CLEAR_FILTERS:
      return {};
  }
  return state;
};

class SortState {
  classes: OrderBy[] = [
    { tag: "Name", order: SortOrder.Ascending },
    { tag: "LastActiveTime", order: SortOrder.Descending },
    { tag: "MemberCount", order: SortOrder.Descending },
    { tag: "CoursesUUID", order: SortOrder.Descending },
    { tag: "Progress", order: SortOrder.Descending },
    { tag: "Outliers", order: SortOrder.Descending },
  ];
  users: OrderBy[] = [
    { tag: "LastActive", order: SortOrder.Descending },
    { tag: "IsOnline", order: SortOrder.Descending },
    { tag: "FirstName", order: SortOrder.Descending },
    { tag: "SchoolClass", order: SortOrder.Descending },
    { tag: "RunTime", order: SortOrder.Descending },
    { tag: "Progress", order: SortOrder.Descending },
    { tag: "IndividualCourses", order: SortOrder.Descending },
  ];
  drivingRecord: OrderBy[] = [
    { tag: "Timestamp", order: SortOrder.Descending },
    { tag: "ExerciseName", order: SortOrder.Descending },
    { tag: "CourseName", order: SortOrder.Descending },
    { tag: "Runtime", order: SortOrder.Descending },
    { tag: "Assessments", order: SortOrder.Descending },
    { tag: "Status", order: SortOrder.Descending },
    { tag: "Stylepoints", order: SortOrder.Descending },
  ];
  classHistory: OrderBy[] = [
    { tag: "FirstName", order: SortOrder.Descending },
    { tag: "LastRun", order: SortOrder.Descending },
    { tag: "TotalRuntime", order: SortOrder.Descending },
    { tag: "RunCount", order: SortOrder.Descending },
  ];
  organizations: OrderBy[] = [
    { tag: "Name", order: SortOrder.Ascending },
    { tag: "ContactInfo", order: SortOrder.Descending },
    { tag: "CreationTime", order: SortOrder.Descending },
    { tag: "ActivatedSimulators", order: SortOrder.Descending },
    { tag: "LicenseCount", order: SortOrder.Descending },
    { tag: "PayedFor", order: SortOrder.Descending },
    { tag: "MoreThanPayedFor", order: SortOrder.Descending },
  ];
}

const sortReducer = function (state = new SortState(), action: any): SortState {
  switch (action.type) {
    case SORT_SCHOOL_CLASSES:
      return {
        ...state,
        classes: action.sortOrder,
      };
    case REVERSE_ORDER_SCHOOL_CLASSES:
      return {
        ...state,
        classes: [
          {
            ...state.classes[0],
            order:
              state.classes[0].order === SortOrder.Ascending
                ? SortOrder.Descending
                : SortOrder.Ascending,
          },
          ...state.classes.slice(1),
        ],
      };
    case SORT_USERS:
      return {
        ...state,
        users: action.sortOrder,
      };
    case REVERSE_ORDER_USERS:
      return {
        ...state,
        users: [
          {
            ...state.users[0],
            order:
              state.users[0].order === SortOrder.Ascending
                ? SortOrder.Descending
                : SortOrder.Ascending,
          },
          ...state.users.slice(1),
        ],
      };
    case SORT_DRIVING_RECORD:
      return {
        ...state,
        drivingRecord: action.sortOrder,
      };
    case SORT_CLASS_HISTORY:
      return {
        ...state,
        classHistory: action.sortOrder,
      };
    case SORT_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.sortOrder,
      };
  }
  return state;
};

class ExerciseNumberingState {
  publicCourseUUID: string = "";
  numbering: { [publicCourseUUID: string]: number } = {};
}

const exerciseNumberingReducer = function (
  state = new ExerciseNumberingState(),
  action: any
): ExerciseNumberingState {
  switch (action.type) {
    case EXERCISE_NUMBERING_SET:
      return {
        publicCourseUUID: action.publicUUID,
        numbering: action.numbering,
      };
  }
  return state;
};

class SimulatorsInitialOrderState {
  initialOrder: { InstallationID: string }[] = [];
}

const simulatorsInitialOrderReducer = function (
  state = new SimulatorsInitialOrderState(),
  action: any
): SimulatorsInitialOrderState {
  switch (action.type) {
    case SIMULATORS_INITIAL_ORDER_SET:
      return {
        initialOrder: action.order,
      };
    case SIMULATORS_INITIAL_ORDER_CLEAR:
      return {
        initialOrder: [],
      };
  }
  return state;
};

class MobileMenuState {
  isOpen: boolean = false;
}

const mobileMenuReducer = function (
  state = new MobileMenuState(),
  action: any
): MobileMenuState {
  switch (action.type) {
    case MOBILE_MENU_SET_IS_OPEN:
      return {
        ...state,
        isOpen: action.status,
      };
  }
  return state;
};

class AllSimulatorsState {
  list: Simulator[] = [];
  map: { [InstallationID: string]: Simulator } = {};
}

const allSimulatorsReducer = function (
  state = new AllSimulatorsState(),
  action: any
): AllSimulatorsState {
  switch (action.type) {
    case ALL_SIMULATORS_SET_SIMULATORS:
      const map: { [InstallationID: string]: Simulator } = {};
      action.simulators.forEach((sim: Simulator) => {
        map[sim.InstallationID] = sim;
      });
      return {
        ...state,
        list: action.simulators,
        map,
      };
  }
  return state;
};

class GlobalUsersMetaDataState {
  orderBy: OrderBy = { tag: "firstname", order: SortOrder.Ascending };
  current_page: number = 0;
  page_size: number = 0;
  first_page: number = 0;
  last_page: number = 0;
  total_records: number = 0;
}

const globalUsersMetaDataReducer = function (
  state = new GlobalUsersMetaDataState(),
  action: any
): GlobalUsersMetaDataState {
  switch (action.type) {
    case GLOBAL_USERS_SET_META_DATA:
      return {
        ...state,
        current_page: action.metaData.current_page,
        page_size: action.metaData.page_size,
        first_page: action.metaData.first_page,
        last_page: action.metaData.last_page,
        total_records: action.metaData.total_records,
      };
    case GLOBAL_USERS_SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
      };
  }
  return state;
};

class ImportUsersState {
  users: ImportUser[] = [];
  result: ImportResult = new ImportResult();
}

const importUsersReducer = function (
  state = new ImportUsersState(),
  action: any
): ImportUsersState {
  switch (action.type) {
    case IMPORT_USERS_SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case IMPORT_USERS_UPDATE_USER: {
      const updatedUsers = produce(state.users, (draft) => {
        draft.splice(action.index, 1, action.updatedUser);
        return draft;
      });
      return {
        ...state,
        users: updatedUsers,
      };
    }
    case IMPORT_USERS_DELETE_USER: {
      const updatedUsers = produce(state.users, (draft) => {
        draft.splice(action.index, 1);
        return draft;
      });
      return {
        ...state,
        users: updatedUsers,
      };
    }
    case IMPORT_USERS_ADD_NEW:
      return {
        ...state,
        users: [...state.users, new ImportUser()],
      };
    case IMPORT_USERS_SET_RESULT:
      return {
        ...state,
        result: action.result,
      };
    case IMPORT_USERS_CLEAR:
      return new ImportUsersState();
  }
  return state;
};

class EmailContent {
  Prefix: string = "";
  Body: string = "";
  Suffix: string = "";
  Code: string = "";
  Language: string = "";
  Subject: string = "";
}

export class TranslatedEmails {
  "sv-SE": EmailContent = new EmailContent();
  "fi-FI": EmailContent = new EmailContent();
  "en-US": EmailContent = new EmailContent();
}
class EmailsState {
  activation: TranslatedEmails = new TranslatedEmails();
  passwordReset: TranslatedEmails = new TranslatedEmails();
}

const emailsReducer = function (
  state = new EmailsState(),
  action: any
): EmailsState {
  switch (action.type) {
    case EMAILS_SET_ACTIVATION_TRANSLATIONS:
      return {
        ...state,
        activation: action.activationTranslations,
      };
    case EMAILS_SET_PASSWORD_RESET_TRANSLATIONS:
      return {
        ...state,
        passwordReset: action.passwordResetTranslations,
      };
  }
  return state;
};

const loadingReducer = function (state = {}, action: any) {
  const { type } = action;
  const matches = /(.*)_(LOADING|SUCCESS|ERROR|RESET)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "LOADING",
  };
};

const errorReducer = function (state = {}, action: any) {
  const { type } = action;
  const matches = /(.*)_(LOADING|SUCCESS|ERROR|RESET)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "ERROR",
  };
};

const successReducer = function (state = {}, action: any) {
  const { type } = action;
  const matches = /(.*)_(LOADING|SUCCESS|ERROR|RESET)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === "SUCCESS",
  };
};

export interface MessageContent {
  requestName: string;
  title: string;
  message: string;
  sort?: "success" | "error";
}
class MessagesState {
  messages: MessageContent[] = [];
}

const messagesReducer = function (
  state = new MessagesState(),
  action: any
): MessagesState {
  const { type } = action;
  const matches = /(.*)_(ADD_MESSAGE|REMOVE_MESSAGE)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  if (requestState === "REMOVE_MESSAGE") {
    return {
      ...state,
      messages: [
        ...state.messages.filter(
          (messageContent: MessageContent) =>
            messageContent.requestName !== requestName
        ),
      ],
    };
  }
  return {
    ...state,
    messages: [
      ...state.messages.filter(
        (messageContent: MessageContent) =>
          messageContent.requestName !== requestName
      ),
      {
        requestName,
        title: action.title,
        message: action.message,
        sort: action.sort,
      },
    ],
  };
};

class RunStatsState {
  fromDate: string = "2022-01-01";
  toDate: string = convertDateToYYYMMDD(new Date());
  data: AggregationList[] = [];
  selectedCalendarMonth?: number;
  organization_filter?: number[];
  organization_filter_strategy: "include" | "exclude" = "include";
}

const runStatsReducer = (
  state = new RunStatsState(),
  action: any
): RunStatsState => {
  switch (action.type) {
    case RUN_STATS_SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.date,
      };
    case RUN_STATS_SET_TO_DATE:
      return {
        ...state,
        toDate: action.date,
      };
    case RUN_STATS_SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case RUN_STATS_SET_SELECTED_CALENDAR_MONTH:
      return {
        ...state,
        selectedCalendarMonth: action.month,
      };
    case "RUN_STATS_SET_SELECTED_ORGANIZATIONS":
      return {
        ...state,
        organization_filter: action.data,
      };
    case "RUN_STATS_SET_SELECTED_ORGANIZATIONS_BEHAVIOUR":
      return {
        ...state,
        organization_filter_strategy: action.data,
      };
  }
  return state;
};

class CourseCategoriesState {
  selectedId = -1;
}

const courseCategoriesReducer = (
  state = new CourseCategoriesState(),
  action: any
): CourseCategoriesState => {
  switch (action.type) {
    case COURSE_CATEGORIES_SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedId: action.id,
      };
  }
  return state;
};

class SelectedCourseState {
  publicUuid?: string;
}

const selectedCourseReducer = function (
  state = new SelectedCourseState(),
  action: any
): SelectedCourseState {
  switch (action.type) {
    case SELECTED_COURSE_SET_PUBLIC_UUID:
      return {
        ...state,
        publicUuid: action.publicUuid,
      };
  }
  return state;
};

const appReducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  success: successReducer,
  extraDataValues: extraDataValuesReducer,
  auth: authReducer,
  organization: organizationReducer,
  applicationVersion: applicationVersionReducer,
  drivingRecord: drivingRecordReducer,
  courses: generateListReducer<Course>("courses", Course),
  lessons: generateListReducer<Lesson>("lessons", Lesson),
  exercises: generateListReducer<Exercise>("exercises", Exercise),
  exerciseNumbering: exerciseNumberingReducer,
  exerciseVariants: exerciseVariantsReducer,
  classes: generateListReducer<SchoolClass>("classes", SchoolClass),
  users: generateListReducer<User>("users", User),
  simulators: generateListReducer<Simulator>("simulators", Simulator),
  allSchools: generateListReducer<School>("allSchools", School),
  classesOptionalSchool: generateListReducer<SchoolClass>(
    "classesOptionalSchool",
    SchoolClass
  ),
  simulatorsInitialOrder: simulatorsInitialOrderReducer,
  filter: filterReducer,
  selectedSchoolClass: selectedSchoolClassReducer,
  selectedUser: selectedUserReducer,
  newSchoolClass: newSchoolClassReducer,
  newUser: newUserReducer,
  sort: sortReducer,
  fetchController: fetchControllerReducer,
  modals: modalsReducer,
  contactForms: contactFormsReducer,
  mobileMenu: mobileMenuReducer,
  support: supportReducer,
  courseEditor: courseEditorReducer,
  locks: locksReducer,
  courseAccess: courseAccessReducer,
  allSimulators: allSimulatorsReducer,
  releaseNotes: releaseNotesReducer,
  homePage: homePageReducer,
  stats: statsReducer,
  importUsers: importUsersReducer,
  globalUsersMetaData: globalUsersMetaDataReducer,
  messages: messagesReducer,
  permissions: permissionsReducer,
  assessments: assessmentsReducer,
  emails: emailsReducer,
  selectedCourse: selectedCourseReducer,
  runStats: runStatsReducer,
  courseCategories: courseCategoriesReducer,
  previousSearches: previousSearchesReducer,
});

const rootReducer = (
  states: ReturnType<typeof appReducer> | undefined,
  action: any
): ReturnType<typeof appReducer> => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, { type: LOGOUT });
  }
  if (action.type === CLEAR_STORE) {
    const auth = states?.auth;
    states = appReducer(undefined, { type: undefined });
    if (auth !== undefined) states = { ...states, auth };
    return states;
  }
  return appReducer(states, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
