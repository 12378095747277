
import { v4 as uuidv4 } from 'uuid';
import { produce } from "immer";
import { Option, ExerciseOption } from '../types/option.types';
import { CourseDraft, CourseName, Exercise, ExerciseVariant, Lesson } from '../types/course.types';
import { 
    COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST, 
    COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST, 
    COURSE_EDITOR_CLEAR_DRAFT, 
    COURSE_EDITOR_CLEAR_LAST_SAVED, 
    COURSE_EDITOR_CLEAR_UUID, 
    COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK, 
    COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK, 
    COURSE_EDITOR_CREATE_NEW_LESSON, 
    COURSE_EDITOR_DELETE_LESSON, 
    COURSE_EDITOR_DUPLICATE_EXERCISE, 
    COURSE_EDITOR_DUPLICATE_LESSON, 
    COURSE_EDITOR_MOVE_EXERCISE, 
    COURSE_EDITOR_MOVE_LESSON, 
    COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST, 
    COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST, 
    COURSE_EDITOR_REMOVE_EXERCISE, 
    COURSE_EDITOR_SET_COURSE_IMAGE, 
    COURSE_EDITOR_SET_VEHICLE_IMAGES, 
    COURSE_EDITOR_SET_COURSE_NAMES, 
    COURSE_EDITOR_SET_DRAFT, 
    COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID,
    COURSE_EDITOR_SET_DUPLICATE_UUID, 
    COURSE_EDITOR_SET_EXERCISE_OPTIONS, 
    COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE, 
    COURSE_EDITOR_SET_INITIAL_DRAFT, 
    COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE, 
    COURSE_EDITOR_SET_IS_DRAGGING_LESSON, 
    COURSE_EDITOR_SET_IS_SAVED, 
    COURSE_EDITOR_SET_LAST_SAVED, 
    COURSE_EDITOR_SET_NEW_UUID, 
    COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION, 
    COURSE_EDITOR_SET_SHOW_EXIT_WARNING, 
    COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE, 
    COURSE_EDITOR_UPDATE_COURSE_TYPE, 
    COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS, 
    COURSE_EDITOR_UPDATE_LESSON_NAME,
    COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON,
    COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION,
    COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID,
    COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON,
    COURSE_EDITOR_SET_MOVED_LESSONS,
    COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED,
    COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS,
    COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE
} from '../types/redux.types';
import { DropResult } from 'react-beautiful-dnd';
import { incrementString } from '../helpers/strings.helper';

class CourseEditorState {
    duplicatedCourseUUID?: string;
    duplicatedPublicCourseUUID?: string;
    initialDraft: CourseDraft = new CourseDraft();
    draft: CourseDraft = new CourseDraft();
    draftAndInitialDraftIntersection: Lesson[] = [];
    uuid?: string;
    vehicleKioskIsActive: boolean = true;
    isDraggingExercise: boolean = false;
    isDraggingLesson: boolean = false;
    existingDraftUUID: string = "";
    isSaved?: boolean;
    selectedVehicleOption: ExerciseOption = new ExerciseOption();    
    selectedCourseImageUUID: string = "";
    selectedCoursePublicUUID: string = "";
    selectedCourseName: string = "";
    weatherOptions: ExerciseOption[] = [];
    trafficOptions: ExerciseOption[] = [];
    difficultyOptions: Option[] = [];
    vehicleOptions: ExerciseOption[] = [];
    exerciseVariantsSelectedVehicle: ExerciseVariant[] = [];
    lastSaved: {[publicUUID: string]: Date} = {};
    showExitWarning: boolean = false;
    clonedExerciseUUIDs: string[] = [];
    clonedLessonUUIDs: string[] = [];
    vehicleImages: string[] = [];
    requestLockRelease?: boolean = false;
    publishAndLeaveConfirmed: boolean = false;
}

export function courseEditorReducer(state = new CourseEditorState(), action: any): CourseEditorState {
    switch (action.type) {
            
        case COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID:
            return {
                ...state,
                duplicatedCourseUUID: action.courseUUID
            }

        case COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID:
            return {
                ...state,
                duplicatedPublicCourseUUID: action.publicUUID
            }

        case COURSE_EDITOR_SET_SHOW_EXIT_WARNING:
            return {
                ...state,
                showExitWarning: action.status
            }

        case COURSE_EDITOR_SET_LAST_SAVED: {
            const updatedState = produce(state, draft => {
                draft.lastSaved[action.publicUUID] = new Date();
            })
            return updatedState;
        }

        case COURSE_EDITOR_CLEAR_LAST_SAVED:
            return {
                ...state,
                lastSaved: {}
            }

        case COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE:
            return {
                ...state,
                vehicleKioskIsActive: action.status
            }

        case COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE:
            return {
                ...state,
                exerciseVariantsSelectedVehicle: action.exerciseVariants
            }

        case COURSE_EDITOR_SET_INITIAL_DRAFT: {
            return {
                ...state,
                initialDraft: action.draft
            }
        }
        case COURSE_EDITOR_SET_DRAFT: 
            return {
                ...state,
                draft: action.draft
            }
        
        case COURSE_EDITOR_SET_IS_SAVED:
            return {
                ...state,
                isSaved: action.status
            }
        

        case COURSE_EDITOR_CLEAR_DRAFT:
            return {
                ...state,
                draft: new CourseDraft()
            }
        
        case COURSE_EDITOR_CLEAR_UUID:
            return {
                ...state,
                uuid: undefined
            }

        case COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION:
            return {
                ...state,
                selectedVehicleOption: action.option
            }
        
        case COURSE_EDITOR_SET_DUPLICATE_UUID:
            return {
                ...state,
                existingDraftUUID: action.uuid
            }

        case COURSE_EDITOR_CREATE_NEW_LESSON: {
            const updatedState = produce(state, draft => {
                draft.draft.Lessons.push({...new Lesson(), PublicUUID: uuidv4(), UUID: uuidv4(), IsValid: true})
            });
            return updatedState;
        }

        case COURSE_EDITOR_MOVE_EXERCISE: {
            const updatedState = produce(state, draft => {
                const toLesson = draft.draft.Lessons.find((lesson: Lesson) => lesson.PublicUUID === action.to);
                const from = draft.draft.Lessons.find((lesson: Lesson) => lesson.PublicUUID === action.from)?.Exercises || [];
                const to = toLesson?.Exercises || [];
                const [removed] = from.splice(action.fromIndex, 1);
                removed.LessonID = toLesson?.ID ?? 0;
                to.splice(action.toIndex, 0, removed);
            });
            return updatedState;
        }

        case COURSE_EDITOR_SET_COURSE_IMAGE: {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    ImageUUID: action.imageUUID
                }
            };
        }

        case COURSE_EDITOR_SET_VEHICLE_IMAGES: {
            return {
              ...state,
              vehicleImages: action.imageUUIDs.map(
                (i: {
                  Hash: string;
                  Category: string;
                  Filename: string;
                  Url: string;
                }) => i.Filename
              ),
            };
        }

        case COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK: {
            const lesson: Lesson = {
                ...new Lesson(),
                Exercises: action.lesson.Exercises.map((e: Exercise) => (
                    {
                        ...e, 
                        UUID: uuidv4()
                    }
                )),
                IsValid: true,
                Name: action.lesson.Name,
                SourceUUID: action.sourceUUID,
                PublicUUID: uuidv4(),
                UUID: uuidv4()
            }
            const updatedState = produce(state, draft => {
                draft.draft.Lessons.splice(action.toIndex, 0, lesson);
            })
            return updatedState;
        }

        case COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK: {
            const uuid = uuidv4();
            const exercise: Exercise = {
                ...new Exercise(),
                Name: action.name ?? state.exerciseVariantsSelectedVehicle.find((ev: ExerciseVariant) => ev.VariantUUID === action.variantUUID)?.Name ?? "",
                UUID: uuid,
                PublicUUID: uuid,
                TemplateVariantUUID: action.variantUUID,
                VehicleUUID: action.vehicleUUID,
                WeatherUUID: action.weatherUUID,
                TrafficUUID: action.trafficUUID,
                Difficulty: action.difficulty,
                IsValid: true
            };
            const updatedState = produce(state, draft => {
                const toLesson = draft.draft.Lessons.find((lesson: Lesson) => lesson.PublicUUID === action.to);
                const to = toLesson?.Exercises || [];
                exercise.LessonID = toLesson?.ID ?? 0;
                to.splice(action.toIndex, 0, exercise);
            });
            return updatedState;
        }

        case COURSE_EDITOR_REMOVE_EXERCISE: {
            const updatedState = produce(state, draft => {
                draft.draft.Lessons.find((lesson: Lesson) => lesson.PublicUUID === action.board)?.Exercises.splice(action.index, 1);
            })
            return updatedState;
        }

        case COURSE_EDITOR_DUPLICATE_EXERCISE: {
            const updatedState = produce(state, draft => {
                const board = draft.draft.Lessons.find((lesson: Lesson) => lesson.PublicUUID === action.board);
                board?.Exercises.splice(action.index + 1, 0, {
                    ...board.Exercises[action.index],
                    ID: 0,
                    UUID: action.uuid,
                    PublicUUID: action.uuid,
                    SourceUUID: board.Exercises[action.index].UUID
                });
            });
            return updatedState;
        }

        case COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST: {
            const updatedState = produce(state, draft => {
                draft.clonedExerciseUUIDs.push(action.uuid);
            })
            return updatedState;
        }
        
        case COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST: {
            const updatedState = produce(state, draft => {
                const index = draft.clonedExerciseUUIDs.findIndex((UUID: string) => UUID === action.uuid);
                draft.clonedExerciseUUIDs.splice(index ,1);
            })
            return updatedState;
        }

        case COURSE_EDITOR_SET_NEW_UUID: {
            return {
                ...state,
                uuid: action.uuid
            }
        }

        case COURSE_EDITOR_UPDATE_LESSON_NAME: {
            const updatedState = produce(state, draft => {
                const lesson = draft.draft.Lessons.find((l: Lesson) => l.UUID === action.board);
                if (lesson) {
                    lesson["Name"] = action.nameSv;
                    lesson["Names"] = [
                        {Language: "sv-SE", Value: action.nameSv},
                        {Language: "fi-FI", Value: action.nameFi},
                        {Language: "en-US", Value: action.nameEn}
                    ]
                }
            });
            return updatedState;
        }

        case COURSE_EDITOR_DELETE_LESSON: {
            const updatedState = produce(state, draft => {
                draft.draft.Lessons.splice(action.index, 1);
            });
            return updatedState;
        }
        
        case COURSE_EDITOR_SET_COURSE_NAMES: {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    Names: action.names,
                    Name: action.names.find((name: CourseName) => name.Language === "sv-SE").Value
                }
            }
        }

        case COURSE_EDITOR_UPDATE_COURSE_TYPE: {
            const updatedDraft = produce(state.draft, draft => {
                draft.IsLinear = action.isLinear;
            });
            return {
                ...state,
                draft: updatedDraft
            }
        }

        case COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS: {
            const updatedState = produce(state, draft => {
                const lesson = draft.draft.Lessons.find((l: Lesson) => l.Exercises.some((e: Exercise) => e.UUID === action.exerciseUUID));
                const lessonIndex = draft.draft.Lessons.findIndex((l: Lesson) => l.Exercises.some((e: Exercise) => e.UUID === action.exerciseUUID));
                const exerciseIndex = lesson?.Exercises.findIndex((e: Exercise) => e.UUID === action.exerciseUUID);
                if (typeof exerciseIndex !== "undefined" && typeof lessonIndex !== "undefined") {
                    const updatedExercise = {
                        ...draft.draft.Lessons[lessonIndex].Exercises[exerciseIndex],
                        UUID: action.exerciseUUID,
                        WeatherUUID: action.WeatherUuid,
                        TrafficUUID: action.TrafficUuid,
                        Difficulty: action.Difficulty
                    }
                    draft.draft.Lessons[lessonIndex].Exercises.splice(exerciseIndex, 1, updatedExercise);
                } 

            })
            return updatedState;
        }

        case COURSE_EDITOR_MOVE_LESSON: {
            const updatedState = produce(state, draft => {
                const from = draft.draft.Lessons;
                const to = from;
                const [removed] = from.splice(action.fromIndex, 1);
                to.splice(action.toIndex, 0, removed);
            })
            return updatedState;
        }

        case COURSE_EDITOR_DUPLICATE_LESSON: {
            const updatedState = produce(state, draft => {
                const from = draft.draft.Lessons;
                const to = from;
                const Name = incrementString(from[action.index].Name);
                const Names = from[action.index].Names.map(n => ({Language: n.Language, Value: incrementString(n.Value)}));
                const [clone] = [{
                    ...from[action.index],
                    ID: 0,
                    UUID: action.uuid,
                    PublicUUID: action.uuid,
                    SourceUUID: from[action.index].UUID,
                    Name,
                    Names,
                    Exercises: from[action.index].Exercises.map((e: Exercise) => {
                        const uuid = uuidv4();
                        return {...e, UUID: uuid, PublicUUID: uuid }
                    })
                }];
                to.splice(action.index + 1, 0, clone);
            });
            return updatedState
        }
        
        case COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST: {
            const updatedState = produce(state, draft => {
                draft.clonedLessonUUIDs.push(action.uuid);
            })
            return updatedState;
        }
        
        case COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST: {
            const updatedState = produce(state, draft => {
                const index = draft.clonedLessonUUIDs.findIndex((UUID: string) => UUID === action.uuid);
                draft.clonedLessonUUIDs.splice(index ,1);
            })
            return updatedState;
        }

        case COURSE_EDITOR_SET_IS_DRAGGING_LESSON: {
            return {
                ...state,
                isDraggingLesson: action.isDragging
            }
        }
        
        case COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE: {
            return {
                ...state,
                isDraggingExercise: action.isDragging
            }
        }

        case COURSE_EDITOR_SET_EXERCISE_OPTIONS: {
            return {
                ...state,
                weatherOptions: action.options.WeatherOptions,
                trafficOptions: action.options.TrafficOptions,
                difficultyOptions: action.options.DifficultyOptions.map((o: {Value: number, Name: string}) => ({value: o.Value, label: o.Name})),
                vehicleOptions: action.options.VehicleOptions
            }
        }

        case COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION: {
            const intersection: Lesson[] = state.draft.Lessons.filter((l: Lesson) =>
            state.initialDraft.Lessons.some((il: Lesson) => il.UUID === l.UUID))
            .map((fl: Lesson) => ({
                ...fl,
                Exercises: fl.Exercises.filter((fle: Exercise) =>
                    state.initialDraft.Lessons.find((il: Lesson) =>
                        il.UUID === fl.UUID)?.Exercises.some((ie: Exercise) =>
                            ie.UUID === fle.UUID)
                )
            }))
            return {
                ...state,
                draftAndInitialDraftIntersection: intersection
            }
        }

        case COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON: {
            return {
                ...state,
                draft: {
                    ...state.draft,
                    ExercisesMovedWithinLesson: []
                }
            }
        }

        case COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON: {
            const { result }: {result: DropResult} = action;
            
            const allExercisesInDraft: Exercise[] = [];
            state.draft.Lessons.forEach((lesson: Lesson) => lesson.Exercises.forEach((exercise: Exercise) => allExercisesInDraft.push(exercise)) );
            const exercise = allExercisesInDraft.find((exercise: Exercise) => exercise.UUID === result.draggableId);

            if (!exercise || !result.destination) 
                return state;
            
            const exercisePublicUUID = exercise.PublicUUID;

            const findInitialLesson = (publicExerciseUUID: string) =>
                state.initialDraft.Lessons.find((l: Lesson) => l.Exercises.some((e: Exercise) => e.PublicUUID === publicExerciseUUID));
            
            const findCurrentLesson = (publicExerciseUUID: string) =>
                state.draftAndInitialDraftIntersection.find((l: Lesson) => l.Exercises.some((e: Exercise) => e.PublicUUID === publicExerciseUUID));
            
            const findInitialPosition = (publicExerciseUUID: string, lesson?: Lesson) =>
                lesson?.Exercises.findIndex((e: Exercise) => e.PublicUUID === publicExerciseUUID);
            
            const findCurrentPosition = (publicExerciseUUID: string, lesson?: Lesson) =>
                lesson?.Exercises.findIndex((e: Exercise) => e.PublicUUID === publicExerciseUUID);

            const getDraftLessonPublicUUIDfromUUID = (uuid: string) => state.draft.Lessons.find((lesson: Lesson) => lesson.UUID === uuid)?.PublicUUID;

            const initialLesson = findInitialLesson(exercisePublicUUID);
            const initialPosition = findInitialPosition(exercisePublicUUID, initialLesson);

            const currentLesson = findCurrentLesson(exercisePublicUUID);
            const currentPosition = findCurrentPosition(exercisePublicUUID, currentLesson);

            const updatedList = [...state.draft.ExercisesMovedWithinLesson];


            if (initialLesson?.PublicUUID === getDraftLessonPublicUUIDfromUUID(result.destination.droppableId) && initialPosition !== currentPosition) {
                if (!state.draft.ExercisesMovedWithinLesson.some((uuid: string) => uuid === exercisePublicUUID)) {
                    // I ursprungslektion och på ny plats
                    updatedList.push(exercisePublicUUID);
                }
            } else {
                const index = state.draft.ExercisesMovedWithinLesson.findIndex((uuid: string) => uuid === exercisePublicUUID);
                if (index !== -1) updatedList.splice(index, 1);
            }

            for (let i = 0; i < updatedList.length; i++) {
                const uuid = updatedList[i];
                const initialLesson = findInitialLesson(uuid);
                const initialPosition = findInitialPosition(uuid, initialLesson);
                const currentLesson = findCurrentLesson(uuid);
                const currentPosition = findCurrentPosition(uuid, currentLesson);
                if (initialLesson?.UUID === result.destination.droppableId && initialPosition === currentPosition) {
                    const index = updatedList.findIndex((exerciseUuid: string) => uuid === exerciseUuid);
                    if (index !== -1) updatedList.splice(index, 1);
                }
            }

            return {
                ...state,
                draft: {
                    ...state.draft,
                    ExercisesMovedWithinLesson: updatedList
                }
            }
        }

        case COURSE_EDITOR_SET_MOVED_LESSONS: {

            const { result }: { result: DropResult } = action;

            if (!result.destination)
                return state;
            
            const initialPosition = state.initialDraft.Lessons.findIndex((lesson: Lesson) => lesson.PublicUUID === result.draggableId);

            if (initialPosition === -1)
                return state;
            
            let LessonsMoved = [...state.draft.LessonsMoved];

            if (initialPosition === result.destination.index) {
                const index = LessonsMoved.findIndex((uuid: string) => uuid === result.draggableId);
                LessonsMoved = LessonsMoved.splice(index, 1);
            }
            
            if (result.destination.index !== initialPosition)
                LessonsMoved.push(result.draggableId)

            return {
                ...state,
                draft: {
                    ...state.draft,
                    LessonsMoved
                }
            }
        }

        case COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED:
            return {
                ...state,
                publishAndLeaveConfirmed: action.status
            }
        
        case COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS:
            return {
                ...state,
                selectedVehicleOption: new ExerciseOption(),
                selectedCoursePublicUUID: "",
                selectedCourseImageUUID: "",
                selectedCourseName: ""
            }

        case COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE:
            return {
                ...state,
                selectedCoursePublicUUID: action.publicCourseUUID,
                selectedCourseImageUUID: action.imageUUID,
                selectedCourseName: action.courseName
            }
    }
    return state;
}
