export const colors = {
    blue: {
        lighter: "#DEE6F7",
        light: "#C2D1F5",
        mid: "#769CED",
        normal: "#3B86F7",
        dark: "#3059B4",
        darker: "#28385C"
    },
    green: {
        lighter: "#D2F1E5",
        light: "#A2EFD9",
        mid: "#42DFB0",
        normal: "#00D6AF",
        dark: "#0FB885",
        darker: "#14654E"
    },
    red: {
        lighter: "#FFE0E4",
        light: "#FAE0E0",
        mid: "#F3C3C3",
        normal: "#FF808F",
        dark: "#E3546E",
        darker: "#6C2029"
    },
    yellow: {
        lighter: "#F6F4C2",
        light: "#F6EEA6",
        mid: "#F1E78B",
        normal: "#FAE636",
        dark: "#BBA902",
        darker: "#806B00"
    },
    purple: {
        lighter: "#F1E8FC",
        light: "#EBE1F7",
        mid: "#BEABF3",
        normal: "#B781FC",
        dark: "#7538C2",
        darker: "#43216D"
    },
    blueGray: {
        lighter: "#EFF3F8",
        light: "#E7EDF5",
        mid: "#DAE1EC",
        normal: "#717F98",
        dark: "#373E4D",
        darker: "#282E3F"
    },
    gray: {
        lighter: "#EEEEEE",
        light: "#D6D6D6",
        mid: "#B4B4B4",
        normal: "#777777",
        dark: "#575757",
        darker: "#333333"
    },
};