import { sortBy } from "lodash";
import { safariDateFix } from "../../../helpers/time.helper";
import { Course } from "../../../types/course.types";
import { SchoolClass } from "../../../types/school.types";
import { Outlier, Progress, User } from "../../../types/user.types";

function getMedian(numbers: number[]) {
    const isEven = numbers.length % 2 === 0;
    return isEven ? (sortBy(numbers)[(numbers.length / 2) - 1] + sortBy(numbers)[(numbers.length / 2)]) / 2 :
        sortBy(numbers)[(numbers.length - 1) / 2];
}

function getQ1(numbers: number[]) {
    const isEven = numbers.length % 2 === 0;
    const lastIndex = numbers.length === 2 ? 2 : isEven ? (numbers.length / 2) - 1 : ((numbers.length + 1) / 2) - 1;
    return getMedian(sortBy(numbers).slice(0, lastIndex));
}

function getQ3(numbers: number[]) {
    const isEven = numbers.length % 2 === 0;
    const firstIndex = numbers.length === 2 ? 1 : isEven ? (numbers.length / 2) + 1 : ((numbers.length + 1) / 2);
    return getMedian(sortBy(numbers).slice(firstIndex, numbers.length));
}

function isLast12Months(LastActive: string): boolean {
    if (LastActive === "") return false;
    const aYearAgo = new Date();
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
    return new Date(safariDateFix(LastActive)).getTime() > aYearAgo.getTime();
}

export async function findOutliers(users: User[], courses: Course[], schoolClasses: SchoolClass[]) {

    const coursesProgressList: { schoolClassID: number, publicCourseUUID: string, userID: number, progress: number }[] = [];
    const limits: { schoolClassID: number, publicCourseUUID: string, lowerLimit: number, upperLimit: number, q1: number, q3: number }[] = [];
    const outliers: Outlier[] = [];
    const iqrConstant = .5;

    for (const schoolClass of schoolClasses) {

        const usersInClass = users.filter((user: User) => user.SchoolClassID === schoolClass.ID);
        const coursesInClass = courses.filter((course: Course) => schoolClass.CoursesUUID.includes(course.UUID));

        if (!usersInClass.some((u: User) => isLast12Months(u.LastActive)))
            continue;

        coursesInClass.forEach((course: Course) => {
            usersInClass.forEach((user: User) => {
                const progressList = user.Progress.filter((p: Progress) => p.PublicCourseUUID === course.PublicUUID);
                const numExercises = progressList.reduce((acc: number, current: Progress) => acc + current.ExerciseCount, 0);
                const numCompleted = progressList.reduce((acc: number, current: Progress) => acc + current.CompletedCount, 0);
                const progress = numCompleted / numExercises;
                coursesProgressList.push({ schoolClassID: schoolClass.ID, publicCourseUUID: course.PublicUUID, userID: user.ID, progress: progress });
            });
        });

        coursesInClass.forEach((course: Course) => {
            const progressList = coursesProgressList.filter((progressList: { schoolClassID: number, publicCourseUUID: string, userID: number, progress: number }) =>
                (progressList.publicCourseUUID === course.PublicUUID && progressList.schoolClassID === schoolClass.ID)
            );
            const progressOnlyList = progressList.map((progress: { schoolClassID: number, publicCourseUUID: string, userID: number, progress: number }) => progress.progress);
            const Q1 = getQ1(progressOnlyList);
            const Q3 = getQ3(progressOnlyList);
            if (!isNaN(Q1) || !isNaN(Q3)) {
                const IQR = Q3 - Q1;
                const lowerOutliersLimit = Q1 - iqrConstant * IQR;
                const upperOutliersLimit = Q3 + iqrConstant * IQR;
                limits.push({
                    schoolClassID: schoolClass.ID,
                    publicCourseUUID: course.PublicUUID,
                    lowerLimit: lowerOutliersLimit,
                    upperLimit: upperOutliersLimit,
                    q1: Q1,
                    q3: Q3
                });
            }
        });

        usersInClass.filter((u: User) => u.Role === 3).forEach((user: User) => {
            coursesInClass.forEach((course: Course) => {
                const progressList = user.Progress.filter((p: Progress) => p.PublicCourseUUID === course.PublicUUID);
                const numExercises = progressList.reduce((acc: number, current: Progress) => acc + current.ExerciseCount, 0);
                const numCompleted = progressList.reduce((acc: number, current: Progress) => acc + current.CompletedCount, 0);
                const progress = numCompleted / numExercises;
                const hasStarted = progressList.some(p => p.Runtime > 0);
                const courseLimits = limits.find((limit: { schoolClassID: number, publicCourseUUID: string, lowerLimit: number, upperLimit: number }) =>
                    (limit.publicCourseUUID === course.PublicUUID && limit.schoolClassID === schoolClass.ID));
                if (hasStarted && courseLimits) {
                    if (progress < courseLimits.lowerLimit && (courseLimits.q3 - progress) > .2) {
                        outliers.push({ schoolClassID: schoolClass.ID, PublicCourseUUID: course.PublicUUID, userID: user.ID, progress: progress, isBehind: true, diff: courseLimits.lowerLimit - progress })
                    } else if (progress > courseLimits.upperLimit && progress > .25) {
                        outliers.push({ schoolClassID: schoolClass.ID, PublicCourseUUID: course.PublicUUID, userID: user.ID, progress: progress, isBehind: false, diff: progress - courseLimits.lowerLimit })
                    }
                }
            });
        });
    };

    return outliers;

}


