
import React, { FormEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Prompt } from "react-router-dom";
import courseEditorActions, {
    getCourseDraft,
    getExerciseOptions,
    moveExercise,
    updateExercise,
    duplicateExercise,
    removeExercise,
    moveLesson,
    duplicateLesson,
    addEmptyLesson,
    changeCourseType,
    changeCourseNames,
    deleteCourseDraft,
    publishCourseDraft,
    deleteLesson,
    cloneExerciseFromKiosk,
    createAndGetCourseDraft,
    cloneExerciseFromExisting,
    cloneLessonFromKiosk,
    updateLesson,
    acquireLockCourse,
    getVehicleImages,
    updateCourse
} from "../../../actions/course-editor";
import { RootState } from "../../../reducers";
import { ExerciseOption, Option } from "../../../types/option.types";
import { SelectCourseModal, SkillsterLoader } from "../../global/Misc/Misc.components";
import { Droppable, DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import {
    AddLesson,
    CourseComparisonPreview,
    CourseEditorHeader,
    CourseImageSelectorModal,
    CourseIsLocked,
    DraftHeader,
    EditableLesson,
    ExerciseModal,
    ExitWarning,
    Kiosk,
    KioskClosed,
    LockTimeoutWarning,
    NoLessonsEmptyState,
    SkillsterToolTip,
    UnpublishedDraftOnServer
} from "./CourseEditor.components";
import { DroppableArea, DraggableLessonWrapper, MainAreaWrapper } from "./CourseEditor.styled.components";
import { Course, CourseDraft, CourseName, Exercise, ExerciseVariant, Lesson } from "../../../types/course.types";
import { COURSES_CUSTOM_PATH, COURSES_PATH } from "../../../navigation/Routes";
import actions, { fetchCourses, fetchLessons } from "../../../actions";
import { get } from "lodash";
import {
    isExistingExerciseDrop,
    isDropFromKiosk,
    isDropToLessons,
    groupVariantsByTemplateUUID,
    isDrop,
    isExerciseDrop,
    isLessonDrop,
    isExistingLessonDrop,
    alertUser,
    isLockedByOther,
    draftsAreEqual,
    getLessonUUIDfromPublicUUID
} from "./CourseEditor.helpers";
import { User } from "../../../types/user.types";
import { searchString } from "../../../helpers/universal.helper";
import { Lock } from "../../../types/editor.types";
import { useTranslation } from "react-i18next";

function CourseEditor() {
    const dispatch = useDispatch();
    const history = useHistory();
    const clonedLessonRef = useRef<HTMLDivElement>(null);
    const { publicUUID } = useParams<Record<string, string>>();
    const { t } = useTranslation();

    const coursesState = useSelector((state: RootState) => state.courses);
    const lessonsState = useSelector((state: RootState) => state.lessons);
    const exercisesState = useSelector((state: RootState) => state.exercises);
    const exerciseVariantsState = useSelector((state: RootState) => state.exerciseVariants);
    const courseEditorState = useSelector((state: RootState) => state.courseEditor);
    const filterState = useSelector((state: RootState) => state.filter);
    const modalsState = useSelector((state: RootState) => state.modals);
    const usersState = useSelector((state: RootState) => state.users);
    const loadingState = useSelector((state: RootState) => state.loading);
    const errorState = useSelector((state: RootState) => state.error);
    const locksState = useSelector((state: RootState) => state.locks);
    const permissionsState = useSelector((state: RootState) => state.permissions);

    const kioskSkillsterCoursesExercisesFilter = get(filterState, "kioskSkillsterCoursesExercises", "");
    const kioskExerciseVariantsFilter = get(filterState, "kioskExerciseVariants", "");

    const [compare, setCompare] = useState(false);
    const [selectedSkillsterCourseLessons, setSelectedSkillsterCourseLessons] = useState<Lesson[]>([]);
    const [filteredSkillsterCourseLessonsByExercise, setFilteredSkillsterCourseLessonsByExercise] = useState<Lesson[]>([]);
    const [groupedExerciseVariants, setGroupedExerciseVariants] = useState([[new ExerciseVariant()]]);
    const [modalExercise, setModalExercise] = useState<Exercise | ExerciseVariant>(new Exercise());
    const [modalExerciseScreenshotUUID, setModalExerciseScreenshotUUID] = useState<string | undefined>();
    const [draftIsEdited, setDraftIsEdited] = useState(false);
    const [lessonsCount, setLessonsCount] = useState(0);
    const [exercisesCount, setExercisesCount] = useState(0);
    const [draftIsLoaded, setDraftIsLoaded] = useState(false);
    const [nonPublishedDraftOnServer, setNonPublishedDraftOnServer] = useState(false);
    const [sourceUUID, setSourceUUID] = useState<string | undefined>(undefined)
    const [createdBy, setCreatedBy] = useState<User>(new User());
    const [lockState, setLockState] = useState({ hasChecked: false, isLocked: true });
    const [secondsLeft, setSecondsLeft] = useState<number | undefined>(undefined);
    const [leaveByTimeout, setLeaveByTimeout] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [deleteDraft, setDeleteDraft] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewConfig, setViewConfig] = useState(permissionsState.loggedInUserViewConfig);
    const [showExitWarning, setShowExitWarning] = useState(false);
    const [kioskClosed, setKioskClosed] = useState(false);
    const [getUnsavedDraft, setGetUnsavedDraft] = useState(false);

    const exerciseVariantMappedIdToUuid: { [id: number]: { vehicleUuid: string, variantUuid: string } } = useMemo(() => ({}), []);
    const loadingPublish: boolean = get(loadingState, "PUBLISH_COURSE_DRAFT", false);
    const loadingCreateAndGetDraft: boolean = get(loadingState, "CREATE_AND_GET_COURSE_DRAFT", false);
    const loadingGetDraft: boolean = get(loadingState, "GET_COURSE_DRAFT", false);
    const vehiclesHasData = courseEditorState.vehicleOptions.length > 0;
    const vehicleIsSelected = courseEditorState.selectedVehicleOption.Value !== "";

    const lock = locksState.list.find((lock: Lock) => lock.ResourceKey === publicUUID);
    const errorLock = get(errorState, "ACQUIRE_LOCK", false);
    const coursesURL = courseEditorState.draft.Native ? COURSES_PATH : COURSES_CUSTOM_PATH;

    const exerciseModalIsOpen = get(modalsState, "exercise", false);
    const courseImageSelectorIsOpen = get(modalsState, "courseImageSelector", false);
    const selectCourseModalIsOpen = get(modalsState, "selectCourse", false);

    const getIdealCompletionTimeFromExercise = useCallback((exercise: Exercise) => {
        return exercisesState.map[exercise.PublicUUID]?.EstimatedRuntime ?? 0;
    }, [exercisesState.map]);

    const onChangeVehicle = useCallback(
        (option: ExerciseOption) => {
            dispatch(courseEditorActions.courseEditor.setSelectedVehicle(option));
            dispatch(courseEditorActions.courseEditor.setExerciseVariantsSelectedVehicle(exerciseVariantsState.list.filter((ev: ExerciseVariant) => ev.Vehicles[option.Value])));
        }, [exerciseVariantsState.list, dispatch])

    const filteredExerciseVariants = useMemo(() => courseEditorState.exerciseVariantsSelectedVehicle.filter((e: ExerciseVariant) =>
        searchString(e.Name, kioskExerciseVariantsFilter)
    ), [kioskExerciseVariantsFilter, courseEditorState.exerciseVariantsSelectedVehicle]);

    useEffect(() => {
        const initialSelectedCourse = coursesState.list[0];
        if (coursesState.hasData && courseEditorState.selectedCoursePublicUUID === "")
            dispatch(courseEditorActions.kiosk.setSelectedCourse(initialSelectedCourse.PublicUUID, initialSelectedCourse.ImageUUID, initialSelectedCourse.Name));
    }, [dispatch, coursesState.list, coursesState.hasData, courseEditorState.selectedCoursePublicUUID]);

    useEffect(() => setViewConfig(permissionsState.loggedInUserViewConfig), [permissionsState.loggedInUserViewConfig]);

    useEffect(() => {
        errorLock && dispatch(acquireLockCourse(publicUUID))
    }, [errorLock, publicUUID, dispatch]);

    useEffect(() => {
        setLockState({
            hasChecked: true,
            isLocked: isLockedByOther(locksState.list, publicUUID)
        });
    }, [locksState.list, publicUUID]);

    useEffect(() => {
        const timeLeftInterval = setInterval(() => lock ? setSecondsLeft(Math.floor((new Date(lock.Expires).getTime() - new Date().getTime()) / 1000)) : null);
        return () => clearInterval(timeLeftInterval);
    }, [locksState.list, lock]);

    useEffect(() => {
        if (secondsLeft && secondsLeft < 1) {
            setDraftIsEdited(false);
            setLeaveByTimeout(true);
        }
    }, [secondsLeft]);

    useEffect(() => {
        if (leaveByTimeout) {
            history.push(coursesURL);
        }
    }, [dispatch, leaveByTimeout, publicUUID, history, coursesURL])

    useEffect(() => {
        setLessonsCount(courseEditorState.draft.Lessons.length);
        setExercisesCount(courseEditorState.draft.Lessons.reduce((acc: number, current: Lesson) => acc + current.Exercises.length, 0));
    }, [courseEditorState.draft.Lessons]);

    useEffect(() =>
    {
        setDraftIsLoaded(courseEditorState.draft.ID !== 0 && courseEditorState.initialDraft.ID !== 0)
    },
        [courseEditorState.draft, courseEditorState.initialDraft]);

    useEffect(() =>
        setCreatedBy(usersState.map[courseEditorState.draft.CreatedBy]),
        [usersState.map, courseEditorState.draft.CreatedBy]);

    useEffect(() =>
        setNonPublishedDraftOnServer(courseEditorState.existingDraftUUID !== ""),
        [courseEditorState.existingDraftUUID]);

    useEffect(() =>
        setSourceUUID(coursesState.list.find((c: Course) => c.PublicUUID === publicUUID)?.UUID),
        [coursesState.list, publicUUID]);

    useEffect(() => {
        if (sourceUUID && !draftIsLoaded && !isLeaving && !coursesState.isFetching)
            dispatch(createAndGetCourseDraft(sourceUUID, publicUUID))
    }, [dispatch, draftIsLoaded, sourceUUID]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!courseEditorState.vehicleImages.length) {
            dispatch(getVehicleImages());
        }
    }, [dispatch, courseEditorState.vehicleImages.length]);

    useEffect(() => {
        if (courseEditorState.isSaved) {
            if (sourceUUID && !coursesState.isFetching) dispatch(createAndGetCourseDraft(sourceUUID, publicUUID));
            dispatch(courseEditorActions.courseEditor.setIsSaved(false));
        }
    }, [dispatch, courseEditorState.isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        dispatch(getExerciseOptions());
        return function cleanup() {
            dispatch(deleteCourseDraft());
            dispatch(courseEditorActions.kiosk.clearSelectedOptions());
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [dispatch]);


    useEffect(() => {
        const isEdited = !draftsAreEqual(courseEditorState.initialDraft, courseEditorState.draft);
        if (draftIsLoaded && draftIsEdited !== isEdited)
            setDraftIsEdited(isEdited);
    }, [courseEditorState.draft, courseEditorState.initialDraft, draftIsLoaded, draftIsEdited]);


    useEffect(() => {
        if (vehiclesHasData && !vehicleIsSelected)
            onChangeVehicle(courseEditorState.vehicleOptions[0]);
    }, [vehiclesHasData, vehicleIsSelected, courseEditorState.vehicleOptions, onChangeVehicle]);

    useEffect(() => setSelectedSkillsterCourseLessons(lessonsState.list.filter((l: Lesson) =>
        l.PublicCourseUUID === courseEditorState.selectedCoursePublicUUID &&
        coursesState.map[courseEditorState.selectedCoursePublicUUID]?.Native
    ) ?? []), [lessonsState.list, coursesState.map, courseEditorState.selectedCoursePublicUUID]);

    useEffect(() => {
        clonedLessonRef.current && clonedLessonRef.current.scrollIntoView({ behavior: "smooth" })
    }, [courseEditorState.clonedLessonUUIDs])

    useEffect(() => {
        setFilteredSkillsterCourseLessonsByExercise(
            selectedSkillsterCourseLessons.filter((l: Lesson) =>
                l.Exercises.some((e: Exercise) => searchString(e.Name, kioskSkillsterCoursesExercisesFilter))
            ).map((lesson: Lesson) =>
            ({
                ...lesson,
                Exercises: lesson.Exercises.filter((exercise: Exercise) =>
                    searchString(exercise.Name, kioskSkillsterCoursesExercisesFilter))
            })
            )
        )
    }, [kioskSkillsterCoursesExercisesFilter, selectedSkillsterCourseLessons]);

    useEffect(() => {
        courseEditorState.exerciseVariantsSelectedVehicle.forEach((ev: ExerciseVariant) =>
            exerciseVariantMappedIdToUuid[ev.ID] = {
                vehicleUuid: ev.Vehicles[courseEditorState.selectedVehicleOption.Value],
                variantUuid: ev.VariantUUID
            }
        );
    }, [
        courseEditorState.selectedVehicleOption.Value,
        courseEditorState.exerciseVariantsSelectedVehicle,
        exerciseVariantMappedIdToUuid
    ])

    useEffect(() => {
        setGroupedExerciseVariants(groupVariantsByTemplateUUID(filteredExerciseVariants))
    }, [courseEditorState.exerciseVariantsSelectedVehicle, filteredExerciseVariants])

    useEffect(() => {
        if (deleteDraft) history.push(coursesURL);
    }, [dispatch, deleteDraft]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>
        setLoading(loadingPublish || loadingCreateAndGetDraft || loadingGetDraft),
        [loadingPublish, loadingCreateAndGetDraft, loadingGetDraft]);

    useEffect(() => {
        function publishAndLeave() {
            dispatch(publishCourseDraft(false, courseEditorState.draft.UUID, publicUUID, true));
            history.push(coursesURL);
        }
        if (courseEditorState.publishAndLeaveConfirmed) publishAndLeave();
    }, [courseEditorState.publishAndLeaveConfirmed]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!getUnsavedDraft)
            return;
        if (!coursesState.hasData) {
            dispatch(fetchCourses());
            return;
        }
        if (!lessonsState.hasData) {
            dispatch(fetchLessons());
            return;
        }
        if (courseEditorState.existingDraftUUID) {
            dispatch(getCourseDraft(courseEditorState.existingDraftUUID, false));
            if (courseEditorState?.duplicatedPublicCourseUUID && courseEditorState?.duplicatedPublicCourseUUID !== "") {
                const initialCourse = coursesState.map[courseEditorState.duplicatedPublicCourseUUID];
                const lessons: Lesson[] = lessonsState.list.filter(l => l.PublicCourseUUID === initialCourse.PublicUUID);
                const initialDraftClone: CourseDraft = {
                    ...new CourseDraft(),
                    ...initialCourse,
                    Lessons: lessons,
                    IsLinear: initialCourse.IsLinear,
                    ImageUUID: initialCourse.ImageUUID,
                    Name: initialCourse.Name,
                    Names: initialCourse.Names
                };
                dispatch(courseEditorActions.courseEditor.setInitialDraft(initialDraftClone));
            }
            dispatch(courseEditorActions.courseEditor.clearDuplicateUUID());
        }
    }, [dispatch, getUnsavedDraft, courseEditorState.existingDraftUUID, courseEditorState.duplicatedPublicCourseUUID, coursesState, lessonsState])

    useEffect(() => {

        if (loading)
            return;

        if (!isLeaving) {
            setShowExitWarning(false);
            return;
        }

        if (courseEditorState.publishAndLeaveConfirmed) {
            return;
        } else if (draftIsEdited) {
            setShowExitWarning(true);
        } else {
            setDeleteDraft(true);
        }

    }, [dispatch, isLeaving, loading]); // eslint-disable-line react-hooks/exhaustive-deps

    const onKeepLock = () => {
        dispatch(acquireLockCourse(publicUUID));
    }

    const onPublishDraft = () => {
        dispatch(publishCourseDraft(false, courseEditorState.draft.UUID, publicUUID));
    }

    const onUpdateCourseImage = (imageUUID: string) => {
        dispatch(courseEditorActions.courseEditor.setCourseImage(imageUUID));
        dispatch(updateCourse(courseEditorState.draft.IsLinear, imageUUID));
    }

    const getScreenshotUUIDfromExerciseVariant = (ev: ExerciseVariant) => {
        const vehicleUUID = ev.Vehicles[courseEditorState.selectedVehicleOption.Value];
        return ev.Screenshots ? ev.Screenshots[vehicleUUID] : undefined;
    }

    const getScreenshotUUIDFromExercise = (templateVariantUUID: string, vehicleUUID: string) => {
        const exerciseVariant = exerciseVariantsState.map[templateVariantUUID];
        if (!exerciseVariant?.Screenshots) return undefined;
        return exerciseVariant.Screenshots[vehicleUUID];
    }

    const getVehicleGUIDfromExercise = (exercise: Exercise) => {
        const exerciseVariant = exerciseVariantsState.map[exercise.TemplateVariantUUID];
        if (!exerciseVariant) return null;
        const guid = Object.keys(exerciseVariant.Vehicles).find((key: string) => exerciseVariant.Vehicles[key] === exercise.VehicleUUID);
        if (!guid) return null;
        return guid;
    }

    const onDiscardUnsavedDraftOnServer = () => {
        dispatch(deleteCourseDraft(true));
        sourceUUID && dispatch(createAndGetCourseDraft(sourceUUID, publicUUID));
    }

    const onGetUnsavedDraft = () => setGetUnsavedDraft(true);

    const sidebarRef: MutableRefObject<any> = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(360);

    const startResizing = React.useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = React.useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = React.useCallback(
        (mouseMoveEvent) => {
            if (isResizing) {
                setSidebarWidth(
                    mouseMoveEvent.clientX -
                    sidebarRef.current.getBoundingClientRect().left
                );
            }
        },
        [isResizing]
    );

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);
        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    const isLessonCloned = (uuid: string) =>
        courseEditorState.clonedLessonUUIDs.some((UUID: string) => UUID === uuid);

    const isExerciseCloned = (uuid: string) =>
        courseEditorState.clonedExerciseUUIDs.some((UUID: string) => UUID === uuid);

    const onPublishAsNewCourse = () => 
        dispatch(publishCourseDraft(true, courseEditorState.draft.UUID, publicUUID));
    
    const onUpdateDifficulty = (e: Exercise, difficulty: 0 | 1 | 2) =>
        dispatch(updateExercise(e.UUID, e.WeatherUUID ?? "", e.TrafficUUID ?? "", difficulty));

    const onUpdateWeather = (e: Exercise, selected: ExerciseOption) =>
        dispatch(updateExercise(e.UUID, selected.Value, e.TrafficUUID ?? "", e.Difficulty ?? 0));

    const onUpdateTraffic = (e: Exercise, selected: ExerciseOption) =>
        dispatch(updateExercise(e.UUID, e.WeatherUUID ?? "", selected.Value, e.Difficulty ?? 0));

    const onRemoveExercise = (index: number, board: string) =>
        dispatch(removeExercise(board, index));

    const onDuplicateExercise = (board: string, index: number) =>
        dispatch(duplicateExercise(board, index));

    const onChangeCourseNames = (names: CourseName[]) =>
        dispatch(changeCourseNames(names));

    const onChangeCourseType = (option: Option) =>
        dispatch(changeCourseType(option.value === 1));

    const onDuplicateLesson = (board: string, index: number) =>
        dispatch(duplicateLesson(board, index))

    const onAddLesson = () =>
        dispatch(addEmptyLesson());

    const onDeleteLesson = (index: number) =>
        dispatch(deleteLesson(index));

    const onChangeLessonName = (board: string, nameSv: string, nameFi: string, nameEn: string) =>
        dispatch(updateLesson(nameSv, nameFi, nameEn, getLessonUUIDfromPublicUUID(board, courseEditorState.draft)));

    const onChooseKiosk = (vehiclesIsActive: boolean) => dispatch(courseEditorActions.kiosk.setVehicleIsActive(vehiclesIsActive));

    const onOpenExerciseModal = (exercise: Exercise | ExerciseVariant) => {
        setModalExercise(exercise);
        if ("TemplateVariantUUID" in exercise && "VehicleUUID" in exercise) {
            setModalExerciseScreenshotUUID(getScreenshotUUIDFromExercise(exercise.TemplateVariantUUID, exercise.VehicleUUID))
        } else {
            setModalExerciseScreenshotUUID(getScreenshotUUIDfromExerciseVariant(exercise));
        }
        dispatch(actions.modals.setIsOpen("exercise", true));
    }

    const onCloseExerciseModal = () => dispatch(actions.modals.setIsOpen("exercise", false));

    const onUpdateFilterExerciseVariants = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        dispatch(actions.filter.setFilter("kioskExerciseVariants", target.value));
    }

    const onUpdateFilterSkillsterCourses = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        dispatch(actions.filter.setFilter("kioskSkillsterCoursesExercises", target.value));
    }

    const onSelectSkillsterCourse = useCallback(
        (course: Course) => dispatch(courseEditorActions.kiosk.setSelectedCourse(course.PublicUUID, course.ImageUUID, course.Name))
        , [dispatch]);

    const onDragStart = useCallback((result: DropResult) => {
        if (isLessonDrop(result))
            dispatch(courseEditorActions.lesson.dragStart());
        if (isExerciseDrop(result))
            dispatch(courseEditorActions.exercise.dragStart());
        }, [dispatch]);
        
    const onDragEnd = useCallback((result: DropResult) => {
        dispatch(courseEditorActions.lesson.dragEnd());
        dispatch(courseEditorActions.exercise.dragEnd());
        if (isDrop(result)) {
            if (!result.destination)
                return;
            if (isExerciseDrop(result)) {
                if (isDropFromKiosk(result)) {
                    const variantUUID = exerciseVariantMappedIdToUuid[Number(result.draggableId)].variantUuid;
                    const vehicleUUID = exerciseVariantMappedIdToUuid[Number(result.draggableId)].vehicleUuid;
                    const exercise = exercisesState.list.find((e: Exercise) => e.TemplateVariantUUID === variantUUID);
                    const weatherUUID = exercise?.WeatherUUID ?? courseEditorState.weatherOptions[0].Value;
                    const trafficUUID = exercise?.TrafficUUID ?? courseEditorState.trafficOptions[0].Value;
                    dispatch(cloneExerciseFromKiosk(
                        result,
                        variantUUID,
                        vehicleUUID,
                        weatherUUID,
                        trafficUUID,
                        courseEditorState.difficultyOptions[0].value
                    ));
                    return;
                } else if (isExistingExerciseDrop(result)) {
                    const exercise = exercisesState.list.find((e: Exercise) => e.UUID === result.draggableId);
                    if (exercise) dispatch(cloneExerciseFromExisting(result, exercise))
                    return;
                }
                dispatch(moveExercise(result));
                return;
            }
            if (isLessonDrop(result)) {
                if (!isDropToLessons(result))
                    return;
                if (isExistingLessonDrop(result)) {
                    const lesson = lessonsState.list.find((l: Lesson) => l.PublicUUID.toString() === result.draggableId);
                    if (lesson) dispatch(cloneLessonFromKiosk(result, lesson));
                    return;
                }
                dispatch(moveLesson(result));
            }
        }
    }, [
        dispatch,
        courseEditorState.difficultyOptions,
        courseEditorState.trafficOptions,
        courseEditorState.weatherOptions,
        exercisesState.list,
        lessonsState.list,
        exerciseVariantMappedIdToUuid
    ]);

    if (errorLock) {
        history.push(coursesURL);
    }

    if ((!draftIsLoaded && !nonPublishedDraftOnServer) || !lockState.hasChecked)
        return <SkillsterLoader />

    if (lockState.hasChecked && lockState.isLocked)
        return <CourseIsLocked onClose={() => { history.push(coursesURL) }} />

    return (
        <>
            <SkillsterToolTip />
            <Prompt
                message={t("courseEditor.leavePrompt")}
                when={draftIsEdited && !deleteDraft && !courseEditorState.publishAndLeaveConfirmed && !loading}
            />
            <SelectCourseModal
                isOpen={selectCourseModalIsOpen}
                courses={coursesState.list}
                onClose={() => dispatch(actions.modals.setIsOpen("selectCourse", false))}
                selectedPublicCourseUUID={courseEditorState.selectedCoursePublicUUID}
                onSelectCourse={onSelectSkillsterCourse}
            />
            <CourseComparisonPreview
                initial={courseEditorState.initialDraft}
                draft={courseEditorState.draft}
                onClose={() => setCompare(false)}
                isOpen={compare}
                weatherOptions={courseEditorState.weatherOptions}
                trafficOptions={courseEditorState.trafficOptions}
                difficultyOptions={courseEditorState.difficultyOptions}
                exercisesMovedWithinLesson={courseEditorState.draft.ExercisesMovedWithinLesson}
                lessonsMoved={courseEditorState.draft.LessonsMoved}
            />
            <CourseImageSelectorModal
                isOpen={courseImageSelectorIsOpen}
                imageUUIDs={courseEditorState.vehicleImages}
                selectedImageUUID={courseEditorState.draft.ImageUUID}
                onUpdateCourseImage={onUpdateCourseImage}
            />
            <ExerciseModal
                screenshotUUID={modalExerciseScreenshotUUID}
                exercise={{ ...modalExercise, Name: modalExercise.Name.replace(/([A-Z0-9])/g, ' $1').trim().replace(/(K M)/g, 'km') }}
                isOpen={exerciseModalIsOpen}
                onClose={onCloseExerciseModal}
            />
            <CourseEditorHeader
                title={courseEditorState.draft.Name}
                onExit={() => setIsLeaving(true)}
                onSave={onPublishDraft}
                onSaveCopy={onPublishAsNewCourse}
                onCompare={() => setCompare(!compare)}
                onChooseKiosk={onChooseKiosk}
                draftIsEdited={draftIsEdited}
                vehiclesIsActive={courseEditorState.vehicleKioskIsActive}
                lastSaved={courseEditorState.lastSaved[publicUUID]}
                isPublishing={loadingPublish}
                isUpdating={loading}
                created={courseEditorState.draft.Created}
                createdBy={createdBy}
                kioskClosed={kioskClosed}
                showAllExercisesInEditor={viewConfig.ShowAllExercisesInEditor}
            />
            <UnpublishedDraftOnServer
                uuid={courseEditorState.existingDraftUUID}
                onDiscardUnsavedDraft={onDiscardUnsavedDraftOnServer}
                onGetUnsavedDraft={onGetUnsavedDraft}
                isOpen={nonPublishedDraftOnServer}
            />
            <ExitWarning
                isOpen={showExitWarning}
                onCancelExit={() => setIsLeaving(false)}
                onConfirmExit={() => setDeleteDraft(true)}
                onExitAndSave={() => dispatch(courseEditorActions.courseEditor.setPublishAndLeaveConfirmed(true))}
            />
            <LockTimeoutWarning
                isOpen={secondsLeft ? secondsLeft < 60 : false}
                onKeepLock={onKeepLock}
                secondsLeft={secondsLeft}
            />
            <div
                ref={sidebarRef}
                style={{
                    display: "grid",
                    overflowX: "visible",
                    gridTemplateRows: `${viewConfig.ShowAllExercisesInEditor ? "115px" : "56px"} calc(100vh - 114px)`,
                    gridTemplateColumns: kioskClosed ? '50px minmax(1270px, 1fr)' : `clamp(340px, ${sidebarWidth}px, 900px) minmax(1270px, 1fr)`
                }}
            >
                <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={onDragEnd}>
                    {kioskClosed ?
                        <>
                            <KioskClosed onToggle={() => setKioskClosed(prev => !prev)} />
                        </>
                        :
                    <Kiosk
                            showAllExercisesInEditor={viewConfig.ShowAllExercisesInEditor}
                        vehiclesIsActive={courseEditorState.vehicleKioskIsActive}
                        vehicleOptions={courseEditorState.vehicleOptions}
                        selectedVehicleOption={courseEditorState.selectedVehicleOption}
                            filterExercises={kioskExerciseVariantsFilter}
                        groupedExerciseVariants={groupedExerciseVariants}
                            courseList={coursesState.list.filter(c => c.Native)}
                            filterSkillsterCourses={kioskSkillsterCoursesExercisesFilter}
                        skillsterLessons={filteredSkillsterCourseLessonsByExercise}
                        handleSearchBarExercises={onUpdateFilterExerciseVariants}
                        onChangeVehicle={onChangeVehicle}
                        getScreenshotUUIDfromExerciseVariant={getScreenshotUUIDfromExerciseVariant}
                            onOpenExerciseModal={onOpenExerciseModal}
                        handleSearchBarSkillsterCourses={onUpdateFilterSkillsterCourses}
                        getScreenshotUUIDFromExercise={getScreenshotUUIDFromExercise}
                        getVehicleGUIDfromExercise={getVehicleGUIDfromExercise}
                        getIdealCompletionTimeFromExercise={getIdealCompletionTimeFromExercise}
                            onToggle={() => setKioskClosed(prev => !prev)}
                            onOpenSkillsterCourseModal={() => dispatch(actions.modals.setIsOpen("selectCourse", true))}

                            selectedCourseImageUUID={courseEditorState.selectedCourseImageUUID}
                            selectedCourseName={courseEditorState.selectedCourseName}
                            selectedCoursePublicUUID={courseEditorState.selectedCoursePublicUUID}
                    />
                    }
                    <MainAreaWrapper>
                        {!kioskClosed &&
                            <div
                                className="resize"
                                onMouseDown={startResizing}
                            />
                        }
                        <div className="content">
                        <DraftHeader
                            name={courseEditorState.draft.Name}
                            names={courseEditorState.draft.Names}
                            imageUUID={courseEditorState.draft.ImageUUID}
                            onChangeCourseNames={onChangeCourseNames}
                            onChangeCourseType={onChangeCourseType}
                            lessonsCount={lessonsCount}
                            exercisesCount={exercisesCount}
                                idealCompletionTime={courseEditorState.draft.EstimatedRuntime}
                            isLinear={courseEditorState.draft.IsLinear}
                        />
                        <Droppable
                            droppableId="lessons"
                            type="lesson"
                        >
                            {(provided, snapshot) => {
                                return (
                                    <DroppableArea
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={`${snapshot.isDraggingOver ? "draggingOver" : ""} ${courseEditorState.isDraggingLesson ? "highlight" : ""}`}
                                    >
                                        {courseEditorState.draft.Lessons.map((l: Lesson, index) => {
                                            const isCloned = isLessonCloned(l.PublicUUID);
                                            return (
                                                <Draggable key={l.UUID} draggableId={l.PublicUUID} index={index} >
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <DraggableLessonWrapper
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`${snapshot.isDragging ? "dragging" : ""} ${isCloned ? "fadeInEntrance" : ""}`}
                                                            >
                                                                <div ref={isLessonCloned(l.PublicUUID) ? clonedLessonRef : null}>
                                                                    <EditableLesson
                                                                        exercises={l.Exercises}
                                                                        name={l.Name}
                                                                        names={l.Names}
                                                                        droppableId={l.PublicUUID}
                                                                        key={l.UUID}
                                                                        weatherOptions={courseEditorState.weatherOptions}
                                                                        trafficOptions={courseEditorState.trafficOptions}
                                                                        vehicleOptions={courseEditorState.vehicleOptions}
                                                                        difficultyOptions={courseEditorState.difficultyOptions}
                                                                        isExerciseCloned={isExerciseCloned}
                                                                        onUpdateWeather={onUpdateWeather}
                                                                        onUpdateTraffic={onUpdateTraffic}
                                                                        onUpdateDifficulty={onUpdateDifficulty}
                                                                        onRemoveExercise={onRemoveExercise}
                                                                        onDuplicateExercise={onDuplicateExercise}
                                                                        onDuplicateLesson={onDuplicateLesson}
                                                                        onDeleteLesson={onDeleteLesson}
                                                                        onChangeLessonName={onChangeLessonName}
                                                                        onOpenExerciseModal={onOpenExerciseModal}
                                                                        getScreenshotUUID={getScreenshotUUIDFromExercise}
                                                                        getVehicleGUIDfromExercise={getVehicleGUIDfromExercise}
                                                                        getIdealCompletionTimeFromExercise={getIdealCompletionTimeFromExercise}
                                                                        index={index}
                                                                        isDraggingExercise={courseEditorState.isDraggingExercise}
                                                                        isDraggingLesson={courseEditorState.isDraggingLesson}
                                                                        lessonIsCloned={isCloned}
                                                                    />
                                                                </div>
                                                            </DraggableLessonWrapper>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                        {courseEditorState.draft.Lessons.length === 0 && <NoLessonsEmptyState isDragging={false} />}
                                        {provided.placeholder}
                                    </DroppableArea>
                                );
                            }}
                        </Droppable>
                        {(!courseEditorState.isDraggingExercise && !courseEditorState.isDraggingLesson) && <AddLesson onClick={onAddLesson} />}
                        </div>
                    </MainAreaWrapper>
                </DragDropContext>
            </div>
        </>
    );
}

export default CourseEditor;