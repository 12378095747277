export class School {
  Active: boolean = false;
  ID: number = 0;
  Name: string = "";
  OrganizationID: number = 0;
  VrEnabled: boolean = false;
  Version: number = 0;
  ReleaseChannel: "stable" | "beta" = "stable";
  LicenseCount: number = 0;
  Address: string = "";
}

export class CourseAccess {
    OrganizationID: number = 0;
    PublicCourseUUID: string = "";
}

export interface CourseAccessMap {[publicCourseUUID: string]: number[]};

export class AccessModal {
    courseID: number = 0;
    publicCourseUUID: string = "";
    allowedOrganizations: number[] = [];
}

export class SchoolClass {
  ID: number = 0;
  Name: string = "";
  MemberCount: number = 0;
  CompletedCount: number = 0;
  ExerciseCount: number = 0;
  IsBehind: boolean = false;
  WizardStep: number = 0;
  Courses: number[] = [];
  CoursesUUID: string[] = [];
  FuelConsumption: number = 0;
  TravelDistance: number = 0;
  SchoolID: number = 0;
  OrganizationID: number = 0;
  RunTime: number = 0;
  CreationTime: string = "";
  CreatedBy: number = 0;
  LastActiveTime: string = "";
  CreatedByName: string = "";
}

// export class SchoolClassExtraData {
//     ID: number = 0;
//     CostCenter: string = "";
//     DriversLicense: string = "";
//     DriversLicenseID: number = 0;
//     EducationType: string = "";
//     EducationTypeID: number = 0;
// }

export class SchoolClassMiniStats {
  travelDistance: number = 0;
  fuelConsumption: number = 0;
  runTime: number = 0;
  creationTime?: string;
  createdByName?: string;
}

export class EducationType {
    ID: number = 0;
    Name: string = "";
}

export class DriversLicense {
    ID: number = 0;
    Name: string = "";
}
