import { Container, Group, Image, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { get, trim } from "lodash";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import actions, { changeOrganization, changeSchool, sortList } from "../../../actions";
import { useGetOnlineUsersFromSchool } from "../../../api/onlineUsers";
import { useGetLoggedInUser } from "../../../helpers/hooks";
import { localStorageGet } from "../../../helpers/localstorage.helper";
import { svLocale } from "../../../helpers/universal.helper";
import { HOME_PATH, USERS_PATH, goToTopLevelRoute } from "../../../navigation/Routes";
import { RootState } from "../../../reducers";
import { Organization } from "../../../types/organization.types";
import { School } from "../../../types/school.types";
import { SortOrder } from "../../../types/sort.types";
import { IconChevron } from "../Misc/Symbols.components";
import skillsterLogoEn from "../Misc/resources/icons/teacher-logo-en.svg";
import skillsterLogoSe from "../Misc/resources/icons/teacher-logo-se.svg";
import { Feedback, MenuItemsDesktop, OnlineCounter, OrganizationsList, OrganizationsSelector, SchoolsList, SchoolsSelector, UserDropDown } from "./Header.components";
import { MobileHeader } from "./Header.styled.components";

function Header() {
    const { t } = useTranslation();
    const organizationState = useSelector((state: RootState) => state.organization);
    const filterState = useSelector((state: RootState) => state.filter);
    const sortState = useSelector((state: RootState) => state.sort);
    const mobileMenuState = useSelector((state: RootState) => state.mobileMenu);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { organizations, selectedOrganization, schools } = organizationState;
    const organizationsFilter = get(filterState, "organizations", "");
    const schoolsFilter = get(filterState, "schools", "");
    const filteredOrganizations = organizations?.filter((o: Organization) => o.Name.toLowerCase().includes(trim(organizationsFilter.toLowerCase())) && o.Active);
    const filteredSchools = schools.filter((s: School) => s.Name.toLowerCase().includes(trim(schoolsFilter.toLowerCase())));
    const selectedSchool = organizationState.schools?.find((s: School) => s.ID === Number(localStorageGet("school_id"))) || new School();
    const { firstName, lastName, loggedInUserID } = useGetLoggedInUser();
    const loggedInUserRole = Number(localStorageGet("role"));
    const onlineUsersQuery = useGetOnlineUsersFromSchool();
    const [onlineUsersTooltip, setOnlineUsersTooltip] = useState<string | undefined>(undefined);
    const mobile = useMediaQuery(`(max-width: 795px)`);
    const xl = useMediaQuery(`(min-width: 1030px)`);

    useEffect(() => {
        if (onlineUsersQuery.isLoading || onlineUsersQuery.isError || onlineUsersQuery.isIdle) {
            setOnlineUsersTooltip(undefined);
        } else {
            const count = onlineUsersQuery.data.length;
            let string = "";
            if (count === 0) {
                string = t("header.onlineCounter.noOne");
            }
            else if (count === 1) {
                string = t("header.onlineCounter.one", { name: onlineUsersQuery.data[0]?.FirstName });
            } else {
                string = t("header.onlineCounter.twoOrMore", { name: onlineUsersQuery.data[0]?.FirstName, otherCount: count - 1 });
            }
            setOnlineUsersTooltip(string);
        }
    }, [onlineUsersQuery, t])

    const openOrganizationList = () => {
        dispatch(actions.organizations.setSchoolsIsOpen(false));
        dispatch(actions.organizations.setOrganizationsIsOpen(true));
    }
    const openSchoolList = () => {
        dispatch(actions.organizations.setOrganizationsIsOpen(false));
        dispatch(actions.organizations.setSchoolsIsOpen(true));
    }
    const closeOrganizationList = () => dispatch(actions.organizations.setOrganizationsIsOpen(false));
    const closeSchoolList = () => dispatch(actions.organizations.setSchoolsIsOpen(false));
    const closeOrgAndSchoolLists = () => {
        closeOrganizationList();
        closeSchoolList();
    }
    const openFeedbackModal = () => dispatch(actions.modals.setIsOpen("feedback", true));
    const closeMobileMenu = () => dispatch(actions.mobileMenu.setIsOpen(false));

    const onClickMobileLogo = () => dispatch(actions.mobileMenu.setIsOpen(!mobileMenuState.isOpen));
    const onLogOut = () => dispatch(actions.auth.logout());

    const onClickOrganization = (o: Organization) => {
        dispatch(changeOrganization(o));
        goToTopLevelRoute(location.pathname, history);
    }

    const onClickSchool = (s: School) => {
        dispatch(changeSchool(s));
        goToTopLevelRoute(location.pathname, history);
    }

    const handleFilterOrganizations = (event: FormEvent<Element>) => {
        const target = event.target as HTMLInputElement;
        dispatch(actions.filter.setFilter("organizations", target.value));
    }

    const handleFilterSchools = (event: FormEvent<Element>) => {
        const target = event.target as HTMLInputElement;
        dispatch(actions.filter.setFilter("schools", target.value));
    }

    return (
        <>
            {mobile ? (
                <MobileHeader mobileMenuIsOpen={mobileMenuState.isOpen} className="hideOnPrint">
                    <div className="left">
                        <Link to={USERS_PATH} onClick={() => dispatch(sortList(sortState.users, "IsOnline", actions.sort.sortUserList, SortOrder.Descending))}>
                            <OnlineCounter numOnline={onlineUsersQuery.data?.length} loading={onlineUsersQuery.isLoading} />
                        </Link>
                        <Feedback onClick={openFeedbackModal} />
                    </div>
                    <div className="center">
                        <div className="logo" onClick={onClickMobileLogo}>
                            <Image src={svLocale() ? skillsterLogoSe : skillsterLogoEn} />
                            <IconChevron className="chevron" />
                        </div>
                    </div>
                    <div className="right">
                        <UserDropDown
                            firstName={firstName}
                            lastName={lastName}
                            userID={loggedInUserID}
                            onLogout={onLogOut}
                            role={loggedInUserRole}
                            handleCloseOrgSchool={closeOrgAndSchoolLists}
                        />
                    </div>
                </MobileHeader>
            ) : (
                <Container fluid className="hideOnPrint" sx={(theme) => ({ backgroundColor: theme.colors.bluegray[9] })} px={0}>
                    <Container size="lg" py="sm">
                        <Group position="apart">
                            <Link to={HOME_PATH}>
                                <Image src={svLocale() ? skillsterLogoSe : skillsterLogoEn} />
                            </Link>
                            <Group>
                                <Link to={USERS_PATH} onClick={() => dispatch(sortList(sortState.users, "IsOnline", actions.sort.sortUserList, SortOrder.Descending))} style={{ marginLeft: "auto" }}>
                                    <Tooltip label={onlineUsersTooltip} openDelay={500} disabled={onlineUsersQuery.isLoading}>
                                        <OnlineCounter numOnline={onlineUsersQuery.data?.length} loading={onlineUsersQuery.isLoading} />
                                    </Tooltip>
                                </Link>
                                    <Tooltip label={t("common:feedbackTooltip")} openDelay={500}>
                                    <Feedback onClick={openFeedbackModal} />
                                </Tooltip>
                                <MenuItemsDesktop onClick={closeOrgAndSchoolLists} closeMobileMenu={closeMobileMenu} />
                                </Group>
                                <Group>
                                    {xl ? (
                                        <>
                                            <OrganizationsSelector
                                                show={organizationState.organizations.length > 0}
                                                selectedOrganization={selectedOrganization}
                                                isOpen={organizationState.organizationsIsOpen}
                                                onClick={organizationState.organizationsIsOpen ? closeOrganizationList : openOrganizationList}
                                            />
                                            <SchoolsSelector
                                                selectedSchool={selectedSchool}
                                                isOpen={organizationState.schoolsIsOpen}
                                                onClick={organizationState.schoolsIsOpen ? closeSchoolList : openSchoolList}
                                            />
                                        </>
                                    ) : null}
                                    <UserDropDown
                                        firstName={firstName}
                                        lastName={lastName}
                                        userID={loggedInUserID}
                                        onLogout={onLogOut}
                                        role={loggedInUserRole}
                                        handleCloseOrgSchool={closeOrgAndSchoolLists}
                                    />
                                </Group>
                            </Group>
                        </Container>
                    </Container>
            )}


            <OrganizationsList
                organizations={filteredOrganizations}
                onClear={() => dispatch(actions.filter.clearFilter("organizations"))}
                isOpen={organizationState.organizationsIsOpen}
                onClick={onClickOrganization}
                handleFilter={handleFilterOrganizations}
                searchBarValue={organizationsFilter}
                handleClose={closeOrganizationList}
            />
            <SchoolsList
                schools={filteredSchools}
                onClear={() => dispatch(actions.filter.clearFilter("schools"))}
                isOpen={organizationState.schoolsIsOpen}
                onClick={onClickSchool}
                handleFilter={handleFilterSchools}
                searchBarValue={schoolsFilter}
                handleClose={closeSchoolList}
            />
        </>
    );
}

export default Header;