import { useDispatch } from "react-redux";
import actions from "../../../actions";
import { Trans } from 'react-i18next'
import { MenuItems } from "../Header/Header.components";
import { Logo, LanguageSelector } from "../Misc/Misc.components";
import { ContactPerson } from "./Footer.components";
import { FooterWrapper } from "./Footer.styled.components";
import { useTranslation } from 'react-i18next';
import { GuideBoxWrapper } from "../../pages/Support/Support.styled.components";
import { Link } from "react-router-dom";
import { INSTALLATION_PATH } from "../../../navigation/Routes";
import { Box, Button, Container, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { isAdminUser } from "../../../helpers/universal.helper";
import { IconRefresh } from "@tabler/icons";

function Footer() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const closeMobileMenu = () => dispatch(actions.mobileMenu.setIsOpen(false));
    const openCookiesModal = () => dispatch(actions.modals.setIsOpen("cookies", true));

    const [desktop, setDesktop] = useState(false);

    useEffect(() => {
        const myViewport = document.querySelector("meta[name=viewport]");
        if (desktop) {
            myViewport?.setAttribute("content", "width=1024");
        } else {
            myViewport?.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0");
        }
    }, [desktop]);
    return (
        <>
            <FooterWrapper className="hideOnPrint">
                <Container size="lg">
                    <div className="content">
                        <div className="col1">
                            <Logo className="logo" />
                            <div className="copyright">
                                © {new Date().getFullYear()} Skillster
                            </div>
                            <div className="address">
                                <span>{t('footer.address.line1')}</span>
                                <span>{t('footer.address.line2')}</span>
                                <span>{t('footer.address.line3')}</span>
                                <a href="https://www.skillster.se" target="_blank" rel="noreferrer">www.skillster.se</a>
                            </div>
                        </div>
                        <div className="col2">
                            <div className="menuItems">
                                <MenuItems footer closeMobileMenu={closeMobileMenu} />
                            </div>
                            <ContactPerson />
                        </div>
                        <Stack>
                            <GuideBoxWrapper>
                                <strong>{t('support.guideBox.title')}</strong>
                                <Link to={INSTALLATION_PATH}>
                                    <Button>
                                        {t('support.guideBox.button')}
                                    </Button>
                                </Link>
                            </GuideBoxWrapper>
                            <div className="cookies">
                                <Text>
                                    <Trans i18nKey="footer.cookies">
                                        text <span onClick={openCookiesModal} className="link underscore"> link</span> text
                                    </Trans>
                                </Text>
                            </div>
                            <Box sx={{ marginLeft: "auto" }}><LanguageSelector /></Box>
                        </Stack>
                    </div>
                    {isAdminUser() ? (
                        <Button
                            variant="light"
                            onClick={() => setDesktop(!desktop)}
                            leftIcon={<IconRefresh size={12} />}
                            mt="xl"
                        >
                            {desktop ? "Mobilläge" : "Desktopläge"}
                        </Button>
                    ) : null}
                </Container>
            </FooterWrapper>
        </>
    );
}

export default Footer;