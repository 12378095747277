import { colors } from "./palette";

const teacherTheme = {
    colors: {
        primary: {
            lighter: colors.blue.lighter,
            light: colors.blue.light,
            mid: colors.blue.mid,
            normal: colors.blue.normal,
            dark: colors.blue.dark,
            darker: colors.blue.darker
        },
        secondary: {
            lighter: colors.green.lighter,
            light: colors.green.light,
            mid: colors.green.mid,
            normal: colors.green.normal,
            dark: colors.green.dark,
            darker: colors.green.darker
        },
        comp1: {
            lighter: colors.red.lighter,
            light: colors.red.light,
            mid: colors.red.mid,
            normal: colors.red.normal,
            dark: colors.red.dark,
            darker: colors.red.darker
        },
        comp2: {
            lighter: colors.yellow.lighter,
            light: colors.yellow.light,
            mid: colors.yellow.mid,
            normal: colors.yellow.normal,
            dark: colors.yellow.dark,
            darker: colors.yellow.darker
        },
        comp3: {
            lighter: colors.purple.lighter,
            light: colors.purple.light,
            mid: colors.purple.mid,
            normal: colors.purple.normal,
            dark: colors.purple.dark,
            darker: colors.purple.darker
        },
        comp4: {
            lighter: colors.blueGray.lighter,
            light: colors.blueGray.light,
            mid: colors.blueGray.mid,
            normal: colors.blueGray.normal,
            dark: colors.blueGray.dark,
            darker: colors.blueGray.darker
        },
        comp5: {
            lighter: colors.gray.lighter,
            light: colors.gray.light,
            mid: colors.gray.mid,
            normal: colors.gray.normal,
            dark: colors.gray.dark,
            darker: colors.gray.darker
        }
    },
    border: {
        radius: {
            small: "2px",
            medium: "4px",
            large: "6px",
            xlarge: "8px"
        }
    },
    page: {
        width: "1140px"
    },
    breakpoints: {
        small: "375px",
        mobile: "800px",
        medium: "1000px",
        large: "1045px",
        xlarge: "1070px"
    },
    footer: {
        height: "300px"
    },
    buttons: {
        height: {
            small: "36px",
            medium: "44px"
        }
    },
    boxShadow: "0px 7px 19px rgba(0, 0, 0, 0.1)"
};

export default teacherTheme;