import { Exercise } from "../types/course.types";
import { useQuery } from "react-query";
import { apiGet } from "../helpers/requests.helper";
import {fetchLang} from "../helpers/universal.helper"

export function useGetExercises() {
    return useQuery(
      ["exercises"],
      async () => {
        const data = await apiGet("/exercises", { language: fetchLang() });
        const list: Exercise[] = data.Exercises;
        const map: Record<string, Exercise> = {};
        list.forEach((e) => (map[e.PublicUUID] = e));
        return { list, map };
      },
      {
        staleTime: 20 * 1000,
      }
    );
}