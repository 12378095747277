import { get } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import {localStorageGet} from "./localstorage.helper";

class NetworkError extends Error {
  Response: any;
  StatusCode: number;

  constructor(
    statusCode: number,
    statusText: string,
    endpoint: string,
    body: any
  ) {
    super(`API error ${endpoint}: ${statusCode} ${statusText}`);
    this.StatusCode = statusCode;
    this.Response = body;
  }
}

export class ErrorResponse extends Error {
    Response: any;

    constructor(response: any) {
        super(`Error Response: ${response.toString()}`);
        this.Response = response;
    }
}

async function apiGenericRequest(method: string, endpoint: string, extraArgs: any, body: any, signal?: AbortSignal): Promise<any> {
    const token = localStorageGet("authentication_token");
    const schoolID = extraArgs?.school_id ? undefined : localStorageGet("school_id");
    const loggedIn = localStorageGet("status") === "logged in";

    let querySchoolID = schoolID ? `&school_id=${schoolID}` : '';

    if (loggedIn && (!Number(schoolID) && querySchoolID === '' && !extraArgs)) throw new Error();

    let query = `?token=${token}${querySchoolID}`;
    for (let key in extraArgs) {
        if (extraArgs.hasOwnProperty(key)) {
            query += `&${key}=${extraArgs[key]}`;
        }
    }

    if (body !== undefined) body = JSON.stringify(body);
    const response = await fetch(`${process.env.REACT_APP_API_URI}/frontend${endpoint}${query}`,{ method, body, signal });
    
    const json = await response.json();

    if (!response.ok) throw new NetworkError(response.status, response.statusText, endpoint, json);

    if (json.Status !== 1) throw new ErrorResponse(json.Error);

    return json.Data;
}

export function apiGet(endpoint: string, extraArgs?: any, signal?: AbortSignal): Promise<any> {
    return apiGenericRequest("GET", endpoint, extraArgs, undefined, signal);
}

export function apiPost(endpoint: string, body: any, extraArgs?: any): Promise<any> {
  return apiGenericRequest("POST", endpoint, extraArgs, body);
}

export function apiDelete(endpoint: string, body: any): Promise<any> {
    return apiGenericRequest("DELETE", endpoint, undefined, body);
}

export function apiPut(endpoint: string, body: any): Promise<any> {
    return apiGenericRequest("PUT", endpoint, undefined, body);
}

export function apiUnauthenticatedPost(endpoint: string, body: any): Promise<any> {
    return apiGenericRequest("POST", endpoint, null, body);
}

export function useGetLoadingStates(type: string): {
  loading: boolean;
  error: boolean;
  success: boolean;
} {
  const loadingState = useSelector((state: RootState) => state.loading);
  const errorState = useSelector((state: RootState) => state.error);
  const successState = useSelector((state: RootState) => state.success);
  const loading: boolean = get(loadingState, type, false);
  const error: boolean = get(errorState, type, false);
  const success: boolean = get(successState, type, false);
  return { loading, error, success };
}