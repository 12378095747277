import styled from "styled-components";

export const DescriptionWrapper = styled.div`
  .dot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 0.5s infinite linear alternate;
    animation-delay: 0.25s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -12px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 500ms infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 12px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primary.normal};
    color: ${(props) => props.theme.colors.primary.normal};
    animation: dotFlashing 500ms infinite alternate;
    animation-delay: 500ms;
  }

  @keyframes dotFlashing {
    0% {
      background-color: ${(props) => props.theme.colors.primary.normal};
    }
    50%,
    100% {
      background-color: ${(props) => props.theme.colors.primary.light};
    }
  }

  .inner {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;
  }

  .bug-info,
  .bug-description {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.comp4.light};

    padding: 20px;
  }
  .bug-description {
    justify-content: flex-end;
    flex-grow: 1;
    .section {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 15px;
      max-width: 50%;
      &:not(.skillster):not(.skillster-private) {
        .bubble {
          .text:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 0;
            height: 0;
            border: 9px solid transparent;
            border-right-color: ${(props) => props.theme.colors.primary.normal};
            border-left: 0;
            margin-top: -9px;
            margin-left: -9px;
          }
        }
      }
      &.skillster {
        margin-left: auto;
        .bubble {
          .text {
            background-color: ${(props) => props.theme.colors.comp4.dark};
            :after {
              content: "";
              position: absolute;
              right: 0px;
              bottom: 10px;
              width: 0;
              height: 0;
              border: 9px solid transparent;
              border-left-color: ${(props) => props.theme.colors.comp4.dark};
              border-right: 0;
              margin-top: -9px;
              margin-right: -9px;
            }
          }
        }
      }
      &.skillster-private {
        margin-left: auto;
        .bubble {
          .text {
            background-color: ${(props) => props.theme.colors.comp4.dark};
            opacity: 0.8;
            :after {
              content: "";
              position: absolute;
              right: 0px;
              bottom: 10px;
              width: 0;
              height: 0;
              border: 9px solid transparent;
              border-left-color: ${(props) => props.theme.colors.comp4.dark};
              border-right: 0;
              margin-top: -9px;
              margin-right: -9px;
            }
          }
        }
      }
    }
    .bubble {
      .name {
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 0.8em;
        color: rgba(255, 255, 255, 0.6);
      }
      .date {
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        margin-top: 2px;
        margin-bottom: 5px;
        font-size: 0.8em;
      }
      &.dots {
        .text {
          padding: 20px 30px;
          min-width: 0;
        }
      }
      .text {
        position: relative;
        font-weight: 600;
        font-size: 1em;
        padding: 9px 13px 3px 13px;
        min-width: 250px;
        border-radius: ${(props) => props.theme.border.radius.large};
        background-color: ${(props) => props.theme.colors.primary.normal};
        color: white;
        box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.1);
      }
      ol,
      ul {
        margin-left: 2rem;
      }
      blockquote {
        color: rgba(255, 255, 255, 0.6);
        margin: 8px 0;
        padding: 0 0 0 8px;
        border-left: 2px solid rgba(255, 255, 255, 0.6);
      }
      .message-content a {
        color: white;
        text-decoration: underline;
      }
      .message-content blockquote a {
        color: rgba(255, 255, 255, 0.6);
      }
      .message-more {
        color: rgba(255, 255, 255, 0.6);
        font-size: 0.8em;
        cursor: pointer;
      }
    }
  }
`;

export const NoReplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 7px 19px rgba(0, 0, 0, 0.01);
  border-radius: 16px;
  width: 250px;
  height: 100%;
  min-height: 200px;
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.comp4.normal};
  user-select: none;
`;

export const LazyLogWrapper = styled.div`
  &.window {
    position: relative;
    margin-top: -50px;
    width: 90%;
    height: 80%;
    button.close {
      position: absolute;
      top: 10px;
      left: 10px;
      .icon {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        .fill {
          fill: white;
        }
      }
    }
  }
  input.react-lazylog-searchbar-input {
    padding: 20px 10px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
