import ReactGA from "react-ga";
import { enGB, sv, fi } from "date-fns/locale";
import { localStorageGet } from "./localstorage.helper";
import { useEffect, useRef } from "react";
import { dateFormat, datesAreOnSameDay } from "./time.helper";
import { format } from "date-fns";
import { IconLongArrow } from "../components/global/Misc/Symbols.components";
import { Group, Text } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";

export const GAevent = (
  categoryName: string,
  eventName: string,
  labelName?: string
) => {
  if (!isDevel()) {
    ReactGA.event({
      category: categoryName, // Required
      action: eventName, // Required
      label: labelName ? labelName : "label",
      value: 10,
      nonInteraction: false,
    });
  }
};

export const fuelConumptionToLiters = (fuelConsumption: number) =>
  fuelConsumption / 0.81;

export const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export const fetchLang = () =>
  svLocale() ? "sv-SE" : fiLocale() ? "fi-FI" : "en-US";
export const svLocale = () => localStorageGet("i18nextLng")?.startsWith("sv");
export const fiLocale = () => localStorageGet("i18nextLng")?.startsWith("fi");
export const enLocale = () => localStorageGet("i18nextLng")?.startsWith("en");

const nthChildObjectGeneratorList = (i: number) => ({
  [`.animate-in:nth-of-type(${i + 1})`]: {
    animationDelay: `${`${i * 20}`}ms`,
  },
});

export function getNthChildObjectStylingList() {
  let objs = [];
  for (let i = 0; i < 20; i += 1) {
    objs.push(nthChildObjectGeneratorList(i));
  }
  return objs;
}

function nthChildGeneratorList(i: number) {
  return `
        :nth-child(${i + 1}) > div {
            animation-delay: ${`${i * 20}`}ms;
        }
    `;
}
function nthChildGeneratorGrid(i: number) {
  return `
        :nth-child(${i + 1}) {
            animation-delay: ${`${i * 20}`}ms;
        }
    `;
}

export function getNthChildStylingList() {
  let str = "";
  for (let i = 0; i < 20; i += 1) {
    str += nthChildGeneratorList(i);
  }
  return str;
}

export function getNthChildStylingGrid() {
  let str = "";
  for (let i = 0; i < 20; i += 1) {
    str += nthChildGeneratorGrid(i);
  }
  return str;
}

export const isDevel = () =>
  process.env.REACT_APP_API_URI !== "https://admin.skillster.se";

export const PageView = () =>
  localStorageGet("status") === "logged in" &&
  localStorageGet("user_id") !== "0" &&
  localStorageGet("organization_id") !== "1" &&
  !isDevel() &&
  ReactGA.pageview(window.location.pathname + window.location.search);

export function getCourseBackgroundIDfromUUID(uuid: string) {
  const digits = uuid.match(/[0-9]/g) ?? [0];
  const firstDigit = Number(digits[0]);
  return Math.ceil(firstDigit * 0.5);
}

const normalizeString = (str: string) =>
  str.normalize("NFD").replace(/\p{Diacritic}/gu, "");

export const searchString = (source: string, searchString: string) =>
  normalizeString(searchString)
    .split(" ")
    .every((s: string) =>
      normalizeString(source).trim().toLowerCase().includes(s.toLowerCase())
    );

export const isAdminUser = () => localStorageGet("role") === "1";
export const isAdminUserOnSkillsterOrganization = () =>
  isAdminUser() && localStorageGet("organization_id") === "1";

export function downloadObjectAsJson(
  exportObj: string,
  exportName: string,
  exportFiletype?: string
) {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(JSON.parse(exportObj), null, 4));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute(
    "download",
    exportName + (exportFiletype ? `.${exportFiletype}` : ".json")
  );
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function eqSet(as: Set<any>, bs: Set<any>) {
  if (as.size !== bs.size) return false;
  for (var a of as) if (!bs.has(a)) return false;
  return true;
}

export function base64ToArrayBuffer(base64: string) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export const getFreshDeskUrl = (ticketId: number): string =>
  `https://skillster.freshdesk.com/a/tickets/${ticketId}`;

export function formatDateRangeWithArrow(from?: Date, to?: Date): JSX.Element {
  if (!from) return <></>;
  if (!to || datesAreOnSameDay(from, to))
    return (
      <>{`${format(new Date(from), dateFormat(from, to), {
        locale: svLocale() ? sv : fiLocale() ? fi : enGB,
      })}`}</>
    );

  return (
    <Group align="center" spacing="xs">
      <Text>
        {format(new Date(from), dateFormat(from, to), {
          locale: svLocale() ? sv : fiLocale() ? fi : enGB,
        })}
      </Text>
      <IconLongArrow />
      <Text>
        {format(new Date(to), dateFormat(from, to), {
          locale: svLocale() ? sv : fiLocale() ? fi : enGB,
        })}
      </Text>
    </Group>
  );
}

export const toBase64 = (file: FileWithPath) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
