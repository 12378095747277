import styled, { css } from "styled-components";
import { sonar } from "../../../resources/styles/animations";
interface MobileHeaderProps {
    mobileMenuIsOpen?: boolean;
}

export const MobileHeader = styled.div<MobileHeaderProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 10px;
    background-color: ${props => props.theme.colors.comp4.darker};
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
    > div {
        display: flex;
        flex: 1;
    }
    .left {
        > :first-child {
            margin-right: 10px;
        }
    }
    .right {
        justify-content: flex-end;
    }
    .center {
        justify-content: center;
        flex: 2;
        margin: 0 10px;
    }
    .logo {
        position: relative;
        display: flex;
        align-items: center;
        .chevron {
            flex-shrink: 0;
            margin-left: 10px;
            transform: ${props => props.mobileMenuIsOpen ? "rotate(180deg)" : "rotate(0)"};
            transition: transform 200ms ease-in-out;
        }
        img {
            width: 100%;
        }
    }
`;

interface SelectorWrapperProps {
    isOpen?: boolean;
}

export const SelectorWrapper = styled.div<SelectorWrapperProps>`
    display: flex;
    position: relative;
    user-select: none;
    max-width: 115px;
    overflow-x: hidden;
    align-items: center;
    justify-content: flex-start;
    font-family: "Open Sans", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    height: 50px;
    background-color: ${props => props.isOpen ? "rgba(255,255,255,.15)" : "rgba(255,255,255,.035)"};
    border-radius: ${props => props.theme.border.radius.medium};
    color: rgba(255,255,255,.75);
    padding: 0 40px 0 10px;
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    :hover {
        ${props => !props.isOpen && css`
            background-color: ${props => props.theme.colors.comp4.dark};
        `};
    }
    .chevron {
        position: absolute;
        top: 50%;
        right: 15px; 
        transform: ${props => props.isOpen ? "rotate(180deg) translateY(50%)" : "rotate(0deg) translateY(-30%)"};
        transition: transform 200ms ease-out;
    }
    .spinner {
        position: absolute;
        right: 15px;
    }
`;

interface HeaderCircleWrapperProps {
    online?: boolean;
}

export const HeaderCircleWrapper = styled.div<HeaderCircleWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid ${props => props.theme.colors.comp4.dark};
    border-radius: 50%;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    font-family: "Open Sans", "sans-serif";
    color: white;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    .onlineIcon {
        margin-top: -2px;
    }
    :hover {
        border-color: ${props => props.theme.colors.comp4.normal};
    }
    .dot {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        color: ${props => props.theme.colors.comp4.dark};
        top: -6px;
        right: -6px;
        width: 20px;
        height: 20px;
        background-color: ${props => props.theme.colors.comp4.normal};
        border-radius: 50%;

        ${props => props.online && css`
            background-color: ${props => props.theme.colors.secondary.normal};
            color: ${props => props.theme.colors.secondary.darker};
            box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
            animation: ${sonar} 2s infinite;
        `}
    }
    .feedbackPlus {
        position: absolute;
        right: -2px;
        top: -3px;
    }
`;

export const OrganizationsListWrapper = styled.div`
    position: absolute;
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${props => props.theme.colors.comp4.light};
    padding: 5px 0 20px 0;
    .close label { text-decoration: underline; }
    .top {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        .box {
            display: flex;
            align-items: center;
            &.title {
                grid-column: 1 / 4;
                grid-row: 1 / 2;
            }
            &.search {
                grid-column: 1 / 6;
                grid-row: 2 / 3;
            }
            &.close {
                margin-left: auto;
                grid-column: 4 / 6;
                grid-row: 1 / 2;
            }
        }
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .box {
                flex: 1;
                display: flex;
                justify-content: center;
                &:first-child > * { margin-right: auto; }
                &:last-child > * { margin-left: auto; }
            }
            .close {
                cursor: pointer;
                :hover {
                    color: ${props => props.theme.colors.comp4.dark};
                }
            }
        }
    }
`;

export const MobileMenuWrapper = styled.div`
    background-color: ${props => props.theme.colors.comp4.darker};
    color: ${props => props.theme.colors.comp4.normal};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    z-index: 5;
    .menuItem:not(:first-child) {
        margin-top: 10px;
    }
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;

export const MenuItemsMobileWrapper = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    font-family: "Nunito Sans", "sans-serif";
    .section {
        display: flex;
        flex-direction: column;
    }
    .menuItem {
        font-size: 35px;
    }
    a {
        color: ${props => props.theme.colors.comp4.normal};
        font-weight: bold;
        &.active {
            color: white;
        }
    }
`;

export const MobileOrgSchoolSelectorsWrapper = styled.div`
    padding: 20px;
    > div {
        max-width: 100%;
        &:first-child { margin-bottom: 20px; }
    }
`;

export const PrintLogoWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    display: none;
    img {
        width: 200px;
        height: auto;
    }
    @media only print {display: block;}
`;