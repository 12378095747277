import styled from "styled-components";

export const KioskWrapper = styled.div`
    position: relative;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    background-color: #E6ECF3;
    overflow-y: auto;
    left: 0;
    padding: 30px;
    .dropdown {
        width: 100%;
    }
    .searchBar {
        margin: 5px 0 10px 0;
        width: 100%;
    }
    .minimize {
        position: absolute;
        right: 10px;
        top: 10px;

        .chevron {
            cursor: pointer;
            .background {
                fill: white;
            }
            .fill {
                fill: ${props => props.theme.colors.primary.normal};
            }
            :hover {
                .background {
                    fill: ${props => props.theme.colors.primary.lighter};
                }
                .fill {
                    fill: ${props => props.theme.colors.primary.normal};
                }
            }
        }
    }
`;

export const KioskClosedWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary.lighter};
    :hover {
        background-color: white;
        .chevron {
            .background {
                fill: ${props => props.theme.colors.comp4.lighter};
            }
            .fill {
                fill: ${props => props.theme.colors.primary.normal};
            }
        }
    }
    .chevron {
        transform: rotate(180deg);
        cursor: pointer;
        .background {
            fill: white;
        }
        .fill {
            fill: ${props => props.theme.colors.primary.normal};
        }
    }
`;

export const MainAreaWrapper = styled.div`
    display: flex;
    align-items: stretch;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    .content {
        padding: 30px;
        overflow-y: auto;
        width: 100%;
    }
    .resize {
        width: 5px;
        height: 100%;
        background-color: ${props => props.theme.colors.primary.lighter};
        cursor: col-resize;
        :hover {
            background-color: ${props => props.theme.colors.primary.light};
        }
    }
    .courseName {
        display: flex;
        align-items: center;
        h2 {
            margin: 0 20px 0 0;
        }
    }
`;


export const DraftHeaderWrapper = styled.div`
    min-height: 70px;
    margin-bottom: 10px;
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    input {
        width: 300px;
    }
    .courseType {
        width: 130px;
        margin-bottom: -14px;
        margin-left: 20px;
    }
    .info {
        display: flex;
        align-items: center;
        .selected-course-type {
            font-family: "Nunito Sans";
            font-style: normal;
            font-weight: 800;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: ${props => props.theme.colors.primary.normal};
            &.free {
                color: ${props => props.theme.colors.secondary.dark};
            }
        }
    }
`;

export const CourseImageThumbnailWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    cursor: pointer;
    .vehicle {
        height: 70%;
        width: auto;
    }
    .icon {
        .fill {
            fill: ${props => props.theme.colors.comp4.normal};
        }
    }
    :hover {
        background-color: ${props => props.theme.colors.primary.lighter};
        img, .icon {
            display: none;
        }
        .change {
            display: block;
        }
    }
    .change {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
    }
    label {
        color: ${props => props.theme.colors.primary.normal};
    }
`;

export const CourseImageSelectorWrapper = styled.div`
    .images {
        display: grid;
        grid-template-columns: repeat(4, 100px);
        gap: 10px;
        margin-top: 20px;
    }
    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        background-color: ${props => props.theme.colors.comp4.lighter};
        padding: 5px 0;
        :hover {
            box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.light};
        }
        &.selected {
            box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.normal};
        }
    }

`;

export const UpdatedCourseImageWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0 20px 0;
    .initial, .draft {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: gray;
        width: 80px;
        height: 80px;
        img {
            width: 120%;
            max-width: 120%!important;
        }
    }
    .initial {
        background-color: ${props => props.theme.colors.primary.lighter};
        opacity: .4;
        transform: scale(.9);
    }
    .draft {
        background-color: ${props => props.theme.colors.comp4.light};
        &.offsetLeft {
            margin-left: -20px;
        }
        box-shadow: ${props => props.theme.boxShadow}, 0 0 0 2px ${props => props.theme.colors.comp4.normal};
        .badge {
            position: absolute;
            top: 3px;
            right: -3px;
        }
    }
`;

export const UpdatedCourseTypeWrapper = styled.div`
    margin: 20px 0;
    h4 {
        margin-bottom: 5px;
    }
    .description {
        display: flex;
        align-items: center;
        .changedTo {
            margin-right: 15px;
        }
    }
`;

export const CourseEditorHeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 9;
    /* box-shadow: ${props => props.theme.boxShadow}; */
`;

export const CourseEditorBlueBarWrapper = styled.div`
    position: relative;
    width: 100%;
    background-color: ${props => props.theme.colors.primary.normal};
    color: white;
    padding: 8px 45px 8px 30px;
    font-weight: 600;
    font-size: 12px;
    h3 {
        display: flex;
        align-items: center;
        img {
            margin-right: 17px;
        }
    }
    button {
        margin-left: 10px;
        width: auto;
        min-width: auto;
        height: 40px;
        padding: 0 20px;
        /* background-color: #2D78EA; */
        /* color: white; */
        /* &.exit:disabled {
            opacity: .6;
        } */
        /* .icon {
            fill: white;
            margin-right: 10px;
        } */
        /* &.save {
            background-color: ${props => props.theme.colors.primary.dark};
            :hover:not(:disabled) {
                background-color: ${props => props.theme.colors.primary.darker};
            }
        }
        :hover:not(:disabled) {
            background-color: ${props => props.theme.colors.primary.dark};
        } */
    }
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-wrapper {
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
            .course-name {
                margin-left: 7px;
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            label {
                color: white;
                margin-right: 10px;
            }
        }
    }
`;

export const MiniStatsWrapper = styled.div`
    display: flex;
    h5 {
        color: ${props => props.theme.colors.comp4.normal};
    }
    label {
        color: ${props => props.theme.colors.comp4.dark};
    }
    .item {
        display: flex;
        margin-right: 50px;
    }
    .icon {
        margin-right: 10px;
        .background {
            fill: ${props => props.theme.colors.comp4.light};
        }
        .fill {
            fill: ${props => props.theme.colors.comp4.normal};
        }
    }
`;

export const DraggableLessonWrapper = styled.div`

    margin-bottom: 15px;

    :not(.no-hover):hover {
        box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.normal};
    }

    &.react-beautiful-dnd-copy ~ .card {
        transform: none !important;
    }
    &.react-beautiful-dnd-copy {
        background-color: ${props => props.theme.colors.comp2.lighter};
        opacity: .5;
    }
    &.react-beautiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
       display: none !important;
    }

`;

export const DraggableExerciseCard = styled.div`
    position: relative;

    &.green {
        .inner {
            background-color: ${props => props.theme.colors.secondary.light};
        }
    }
    :hover {
        box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.normal}, ${props => props.theme.boxShadow};
    }

    &.inverted {
        .inner {
            background-color: ${props => props.theme.colors.comp4.lighter};
        }
    }
    :not(:first-child) {
        margin-top: 6px;
    }
    .infoWrapper {
        margin-left: auto;
        margin-right: 10px;
    }
    .vehicleWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: ${props => props.theme.colors.comp4.lighter};
        border-radius: 50%;
        img {
            height: 80%;
        }
    }
    .inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 5px 10px 5px 7px;
        margin-bottom: 0!important;
        border-radius: ${props => props.theme.border.radius.medium};
        transition: background-color 300ms ease-in-out;
        .dropDowns {
            display: flex;
            font-size: 0.9em;
            margin-left: 20px;
            > div {
                min-width: 120px;
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .delete, .duplicate {
                cursor: pointer;
                margin-left: 15px;
                :hover {
                    transform: scale(1.07);
                }
            }
        }
    }
    &.dragging {
        box-shadow: ${props => props.theme.boxShadow};
        .inner {
            background-color: ${props => props.theme.colors.comp2.light};
            border: 2px solid ${props => props.theme.colors.primary.normal};
            border-radius: ${props => props.theme.border.radius.medium};
        }
    }
    &.react-beautiful-dnd-copy ~ .inverted, &.react-beautiful-dnd-copy ~ .card {
        transform: none !important;
    }
    &.react-beautiful-dnd-copy {
        opacity: .5;
        .inner {
            background-color: ${props => props.theme.colors.comp4.lighter};
        }
    }
    &.react-beautiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
       display: none !important;
    }
`;


export const DroppableArea = styled.div`
    position: relative;
    background-color: ${props => props.theme.colors.comp4.light};
    min-height: 70px;
    width: 100%;
    padding: 10px;
    transition: background-color 200ms ease-in-out;
    border-radius: ${props => props.theme.border.radius.medium};
    &.highlight {
        background-color: ${props => props.theme.colors.comp2.lighter};
    }
    &.isCollapsed {
        height: 0;
        min-height: 0;
        padding: 0;
    }
    &.draggingOver {
        background-color: ${props => props.theme.colors.primary.light};
    }
    &.kioskLessonsDroppable {
        padding: 0;
    }
    &.kiosk {
        background-color: white;
    }
`;

export const CustomLessonWrapper = styled.div`
    position: relative;
    border-radius: ${props => props.theme.border.radius.medium};
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    transition: background-color 600ms ease-in-out;
    > .inner {
        padding: 0 10px 10px 10px;
    }
    &.isCollapsed {
        > .inner {
            padding: 0px 10px;
        }
    }
    &.isCloned {
        background-color: ${props => props.theme.colors.secondary.light};
    }
`;

export const CreatedByWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    .badge {
        margin-right: 10px;
    }
    .text {
        display: flex;
        flex-direction: column;
        .date {
            color: ${props => props.theme.colors.comp4.dark};
        }
    }
`;

export const DropItemsPlaceholderWrapper = styled.div`
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${props => props.theme.colors.comp4.normal};
    img {
        margin-right: 20px;
    }
`;

export const LessonHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    min-height: 60px;
    .collapse {
        display: flex;
        align-items: center;
        .chevronWrapper {
            margin-right: 20px;
            cursor: pointer;
        }
    }
    .editButton {
        margin-left: 20px;
    }
    .lessonName, .actions {
        .inputWrapper {
            margin-bottom: 0;
            margin-right: 10px;
        }
        cursor: pointer;
        display: flex;
        align-items: center;
        .duplicate, .delete {
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: ${props => props.theme.border.radius.large};
            :active {
                position: relative;
                top: 1px;
            }
            img {
                margin-right: 10px;
            }
            :not(:last-child) {
                margin-right: 20px;
            }
        }
        .duplicate:hover {
            label {
                color: ${props => props.theme.colors.primary.normal};
            }
        }
        .delete:hover {
            label {
                color: ${props => props.theme.colors.comp1.normal};
            }
        }
    }
`;

export const LessonActionsWrapper = styled.div`
    .inputWrapper {
        margin-bottom: 0;
        margin-right: 10px;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    .duplicate, .delete {
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: ${props => props.theme.border.radius.large};
        :active {
            position: relative;
            top: 1px;
        }
        img {
            margin-right: 10px;
        }
        :not(:last-child) {
            margin-right: 20px;
        }
    }
    .duplicate:hover {
        label {
            color: ${props => props.theme.colors.primary.normal};
        }
    }
    .delete:hover {
        label {
            color: ${props => props.theme.colors.comp1.normal};
        }
    }
`;

export const LessonNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    &.deleted {
        h3, h4 {
            text-decoration: line-through;
        }
    }
`;

interface ChangeLessonNameWrapperProps {
    inputWidth: string;
}

export const ChangeLessonNameWrapper = styled.div<ChangeLessonNameWrapperProps>`
    display: flex;
    align-items: center;
    .inputWrapper {
        margin-bottom: 0;
    }
    input {
        width: ${props => props.inputWidth};
        margin-bottom: 0;
    }
    .buttons {
        padding-top: 20px;
        display: flex;
        align-items: center;
        .cancel {
            cursor: pointer;
            margin-left: 15px;
            :hover {
                text-decoration: underline;
            }
        }

    }
    .toggle-language-label {
        margin-top: 20px;
        font-family: "Rajdhani";
        font-weight: 800;
        transition: transform 100ms ease-in-out;
        color: ${props => props.theme.colors.comp4.normal};
        :hover {
            color: ${props => props.theme.colors.comp4.darker};
            transform: scale(1.05);
        }
    }
    > * {
        margin-right: 15px;
    }
`;

export const EditButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 14px 0 10px;
    height: 26px;
    border-radius: 100px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.comp4.lighter};
    &.inverted {
        background-color: white;
    }
    :hover {
        background-color: ${props => props.theme.colors.comp4.light};
    }
    :active {
        position: relative;
        top: 1px;
    }
    label {
        color: ${props => props.theme.colors.primary.normal};
    }
    img {
        margin-right: 10px;
    }
`;

export const SaveButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 26px;
    border-radius: 100px;
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary.normal};
    :hover {
        background-color: ${props => props.theme.colors.primary.dark};
    }
    :active {
        position: relative;
        top: 1px;
    }
    label {
        color: white;
    }
    img {
        margin-right: 10px;
    }
`;

export const SavedStatusWrapper = styled.div`
    .showCompare {
        cursor: pointer;
        text-decoration: underline;
    }
    .spinner {
        margin-right: 5px;
        margin-bottom: -1px;
    }
    .check {
        margin-right: 8px;
        margin-bottom: 1px;
        .fill {
            fill: white;
        }
    }
    label {
        font-weight: normal;
    }
`;

export const RoundIconBase = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.comp4.lighter};
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        background-color: ${props => props.theme.colors.comp4.light};
    }
`;

export const CollapseChevronWrapper = styled(RoundIconBase)`
    .chevron {
        transition: transform 200ms ease-in-out;
    }
    &.isCollapsed {
        .chevron {
            transform: rotate(-90deg);
        }
    }
`;

export const AddNewLessonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.theme.border.radius.medium};
    padding: 16px 0;
    margin-top: 40px;
    cursor: pointer;
    background-color: white;
    :hover {
        background-color: ${props => props.theme.colors.primary.lighter};
    }
    .inner {
        display: flex;
        align-items: center;
    }
    .plus {
        margin-right: 10px;
    }
`;

export const KioskLessonInnerWrapper = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${props => props.theme.border.radius.medium};
    &.dragging {
        background-color: ${props => props.theme.colors.comp2.lighter};
        .inner {
            box-shadow: ${props => props.theme.boxShadow};
        }
    }
    .header {
        display: flex;
        align-items: center;
        padding: 15px 10px 5px 10px;
        h3 {
            cursor: pointer;
        }
        .chevronWrapper {
            margin-right: 10px;
            margin: 0 10px 0 7px;
        }
    }
    &.collapsed {
        .header {
            padding: 15px 10px;
        }
    }
`;

export const NoLessonsEmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
`;

export const ComparisonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    .course-name {
        display: flex;
        align-items: baseline;
        .current-name {
            margin-right: 10px;
        }
    }
    .header {
        margin-bottom: 30px;
    }
    .lessons {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .lesson {
        margin-bottom: 20px;
        background-color: ${props => props.theme.colors.primary.lighter};
        border-radius: ${props => props.theme.border.radius.medium};
        padding: 12px;
        &.deleted {
            background-color: ${props => props.theme.colors.comp5.lighter};
            > h3, h4 {
                opacity: .5;
            }
        }
        .lesson-name {
            min-height: 25px;
            color: ${props => props.theme.colors.primary.darker};
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            .badges {
                display: flex;
                gap: 10px;
            }
        }
    }
    .exercise {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-radius: ${props => props.theme.border.radius.medium};
        background-color: white;
        padding: 15px 10px;
        .name {
            min-height: 24px;
            &.moved {
                opacity: .5;
            }
            &.deleted {
                text-decoration: line-through;
            }
        }
        .badges {
            display: flex;
            gap: 10px;
            margin-left: auto;
        }
    }
`;

export const BadgeWrapper = styled.div`
    background-color: ${props => props.theme.colors.comp4.light};
    margin-left: auto;
    font-size: 10px;
    font-weight: bold;
    border-radius: ${props => props.theme.border.radius.medium};
    padding: 5px 10px;
    height: 24px;
    &.new {
        background-color: ${props => props.theme.colors.secondary.light};
    }
    &.moved:not(.new) {
        background-color: ${props => props.theme.colors.comp2.light};
    }
    &.deleted {
        background-color: ${props => props.theme.colors.comp1.light};
    }
`;

export const CourseEditorSubMenuWrapper = styled.div`
    height: 57px;
    width: 100%;
    background-color: white;
    padding: 0 47px 2px 30px;
    display: flex;
    justify-content: space-between;
    .kioskButtons {
        display: flex;
    }
    .item {
        display: flex;
        align-items: center;
        padding: 0 5px;
        margin-right: 20px;
        cursor: pointer;
        .icon {
            margin-right: 10px;
            .fill {
                fill: ${props => props.theme.colors.comp4.normal};
            }
        }
        :hover {
            box-shadow: 0 2px 0 ${props => props.theme.colors.comp4.dark};
            label {
                color: ${props => props.theme.colors.comp4.dark};
            }
            .icon {
                .fill {
                    fill: ${props => props.theme.colors.comp4.dark};
                }
            }
        }
        &.isActive {
            box-shadow: 0 2px 0 ${props => props.theme.colors.primary.normal};
            label {
                color: ${props => props.theme.colors.primary.normal};
            }
            .icon {
                .fill {
                    fill: ${props => props.theme.colors.primary.normal};
                }
            }
        }
    }
`;

export const DeleteConfirmationWrapper = styled.div`
    position: absolute;
    display: flex;
    border-radius: ${props => props.theme.border.radius.medium};
    z-index: 2;
    width: 100%;
    height: 100%;
    background: rgba(73,37,42,.8);
    color: white;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &.column {
        flex-direction: column;
        button {
            width: 100%;
        }
    }
    :not(.column) {
        button.delete {
            margin-left: 20px;
        }
        button.cancel {
            width: auto;
            min-width: auto;
        }
    }
    button {
        &.delete {
            background-color: ${props => props.theme.colors.comp1.dark};
            color: white;
            :hover {
                background-color: ${props => props.theme.colors.comp1.normal};
            }
        }
        &.cancel {
            background-color: transparent;
            :hover {
                text-decoration: underline;
            }
        }
    }
`;

export const InfoSymbolWrapper = styled.div`
    cursor: pointer;

    .background {
        fill: ${props => props.theme.colors.comp4.lighter};
    }
    .symbol {
        fill: ${props => props.theme.colors.primary.normal};
    }

    &.inverted {
        .background {
            fill: white;
        }
        .symbol {
            fill: ${props => props.theme.colors.primary.normal};
        }
    }

    :hover {
        transform: scale(1.05);
        .background {
            fill: ${props => props.theme.colors.primary.light};
        }
        .symbol {
            fill: ${props => props.theme.colors.primary.dark};
        }
    }
`;

export const LessonCloneWrapper = styled.div`
    position: relative;
    max-height: 500px;
    overflow: hidden;
    &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 9;
    }
`;

export const ExitWarningModalWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .description {
        padding: 0 40px;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
    }
`;

export const ExerciseCardNameWrapper = styled.div`
    display: flex;
    align-items: center;
    .name-and-runtime {
        display: flex;
        flex-direction: column;
        strong {
            color: ${props => props.theme.colors.comp4.dark};
            font-size: 0.9em;
        }
    }
    .exerciseVariantName {
        overflow-wrap: break-word;
        padding-right: 10px;
    }
    .image {
        min-width: 53px;
        width: 53px;
        height: 45px;
        border-radius: ${props => props.theme.border.radius.medium};
        margin-right: 15px;
        overflow: hidden;
        img {
            height: 100%;
            width: auto;
        }
    }
    `;

export const KioskSelectedCourseWrapper = styled.div`
    display: flex;
    margin: 4px 0 15px 0;
    align-items: center;
    justify-content: space-between;
    border-radius: ${props => props.theme.border.radius.large};
    background-color: white;
    padding: 5px 10px;
    .selected {
        display: flex;
        align-items: center;
        gap: 2px;
    }
`;

export const ExerciseModalInnerWrapper = styled.div`
    .exercise-image {
        background-color: ${props => props.theme.colors.comp4.light};
        border-radius: ${props => props.theme.border.radius.medium};
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16 / 9;
        margin-bottom: 16px;
    }
`;
