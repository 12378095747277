export type Language = "sv-SE" | "fi-FI" | "en-US";

export class Organization {
  ID: number = 0;
  Name: string = "";
  ContactInfo: string = "";
  CreatedBy: number = 0;
  CreationTime: string = "";
  LicenseCount: number = 0;
  SaveDailyStats: boolean = false;
  Version: number = 0;
  Active: boolean = true;
  DefaultLanguage: Language = "sv-SE";
  Environment: "SE" | "FI" = "SE";
}
