import styled from "styled-components";
import { Page } from "../../global/Containers/Containers.styled.components";

export const WelcomeWrapper = styled(Page)`
    display: flex;
    padding-top: 90px;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    max-width: 590px;
    padding-bottom: 50px;
    .top {
        margin-bottom: 20px;
    }
    button {
        align-self: center;
    }
`;

export const Section = styled.section`
    display: flex;
    padding: 0 40px;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        flex-direction: row;
    }
    img {
        flex-shrink: 0;
        width: 145px;
    }
    :not(:last-child) {
        margin-bottom: 35px;
    }
    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
            margin-top: 10px;
        }
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            text-align: left;
            padding-left: 35px;
        }
    }
`;