export const LOGIN = "LOGIN";
export const STAY_LOGGED_IN = "STAY_LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const CLEAR_STORE = "CLEAR_STORE";
export const SET_IS_CHECKING_SESSION = "SET_IS_CHECKING_SESSION";
export const SET_HAS_SEEN_WELCOME_SCREEN = "SET_HAS_SEEN_WELCOME_SCREEN";
export const CHANGE_ORGANIZATION = "CHANGE_ORGANIZATION";
export const CHANGE_SCHOOL = "CHANGE_SCHOOL";

export const LOGIN_SET_EMAIL = "LOGIN_SET_EMAIL";
export const LOGIN_SET_PASSWORD = "LOGIN_SET_PASSWORD";

export const LOCKS_SET_LOCKS = "LOCKS_SET_LOCKS";
export const LOCKS_ADD_LOCK = "LOCKS_ADD_LOCK";

export const ASSESSMENTS_SET = "ASSESSMENTS_SET";

export const PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG =
  "PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG";
export const PERMISSIONS_SET_SELECTED_USER_PERMISSIONS =
  "PERMISSIONS_SET_SELECTED_USER_PERMISSIONS";

export const USER_SET_USER = "USER_SET_USER";
export const USER_SET_COURSES = "USER_SET_COURSES";
export const USER_SET_EXERCISES = "USER_SET_EXERCISES";
export const USER_SET_EXERCISES_BY_COURSE = "USER_SET_EXERCISES_BY_COURSE";
export const USER_SET_TOTAL_FUEL_CONSUMPTION = "USER_SET_TOTAL_FUEL_CONSUMPTION";
export const USER_SET_TOTAL_TRAVEL_DISTANCE = "USER_SET_TOTAL_TRAVEL_DISTANCE";
export const USER_SET_IS_FETCHING_SIMULATOR_DATA = "USER_SET_IS_FETCHING_SIMULATOR_DATA";

export const EDIT_USER_SET_USER = "EDIT_USER_SET_USER";
export const EDIT_USER_SET_FIRST_NAME = "EDIT_USER_SET_FIRST_NAME";
export const EDIT_USER_SET_LAST_NAME = "EDIT_USER_SET_LAST_NAME";
export const EDIT_USER_SET_EMAIL = "EDIT_USER_SET_EMAIL";
export const EDIT_USER_SET_PASSWORD = "EDIT_USER_SET_PASSWORD";
export const EDIT_USER_SET_IS_SAVING = "EDIT_USER_SET_IS_SAVING";
export const EDIT_USER_SET_SAVE_SUCCESS = "EDIT_USER_SET_SAVE_SUCCESS";
export const EDIT_USER_SET_SAVE_ERROR = "EDIT_USER_SET_SAVE_ERROR";

export const DRIVING_RECORD_SET_FROM_DATE = "DRIVING_RECORD_SET_FROM_DATE";
export const DRIVING_RECORD_SET_TO_DATE = "DRIVING_RECORD_SET_TO_DATE";
export const DRIVING_RECORD_SET_RUNS = "DRIVING_RECORD_SET_RUNS";
export const DRIVING_RECORD_SET_OPTIONS = "DRIVING_RECORD_SET_OPTIONS";
export const DRIVING_RECORD_SET_DAYS_WITH_RUNS = "DRIVING_RECORD_SET_DAYS_WITH_RUNS";
export const DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY = "DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY";
export const DRIVING_RECORD_SET_IS_LOADING = "DRIVING_RECORD_SET_IS_LOADING";
export const DRIVING_RECORD_SET_SELECTED_OPTION = "DRIVING_RECORD_SET_SELECTED_OPTION";

export const MODAL_SET_OPEN = "MODAL_SET_OPEN";
export const MODAL_CLEAR_ALL = "MODAL_CLEAR_ALL";

export const SET_RELEASE_NOTES = "SET_RELEASE_NOTES";

export const PREVIOUS_SEARCHES_ADD_ENTRY = "PREVIOUS_SEARCHES_ADD_ENTRY";
export const PREVIOUS_SEARCHES_REMOVE_ENTRY = "PREVIOUS_SEARCHES_REMOVE_ENTRY";
export const PREVIOUS_SEARCHES_SET_ALL = "PREVIOUS_SEARCHES_SET_ALL";

export const CONTACT_FORM_SET_FEEDBACK_FROM = "CONTACT_FORM_SET_FEEDBACK_FROM";
export const CONTACT_FORM_SET_FEEDBACK_MESSAGE = "CONTACT_FORM_SET_FEEDBACK_MESSAGE";

export const EXERCISE_NUMBERING_SET = "EXERCISE_NUMBERING_SET";

export const SIMULATORS_INITIAL_ORDER_SET = "SIMULATORS_INITIAL_ORDER_SET";
export const SIMULATORS_INITIAL_ORDER_CLEAR = "SIMULATORS_INITIAL_ORDER_CLEAR";

export const MOBILE_MENU_SET_IS_OPEN = "MOBILE_MENU_SET_IS_OPEN";

export const LATEST_APPLICATION_VERSION = "LATEST_APPLICATION_VERSION";

export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";
export const SET_ORGANIZATIONS_IS_OPEN = "SET_ORGANIZATIONS_IS_OPEN";
export const SET_SCHOOLS_IS_OPEN = "SET_SCHOOLS_IS_OPEN";
export const SET_NO_SCHOOLS_IN_ORGANIZATION = "SET_NO_SCHOOLS_IN_ORGANIZATION";

export const SET_SCHOOLS = "SET_SCHOOLS";
export const SET_SELECTED_SCHOOL = "SET_SELECTED_SCHOOL";

export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const SELECTED_USER_SET_DETAILED_PROGRESS = "SELECTED_USER_SET_DETAILED_PROGRESS";
export const SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA = "SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA";
export const SELECTED_USER_SET_IS_DELETED = "SELECTED_USER_SET_IS_DELETED";
export const SELECTED_USER_CLEAR_STATE = "SELECTED_USER_CLEAR_STATE";

export const EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES = "EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES";

export const NEW_SCHOOL_CLASS_SET_NAME = "NEW_SCHOOL_CLASS_SET_NAME";
export const NEW_SCHOOL_CLASS_SET_EXTRA_DATA = "NEW_SCHOOL_CLASS_SET_EXTRA_DATA";
export const NEW_SCHOOL_CLASS_SET_LOADING = "NEW_SCHOOL_CLASS_SET_LOADING";
export const NEW_SCHOOL_CLASS_SET_CLASS_ID = "NEW_SCHOOL_CLASS_SET_CLASS_ID";
export const NEW_SCHOOL_CLASS_CLEAR_STATE = "NEW_SCHOOL_CLASS_CLEAR_STATE";

export const NEW_USER_SET_FIRST_NAME = "NEW_USER_SET_FIRST_NAME";
export const NEW_USER_SET_LAST_NAME = "NEW_USER_SET_LAST_NAME";
export const NEW_USER_SET_EMAIL = "NEW_USER_SET_EMAIL";
export const NEW_USER_SET_SCHOOLCLASS_ID = "NEW_USER_SET_SCHOOL_ID";
export const NEW_USER_SET_ROLE = "NEW_USER_SET_ROLE";
export const NEW_USER_CLEAR_STATE = "NEW_USER_CLEAR_STATE";
export const NEW_USER_EMPTY_FIELDS = "NEW_USER_EMPTY_FIELDS";
export const NEW_USER_SET_DUPLICATE_USER = "NEW_USER_SET_DUPLICATE_USER";

export const SORT_SCHOOL_CLASSES = "SORT_SCHOOL_CLASSES";
export const REVERSE_ORDER_SCHOOL_CLASSES = "REVERSE_ORDER_SCHOOL_CLASSES";
export const SORT_USERS = "SORT_USERS";
export const REVERSE_ORDER_USERS = "REVERSE_ORDER_USERS";
export const SORT_DRIVING_RECORD = "SORT_DRIVING_RECORD";
export const SORT_CLASS_HISTORY = "SORT_CLASS_HISTORY";
export const SORT_ORGANIZATIONS = "SORT_ORGANIZATIONS";

export const SELECTED_SCHOOLCLASS_SET_CLASS_ID =
  "SELECTED_SCHOOLCLASS_SET_CLASS_ID";
export const SELECTED_SCHOOLCLASS_SET_MINI_STATS =
  "SELECTED_SCHOOLCLASS_SET_MINI_STATS";
export const SELECTED_SCHOOLCLASS_SET_EXTRA_DATA =
  "SELECTED_SCHOOLCLASS_SET_EXTRA_DATA";
export const SELECTED_SCHOOLCLASS_SET_MEMBER_LIST =
  "SELECTED_SCHOOLCLASS_SET_MEMBER_LIST";
export const SELECTED_SCHOOLCLASS_SET_COURSE_LIST =
  "SELECTED_SCHOOLCLASS_SET_COURSE_LIST";
export const SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS =
  "SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS";
export const SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE =
  "SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE";
export const SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES =
  "SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES";
export const SELECTED_SCHOOLCLASS_CLEAR_STATE =
  "SELECTED_SCHOOLCLASS_CLEAR_STATE";
export const SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS =
  "SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS";
export const SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION =
  "SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION";
export const SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY =
  "SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY";

export const CLEAR_COURSES_FETCH_CONTROLLER = "CLEAR_COURSES_FETCH_CONTROLLER";
export const CLEAR_CLASSES_FETCH_CONTROLLER = "CLEAR_CLASSES_FETCH_CONTROLLER";
export const CLEAR_USERS_FETCH_CONTROLLER = "CLEAR_USERS_FETCH_CONTROLLER";
export const CLEAR_SIMULATORS_FETCH_CONTROLLER =
  "CLEAR_SIMULATORS_FETCH_CONTROLLER";
export const CLEAR_LESSONS_FETCH_CONTROLLER = "CLEAR_LESSONS_FETCH_CONTROLLER";
export const CLEAR_EXERCISES_FETCH_CONTROLLER =
  "CLEAR_EXERCISES_FETCH_CONTROLLER";

export const SUPPORT_SET_EMAIL = "SUPPORT_SET_EMAIL";
export const SUPPORT_SET_MESSAGE = "SUPPORT_SET_MESSAGE";
export const SUPPORT_SUCCESS = "SUPPORT_SUCCESS";

export const EXERCISE_VARIANTS_SET_VARIANTS = "EXERCISE_VARIANTS_SET_VARIANTS";

export const COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID =
  "COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID";
export const COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID =
  "COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID";
export const COURSE_EDITOR_SET_SHOW_EXIT_WARNING =
  "COURSE_EDITOR_SET_SHOW_EXIT_WARNING";
export const COURSE_EDITOR_SET_LAST_SAVED = "COURSE_EDITOR_SET_LAST_SAVED";
export const COURSE_EDITOR_CLEAR_LAST_SAVED = "COURSE_EDITOR_CLEAR_LAST_SAVED";
export const COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE =
  "COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE";
export const COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE =
  "COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE";
export const COURSE_EDITOR_SET_INITIAL_DRAFT =
  "COURSE_EDITOR_SET_INITIAL_DRAFT";
export const COURSE_EDITOR_SET_DRAFT = "COURSE_EDITOR_SET_DRAFT";
export const COURSE_EDITOR_SET_IS_SAVED = "COURSE_EDITOR_SET_IS_SAVED";
export const COURSE_EDITOR_CLEAR_DRAFT = "COURSE_EDITOR_CLEAR_DRAFT";
export const COURSE_EDITOR_CLEAR_UUID = "COURSE_EDITOR_CLEAR_UUID";
export const COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION =
  "COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION";
export const COURSE_EDITOR_SET_DUPLICATE_UUID =
  "COURSE_EDITOR_SET_DUPLICATE_UUID";
export const COURSE_EDITOR_CREATE_NEW_LESSON =
  "COURSE_EDITOR_CREATE_NEW_LESSON";
export const COURSE_EDITOR_MOVE_EXERCISE = "COURSE_EDITOR_MOVE_EXERCISE";
export const COURSE_EDITOR_SET_COURSE_IMAGE = "COURSE_EDITOR_SET_COURSE_IMAGE";
export const COURSE_EDITOR_SET_VEHICLE_IMAGES =
  "COURSE_EDITOR_SET_VEHICLE_IMAGES";
export const COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK =
  "COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK";
export const COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK =
  "COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK";
export const COURSE_EDITOR_REMOVE_EXERCISE = "COURSE_EDITOR_REMOVE_EXERCISE";
export const COURSE_EDITOR_DUPLICATE_EXERCISE =
  "COURSE_EDITOR_DUPLICATE_EXERCISE";
export const COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST =
  "COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST";
export const COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST =
  "COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST";
export const COURSE_EDITOR_SET_NEW_UUID = "COURSE_EDITOR_SET_NEW_UUID";
export const COURSE_EDITOR_UPDATE_LESSON_NAME =
  "COURSE_EDITOR_UPDATE_LESSON_NAME";
export const COURSE_EDITOR_DELETE_LESSON = "COURSE_EDITOR_DELETE_LESSON";
export const COURSE_EDITOR_SET_COURSE_NAMES = "COURSE_EDITOR_SET_COURSE_NAMES";
export const COURSE_EDITOR_UPDATE_COURSE_TYPE =
  "COURSE_EDITOR_UPDATE_COURSE_TYPE";
export const COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS =
  "COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS";
export const COURSE_EDITOR_MOVE_LESSON = "COURSE_EDITOR_MOVE_LESSON";
export const COURSE_EDITOR_SET_MOVED_LESSONS =
  "COURSE_EDITOR_SET_MOVED_LESSONS";
export const COURSE_EDITOR_DUPLICATE_LESSON = "COURSE_EDITOR_DUPLICATE_LESSON";
export const COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST =
  "COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST";
export const COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST =
  "COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST";
export const COURSE_EDITOR_SET_IS_DRAGGING_LESSON =
  "COURSE_EDITOR_SET_IS_DRAGGING_LESSON";
export const COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE =
  "COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE";
export const COURSE_EDITOR_SET_EXERCISE_OPTIONS =
  "COURSE_EDITOR_SET_EXERCISE_OPTIONS";
export const COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON =
  "COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON";
export const COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON =
  "COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON";
export const COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION =
  "COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION";
export const COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED =
  "COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED";
export const COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS =
  "COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS";
export const COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE =
  "COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE";

export const COURSE_ACCESS_SET_ACCESS_MODAL = "COURSE_ACCESS_SET_ACCESS_MODAL";
export const COURSE_ACCESS_CLEAR_MODAL = "COURSE_ACCESS_CLEAR_MODAL";
export const COURSE_ACCESS_SET_ACCESSES = "COURSE_ACCESS_SET_ACCESSES";
export const COURSE_ACCESS_ADD_TO_CLIPBOARD = "COURSE_ACCESS_ADD_TO_CLIPBOARD";
export const COURSE_ACCESS_CLEAR_CLIPBOARD = "COURSE_ACCESS_CLEAR_CLIPBOARD";
export const ALL_SIMULATORS_SET_SIMULATORS = "ALL_SIMULATORS_SET_SIMULATORS";

export const HOME_PAGE_SET_SCHOOL_RUNTIME = "HOME_PAGE_SET_SCHOOL_RUNTIME";
export const HOME_PAGE_SET_DATE_RANGE = "HOME_PAGE_SET_DATE_RANGE";
export const HOME_PAGE_SET_OUTLIERS = "HOME_PAGE_SET_OUTLIERS";
export const HOME_PAGE_SET_FROM_DATE = "HOME_PAGE_SET_FROM_DATE";
export const HOME_PAGE_SET_TO_DATE = "HOME_PAGE_SET_TO_DATE";
export const HOME_PAGE_SET_DATE_RANGE_ON_DATA =
  "HOME_PAGE_SET_DATE_RANGE_ON_DATA";
export const HOME_PAGE_SET_ACTIVE_PRESET = "HOME_PAGE_SET_ACTIVE_PRESET";
export const HOME_PAGE_SET_SCHOOL_MINI_STATS =
  "HOME_PAGE_SET_SCHOOL_MINI_STATS";
export const HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME =
  "HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME";
export const HOME_PAGE_SET_LATEST_RUNS = "HOME_PAGE_SET_LATEST_RUNS";
export const HOME_PAGE_SET_LATEST_EXERCISES = "HOME_PAGE_SET_LATEST_EXERCISES";
export const HOME_PAGE_CLEAR = "HOME_PAGE_CLEAR";

export const STATS_SET_USERS = "STATS_SET_USERS";
export const STATS_SET_USERS_PER_DAY = "STATS_SET_USERS_PER_DAY";

export const GLOBAL_USERS_SET_META_DATA = "GLOBAL_USERS_SET_META_DATA";
export const GLOBAL_USERS_SET_ORDER_BY = "GLOBAL_USERS_SET_ORDER_BY";
export const IMPORT_USERS_SET_USERS = "IMPORT_USERS_SET_USERS";
export const IMPORT_USERS_UPDATE_USER = "IMPORT_USERS_UPDATE_USER";
export const IMPORT_USERS_ADD_NEW = "IMPORT_USERS_ADD_NEW";
export const IMPORT_USERS_CLEAR = "IMPORT_USERS_CLEAR";
export const IMPORT_USERS_DELETE_USER = "IMPORT_USERS_DELETE_USER";
export const IMPORT_USERS_SET_RESULT = "IMPORT_USERS_SET_RESULT";

export const EMAILS_SET_ACTIVATION_TRANSLATIONS = "EMAILS_SET_ACTIVATION_TRANSLATIONS";
export const EMAILS_SET_PASSWORD_RESET_TRANSLATIONS =
  "EMAILS_SET_PASSWORD_RESET_TRANSLATIONS";

export const SELECTED_COURSE_SET_PUBLIC_UUID = "SELECTED_COURSE_SET_PUBLIC_UUID";
export const RUN_STATS_SET_DATA = "RUN_STATS_SET_DATA";
export const RUN_STATS_SET_FROM_DATE = "RUN_STATS_SET_FROM_DATE";
export const RUN_STATS_SET_TO_DATE = "RUN_STATS_SET_TO_DATE";
export const RUN_STATS_SET_SELECTED_CALENDAR_MONTH =
  "RUN_STATS_SET_SELECTED_CALENDAR_MONTH";

export const COURSE_CATEGORIES_SET_SELECTED_CATEGORY =
  "COURSE_CATEGORIES_SET_SELECTED_CATEGORY";
