import styled, { css } from "styled-components";

export const GuideBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    button {
        margin-left: 20px;
    }
`;

interface QACardWrapperProps {
    isOpen?: boolean;
}
export const QACardWrapper = styled.div<QACardWrapperProps>`
    display: flex;
    flex-direction: column;
    align-items: stretch!important;
    background-color: white;
    margin-bottom: 10px;
    padding: 10px;
    transition: all 100ms ease-in-out;
    ${props => props.isOpen && css`
        transform: scale(1.02);
        box-shadow: ${props => props.theme.boxShadow};
    `}
    img {
        margin-right: 15px;
    }
    .question {
        display: flex;
        align-items: center;
    }
    .answer {
        display: ${props => props.isOpen ? "flex" : "none"};
        align-items: center;
        padding-top: 10px;
        img {
            align-self: flex-start;
        }
    }
`;

export const ManualWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.primary.lighter};
    border-radius: ${props => props.theme.border.radius.medium};
    padding: 10px 10px 14px 10px;
    img {
        margin-right: 7px;
        margin-bottom: -15px;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a {
            text-decoration: underline;
            font-weight: bold;
            color: ${props => props.theme.colors.primary.dark};
        }
    }
`;
