import { Box, Card, Center, Group, Image, LoadingOverlay, Progress, Stack, Title, Text, useMantineTheme } from "@mantine/core";
import { getCloudinaryVehicleUrl, trimCourseName } from "../../../../helpers/strings.helper";
import { Course } from "../../../../types/course.types";
import { CourseTypeIcon } from "../../Misc/Misc.components";
import lakeFree from "../../../../resources/illustrations/lake-free.svg";
import lakeLinear from "../../../../resources/illustrations/lake-linear.svg";
import { useMediaQuery } from "@mantine/hooks";
import { IndividualCourseSymbol } from "./CourseCard.components";
import bg01 from "./resources/bg01.png";
import bg02 from "./resources/bg02.png";
import bg03 from "./resources/bg03.png";
import bg04 from "./resources/bg04.png";
import bg05 from "./resources/bg05.png";
import bg06 from "./resources/bg06.png";
import { getCourseBackgroundIDfromUUID } from "../../../../helpers/universal.helper";
import { AlertCircle } from "react-feather";

interface CourseCardProps {
    selected?: boolean;
    course: Course;
    onClick?: () => void;
    progress?: number;
    isStarted?: boolean;
    isIndividualCourse?: boolean;
    thinBorder?: boolean;
    hasLaggers?: boolean;
    isLoading?: boolean;
    isCarousel?: boolean;
    isGrid?: boolean;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
}

const CourseCard = (props: CourseCardProps) => {
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    const selectedBorderWidth = mobile ? 6 : (props.thinBorder ? 5 : 9);
    const percent = props.progress ? props.progress * 100 : 0;
    const url = getCloudinaryVehicleUrl(props.course.ImageUUID, 145);

    const courseImageUrl =
        props.course.ImageUUID ?
            getCloudinaryVehicleUrl(props.course.ImageUUID, 145) :
            undefined;

    const id = getCourseBackgroundIDfromUUID(props.course.PublicUUID);

    const customCourseBg = id === 1 ? bg01 : id === 2 ? bg02 : id === 3 ? bg03 : id === 4 ? bg04 : id === 5 ? bg05 : bg06;

    return (
        <Card
            sx={(theme) => ({
                position: "relative",
                width: props.isGrid ? undefined : 145,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: props.selected ? `0 0 0 ${selectedBorderWidth}px ${theme.colors.indigo[2]}` : undefined,
                cursor: "pointer",
                transition: "transform 300ms ease-in-out",
                '.course-image': {
                    transition: "transform 200ms ease-in-out"
                },
                '&:hover': {
                    boxShadow: `
                        0 0 0 2px ${theme.colors.blue[5]}
                        ${props.selected ? `, 0 0 0 ${selectedBorderWidth}px ${theme.colors.indigo[2]}` : ""}
                    `,
                    '.course-image': {
                        transform: "scale(1.05)"
                    }
                },
            })}
            onClick={props.onClick}
            p={0}
        >
            <LoadingOverlay visible={props.isLoading ?? false} />
            {props.hasLaggers && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 5,
                        right: 9,
                    }}
                >
                    <AlertCircle color={theme.colors.pink[5]} width={15} strokeWidth={2.5} />
                </Box>
            )}
            <Stack
                sx={() => ({
                    background: !props.course.Native ? `url(${customCourseBg})` : undefined,
                    backgroundSize: "cover",
                    flexGrow: 1,
                })}
                spacing={0}
                p={8}
                pb={0}
            >
                <Group
                    sx={{
                        padding: 0,
                        flexGrow: 0,
                        flexShrink: 0
                    }}
                    position="apart"
                >
                    <CourseTypeIcon
                        isLinear={props.course.IsLinear}
                        showText
                        customCourse={!props.course.Native}
                    />
                    {props.isIndividualCourse ? (
                        <IndividualCourseSymbol />
                    ) : null}
                </Group>
                {!props.course.Native ? (
                    <Card.Section
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "auto",
                        }}
                        mb="md"
                    >
                        <Group spacing="xs">
                            {courseImageUrl ? (
                                <Box
                                    sx={{
                                        width: 38,
                                        height: 38,
                                        backgroundColor: "white",
                                        overflow: "hidden",
                                        borderRadius: "100%",
                                    }}
                                >
                                    <img
                                        src={courseImageUrl}
                                        alt={props.course.Name}
                                        style={{ transform: "translateX(-20%) translateY(12%)" }}
                                        height="80%"
                                        className="course-image"
                                    />
                                </Box>

                            ) :
                                props.course.Vehicles?.slice(0, 2).map(guid => (
                                    <Box
                                        key={guid}
                                        sx={{
                                            width: 38,
                                            height: 38,
                                            backgroundColor: "white",
                                            overflow: "hidden",
                                            borderRadius: "100%",
                                        }}
                                    >
                                        <img
                                            src={getCloudinaryVehicleUrl(guid, 145)}
                                            alt={props.course.Name}
                                            style={{ transform: "translateX(-20%) translateY(12%)" }}
                                            height="80%"
                                            className="course-image"
                                        />
                                    </Box>
                                ))
                            }
                            {props.course.Vehicles?.length > 2 ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 27,
                                        height: 27,
                                        borderRadius: "100%",
                                        backgroundColor: "white"
                                    }}
                                >
                                    <Title
                                        order={6}
                                        sx={(theme) => ({
                                            color: theme.colors.bluegray[6]
                                        })}
                                    >
                                        +{props.course.Vehicles.length - 2}
                                    </Title>
                                </Box>
                            ) : null}
                        </Group>
                    </Card.Section>
                ) : (
                        <Card.Section
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 1,
                                flexShrink: 0,
                                flexBasis: 0,
                                justifyContent: "center",
                                background: `url(${props.course.IsLinear ? lakeLinear : lakeFree}) center center no-repeat`,
                                backgroundSize: "contain",
                            }}
                        >
                            <Center>
                                <Image
                                    src={url}
                                    alt={props.course.Name}
                                    className="course-image"
                                />
                            </Center>
                        </Card.Section>
                )}


            </Stack>
            <Stack
                spacing={0}
                pt={3}
                px={8}
                sx={{
                    height: 42,
                    maxHeight: 42,
                    backgroundColor: 'white',
                }}
            >
                <Group position="center">
                    <Stack spacing={0} sx={{ width: "100%" }}>
                        <Title
                            order={6}
                            my={0}
                            pb={0}
                            sx={{
                                textAlign: "center",
                                paddingTop: 5,
                                paddingBottom: 5,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {trimCourseName(props.course.Name)}
                        </Title>
                        <Box
                            sx={{
                                flexGrow: 0,
                                flexShrink: 0,
                                height: 4,
                            }}
                        >
                            {(props.isStarted) ? (
                                <Group noWrap spacing={5} align="center">
                                    <Box style={{ width: "100%", }}>
                                        <Progress
                                            sx={(theme) => ({
                                                height: 4,
                                                backgroundColor: theme.colors.bluegray[1],
                                                marginTop: "auto"
                                            })}
                                            styles={{
                                                bar: {
                                                    backgroundColor: theme.colors.blue[3]
                                                }
                                            }}
                                            value={percent}
                                        />
                                    </Box>
                                    <Text
                                        weight="bold"
                                        sx={{
                                            color: theme.colors.bluegray[5],
                                            fontSize: 11,
                                            fontFamily: theme.headings.fontFamily,

                                        }}
                                    >{Math.floor(percent)}%</Text>
                                </Group>
                            ) : null}
                        </Box>
                    </Stack>
                </Group>
            </Stack>
        </Card>
    );
}

export default CourseCard;
