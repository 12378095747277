import { v4 as uuidv4 } from "uuid";
export class Progress {
  CourseID: number = 0;
  PublicCourseUUID: string = "";
  CompletedCount: number = 0;
  ExerciseCount: number = 0;
  Runtime: number = 0;
  Completed: boolean = false;
  Achievements: UserAchievement[] = [];
}

export enum ExerciseRunResult {
  Completed = 1,
  Failed = -1,
  Aborted = -2,
  Timeout = -3,
  FailedToFollowInstructions = -4,
  OutsideExerciseArea = -5,
  FailedPenaltyOverFailureThreshold = -6,
}

class UserAchievement {
  Code: string = "";
  Name: string = "";
  UserID: number = 0;
  ExerciseRunID: number = 0;
  RefID: string = "";
  CreatedAt: string = "";
  HasSeen: boolean = false;
}

export class DetailedProgress {
  PublicExerciseUUID: string = "";
  IsCompleted: number = 0;
  Runtime: number = 0;
  LastRun: string = "";
  RunCount: number = 0;
  CompletedRuns: number = 0;
  Runs: RunTemp[] = [];
  UserAchievements: UserAchievement[] = [];
}

export class User {
  ID: number = 0;
  FirstName: string = "";
  LastName: string = "";
  Email: string = "";
  OrganizationID: number = 0;
  SchoolID: number = 0;
  SchoolClassID: number = 0;
  Role: number = 3;
  Active: boolean = false;
  AssignedCourses: string[] = [];

  CreatorName: string = "";
  CreatedBy: number = 0;
  CreationTime: string = "";

  // Lookup these names in the respective structs based on ID
  SchoolClass: string = "";
  School: string = "";
  Organization?: string = "";

  // UserActivity
  Progress: Progress[] = [];
  RunCount: number = 0;
  LastRun: string = "";
  LastActive: string = "";
  TotalRuntime: number = 0;
  IsOnline: boolean = false;
  CurrentExerciseID: number = 0;
  CurrentCourseID: number = 0;
}

export interface UserInSchoolClass {
  UserID: number;
  Progress: Progress[];
}

export class Assessment {
  ID: number = 0;
  UUID: string = "";
  Name: string = "";
  Severity: number = 0;
}

export interface AssessmentLocation {
  x: number;
  y: number;
  z: number;
}
export interface ExerciseRunAssessment {
  ExerciseRunID: number;
  AssessmentID: number;
  Timestamp: number;
  Location: AssessmentLocation;
}

export interface AssessmentWithName {
  id: number;
  name: string;
  timestamp: number;
  location: AssessmentLocation;
  uuid: string;
}

export class Assessments {
  List: ExerciseRunAssessment[] = [];
  Counts: Record<string, number> = {};
}

export class DrivingRecordChartEntry {
  Timestamp: string = "";
  Runtime: number = 0;
}

export class Outlier {
  userID: number = 0;
  PublicCourseUUID: string = "";
  schoolClassID: number = 0;
  progress: number = 0;
  isBehind?: boolean = false;
  diff: number = 0;
}

export class ImportUser {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  schoolClassID: number = 0;
  uuid: string = uuidv4();
  role: number = 3;
}

export class ImportResult {
  Success: {
    ID: number;
    FirstName: string;
    LastName: string;
    Email: string;
  }[] = [];
  Failed: string[] = [];
  Conflict: string[] = [];
  CreatedShoolClassId?: number = 0;
}

export class ViewConfig {
  Admin: boolean = false;
  ChangePermissions: boolean = false;
  ShowPermissions: boolean = false;
  CourseAccessList: boolean = false;
  ShowAllExercisesInEditor: boolean = false;
  EditMessages: boolean = false;
  ShowMessages: boolean = false;
  ShowBillingPage: boolean = false;
  ShowLicenseGenerationPage: boolean = false;
  ShowTwoFactorOption: boolean = false;
}

export type ViewConfigKey = keyof ViewConfig;

export interface Permission {
  ID: number;
  Code: string;
  AdminOnly: boolean;
}

export class SelectedUserPermissions {
  userID: number = 0;
  permissions: Permission[] = [];
}

export class RunTemp {
  UserID: number = 0;
  Runtime: number = 0;
  Timestamp: string = "";
  Result: ExerciseRunResult = ExerciseRunResult.Failed;
  ID: number = 0;
  PublicExerciseUUID: string = "";
  CourseImageUUID: string = "";
  StylePointEarned: boolean = false;
  ExerciseID: number = -1;
  ExerciseName: string = "";
  CourseID: number = -1;
  PublicCourseUUID: string = "";
  CourseName: string = "";
  Assessments: Assessments = new Assessments();
}
