import { Button, Text, Center, Select, Stack, Group } from "@mantine/core";
import { Course } from "../../../../../../types/course.types"
import CourseCard from "../../CourseCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../reducers";
import { useState } from "react";
import { useCopyCourseToSchoolMutation } from "./api/useCopyCourseToSchool";
import { IconCheck } from "@tabler/icons";
import { useTranslation } from "react-i18next";

interface CopyToSchoolProps {
    course: Course;
    onClose: () => void;
}

function CopyToSchool(props: CopyToSchoolProps) {
    const { t } = useTranslation();
    const organizationState = useSelector((state: RootState) => state.organization);
    const { schools } = organizationState;
    const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
    const mutation = useCopyCourseToSchoolMutation();

    function onSubmit() {
        mutation.mutate({ sourceUuid: props.course.UUID, targetSchoolId: Number(selectedSchoolId) })
    }

    return (
        <Stack>
            <Center
                sx={(theme) => ({
                    borderRadius: 5,
                    backgroundColor: mutation.isSuccess ? theme.colors.green[1] : theme.colors.bluegray[3],
                    pointerEvents: "none"
                })}
                p="md"
            >
                <CourseCard course={props.course} />
            </Center>
            {mutation.isError && (
                <Text color="red" align="center" weight="bold">{t("common:toasts.genericError")}</Text>
            )}
            {mutation.isSuccess ? (
                <Stack>
                    <Group spacing="xs" position="center">
                        <IconCheck />
                        <Text align="center" weight="bold">{t("courses.dropDown.copiedToSchoolName", { name: schools.find(s => s.ID === Number(selectedSchoolId))?.Name })}</Text>
                    </Group>
                    <Button variant="subtle" onClick={props.onClose}>
                        {t("common:buttons.close")}
                    </Button>
                </Stack>
            ) : (
                <>
                    <Select
                            label={t("schools.title")}
                        data={schools.map(school => {
                            const isCurrent = school.ID === Number(localStorage.getItem("school_id"));
                            return ({
                                label: `${school.Name}${isCurrent ? ` (${t("courses.dropDown.currentSchool")})` : ""}`,
                                value: school.ID.toString(),
                                disabled: isCurrent
                            });
                        })}
                        value={selectedSchoolId}
                        onChange={setSelectedSchoolId}
                            placeholder={t("schools.title")}
                    />
                    <Stack spacing="xs">
                        <Button
                            disabled={selectedSchoolId === null}
                            loading={mutation.isLoading}
                            onClick={onSubmit}
                        >
                                {selectedSchoolId === null ?
                                    t("common:misc.copy") :
                                    t("courses.dropDown.copyToSchoolName", { name: schools.find(s => s.ID === Number(selectedSchoolId))?.Name })}
                        </Button>
                        <Button variant="subtle" onClick={props.onClose}>
                                {t("common:buttons.cancel")}
                        </Button>
                    </Stack>
                </>
            )}
        </Stack>
    )
}

export default CopyToSchool