import { Checkbox, Loader } from '@mantine/core';
import { useGetAllSchools } from '../../../../../../../api/admin/allSchools';
import { Organization } from '../../../../../../../types/organization.types';

interface OrganizationAccessProps {
    organization: Organization;
    publicCourseUuid: string;
    createdBySchoolId: number;
    schoolsWithAccess: number[];
    addSchoolIds: (id: number[]) => void;
    removeSchoolIds: (id: number[]) => void;
}

function OrganizationAccess(props: OrganizationAccessProps) {
    const schoolsQuery = useGetAllSchools();
    const schools = schoolsQuery.data?.list.filter(school => school.OrganizationID === props.organization.ID) ?? [];
    const schoolsInOrganizationWithAccess = schools.filter(s => props.schoolsWithAccess.some(id => id === s.ID));

    if (schoolsQuery.isLoading) return <Loader />;

    const allChecked = schoolsInOrganizationWithAccess.length === schools.length;
    const indeterminate = schoolsInOrganizationWithAccess.length > 0 && !allChecked;

    function handleCheckSchool(event: React.ChangeEvent<HTMLInputElement>, schoolIds: number[]) {
        if (event.currentTarget.checked) {
            props.addSchoolIds(schoolIds)
        } else {
            props.removeSchoolIds(schoolIds)
        }
    }

    function handleCheckOrganization() {
        if (allChecked) {
            props.removeSchoolIds(schools.filter(s => s.ID !== props.createdBySchoolId).map(s => s.ID))
        } else {
            schools.map(s => s.ID).forEach(id => {
                if (!props.schoolsWithAccess.some(sId => sId === id))
                    props.addSchoolIds([id]);
            })
        }
    }

    const schoolCheckboxes = schools.map((school) => (
        <Checkbox
            key={school.ID}
            disabled={school.ID === props.createdBySchoolId}
            mt="xs"
            ml={schools?.length > 1 ? 33 : 0}
            label={`${school.Name} ${school.ID === props.createdBySchoolId ? " (ägare, ser alltid kursen)" : ""}`}
            checked={props.schoolsWithAccess.some(id => id === school.ID)}
            onChange={(event) => handleCheckSchool(event, [school.ID])}
        />
    ));

    return (
        <>
            {schoolCheckboxes.length > 1 && (
                <Checkbox
                    mt="xs"
                    checked={allChecked}
                    indeterminate={indeterminate}
                    label={props.organization.Name}
                    transitionDuration={0}
                    onChange={handleCheckOrganization}
                />
            )}
            {schoolCheckboxes}
        </>
    );
}

export default OrganizationAccess;