import { Center, Tooltip } from "@mantine/core";
import { ReactNode } from "react";

interface PresentationButtonProps {
    icon: ReactNode;
    label: string;
    variant: "round" | "square";
    onClick?: () => void;
    disabled?: boolean;
}

function PresentationButton(props: PresentationButtonProps) {
    const content = (
        <Center
            sx={(theme) => ({
                backgroundColor: "white",
                borderRadius: props.variant === "square" ? theme.radius.sm : "100%",
                minWidth: 46,
                minHeight: 46,
                transition: "transform 150ms ease-in-out",
                cursor: !props.disabled ? "pointer" : undefined,
                ':hover': {
                    transform: !props.disabled ? "scale(1.06)" : undefined,
                },
                ':active': !!props.onClick ? {
                    position: "relative",
                    top: 2
                } : {},
                boxShadow: theme.shadows.xl,
            })}
            onClick={props.onClick}
        >
            {props.icon}
        </Center>
    );

    if (props.disabled)
        return content;

    return (
        <Tooltip
            label={props.label}
            withArrow
            offset={10}
            withinPortal
        >
            {content}
        </Tooltip>
    )
}

export default PresentationButton;
