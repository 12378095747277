import {
  Box,
  Container,
  Group,
  Highlight,
  Image,
  Loader,
  Button as MantineButton,
  Checkbox as MantineCheckbox,
  MantineNumberSize,
  NativeSelect,
  Paper,
  Select,
  Skeleton,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconSearch, IconX } from "@tabler/icons";
import { debounce, get } from "lodash";
import React, {
  FormEvent,
  MutableRefObject,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { CSSTransition } from "react-transition-group";
import actions, { postToFreshDesk, setReset } from "../../../actions";
import { VIEW_HEIGHT } from "../../../helpers/constants";
import { useMessageIsOpen } from "../../../helpers/hooks";
import { localStorageGet } from "../../../helpers/localstorage.helper";
import {
  firstChar,
  getCloudinaryExerciseUrl,
  getCloudinaryVehicleUrl,
  trimCourseName,
} from "../../../helpers/strings.helper";
import {
  convertSecondsToReadable,
  formatTimeDistanceStrict,
} from "../../../helpers/time.helper";
import {
  fetchLang,
  fiLocale,
  isAdminUser,
  svLocale,
  usePrevious,
} from "../../../helpers/universal.helper";
import i18n from "../../../i18n";
import { USERS_PATH } from "../../../navigation/Routes";
import { RootState } from "../../../reducers";
import skillsterSymbol from "../../../resources/icons/skillster-symbol-dark.svg";
import skillsterSymbolWhite from "../../../resources/icons/skillster-symbol-white.svg";
import { paperStyles } from "../../../resources/styles/paperStyles";
import { Course } from "../../../types/course.types";
import { ExerciseOption, Option } from "../../../types/option.types";
import { OrderBy, SortOrder } from "../../../types/sort.types";
import { Outlier, Progress, User } from "../../../types/user.types";
import sortIcon from "../../global/Tables/resources/img/sort.svg";
import { SkillsterToolTip } from "../../pages/CourseEditor/CourseEditor.components";
import LicenseAgreement from "../../pages/Login/LicenceAgreement";
import UsersRowColumns from "../../pages/Users/components/Columns";
import { AnimatedStack } from "../AnimatedLists/AnimatedStack/AnimatedStack";
import { MobileSorter } from "../MobileSorter/MobileSorter";
import {
  Bar,
  ButtonWrapper,
  CheckboxWrapper,
  CookiesModalContentWrapper,
  DropDownBtn,
  DropDownItemsWrapper,
  DropDownStringValueWrapper,
  DropDownWrapper,
  DropdownVehicleThumbWrapper,
  EmptyStateWrapper,
  FlipLoader,
  FlipLoaderContainer,
  InitialsBadgeWrapper,
  LicenseAgreementModalContentWrapper,
  LoadingScreenWrapper,
  MessageWrapper,
  MobileSubBarSearchBar,
  ModalCourseWrapper,
  ModalWrapper,
  NoSearchResultsWrapper,
  PageLoaderWrapper,
  ProgressBarWrapper,
  RadioButtonWrapper,
  SelectCourseModalGridWrapper,
  SelectCourseModalInnerWrapper,
  SelectCourseModalTitleWrapper,
  SkeletonBlockWrapper,
  SkeletonBlocksWrapper,
  SkillsterBadgeWrapper,
  SmallSpinner,
  SubBarWrapper,
} from "./Misc.styled.components";
import {
  IconCheckbox,
  IconCoursesHeadline,
  IconFree,
  IconFreeAlt,
  IconLinear,
  IconLinearAlt,
  IconRadioButton,
  IconSkillsterSymbolWhite,
  IllustrationCoffeeCup,
} from "./Symbols.components";
import chevron from "./resources/icons/chevron.svg";
import globe from "./resources/icons/globe.svg";
import noSearchResults from "./resources/icons/no-search-results.svg";
import animationSuccess from "./resources/lottie/37265-success-animation.json";
import animationError from "./resources/lottie/67782-error.json";

interface LogoProps {
  onClick?: () => void;
  width?: number;
  className?: string;
  color?: string;
}
export const Logo = (props: LogoProps) => (
  <svg
    width={props.width ?? "369"}
    className={props.className}
    onClick={props.onClick}
    height="48"
    viewBox="0 0 369 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0718 0L48.1435 24L24.0718 48L0 24L24.0718 0ZM33.1524 23.2598C32.1997 22.5908 30.7962 22.2564 28.9418 22.2564H21.1154C20.0266 22.2564 19.4822 21.9305 19.4822 21.2787C19.4822 20.7641 19.6438 20.4039 19.9671 20.1981C20.2903 19.9751 20.8092 19.8637 21.5237 19.8637H28.5014L32.6865 15.387H22.7231C21.226 15.387 19.95 15.5413 18.8953 15.8501C17.8405 16.1588 16.9729 16.5962 16.2924 17.1622C15.6289 17.7111 15.144 18.3628 14.8378 19.1175C14.5316 19.8551 14.3785 20.6612 14.3785 21.536C14.3785 22.7881 14.8293 23.7915 15.7309 24.5462C16.6326 25.3009 18.0532 25.6782 19.9926 25.6782H27.8445C28.9163 25.6782 29.4522 26.0213 29.4522 26.7073C29.4522 27.2047 29.2906 27.5735 28.9674 27.8136C28.6441 28.0538 28.1252 28.1738 27.4107 28.1738H18.0297L12.5588 32.5991H26.2113C27.7254 32.5991 29.0099 32.4447 30.0647 32.136C31.1364 31.8101 32.0041 31.3641 32.6676 30.7981C33.3481 30.2149 33.8414 29.5289 34.1476 28.7399C34.4539 27.9509 34.607 27.0933 34.607 26.167C34.607 24.8806 34.1221 23.9115 33.1524 23.2598Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M98.2546 20.9356C101.448 20.9356 103.864 21.5051 105.505 22.6441C107.174 23.7539 108.009 25.404 108.009 27.5944C108.009 29.1715 107.745 30.6318 107.218 31.9752C106.691 33.3186 105.841 34.4869 104.67 35.4798C103.527 36.4436 102.033 37.2029 100.188 37.7578C98.3718 38.2835 96.1602 38.5464 93.5532 38.5464H66.1792L73.3412 31.0114H95.6183C96.8486 31.0114 97.742 30.807 98.2986 30.3981C98.8551 29.9892 99.1334 29.3613 99.1334 28.5144C99.1334 27.3462 98.2107 26.7621 96.3652 26.7621H80.3275C76.9882 26.7621 74.5422 26.1195 72.9897 24.8345C71.4372 23.5495 70.661 21.841 70.661 19.709C70.661 18.2195 70.9246 16.8469 71.4519 15.591C71.9791 14.306 72.814 13.1962 73.9564 12.2616C75.1281 11.2979 76.622 10.5531 78.4382 10.0274C80.2543 9.50174 82.4513 9.23889 85.029 9.23889H110.558L103.352 16.8615H82.9639C81.7336 16.8615 80.8402 17.0513 80.2836 17.431C79.727 17.7814 79.4488 18.3947 79.4488 19.2709C79.4488 20.3807 80.3861 20.9356 82.2608 20.9356H98.2546Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M143.771 38.5464H130.018L122.504 30.8362C121.215 29.522 120.219 28.4998 119.516 27.7696C118.843 27.0395 118.315 26.4262 117.934 25.9297L117.319 30.1353L115.825 38.5464H106.686L111.915 9.23889H121.01L119.824 16.0291C119.648 16.9345 119.458 17.8252 119.253 18.7014C119.048 19.5776 118.872 20.3953 118.725 21.1546C119.399 20.629 120.219 19.9864 121.186 19.2271C122.182 18.4386 123.441 17.4894 124.965 16.3796L134.719 9.23889H147.901L135.202 17.65C134.089 18.3801 133.108 19.0227 132.259 19.5776C131.409 20.1325 130.647 20.6143 129.974 21.0232C129.329 21.4321 128.743 21.7972 128.216 22.1184C127.689 22.4397 127.162 22.7463 126.634 23.0384C127.367 23.6225 128.245 24.3526 129.271 25.2288C130.325 26.1049 131.673 27.317 133.313 28.8648L143.771 38.5464Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M152.475 38.5464H143.38L148.609 9.23889H157.66L152.475 38.5464Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M187.84 31.0114L180.59 38.5464H154.622L159.851 9.23889H168.903L165.036 31.0114H187.84Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M219.21 31.0114L211.96 38.5464H185.992L191.221 9.23889H200.273L196.406 31.0114H219.21Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M247.724 20.9356C250.917 20.9356 253.334 21.5051 254.974 22.6441C256.644 23.7539 257.479 25.404 257.479 27.5944C257.479 29.1715 257.215 30.6318 256.688 31.9752C256.16 33.3186 255.311 34.4869 254.139 35.4798C252.997 36.4436 251.503 37.2029 249.658 37.7578C247.841 38.2835 245.63 38.5464 243.023 38.5464H215.649L222.811 31.0114H245.088C246.318 31.0114 247.212 30.807 247.768 30.3981C248.325 29.9892 248.603 29.3613 248.603 28.5144C248.603 27.3462 247.68 26.7621 245.835 26.7621H229.797C226.458 26.7621 224.012 26.1195 222.459 24.8345C220.907 23.5495 220.131 21.841 220.131 19.709C220.131 18.2195 220.394 16.8469 220.921 15.591C221.449 14.306 222.284 13.1962 223.426 12.2616C224.598 11.2979 226.092 10.5531 227.908 10.0274C229.724 9.50174 231.921 9.23889 234.499 9.23889H260.027L252.821 16.8615H232.433C231.203 16.8615 230.31 17.0513 229.753 17.431C229.197 17.7814 228.918 18.3947 228.918 19.2709C228.918 20.3807 229.856 20.9356 231.73 20.9356H247.724Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M297.898 9.23889L290.604 16.8615H280.146L276.28 38.5464H267.184L271.007 16.8615H257.21L264.504 9.23889H297.898Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M325.545 31.0114L320.199 38.5464H291.902L297.131 9.23889H331.14L323.89 16.8615H304.864L304.249 20.3661H327.493L321.166 27.0249H303.063L302.36 31.0114H325.545Z"
      fill={props.color ?? "#282E3C"}
    />
    <path
      d="M367.33 38.5464H355.159L349.975 30.7924H336.002L334.64 38.5464H325.545L328.181 23.5641H353.753C354.515 23.5641 355.233 23.4765 355.906 23.3012C356.609 23.126 357.21 22.8778 357.708 22.5565C358.235 22.2352 358.645 21.841 358.938 21.3737C359.26 20.8772 359.421 20.3369 359.421 19.7528C359.421 18.789 359.026 18.0735 358.235 17.6062C357.444 17.1097 356.346 16.8615 354.94 16.8615H329.367L336.661 9.23889H355.555C357.078 9.23889 358.631 9.37032 360.212 9.63316C361.823 9.89601 363.273 10.3779 364.562 11.0788C365.88 11.7505 366.95 12.6559 367.77 13.7949C368.59 14.9339 369 16.3796 369 18.1319C369 19.4461 368.766 20.702 368.297 21.8994C367.858 23.0968 367.213 24.1774 366.364 25.1412C365.544 26.1049 364.548 26.9227 363.376 27.5944C362.204 28.2661 360.915 28.7334 359.509 28.9963C359.919 29.3759 360.373 29.8578 360.871 30.4419C361.399 31.026 362.072 31.8292 362.893 32.8514L367.33 38.5464Z"
      fill={props.color ?? "#282E3C"}
    />
  </svg>
);

interface SkillsterLoaderProps {
  className?: string;
  inverted?: boolean;
}

export const SkillsterLoader = (props: SkillsterLoaderProps) => (
  <FlipLoaderContainer className={props.className}>
    <FlipLoader src={props.inverted ? skillsterSymbolWhite : skillsterSymbol} />
  </FlipLoaderContainer>
);

export const PageLoader = () => (
  <PageLoaderWrapper>
    <SkillsterLoader />
  </PageLoaderWrapper>
);

interface CourseImageProps {
  imageUUID: string;
  width: number;
  alt: string;
  className?: string;
}

export const CourseImage = (props: CourseImageProps) => {
  const url = getCloudinaryVehicleUrl(props.imageUUID, props.width);
  return <img src={url} className={props.className} alt={props.alt} />;
};
export const getCourseImageUrl = (
  props: Omit<CourseImageProps, "alt" | "className">
) => getCloudinaryVehicleUrl(props.imageUUID, props.width);

interface ExerciseTemplateVariantImageProps {
  uuid?: string;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  aspectRatio?: string;
}

export const ExerciseTemplateVariantImage = (
  props: ExerciseTemplateVariantImageProps
) => {
  if (!props.uuid) return null;
  const url = getCloudinaryExerciseUrl(
    props.uuid,
    props.width,
    props.height,
    props.aspectRatio
  );
  return <img src={url} className={props.className} alt={props.alt} />;
};

interface ProgressBarProps {
  showText?: boolean;
  small?: boolean;
  percent: number;
  className?: string;
  isOutlier?: boolean;
  disableAnimation?: boolean;
  progress?: { completed: number; totalCount: number };
}

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <ProgressBarWrapper
      className={`${props.className} ${props.isOutlier ? "outlier" : ""} ${
        props.progress ? "quota" : ""
      }`}
    >
      <Bar
        percent={props.percent}
        small={props.small}
        className={`${props.isOutlier ? "outlier" : ""} ${
          props.disableAnimation ? "disable-animation" : ""
        }`}
      >
        <div />
      </Bar>
      {props.showText && (
        <div className="text">
          <div className="inner">
            {props.progress ? (
              `${props.progress.completed.toLocaleString(
                fetchLang()
              )} / ${props.progress.totalCount.toLocaleString(fetchLang())}`
            ) : Math.floor(props.percent * 100) ? (
              <>{Math.floor(props.percent * 100)} %</>
            ) : (
              <em>0 %</em>
            )}
          </div>
        </div>
      )}
    </ProgressBarWrapper>
  );
};

interface InitialsBadgeProps {
  user: User;
  className?: string;
  noDot?: boolean;
  inverted?: boolean;
  showHover?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

export const InitialsBadge = (props: InitialsBadgeProps) => {
  if (!props.user) return null;
  return (
    <InitialsBadgeWrapper
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      isOnline={props.user.IsOnline}
      showHover={props.showHover}
      className={`${props.className} notranslate ${
        props.inverted ? "inverted" : ""
      }`}
    >
      {!props.noDot && <div className="dot" />}
      {firstChar(props.user.FirstName).toUpperCase()}
      {firstChar(props.user.LastName).toUpperCase()}
    </InitialsBadgeWrapper>
  );
};

export const SkillsterBadge = () => (
  <SkillsterBadgeWrapper>
    <IconSkillsterSymbolWhite />
  </SkillsterBadgeWrapper>
);
interface UserListProps {
  users: User[];
  outliers?: Outlier[];
  selectedPublicCourseUUID?: string;
  selectAll?: boolean;
  highlight?: string;
  showQuotaProgress?: boolean;
  onSortByOnline: () => void;
  onSortByName: () => void;
  onSortBySchoolClass: () => void;
  onSortByRuntime: () => void;
  onSortByLastActive: () => void;
  onSortByProgress: () => void;
  onReverseOrder: () => void;
  setSelectedUserIds: React.Dispatch<React.SetStateAction<number[]>>;
  currentSortOrder: OrderBy;
  isLoading?: boolean;
  isEditing?: boolean;
  selectedUserIds: number[];
}

export const UserList = (props: UserListProps) => {
  const { t } = useTranslation();
  const [initialLength] = useState(props.users.length);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [value, setValue] = useState("");

  const onCheck = (userId: number) => {
    if (props.selectedUserIds.some((id) => id === userId)) {
      props.setSelectedUserIds(
        props.selectedUserIds.filter((id) => id !== userId)
      );
    } else {
      props.setSelectedUserIds([...props.selectedUserIds, userId]);
    }
  };

  const {
    onSortByOnline,
    onSortByName,
    onSortBySchoolClass,
    onSortByRuntime,
    onSortByLastActive,
    onSortByProgress,
  } = props;

  const onSortByOnlineCallback = useCallback(
    () => onSortByOnline(),
    [onSortByOnline]
  );
  const onSortByNameCallback = useCallback(
    () => onSortByName(),
    [onSortByName]
  );
  const onSortBySchoolClassCallback = useCallback(
    () => onSortBySchoolClass(),
    [onSortBySchoolClass]
  );
  const onSortByRuntimeCallback = useCallback(
    () => onSortByRuntime(),
    [onSortByRuntime]
  );
  const onSortByLastActiveCallback = useCallback(
    () => onSortByLastActive(),
    [onSortByLastActive]
  );
  const onSortByProgressCallback = useCallback(
    () => onSortByProgress(),
    [onSortByProgress]
  );

  useEffect(() => {
    switch (value) {
      case t("common:listTitles.active"):
        onSortByOnlineCallback();
        break;
      case t("common:listTitles.name"):
        onSortByNameCallback();
        break;
      case t("common:listTitles.schoolClass"):
        onSortBySchoolClassCallback();
        break;
      case t("common:listTitles.lastActive"):
        onSortByLastActiveCallback();
        break;
      case t("common:listTitles.runtime"):
        onSortByRuntimeCallback();
        break;
      case t("common:listTitles.progress"):
        onSortByProgressCallback();
        break;
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {mobile ? (
        <MobileSorter
          data={[
            {
              label: t("common:listTitles.active"),
              value: t("common:listTitles.active"),
            },
            {
              label: t("common:listTitles.name"),
              value: t("common:listTitles.name"),
            },
            {
              label: t("common:listTitles.schoolClass"),
              value: t("common:listTitles.schoolClass"),
            },
            {
              label: t("common:listTitles.lastActive"),
              value: t("common:listTitles.lastActive"),
            },
            {
              label: t("common:listTitles.runtime"),
              value: t("common:listTitles.runtime"),
            },
            {
              label: t("common:listTitles.progress"),
              value: t("common:listTitles.progress"),
            },
          ]}
          label="Sort"
          my="sm"
          order={props.currentSortOrder.order}
          handleChange={(event) => setValue(event.currentTarget.value)}
          handleReverseOrder={props.onReverseOrder}
        />
      ) : (
        <Paper
          sx={{
            backgroundColor: "transparent",
            paddingLeft: props.isEditing ? 35 : undefined,
          }}
        >
          <UserListTitles
            onSortByOnline={props.onSortByOnline}
            onSortByName={props.onSortByName}
            onSortByRuntime={props.onSortByRuntime}
            onSortByLastActive={props.onSortByLastActive}
            onSortBySchoolClass={props.onSortBySchoolClass}
            onSortByProgress={props.onSortByProgress}
            currentSortOrder={props.currentSortOrder}
          />
        </Paper>
      )}
      <AnimatedStack spacing={5}>
        {props.isLoading ? (
          <UserCardSkeletons />
        ) : (
          props.users.map((user: User, index) => {
            const progressList =
              props.selectedPublicCourseUUID && !props.selectAll
                ? user.Progress.filter(
                    (p: Progress) =>
                      p.PublicCourseUUID === props.selectedPublicCourseUUID
                  )
                : user.Progress;
            const numExercises = progressList.reduce(
              (acc: number, current: Progress) => acc + current.ExerciseCount,
              0
            );
            const numCompleted = progressList.reduce(
              (acc: number, current: Progress) => acc + current.CompletedCount,
              0
            );
            const runtime = progressList.reduce(
              (acc: number, current: Progress) => acc + current.Runtime,
              0
            );
            const progress = numCompleted / numExercises;
            const isOutlier = props.selectAll
              ? false
              : props.outliers?.some(
                  (outlier: Outlier) =>
                    outlier.userID === user.ID &&
                    outlier.isBehind &&
                    outlier.PublicCourseUUID === props.selectedPublicCourseUUID
                );
            const rowNumber = index + 1;

            if (mobile)
              return props.isEditing ? (
                <Group key={user.ID} noWrap>
                  <MantineCheckbox
                    checked={props.selectedUserIds.some((id) => id === user.ID)}
                    onChange={() => onCheck(user.ID)}
                  />
                  <Paper
                    sx={{
                      position: "relative",
                      flexGrow: 1,
                      border: props.selectedUserIds.some((id) => id === user.ID)
                        ? `2px solid ${theme.colors.orange[2]}`
                        : `2px solid transparent`,
                      backgroundColor: props.selectedUserIds.some(
                        (id) => id === user.ID
                      )
                        ? theme.colors.orange[0]
                        : undefined,
                    }}
                    p="xs"
                    className={`animate-in ${
                      index > 20 || initialLength === 0
                        ? "disable-animation"
                        : ""
                    }`}
                  >
                    <Stack spacing={0}>
                      <Group position="apart" noWrap>
                        <Group noWrap>
                          <InitialsBadge user={user} />
                          <Stack spacing={0}>
                            <strong className="notranslate">
                              <Highlight
                                highlight={props.highlight?.split(" ") ?? ""}
                              >{`${user.FirstName} ${user.LastName}`}</Highlight>
                            </strong>
                            <Text color="gray">{user.SchoolClass}</Text>
                          </Stack>
                        </Group>
                        <Stack spacing={0} align="flex-end">
                          <Text
                            sx={(theme) => ({
                              color: user.LastActive.length
                                ? undefined
                                : theme.colors.bluegray[5],
                            })}
                            align="right"
                          >
                            {user.IsOnline
                              ? t("common:presence.isActive")
                              : user.LastActive.length
                              ? formatTimeDistanceStrict(user.LastActive)
                              : t("common:presence.neverLoggedIn")}
                          </Text>
                        </Stack>
                      </Group>
                      <ProgressBar
                        disableAnimation={index > 20}
                        showText
                        percent={progress || 0}
                        isOutlier={isOutlier}
                        progress={
                          props.showQuotaProgress
                            ? {
                                completed: numCompleted,
                                totalCount: numExercises,
                              }
                            : undefined
                        }
                      />
                    </Stack>
                  </Paper>
                </Group>
              ) : (
                <Paper
                  sx={[paperStyles, { position: "relative" }]}
                  component={Link}
                  to={`${USERS_PATH}/${user.ID}`}
                  key={index}
                  p="xs"
                  className={`animate-in ${
                    index > 20 || initialLength === 0 ? "disable-animation" : ""
                  }`}
                >
                  <Stack spacing={0}>
                    <Group position="apart" noWrap>
                      <Group noWrap>
                        <InitialsBadge user={user} />
                        <Stack spacing={0}>
                          <strong className="notranslate">
                            <Highlight
                              highlight={props.highlight?.split(" ") ?? ""}
                            >{`${user.FirstName} ${user.LastName}`}</Highlight>
                          </strong>
                          <Text color="gray">{user.SchoolClass}</Text>
                        </Stack>
                      </Group>
                      <Stack spacing={0} align="flex-end">
                        <Text
                          sx={(theme) => ({
                            color: user.LastActive.length
                              ? undefined
                              : theme.colors.bluegray[5],
                          })}
                          align="right"
                        >
                          {user.IsOnline
                            ? t("common:presence.isActive")
                            : user.LastActive.length
                            ? formatTimeDistanceStrict(user.LastActive)
                            : t("common:presence.neverLoggedIn")}
                        </Text>
                      </Stack>
                    </Group>
                    <ProgressBar
                      disableAnimation={index > 20}
                      showText
                      percent={progress || 0}
                      isOutlier={isOutlier}
                      progress={
                        props.showQuotaProgress
                          ? {
                              completed: numCompleted,
                              totalCount: numExercises,
                            }
                          : undefined
                      }
                    />
                  </Stack>
                </Paper>
              );
            return props.isEditing ? (
              <Group key={user.ID}>
                <MantineCheckbox
                  checked={props.selectedUserIds.some((id) => id === user.ID)}
                  onChange={() => onCheck(user.ID)}
                />
                <Paper
                  sx={{
                    position: "relative",
                    flexGrow: 1,
                    border: props.selectedUserIds.some((id) => id === user.ID)
                      ? `2px solid ${theme.colors.orange[2]}`
                      : `2px solid transparent`,
                    backgroundColor: props.selectedUserIds.some(
                      (id) => id === user.ID
                    )
                      ? theme.colors.orange[0]
                      : undefined,
                  }}
                  py="xs"
                >
                  <UsersRowColumns
                    columns={[
                      <InitialsBadge user={user} />,
                      <strong className="notranslate">
                        <Highlight
                          highlight={props.highlight?.split(" ") ?? ""}
                        >{`${user.FirstName} ${user.LastName}`}</Highlight>
                      </strong>,
                      <>
                        {user.SchoolClass !== "" ? (
                          <Highlight
                            highlight={props.highlight?.split(" ") ?? ""}
                          >
                            {user.SchoolClass}
                          </Highlight>
                        ) : (
                          <em>{t("user.dataEmptyStates.noSchoolClass")}</em>
                        )}
                      </>,
                      <Text
                        sx={(theme) => ({
                          color: user.LastActive.length
                            ? undefined
                            : theme.colors.bluegray[5],
                        })}
                      >
                        {user.IsOnline
                          ? t("common:presence.isActive")
                          : user.LastActive.length
                          ? formatTimeDistanceStrict(user.LastActive)
                          : t("common:presence.neverLoggedIn")}
                      </Text>,
                      <>
                        {runtime ? (
                          <Text>{convertSecondsToReadable(runtime)}</Text>
                        ) : (
                          <em>{convertSecondsToReadable(runtime)}</em>
                        )}
                      </>,
                      <ProgressBar
                        disableAnimation={index > 20}
                        showText
                        percent={progress || 0}
                        isOutlier={isOutlier}
                        progress={
                          props.showQuotaProgress
                            ? {
                                completed: numCompleted,
                                totalCount: numExercises,
                              }
                            : undefined
                        }
                      />,
                    ]}
                  />
                </Paper>
              </Group>
            ) : (
              <Paper
                sx={[paperStyles, { position: "relative" }]}
                component={Link}
                to={`${USERS_PATH}/${user.ID}`}
                key={index}
                py="xs"
                className={`animate-in ${
                  index > 20 || initialLength === 0 ? "disable-animation" : ""
                }`}
              >
                <AdminRowNumber number={rowNumber} />
                <UsersRowColumns
                  columns={[
                    <InitialsBadge user={user} />,
                    <strong className="notranslate">
                      <Highlight
                        highlight={props.highlight?.split(" ") ?? ""}
                      >{`${user.FirstName} ${user.LastName}`}</Highlight>
                    </strong>,
                    <>
                      {user.SchoolClass !== "" ? (
                        <Highlight
                          highlight={props.highlight?.split(" ") ?? ""}
                        >
                          {user.SchoolClass}
                        </Highlight>
                      ) : (
                        <em>{t("user.dataEmptyStates.noSchoolClass")}</em>
                      )}
                    </>,
                    <Text
                      sx={(theme) => ({
                        color: user.LastActive.length
                          ? undefined
                          : theme.colors.bluegray[5],
                      })}
                    >
                      {user.IsOnline
                        ? t("common:presence.isActive")
                        : user.LastActive.length
                        ? formatTimeDistanceStrict(user.LastActive)
                        : t("common:presence.neverLoggedIn")}
                    </Text>,
                    <>
                      {runtime ? (
                        <Text>{convertSecondsToReadable(runtime)}</Text>
                      ) : (
                        <em>{convertSecondsToReadable(runtime)}</em>
                      )}
                    </>,
                    <ProgressBar
                      disableAnimation={index > 20}
                      showText
                      percent={progress || 0}
                      isOutlier={isOutlier}
                      progress={
                        props.showQuotaProgress
                          ? {
                              completed: numCompleted,
                              totalCount: numExercises,
                            }
                          : undefined
                      }
                    />,
                  ]}
                />
              </Paper>
            );
          })
        )}
      </AnimatedStack>
    </>
  );
};

interface AdminRowNumberProps {
  number: number;
}

export const AdminRowNumber = (props: AdminRowNumberProps) =>
  isAdminUser() ? (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: -30,
        height: "100%",
        width: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Text size="xs" weight="bold" color="dimmed">
        {props.number.toLocaleString(fetchLang())}
      </Text>
    </Box>
  ) : null;

const UserCardSkeletons = () => {
  const count = Math.round(VIEW_HEIGHT / 58);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  if (mobile)
    return (
      <>
        {Array(count)
          .fill("")
          .map((_, index: number) => (
            <Paper
              className="animate-in"
              key={index}
              style={{
                opacity: count > 5 ? 1 - (1 / count) * (index + 1) : 1,
              }}
              sx={paperStyles}
              p="sm"
            >
              <Stack spacing="xs">
                <Group position="apart" noWrap>
                  <Group noWrap>
                    <Skeleton width={38} height={38} circle />
                    <Stack spacing="xs">
                      <Skeleton width={170} height={12} />
                      <Skeleton width={50} height={12} />
                    </Stack>
                  </Group>
                  <Stack spacing="xs" align="flex-end">
                    <Skeleton width={70} height={12} />
                    <Skeleton width={60} height={12} />
                  </Stack>
                </Group>
                <Skeleton width={"100%"} height={12} />
              </Stack>
            </Paper>
          ))}
      </>
    );

  return (
    <>
      {Array(count)
        .fill("")
        .map((_, index: number) => (
          <Paper
            className="animate-in"
            key={index}
            style={{
              opacity: count > 5 ? 1 - (1 / count) * (index + 1) : 1,
            }}
            sx={paperStyles}
            py="xs"
          >
            <UsersRowColumns
              columns={[
                <Skeleton width={38} height={38} circle />,
                <Skeleton width={220} height={12} />,
                <Skeleton width={160} height={12} />,
                <Skeleton width={70} height={12} />,
                <Skeleton width={70} height={12} />,
                <Skeleton width={70} height={12} />,
              ]}
            />
          </Paper>
        ))}
    </>
  );
};

interface UserListTitlesProps {
  onSortByOnline: () => void;
  onSortByName: () => void;
  onSortBySchoolClass: () => void;
  onSortByRuntime: () => void;
  onSortByLastActive: () => void;
  onSortByProgress: () => void;
  currentSortOrder: OrderBy;
}

const UserListTitles = (props: UserListTitlesProps) => {
  const sort = props.currentSortOrder;
  const { t } = useTranslation("common");
  return (
    <UsersRowColumns
      columns={[
        <ListTitle
          onClick={props.onSortByOnline}
          isActive={sort.tag === "IsOnline"}
          title={t("listTitles.active")}
          order={sort.order}
        />,
        <ListTitle
          onClick={props.onSortByName}
          isActive={sort.tag === "FirstName"}
          title={t("listTitles.name")}
          order={sort.order}
        />,
        <ListTitle
          onClick={props.onSortBySchoolClass}
          isActive={sort.tag === "SchoolClass"}
          title={t("listTitles.schoolClass")}
          order={sort.order}
        />,
        <ListTitle
          onClick={props.onSortByLastActive}
          isActive={sort.tag === "LastActive"}
          title={t("listTitles.lastActive")}
          order={sort.order}
        />,
        <ListTitle
          onClick={props.onSortByRuntime}
          isActive={sort.tag === "RunTime"}
          title={t("listTitles.runtime")}
          order={sort.order}
        />,
        <ListTitle
          onClick={props.onSortByProgress}
          isActive={sort.tag === "Progress"}
          title={t("listTitles.progress")}
          order={sort.order}
        />,
      ]}
    />
  );
};

interface SubBarProps {
  title?: string;
  user?: User;
  placeholder?: string;
  handleSearchBar?: (event: FormEvent<Element>) => void;
  onClear?: () => void;
  searchBarValue?: string;
  onClickButton?: () => void;
  buttonText?: string;
  buttonLink?: string;
  buttonDisabled?: boolean;
  disabledReason?: string;
  debounceMs?: number;
  autoFocus?: boolean;
}

export const SubBar = (props: SubBarProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [props.disabledReason]);

  return (
    <>
      <SkillsterToolTip />
      <SubBarWrapper>
        <Container size="lg" className="content" sx={{ position: "relative" }}>
          <div className="box">
            {props.user ? (
              <div className="userTitle">
                <InitialsBadge user={props.user} className="badge" />
                <Title order={3} className="notranslate">
                  {props.user.FirstName} {props.user.LastName}
                </Title>
              </div>
            ) : (
              <Title
                order={3}
                className="notranslate group-name-title"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {props?.title}
              </Title>
            )}
          </div>
          <div className="box search hideOnPrint">
            {props.handleSearchBar && (
              <SearchBar
                onClear={props.onClear}
                searchBarValue={props.searchBarValue}
                handleSearchBar={props.handleSearchBar}
                placeholder={props.placeholder}
                debounceMs={props.debounceMs}
                invertColors
                autoFocus={props.autoFocus}
              />
            )}
          </div>
          <div className="box hideOnPrint">
            {props.onClickButton && props.buttonText && (
              <MantineButton
                onClick={props.onClickButton}
                disabled={props.buttonDisabled}
                data-tip={props.disabledReason ?? undefined}
              >
                {props.buttonText}
              </MantineButton>
            )}
            {props.buttonLink && props.buttonText && (
              <Link
                to={props.buttonLink}
                data-tip={props.disabledReason ?? undefined}
                data-place="bottom"
              >
                <MantineButton disabled={props.buttonDisabled}>
                  {props.buttonText}
                </MantineButton>
              </Link>
            )}
          </div>
        </Container>
      </SubBarWrapper>
      {props.handleSearchBar && (
        <Container size="lg">
          <MobileSubBarSearchBar>
            <SearchBar
              onClear={props.onClear}
              searchBarValue={props.searchBarValue}
              handleSearchBar={props.handleSearchBar}
              placeholder={props.placeholder}
              invertColors
            />
          </MobileSubBarSearchBar>
        </Container>
      )}
    </>
  );
};

interface SearchBarProps {
  onClear?: () => void;
  searchBarValue?: string;
  handleSearchBar: (event: FormEvent) => void;
  placeholder?: string;
  autoFocus?: boolean;
  invertColors?: boolean;
  className?: string;
  debounceMs?: number;
}

export const SearchBar = (props: SearchBarProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props.searchBarValue);
  const [isDebouncing, setIsDebouncing] = useState(false);
  /* eslint-disable */
  const debouncedUpdate = useCallback(
    debounce((event: FormEvent) => {
      setIsDebouncing(false);
      props.handleSearchBar(event);
    }, props.debounceMs),
    []
  );
  /* eslint-disable */

  const onChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setValue(target.value.replace("mailto:", ""));
    if (props.debounceMs) {
      setIsDebouncing(true);
      debouncedUpdate(event);
    } else {
      props.handleSearchBar(event);
    }
  };
  const onClear = () => {
    setValue("");
    props.onClear && props.onClear();
  };
  useEffect(() => {
    if (props.searchBarValue === "") setValue("");
  }, [props.searchBarValue]);
  return (
    <TextInput
      autoFocus={props.autoFocus}
      rightSection={
        isDebouncing ? (
          <Loader size="xs" />
        ) : value !== "" ? (
          <IconX size={12} style={{ cursor: "pointer" }} onClick={onClear} />
        ) : undefined
      }
      placeholder={props.placeholder ?? t("common:misc.search")}
      onChange={onChange}
      radius="xl"
      value={value}
      styles={(theme) => ({
        wrapper: {
          minWidth: 300,
        },
        input: {
          paddingLeft: 20,
        },
      })}
    />
  );
};

interface OrderArrowProps {
  order: SortOrder;
  active?: boolean;
  className?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}
export const OrderArrow = (props: OrderArrowProps) => {
  return (
    <Image
      src={sortIcon}
      className={props.className}
      width={props.width ?? 10}
      height={props.height}
      onClick={props.onClick}
      sx={{
        opacity: props.active ? 1 : 0,
        transition: "transform 200ms ease-in-out",
        transform:
          props.order === SortOrder.Ascending ? "rotate(180deg)" : "rotate(0)",
      }}
    />
  );
};

interface SearchingForProps {
  filter: string;
  resultsCount?: number;
  color?: string;
  title?: string;
  size?: MantineNumberSize;
}

export const SearchingFor = (props: SearchingForProps) => {
  const { t } = useTranslation("common");
  return props.filter.length ? (
    <Container size={props.size ?? "lg"} px={0}>
      <Paper
        sx={(theme) => ({
          backgroundColor: props.color ?? theme.colors.bluegray[1],
        })}
        p="md"
      >
        <Group spacing="xs">
          <IconSearch width={15} />
          <Text>
            {props.title ?? t("searching.resultsTitle")}:{" "}
            <Text span weight="bold">
              {props.filter}
            </Text>
            {props.resultsCount
              ? ` (${props.resultsCount.toLocaleString(fetchLang())}${
                  svLocale() ? " " : ""
                }${t("units.numOf")})`
              : ""}
          </Text>
        </Group>
      </Paper>
    </Container>
  ) : null;
};

interface NoSearchResultsProps {
  onClear?: () => void;
}
export const NoSearchResults = (props: NoSearchResultsProps) => {
  const { t } = useTranslation("common");
  return (
    <NoSearchResultsWrapper className="animate-in">
      <Stack align="center">
        <img src={noSearchResults} alt={t("searching.noResults.title")} />
        <Title order={3}>{t("searching.noResults.title")}</Title>
        {props.onClear ? (
          <Button onClick={props.onClear}>
            {t("searching.noResults.button")}
          </Button>
        ) : null}
      </Stack>
    </NoSearchResultsWrapper>
  );
};

interface CourseTypeIconProps {
  isLinear?: boolean;
  showText?: boolean;
  customCourse?: boolean;
  smallText?: boolean;
  className?: string;
}

export const CourseTypeIcon = (props: CourseTypeIconProps) => {
  const { t } = useTranslation("common");
  return (
    <Group spacing={8}>
      {props.customCourse ? (
        props.isLinear ? (
          <IconLinearAlt />
        ) : (
          <IconFreeAlt />
        )
      ) : props.isLinear ? (
        <IconLinear />
      ) : (
        <IconFree />
      )}
      {props.showText ? (
        <Title
          order={6}
          sx={(theme) => ({
            color: props.isLinear
              ? theme.colors.blue[props.customCourse ? 8 : 5]
              : theme.colors.green[7],
          })}
        >
          {props.isLinear
            ? t("courseTypes.linear.singular")
            : t("courseTypes.open.singular")}
        </Title>
      ) : null}
    </Group>
  );
};

export const useOutsideAlerter = (
  ref: MutableRefObject<any>,
  onClickOutside: () => void
) => {
  useEffect(() => {
    function handleClickOutside(e: Event) {
      if (ref.current && !ref.current.contains(e.target)) onClickOutside();
    }
    const keyPress = (e: KeyboardEvent) =>
      e.key === "Escape" && onClickOutside();
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", keyPress);
    };
  }, [ref, onClickOutside]);
};

interface DropDownProps {
  options: Option[];
  selected?: Option;
  onChange: (option: Option) => void;
  label?: string;
  className?: string;
  inverted?: boolean;
  loading?: boolean;
  selectedClassName?: string;
  disabled?: boolean;
  adjustHeightOnOpen?: boolean;
  allowFiltering?: boolean;
}

export const DropDown = (props: DropDownProps) => {
  const { t } = useTranslation();
  const ref: MutableRefObject<any> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(ref, () => setIsOpen(false));
  return (
    <DropDownWrapper
      ref={ref}
      className={`${props.className} ${
        props.adjustHeightOnOpen && isOpen ? "is-open-height" : ""
      }`}
    >
      {props.label && <label>{props.label}</label>}
      <DropDownBtn
        className={`${props.inverted ? "inverted" : ""} ${
          props.selectedClassName
        } ${props.selected?.value === 2 ? "free" : ""} ${
          props.disabled || props.options.length === 1 ? "disabled" : ""
        } ${props.loading ? "loading" : ""}`}
        onClick={() =>
          !props.disabled && props.options.length > 1 && setIsOpen(!isOpen)
        }
        isOpen={isOpen}
      >
        {props.selected?.icon && props.selected.icon()}
        <h5 className="selected">
          {props.loading
            ? t("common:misc.loading")
            : props.selected?.label ?? `Välj ${props.label?.toLowerCase()}`}
        </h5>
        {!props.disabled && !props.loading && props.options.length > 1 && (
          <img src={chevron} alt="Välj" className="chevron" />
        )}
        {props.loading && <SmallSpinner className="spinner" />}
      </DropDownBtn>
      <DropDownItems
        options={props.options}
        isOpen={isOpen}
        onChange={props.onChange}
        onClose={() => setIsOpen(false)}
        selected={props.selected}
        hasLabel={!!props.label}
        allowFiltering={props.allowFiltering}
      />
    </DropDownWrapper>
  );
};

interface DropDownItemsProps {
  options: Option[];
  isOpen?: boolean;
  selected?: Option;
  hasLabel?: boolean;
  onChange: (option: Option) => void;
  onClose: () => void;
  allowFiltering?: boolean;
}
const DropDownItems = (props: DropDownItemsProps) => {
  const { t } = useTranslation();
  const ref: MutableRefObject<any> = useRef(null);
  const listRef: MutableRefObject<any> = useRef(null);
  const selectedRef: MutableRefObject<any> = useRef(null);
  const [filter, setFilter] = useState("");
  const options = props.options.filter((o: Option) =>
    o.label.toLowerCase().includes(filter.toLowerCase().trim())
  );
  const onChangeFilter = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFilter(target.value);
  };
  useEffect(() => {
    if (props.isOpen) {
      const topPos = selectedRef.current?.offsetTop;
      listRef.current.scrollTop = topPos - 50;
    }
  }, [props.isOpen]);
  return (
    <CSSTransition
      unmountOnExit
      nodeRef={ref}
      in={props.isOpen}
      timeout={300}
      classNames="fadeInDown"
    >
      <DropDownItemsWrapper ref={ref} hasLabel={props.hasLabel}>
        {props.allowFiltering && (
          <div className="filter-wrapper">
            <input
              type="text"
              value={filter}
              onChange={onChangeFilter}
              className="input-filter"
              autoFocus
              placeholder={t("common:misc.filter")}
            />
          </div>
        )}
        {filter.length > 0 && options.length === 0 && (
          <div className="empty-state">
            {t("common:searching.noResults.title")}
          </div>
        )}
        <div className="list" ref={listRef}>
          {options.map((o: Option, index) => {
            return (
              <li
                onClick={() => {
                  props.onChange(o);
                  props.onClose();
                }}
                className={`${
                  props.selected?.value === o.value ? "selected" : ""
                }`}
                key={index}
                ref={
                  props.selected?.value === o.value ? selectedRef : undefined
                }
              >
                {o?.icon && o.icon()}
                <label>{o.label}</label>
              </li>
            );
          })}
        </div>
      </DropDownItemsWrapper>
    </CSSTransition>
  );
};

interface DropDownStringValueProps {
  options: ExerciseOption[];
  selected?: ExerciseOption;
  onChange: (option: ExerciseOption) => void;
  label?: string;
  className?: string;
  inverted?: boolean;
  showThumbs?: boolean;
  disabled?: boolean;
  allowFiltering?: boolean;
}

export const DropDownStringValue = (props: DropDownStringValueProps) => {
  const ref: MutableRefObject<any> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(ref, () => setIsOpen(false));
  return (
    <DropDownStringValueWrapper ref={ref} className={`${props.className}`}>
      {props.label && <label>{props.label}</label>}
      <DropDownBtn
        className={`${props.inverted ? "inverted" : ""} ${
          props.disabled || props.options.length === 1 ? "disabled" : ""
        }`}
        onClick={() =>
          !props.disabled && props.options.length > 1 && setIsOpen(!isOpen)
        }
        isOpen={isOpen}
      >
        {props.showThumbs && props.selected?.Value && (
          <DropdownVehicleThumbWrapper>
            <img
              src={getCloudinaryVehicleUrl(props.selected?.Value, 145)}
              alt={props.selected?.Name}
            />
          </DropdownVehicleThumbWrapper>
        )}
        <h5 className="selected">
          {props.selected?.Name ?? `Välj ${props.label?.toLowerCase()}`}
        </h5>
        {!props.disabled && props.options.length > 1 && (
          <img src={chevron} alt="Välj" className="chevron" />
        )}
      </DropDownBtn>
      <DropDownStringValueItems
        options={props.options}
        isOpen={isOpen}
        onChange={props.onChange}
        onClose={() => setIsOpen(false)}
        selected={props.selected}
        hasLabel={!!props.label}
        showThumbs={props.showThumbs}
        allowFiltering={props.allowFiltering}
      />
    </DropDownStringValueWrapper>
  );
};
interface SelectCourseModalProps {
  courses: Course[];
  isOpen?: boolean;
  selectedPublicCourseUUID?: string;
  onSelectCourse: (course: Course) => void;
  onClose: () => void;
}
export const SelectCourseModal = (props: SelectCourseModalProps) => {
  const transitionRef: MutableRefObject<any> = useRef(null);
  const clickOutsideRef: MutableRefObject<any> = useRef(null);
  useOutsideAlerter(clickOutsideRef, props.onClose);
  const [showWindow, setShowWindow] = useState(false);
  useEffect(() => {
    setTimeout(
      () => setShowWindow(props.isOpen ?? false),
      props.isOpen ? 20 : 0
    );
  }, [props.isOpen]);

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={transitionRef}
      unmountOnExit
      in={props.isOpen}
      timeout={300}
      classNames="fade"
    >
      <ModalWrapper ref={transitionRef}>
        <CSSTransition
          nodeRef={clickOutsideRef}
          unmountOnExit
          in={showWindow}
          timeout={100}
          classNames="fadeSlideUp"
        >
          <SelectCourseModalInnerWrapper ref={clickOutsideRef}>
            <SelectCourseModalTitle onClose={props.onClose} />
            <SelectCourseModalGrid
              isLinear={false}
              courses={props.courses.filter(
                (course: Course) => !course.IsLinear && course.Native
              )}
              onSelectCourse={props.onSelectCourse}
              onClose={props.onClose}
              selectedPublicCourseUUID={props.selectedPublicCourseUUID}
            />
            <SelectCourseModalGrid
              isLinear
              courses={props.courses.filter(
                (course: Course) => course.IsLinear && course.Native
              )}
              onSelectCourse={props.onSelectCourse}
              onClose={props.onClose}
              selectedPublicCourseUUID={props.selectedPublicCourseUUID}
            />
          </SelectCourseModalInnerWrapper>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>,
    document.getElementById("modal") as HTMLElement
  );
};

interface SelectCourseModalTitleProps {
  onClose: () => void;
}
const SelectCourseModalTitle = (props: SelectCourseModalTitleProps) => {
  const { t } = useTranslation();
  return (
    <SelectCourseModalTitleWrapper>
      <IconCoursesHeadline />
      <div className="text">
        <h3>{t("courseEditor.kiosk.courses.modal.title")}</h3>
        <span className="smallText">
          {t("courseEditor.kiosk.courses.modal.description")}
        </span>
      </div>
      <label onClick={props.onClose} className="close">
        {t("common:buttons.close")}
      </label>
    </SelectCourseModalTitleWrapper>
  );
};

interface SelectCourseModalGridProps {
  courses: Course[];
  onSelectCourse: (course: Course) => void;
  onClose: () => void;
  isLinear?: boolean;
  selectedPublicCourseUUID?: string;
}

const SelectCourseModalGrid = (props: SelectCourseModalGridProps) => {
  const { t } = useTranslation();
  return (
    <SelectCourseModalGridWrapper>
      <h4 className="title">
        {props.isLinear ? (
          <>
            <IconLinear /> {t("courses.linear.title")}
          </>
        ) : (
          <>
            <IconFree /> {t("courses.open.title")}
          </>
        )}
      </h4>
      <div className="grid">
        {props.courses.map((course: Course) => (
          <ModalCourse
            course={course}
            key={course.PublicUUID}
            onClick={() => {
              props.onSelectCourse(course);
              props.onClose();
            }}
            className={`${
              props.selectedPublicCourseUUID === course.PublicUUID
                ? "selected"
                : ""
            }`}
          />
        ))}
      </div>
    </SelectCourseModalGridWrapper>
  );
};
interface ModalCourseProps {
  course: Course;
  onClick: () => void;
  className?: string;
}

const ModalCourse = (props: ModalCourseProps) => {
  return (
    <ModalCourseWrapper className={props.className} onClick={props.onClick}>
      <CourseImage
        imageUUID={props.course.ImageUUID}
        width={145}
        alt={props.course.Name}
        className="course-image"
      />
      <label>{trimCourseName(props.course.Name)}</label>
    </ModalCourseWrapper>
  );
};

interface DropDownStringValueItemsProps {
  options: ExerciseOption[];
  isOpen?: boolean;
  selected?: ExerciseOption;
  hasLabel?: boolean;
  onChange: (option: ExerciseOption) => void;
  onClose: () => void;
  allowFiltering?: boolean;
  showThumbs?: boolean;
}
const DropDownStringValueItems = (props: DropDownStringValueItemsProps) => {
  const { t } = useTranslation();
  const ref: MutableRefObject<any> = useRef(null);
  const listRef: MutableRefObject<any> = useRef(null);
  const selectedRef: MutableRefObject<any> = useRef(null);
  const [filter, setFilter] = useState("");
  const options = props.options.filter((o: ExerciseOption) =>
    o.Name.toLowerCase().includes(filter.toLowerCase().trim())
  );
  const onChangeFilter = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    setFilter(target.value);
  };
  useEffect(() => {
    if (props.isOpen) {
      const topPos = selectedRef.current?.offsetTop;
      listRef.current.scrollTop = topPos - 50;
    }
  }, [props.isOpen]);
  return (
    <CSSTransition
      unmountOnExit
      nodeRef={ref}
      in={props.isOpen}
      timeout={300}
      classNames="fadeInDown"
    >
      <DropDownItemsWrapper ref={ref} hasLabel={props.hasLabel}>
        {props.allowFiltering && (
          <div className="filter-wrapper">
            <input
              type="text"
              value={filter}
              onChange={onChangeFilter}
              className="input-filter"
              autoFocus
              placeholder={t("common:misc.filter")}
            />
          </div>
        )}
        <ul className="list" ref={listRef}>
          {options.map((o: ExerciseOption, index) => {
            return (
              <li
                onClick={() => {
                  props.onChange(o);
                  props.onClose();
                }}
                className={`${
                  props.selected?.Value === o.Value ? "selected" : ""
                }`}
                key={index}
                ref={
                  props.selected?.Value === o.Value ? selectedRef : undefined
                }
              >
                {props.showThumbs && o?.Value && (
                  <DropdownVehicleThumbWrapper>
                    <img
                      src={getCloudinaryVehicleUrl(o.Value, 145)}
                      alt={o?.Name}
                    />
                  </DropdownVehicleThumbWrapper>
                )}
                <label>{o.Name}</label>
              </li>
            );
          })}
        </ul>
      </DropDownItemsWrapper>
    </CSSTransition>
  );
};

interface ModalProps {
  children: ReactNode;
  title: string | JSX.Element;
  onClose?: () => void;
  onSave?: () => void;
  isOpen?: boolean;
  wide?: boolean;
  tall?: boolean;
  hasError?: boolean;
  allowOverflow?: boolean;
  noPadding?: boolean;
  disableClickOutsideToClose?: boolean;
}

export const Modal = (props: ModalProps) => {
  const { t } = useTranslation("common");
  const transitionRef: MutableRefObject<any> = useRef(null);
  const clickOutsideRef: MutableRefObject<any> = useRef(null);
  const messageIsOpen = useMessageIsOpen();
  function nullFunction() {}
  useOutsideAlerter(
    clickOutsideRef,
    (props.disableClickOutsideToClose || messageIsOpen
      ? nullFunction
      : props.onClose) ?? nullFunction
  );
  const [showWindow, setShowWindow] = useState(false);
  useEffect(() => {
    setTimeout(
      () => setShowWindow(props.isOpen ?? false),
      props.isOpen ? 20 : 0
    );
  }, [props.isOpen]);
  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={transitionRef}
      unmountOnExit
      in={props.isOpen}
      timeout={300}
      classNames="fade"
    >
      <ModalWrapper
        ref={transitionRef}
        className={`${props.allowOverflow ? "allow-overflow" : ""} ${
          props.wide ? "wide" : ""
        } ${props.noPadding ? "no-padding" : ""} ${props.tall ? "tall" : ""}`}
      >
        <CSSTransition
          nodeRef={clickOutsideRef}
          unmountOnExit
          in={showWindow}
          timeout={100}
          classNames="fadeSlideUp"
        >
          <div className="window" ref={clickOutsideRef}>
            <div className="header">
              {typeof props.title === "string" ? (
                <Title order={2}>{props.title}</Title>
              ) : (
                props.title
              )}
              {props.onClose && (
                <label onClick={props.onClose} className="close">
                  {t("buttons.close")}
                </label>
              )}
            </div>
            <div className="content">{props.children}</div>
            {props.onSave && (
              <div className="buttons">
                {!props.hasError ? (
                  <>
                    <label className="cancel" onClick={props.onClose}>
                      {t("buttons.cancel")}
                    </label>
                    <Button onClick={props.onSave}>{t("buttons.save")}</Button>
                  </>
                ) : (
                  <label className="cancel" onClick={props.onClose}>
                    {t("buttons.close")}
                  </label>
                )}
              </div>
            )}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>,
    document.getElementById("modal") as HTMLElement
  );
};

export const SuccessAnimation = () => {
  const [play, setPlay] = useState(false);
  useEffect(() => {
    setTimeout(() => setPlay(true), 500);
  }, []);
  return (
    <Lottie
      animationData={animationSuccess}
      style={{ width: 90, height: 90 }}
      play={play}
      loop={false}
    />
  );
};
interface MessageProps {
  title: string;
  message: string;
  onClose: () => void;
  isOpen?: boolean;
  success?: boolean;
  error?: boolean;
  onExited?: () => void;
}

export const Message = (props: MessageProps) => {
  const { t } = useTranslation();
  const transitionRef: MutableRefObject<any> = useRef(null);
  const clickOutsideRef: MutableRefObject<any> = useRef(null);
  const [play, setPlay] = useState(false);
  useOutsideAlerter(clickOutsideRef, props.onClose);
  useEffect(() => {
    setTimeout(() => setPlay(true), 500);
  }, []);
  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={transitionRef}
      unmountOnExit
      in={props.isOpen}
      timeout={300}
      classNames="fade"
      onExited={props.onExited}
    >
      <MessageWrapper ref={transitionRef}>
        <div className="window" ref={clickOutsideRef}>
          <div className="content">
            {props.success ? (
              <div className="lottie">
                <Lottie
                  animationData={animationSuccess}
                  style={{ width: 90, height: 90 }}
                  play={play}
                  loop={false}
                />
              </div>
            ) : props.error ? (
              <div className="lottie">
                <Lottie
                  animationData={animationError}
                  style={{ width: 90, height: 90 }}
                  play={play}
                  loop={false}
                />
              </div>
            ) : (
              <IllustrationCoffeeCup className="illustration" />
            )}
            <h2 className="title">{props.title}</h2>
            <p className="message">{props.message}</p>
          </div>
          <div className="buttons">
            <Button className="primary" onClick={props.onClose}>
              {t("common:buttons.ok")}
            </Button>
          </div>
        </div>
      </MessageWrapper>
    </CSSTransition>,
    document.getElementById("modal") as HTMLElement
  );
};

interface FeedbackModalProps {
  isOpen?: boolean;
  onClose: () => void;
}
export const FeedbackModal = (props: FeedbackModalProps) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const contactFormsState = useSelector(
    (state: RootState) => state.contactForms.feedback
  );
  const loadingState = useSelector((state: RootState) => state.loading);
  const loading = get(loadingState, "POST_TO_FRESHDESK", false);
  const prevIsOpen = usePrevious(props.isOpen);
  useEffect(() => {
    if (prevIsOpen && !props.isOpen) dispatch(setReset("POST_TO_FRESHDESK"));
  }, [dispatch, props.isOpen, prevIsOpen]);
  const onChangeFrom = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    dispatch(actions.contactForms.feedback.setFrom(target.value));
  };
  const onChangeMessage = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    dispatch(actions.contactForms.feedback.setMessage(target.value));
  };
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(
      postToFreshDesk(
        contactFormsState.from,
        contactFormsState.message,
        window.location.href,
        ["teacher", "feedback"],
        false
      )
    );
  };
  return (
    <Modal
      isOpen={props.isOpen}
      title={t("feedback.title")}
      onClose={props.onClose}
    >
      <form onSubmit={onSubmit}>
        <Stack>
          <Title order={4}>
            {t("feedback.subTitle")} {localStorageGet("first_name")}
          </Title>
          <Text>{t("feedback.description")}</Text>
          <Stack spacing="xs">
            <TextInput
              label={t("inputLabels.email")}
              value={contactFormsState.from}
              onChange={onChangeFrom}
            />
            <Textarea
              minRows={5}
              label={t("inputLabels.message")}
              value={contactFormsState.message}
              onChange={onChangeMessage}
            />
          </Stack>
          <Button
            submitButton
            isLoading={loading}
            loadingText={t("buttons.sending")}
          >
            {t("buttons.send")}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

interface ExerciseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CookiesModal = (props: ExerciseModalProps) => {
  const { t } = useTranslation();
  interface CookieSubPart {
    title: string;
    description: string;
  }
  interface CookieContent {
    title: string;
    description: string;
    subParts?: CookieSubPart[];
  }
  const cookieContent: CookieContent[] = t("cookies.parts", {
    returnObjects: true,
  });
  return (
    <Modal
      isOpen={props.isOpen}
      title={t("cookies.title")}
      onClose={props.onClose}
    >
      <CookiesModalContentWrapper>
        <section>
          {t("cookies.description", { url: window.location.hostname })}
        </section>
        {cookieContent.map((c: CookieContent, index) => {
          return (
            <section key={index}>
              <h3>{c.title}</h3>
              <p>{c.description}</p>
              {c.subParts && (
                <div className="subParts">
                  {c.subParts.map((subPart: CookieSubPart, index) => {
                    return (
                      <section key={index}>
                        <h4>{subPart.title}</h4>
                        <p>{subPart.description}</p>
                      </section>
                    );
                  })}
                </div>
              )}
            </section>
          );
        })}
      </CookiesModalContentWrapper>
    </Modal>
  );
};

interface LicenseAgreementModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const LicenseAgreementModal = (props: LicenseAgreementModalProps) => {
  return (
    <Modal
      isOpen={props.isOpen}
      title="Licence agreement"
      onClose={props.onClose}
    >
      <LicenseAgreementModalContentWrapper>
        <LicenseAgreement />
      </LicenseAgreementModalContentWrapper>
    </Modal>
  );
};

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  const matchesMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const languageOptions = [
    t("common:languageSelector.options.english"),
    t("common:languageSelector.options.swedish"),
    t("common:languageSelector.options.finnish"),
  ];

  const handleLanguageChange = (val: string) => {
    const lang =
      val === languageOptions[1]
        ? "sv"
        : val === languageOptions[2]
        ? "fi"
        : "en";
    i18n.changeLanguage(lang);
    queryClient.invalidateQueries(["get-user-progress"]);
  };

  if (matchesMobile)
    return (
      <NativeSelect
        value={
          svLocale()
            ? languageOptions[1]
            : fiLocale()
            ? languageOptions[2]
            : languageOptions[0]
        }
        onChange={(event) => handleLanguageChange(event.currentTarget.value)}
        data={languageOptions}
      />
    );

  return (
    <Select
      placeholder="Pick one"
      onChange={(event) => handleLanguageChange(event ?? "")}
      value={
        svLocale()
          ? languageOptions[1]
          : fiLocale()
          ? languageOptions[2]
          : languageOptions[0]
      }
      data={languageOptions}
      transition="pop-top-left"
      transitionDuration={80}
      transitionTimingFunction="ease"
      styles={{
        input: {
          width: 130,
        },
      }}
      icon={<Image src={globe} alt="Globe" width={17} />}
    />
  );
};

interface EmptyStateProps {
  children: ReactNode;
  className?: string;
}
export const EmptyState = (props: EmptyStateProps) => (
  <EmptyStateWrapper className={props.className}>
    {props.children}
  </EmptyStateWrapper>
);

interface ButtonProps {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
  onSubmit?: (event: FormEvent) => void;
  onClick?: () => void;
  submitButton?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}

export const Button = (props: ButtonProps) => (
  <ButtonWrapper>
    <MantineButton
      disabled={props.disabled || props.isLoading}
      type={props.submitButton ? "submit" : "button"}
      onClick={props.onSubmit ?? props.onClick}
      className={`${props.className}`}
      tabIndex={props.tabIndex}
      loading={props.isLoading}
    >
      {props.isLoading ? props.loadingText : props.children}
    </MantineButton>
  </ButtonWrapper>
);

interface CheckboxProps {
  className?: string;
  isChecked?: boolean;
  disabled?: boolean;
  text: string;
  onClick: () => void;
}

export const Checkbox = (props: CheckboxProps) => (
  <CheckboxWrapper
    onClick={props.onClick}
    className={`${props.isChecked ? "checked" : ""} ${
      props.disabled ? "disabled" : ""
    }`}
  >
    <IconCheckbox className={`${props.className} checkbox`} />
    <label>{props.text}</label>
  </CheckboxWrapper>
);

interface RadioButtonProps {
  className?: string;
  isSelected?: boolean;
  text: string;
  onClick: () => void;
}

export const RadioButton = (props: RadioButtonProps) => (
  <RadioButtonWrapper
    onClick={props.onClick}
    className={`${props.isSelected ? "selected" : ""}`}
  >
    <IconRadioButton className={`${props.className} radio`} />
    <label>{props.text}</label>
  </RadioButtonWrapper>
);

interface SkeletonCardProps {
  num?: number;
  height: string;
  width?: string;
  randomWidth?: boolean;
  className?: string;
  inverted?: boolean;
}

export const SkeletonBlock = (props: SkeletonCardProps) => {
  const count = props.num ?? 1;
  return (
    <SkeletonBlocksWrapper
      className={`${props.className} ${count === 1 ? "no-margin-bottom" : ""}`}
    >
      {Array(count)
        .fill("")
        .map((_, index: number) => (
          <SkeletonBlockWrapper
            key={index}
            height={props.height}
            randomWidth={props.randomWidth}
            className={props.inverted ? "inverted" : ""}
            style={{
              width: props.width ?? undefined,
            }}
          />
        ))}
    </SkeletonBlocksWrapper>
  );
};

interface LoadingScreenProps {
  in?: boolean;
}

export const LoadingScreen = (props: LoadingScreenProps) => {
  const [show, setShow] = useState(false);
  const ref: MutableRefObject<any> = useRef(null);
  const minTime = 1000;
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);

  useEffect(() => {
    if (props.in) {
      setShow(true);
      setTimeout(() => {
        setMinTimeElapsed(true);
      }, minTime);
    } else if (minTimeElapsed) {
      setShow(false);
      setMinTimeElapsed(false);
    }
  }, [props.in]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.in) {
      setShow(false);
      setMinTimeElapsed(false);
    }
  }, [minTimeElapsed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CSSTransition
      in={show}
      classNames="fade"
      timeout={300}
      unmountOnExit
      nodeRef={ref}
    >
      <LoadingScreenWrapper ref={ref}>
        <SkillsterLoader inverted />
      </LoadingScreenWrapper>
    </CSSTransition>
  );
};

interface ListTitleProps {
  isActive?: boolean;
  title: string;
  order: SortOrder;
  onClick?: () => void;
}

export const ListTitle = forwardRef<HTMLDivElement, ListTitleProps>(
  (props, ref) => {
    const { isActive, title, order, onClick, ...rest } = props;
    return (
      <Group
        spacing={5}
        noWrap
        ref={ref}
        {...rest}
        sx={(theme) => ({
          cursor: "pointer",
          ":hover": {
            ".title": {
              color: isActive ? theme.colors.blue[6] : theme.colors.bluegray[6],
            },
          },
        })}
        onClick={onClick}
      >
        <Title
          order={6}
          className="title"
          sx={(theme) => ({
            color: isActive ? theme.colors.blue[5] : theme.colors.bluegray[5],
          })}
        >
          {title}
        </Title>
        <OrderArrow order={order} active={isActive} />
      </Group>
    );
  }
);
