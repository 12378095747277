import { Button as MantineButton, Group, TextInput } from "@mantine/core";
import { IconCloudUpload, IconLogout } from "@tabler/icons";
import { format } from "date-fns";
import { enGB, sv } from "date-fns/locale";
import { isEqual } from "lodash";
import { FormEvent, Fragment, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Draggable, DraggableProvided, DraggableRubric, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import actions from "../../../actions";
import { COURSE_TYPE_OPTIONS, KIOSK_EXISTING_EXERCISES_IDENTIFIER, KIOSK_EXISTING_LESSONS_IDENTIFIER, KIOSK_IDENTIFIER } from "../../../helpers/constants";
import { getCloudinaryVehicleUrl } from "../../../helpers/strings.helper";
import { convertSecondsToReadable, dateObjectToReadable } from "../../../helpers/time.helper";
import { enLocale, fetchLang, fiLocale, svLocale } from "../../../helpers/universal.helper";
import { RootState } from "../../../reducers";
import teacherTheme from "../../../resources/themes/teacher";
import { Course, CourseDraft, CourseName, Exercise, ExerciseVariant, Lesson } from "../../../types/course.types";
import { ExerciseOption, Option } from "../../../types/option.types";
import { User } from "../../../types/user.types";
import { Button, CourseImage, CourseTypeIcon, DropDown, DropDownStringValue, ExerciseTemplateVariantImage, InitialsBadge, Modal, SearchBar, SearchingFor, useOutsideAlerter } from "../../global/Misc/Misc.components";
import { DropdownVehicleThumbWrapper, SmallSpinner } from "../../global/Misc/Misc.styled.components";
import { IconButtonInfo, IconChevron, IconCourseEditorSkillster, IconCourseEditorVehicles, IconDelete, IconDragDrop, IconDuplicate, IconExercises, IconImage, IconLessons, IconPen, IconPlusBlue, IconRoundChevron, IconRuntime, IconSkillsterSymbolWhite, IconSmallCheck } from "../../global/Misc/Symbols.components";
import { exercisesAreEqual, exerciseWasMovedFrom, getInitialExercise, getLessonName, isDeletedExercise, isDeletedLesson, isNewExercise, isNewLesson, lessonNameIsChanged, mergeDrafts } from "./CourseEditor.helpers";
import { AddNewLessonWrapper, BadgeWrapper, ChangeLessonNameWrapper, CollapseChevronWrapper, ComparisonWrapper, CourseEditorBlueBarWrapper, CourseEditorHeaderWrapper, CourseEditorSubMenuWrapper, CourseImageSelectorWrapper, CourseImageThumbnailWrapper, CreatedByWrapper, CustomLessonWrapper, DeleteConfirmationWrapper, DraftHeaderWrapper, DraggableExerciseCard, DraggableLessonWrapper, DropItemsPlaceholderWrapper, DroppableArea, EditButtonWrapper, ExerciseCardNameWrapper, ExerciseModalInnerWrapper, ExitWarningModalWrapper, InfoSymbolWrapper, KioskClosedWrapper, KioskLessonInnerWrapper, KioskSelectedCourseWrapper, KioskWrapper, LessonActionsWrapper, LessonCloneWrapper, LessonHeaderWrapper, LessonNameWrapper, MiniStatsWrapper, NoLessonsEmptyStateWrapper, RoundIconBase, SaveButtonWrapper, SavedStatusWrapper, UpdatedCourseImageWrapper, UpdatedCourseTypeWrapper } from "./CourseEditor.styled.components";

const getRenderExerciseVariant = (items: ExerciseVariant[], getScreenshotUUID: (ev: ExerciseVariant) => string | undefined) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric,
) => {
    const item = items[rubric.source.index];
    const screenshotUUID = getScreenshotUUID(item);
    const courseEditorState = useSelector((state: RootState) => state.courseEditor);
    const estimatedRuntime = item.EstimatedRuntimePerVehicle[courseEditorState.selectedVehicleOption.Value] ?? 0;
    return (
        <DraggableExerciseCard
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`card ${snapshot.isDragging ? "dragging" : ""}`}
        >
            <div className="inner">
                <ExerciseCardName
                    name={item.Name.replace(/([A-Z0-9])/g, ' $1').trim().replace(/(K M)/g, 'km')}
                    screenshotUUID={screenshotUUID}
                    completionTime={estimatedRuntime}
                />
                <InfoSymbol inverted onClick={() => { }} />
            </div>
        </DraggableExerciseCard>
    );
};

const getRenderExercise = (items: Exercise[], getScreenshotUUID: (templateVariantUUID: string, vehicleUUID: string) => string | undefined, getIdealCompletionTimeFromExercise: (exercise: Exercise) => number) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric,
) => {
    const item = items[rubric.source.index];
    const screenshotUUID = getScreenshotUUID(item.TemplateVariantUUID, item.VehicleUUID)
    return (
        <DraggableExerciseCard
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`card ${snapshot.isDragging ? "dragging" : ""}`}
        >
            <div className="inner">
                <ExerciseCardName
                    name={item.Name}
                    screenshotUUID={screenshotUUID}
                    completionTime={getIdealCompletionTimeFromExercise(item)}
                />
                <InfoSymbol inverted onClick={() => { }} />
            </div>
        </DraggableExerciseCard>
    );
};

const getRenderLesson = (
    items: Lesson[],
    getScreenshotUUID: (templateVariantUUID: string, vehicleUUID: string) => string | undefined,
    getIdealCompletionTimeFromExercise: (exercise: Exercise) => number
) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
) => {
    const item = items[rubric.source.index];
    return (
        <LessonCloneWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <KioskLessonInner
                lesson={item}
                index={rubric.source.index}
                getScreenshotUUID={getScreenshotUUID}
                isDragging={snapshot.isDragging}
                getIdealCompletionTimeFromExercise={getIdealCompletionTimeFromExercise}
            />
        </LessonCloneWrapper>
    );
};



interface DropItemsPlaceholderProps {
    visible?: boolean;
}

const DropItemsPlaceholder = (props: DropItemsPlaceholderProps) => {
    const ref: MutableRefObject<any> = useRef(null);
    const { t } = useTranslation();
    return (
        <CSSTransition in={props.visible} nodeRef={ref} unmountOnExit classNames="fadeZoom" timeout={300}>
            <DropItemsPlaceholderWrapper ref={ref}>
                <IconDragDrop /> <span>{t("courseEditor.droppable.emptyState")}</span>
            </DropItemsPlaceholderWrapper>
        </CSSTransition>
    );
}

interface EditableLessonProps {
    exercises: Exercise[];
    onRemoveExercise: (index: number, board: string) => void;
    onDuplicateExercise: (board: string, index: number, uuid: string) => void;
    onDuplicateLesson: (board: string, index: number, isCollapsed: boolean) => void;
    onDeleteLesson: (index: number) => void;
    onUpdateWeather: (e: Exercise, selected: ExerciseOption) => void;
    onUpdateTraffic: (e: Exercise, selected: ExerciseOption) => void;
    onUpdateDifficulty: (e: Exercise, difficulty: 0 | 1 | 2) => void;
    onChangeLessonName: (board: string, nameSv: string, nameFi: string, nameEn: string) => void;
    getScreenshotUUID: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    getVehicleGUIDfromExercise: (exercise: Exercise) => string | null;
    onOpenExerciseModal: (exercise: Exercise) => void;
    isExerciseCloned: (uuid: string) => boolean;
    getIdealCompletionTimeFromExercise: (exercise: Exercise) => number;
    weatherOptions: ExerciseOption[];
    trafficOptions: ExerciseOption[];
    vehicleOptions: ExerciseOption[];
    difficultyOptions: Option[];
    index: number;
    droppableId: string;
    name: string;
    names: { Language: string, Value: string }[];
    isDraggingExercise: boolean;
    isDraggingLesson: boolean;
    lessonIsCloned?: boolean;
}

export const EditableLesson = (props: EditableLessonProps) => {
    const noExercises = !props.exercises || props.exercises.length === 0;
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showChangeLessonName, setShowChangeLessonName] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const onNameChange = (nameSv: string, nameFi: string, nameEn: string) => props.onChangeLessonName(props.droppableId, nameSv, nameFi, nameEn);
    return (
        <Droppable droppableId={props.droppableId} type="exercise">
            {(provided, snapshot) => {
                return (
                    <CustomLessonWrapper className={`${collapsed ? "isCollapsed" : ""} ${props.lessonIsCloned ? "isCloned" : ""}`}>
                        <SkillsterToolTip />
                        <DeleteConfirmation
                            courseName={props.name}
                            onCancel={() => setShowConfirmDelete(false)}
                            onDelete={() => props.onDeleteLesson(props.index)}
                            show={showConfirmDelete}
                        />
                        <div className="inner">
                            <div>
                                <LessonHeaderWrapper>
                                    <LessonHeader
                                        index={props.index}
                                        lessonName={props.name}
                                        lessonNames={props.names}
                                        isCollapsed={collapsed}
                                        showChangeLessonName={showChangeLessonName}
                                        onSetShowChangeLessonName={setShowChangeLessonName}
                                        onNameChange={onNameChange}
                                        onSetCollapsed={setCollapsed}

                                    />
                                    <LessonActions
                                        lessonName={props.name}
                                        toggleShowConfirm={setShowConfirmDelete}
                                        onDuplicateLesson={() => props.onDuplicateLesson(props.droppableId, props.index, collapsed)}
                                    />
                                </LessonHeaderWrapper>
                            </div>

                            <DroppableArea
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={`${snapshot.isDraggingOver ? "draggingOver" : ""} ${collapsed ? "isCollapsed" : ""} ${props.isDraggingExercise ? "highlight" : ""}`}
                            >
                                {!collapsed &&
                                    <>
                                        {props.exercises.map((exercise: Exercise, index: number) =>
                                            <ExerciseCard
                                                exercise={exercise}
                                                index={index}
                                                key={exercise.UUID}
                                                weatherOptions={props.weatherOptions}
                                                trafficOptions={props.trafficOptions}
                                                vehicleOptions={props.vehicleOptions}
                                                difficultyOptions={props.difficultyOptions}
                                                completionTime={props.getIdealCompletionTimeFromExercise(exercise)}
                                                updateWeather={props.onUpdateWeather}
                                                updateTraffic={props.onUpdateTraffic}
                                                onUpdateDifficulty={props.onUpdateDifficulty}
                                                board={provided.droppableProps["data-rbd-droppable-id"]}
                                                removeExercise={props.onRemoveExercise}
                                                duplicateExercise={props.onDuplicateExercise}
                                                screenshotUUID={props.getScreenshotUUID(exercise.TemplateVariantUUID, exercise.VehicleUUID)}
                                                onOpenExerciseModal={props.onOpenExerciseModal}
                                                getVehicleGUIDfromExercise={props.getVehicleGUIDfromExercise}
                                                isCloned={props.isExerciseCloned(exercise.UUID)}
                                            />
                                        )}
                                        <DropItemsPlaceholder visible={noExercises} />
                                        {provided.placeholder}
                                    </>
                                }
                            </DroppableArea>
                        </div>
                    </CustomLessonWrapper>
                );
            }}
        </Droppable>
    );
}

interface ExerciseCardProps {
    exercise: Exercise;
    index: number;
    removeExercise: (index: number, board: string) => void;
    duplicateExercise: (board: string, index: number, uuid: string) => void;
    updateWeather: (e: Exercise, selected: ExerciseOption) => void;
    updateTraffic: (e: Exercise, selected: ExerciseOption) => void;
    onUpdateDifficulty: (e: Exercise, difficulty: 0 | 1 | 2) => void;
    onOpenExerciseModal: (exercise: Exercise) => void;
    getVehicleGUIDfromExercise: (exercise: Exercise) => string | null;
    screenshotUUID: string | undefined;
    weatherOptions: ExerciseOption[];
    trafficOptions: ExerciseOption[];
    vehicleOptions: ExerciseOption[];
    difficultyOptions: Option[];
    board: string;
    isCloned?: boolean;
    completionTime: number;
}
const ExerciseCard = (props: ExerciseCardProps) => {

    const { difficultyOptions, vehicleOptions, exercise, index } = props;
    const [showConfirmDeleteExercise, setShowConfirm] = useState(false);
    const vehicleGUID = props.getVehicleGUIDfromExercise(exercise);
    const vehicle = vehicleOptions.find((exOp: ExerciseOption) => exOp.Value === vehicleGUID)?.Name;
    const difficulty = difficultyOptions.find((op: Option) => op.value === exercise.Difficulty);

    const exerciseVariantsState = useSelector((state: RootState) => state.exerciseVariants);

    const onUpdateDifficulty = (o: Option) => {
        (o.value === 0 || o.value === 1 || o.value === 2) && props.onUpdateDifficulty(exercise, o.value)
    }

    const weatherOptions = exerciseVariantsState.map[exercise.TemplateVariantUUID]?.AllowedWeatherOptions.length ?
        props.weatherOptions.filter((o: ExerciseOption) =>
            exerciseVariantsState.map[exercise.TemplateVariantUUID].AllowedWeatherOptions.some((ao: ExerciseOption) => ao.Value === o.Value)) :
        props.weatherOptions;

    const trafficOptions = exerciseVariantsState.map[exercise.TemplateVariantUUID]?.AllowedTrafficOptions.length ?
        props.trafficOptions.filter((o: ExerciseOption) =>
            exerciseVariantsState.map[exercise.TemplateVariantUUID].AllowedTrafficOptions.some((ao: ExerciseOption) => ao.Value === o.Value)) :
        props.trafficOptions;

    const selectedWeather = weatherOptions.find((exOp: ExerciseOption) => exOp.Value === exercise.WeatherUUID);
    const selectedTraffic = trafficOptions.find((exOp: ExerciseOption) => exOp.Value === exercise.TrafficUUID);

    return (
        <Draggable draggableId={exercise.UUID} index={index}>
            {(provided, snapshot) =>
                <DraggableExerciseCard
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`
                        exerciseCard
                        card
                        ${snapshot.isDragging ? "dragging " : ""} 
                        ${props.isCloned ? " fadeInEntrance green " : ""}
                        ${showConfirmDeleteExercise ? " no-hover " : ""}
                    `}
                >
                    <DeleteConfirmation
                        courseName={exercise.Name}
                        onCancel={() => setShowConfirm(false)}
                        onDelete={() => props.removeExercise(props.index, props.board)}
                        show={showConfirmDeleteExercise}
                    />
                    <div className="inner">
                        <ExerciseCardName
                            name={props.exercise.Name}
                            screenshotUUID={props.screenshotUUID}
                            completionTime={props.exercise.EstimatedRuntime}
                        />
                        <div className="infoWrapper">
                            <InfoSymbol className="info" onClick={() => props.onOpenExerciseModal(props.exercise)} />
                        </div>
                        {vehicleGUID && <div className="vehicleWrapper" data-tip={`${vehicle}`} data-delay-show="500"><img src={getCloudinaryVehicleUrl(vehicleGUID, 145)} alt={props.exercise.Name} /></div>}

                        <div className="dropDowns">
                            <DropDown
                                inverted
                                selected={difficulty}
                                options={difficultyOptions}
                                onChange={onUpdateDifficulty}
                            />
                            <DropDownStringValue
                                inverted
                                selected={selectedWeather}
                                options={weatherOptions}
                                onChange={(exOp: ExerciseOption) => props.updateWeather(exercise, exOp)}
                            />
                            <DropDownStringValue
                                inverted
                                selected={selectedTraffic}
                                options={trafficOptions}
                                onChange={(exOp: ExerciseOption) => props.updateTraffic(exercise, exOp)}
                            />
                            <IconDuplicate onClick={() => props.duplicateExercise(props.board, props.index, props.exercise.UUID)} className="duplicate" />
                            <IconDelete onClick={() => setShowConfirm(true)} className="delete" />
                        </div>
                    </div>
                </DraggableExerciseCard>
            }
        </Draggable>
    );
}

interface InfoSymbolProps {
    onClick?: () => void;
    className?: string;
    inverted?: boolean;
}

const InfoSymbol = (props: InfoSymbolProps) => <InfoSymbolWrapper className={`${props.className} ${props.inverted ? " inverted " : ""}`} onClick={props.onClick}><IconButtonInfo /></InfoSymbolWrapper>

interface EditButtonProps {
    text: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    className?: string;
}

const EditButton = (props: EditButtonProps) =>
    <EditButtonWrapper className={props.className} onClick={props.onClick} onMouseEnter={props.onMouseEnter}>
        <IconPen /> <label>{props.text}</label>
    </EditButtonWrapper>



interface SaveButtonProps {
    text: string;
    onClick?: () => void;
    className?: string;
}
const SaveButton = (props: SaveButtonProps) =>
    <SaveButtonWrapper className={props.className} onClick={props.onClick}>
        <label>{props.text}</label>
    </SaveButtonWrapper>


interface CollapseChevronProps {
    isCollapsed?: boolean;
    className?: string;
    onClick?: () => void;
}
const CollapseChevron = (props: CollapseChevronProps) =>
    <CollapseChevronWrapper onClick={props.onClick} className={`${props.isCollapsed ? "isCollapsed" : ""} ${props.className}`}>
        <IconChevron color="blue" className="chevron" />
    </CollapseChevronWrapper>;

interface KioskLessonInnerProps {
    lesson: Lesson;
    index: number;
    isDragging?: boolean;
    getScreenshotUUID: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    onOpenExerciseModal?: (exercise: Exercise) => void;
    getIdealCompletionTimeFromExercise: (exercise: Exercise) => number;
}
const KioskLessonInner = (props: KioskLessonInnerProps) => {
    const { lesson, index } = props;
    const [isCollapsed, setIsCollapsed] = useState(false);
    return (
        <KioskLessonInnerWrapper className={`${props.isDragging ? "dragging" : ""} ${isCollapsed ? "collapsed" : ""}`}>
            <div className="header">
                <CollapseChevron isCollapsed={isCollapsed} className="chevronWrapper" onClick={() => setIsCollapsed(!isCollapsed)} />
                <h3 onClick={() => setIsCollapsed(!isCollapsed)}><LessonName name={props.lesson.Name} names={props.lesson.Names} index={props.index} /></h3>
            </div>
            {!isCollapsed &&
                <KioskLesson
                    key={index}
                publicUUID={lesson.PublicUUID}
                    index={index}
                    exercises={lesson.Exercises}
                    getScreenshotUUID={props.getScreenshotUUID}
                    onOpenExerciseModal={props.onOpenExerciseModal}
                    getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                />
            }
        </KioskLessonInnerWrapper>
    );
}

interface KioskLessonProps {
    exercises: Exercise[];
    publicUUID: string;
    index: number;
    getScreenshotUUID: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    onOpenExerciseModal?: (exercise: Exercise) => void;
    getIdealCompletionTimeFromExercise: (exercise: Exercise) => number;
}
const KioskLesson = (props: KioskLessonProps) => {
    return (
        <>
            <Droppable
                droppableId={`${KIOSK_EXISTING_EXERCISES_IDENTIFIER}${props.publicUUID}`}
                type="exercise"
                isDropDisabled={true}
                renderClone={getRenderExercise(props.exercises, props.getScreenshotUUID, props.getIdealCompletionTimeFromExercise)}
            >
                {(provided, snapshot) => {

                    return (
                        <DroppableArea
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`kiosk ${snapshot.isDraggingOver ? "draggingOver" : ""}`}
                        >
                            {props.exercises.map((exercise: Exercise, i) => {
                                const shouldRenderClone = exercise.UUID === snapshot.draggingFromThisWith;
                                const screenshotUUID = props.getScreenshotUUID(exercise.TemplateVariantUUID, exercise.VehicleUUID);
                                const idealCompletionTime = props.getIdealCompletionTimeFromExercise(exercise);
                                return (
                                    <Fragment key={exercise.UUID}>
                                        {shouldRenderClone ? (
                                            <DraggableExerciseCard className="card react-beautiful-dnd-copy">
                                                <div className="inner">
                                                    <ExerciseCardName
                                                        name={exercise.Name}
                                                        screenshotUUID={screenshotUUID}
                                                        completionTime={idealCompletionTime}
                                                    />
                                                </div>
                                            </DraggableExerciseCard>
                                        ) : (
                                                <Draggable key={exercise.UUID} draggableId={exercise.UUID} index={i}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <DraggableExerciseCard
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={`inverted card ${snapshot.isDragging ? "dragging" : ""}`}
                                                        >
                                                            <div className="inner">
                                                                <ExerciseCardName
                                                                    name={exercise.Name}
                                                                    screenshotUUID={screenshotUUID}
                                                                    completionTime={idealCompletionTime}
                                                                />
                                                                <InfoSymbol
                                                                    inverted
                                                                    onClick={() => { props.onOpenExerciseModal && props.onOpenExerciseModal(exercise) }}
                                                                />
                                                            </div>
                                                        </DraggableExerciseCard>
                                                    );
                                                }}
                                            </Draggable>
                                        )}
                                    </Fragment>
                                )
                            })}
                            {provided.placeholder}
                        </DroppableArea>
                    )
                }}
            </Droppable>
        </>
    );
}

interface ChangeLessonNameProps {
    name: string;
    names: { Language: string, Value: string }[];
    onNameChange: (nameSv: string, nameFi: string, nameEn: string) => void;
    onClose: () => void;
}

const ChangeLessonName = (props: ChangeLessonNameProps) => {
    const { onNameChange, onClose } = props;
    const nameSv = props.names.find((name: { Language: string, Value: string }) => name.Language === "sv-SE")?.Value ?? "";
    const nameFi = props.names.find((name: { Language: string, Value: string }) => name.Language === "fi-FI")?.Value ?? "";
    const nameEn = props.names.find((name: { Language: string, Value: string }) => name.Language === "en-US")?.Value ?? "";
    const [newNameSv, setNewNameSv] = useState(nameSv);
    const [newNameFi, setNewNameFi] = useState(nameFi);
    const [newNameEn, setNewNameEn] = useState(nameEn);
    const [showSv, setShowSv] = useState(nameSv.length > 0 || svLocale());
    const [showEn, setShowEn] = useState(nameEn.length > 0 || fiLocale());
    const [showFi, setShowFi] = useState(nameFi.length > 0 || enLocale());

    const { t } = useTranslation();

    const onTypingSv = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewNameSv(target.value);
    }
    const onTypingFi = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewNameFi(target.value);
    }
    const onTypingEn = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewNameEn(target.value);
    }
    const onDone = useCallback(() => {
        onNameChange(newNameSv, newNameFi, newNameEn);
        onClose();
    }, [newNameSv, newNameFi, newNameEn, onClose, onNameChange]);

    useEffect(() => {
        const keyPress = (e: KeyboardEvent) => {
            e.key === "Escape" && onClose();
            e.key === "Enter" && onDone();
        }
        document.addEventListener("keydown", keyPress);
        return () => document.removeEventListener("keydown", keyPress);
    }, [newNameSv, newNameFi, newNameEn, onDone, onClose]);

    const inputSv = showSv ?
        <TextInput label={t("common:languagesNativeLanguage.swedish")} autoFocus value={newNameSv} onChange={onTypingSv} />
        : <span className="toggle-language-label" onClick={() => setShowSv(true)}>+ {t("common:languagesNativeLanguage.swedish")}</span>;

    const inputEn = showEn ?
        <TextInput label={t("common:languagesNativeLanguage.english")} value={newNameEn} onChange={onTypingEn} />
        : <span className="toggle-language-label" onClick={() => setShowEn(true)}>+ {t("common:languagesNativeLanguage.english")}</span>;

    const inputFi = showFi ?
        <TextInput label={t("common:languagesNativeLanguage.finnish")} value={newNameFi} onChange={onTypingFi} />
        : <span className="toggle-language-label" onClick={() => setShowFi(true)}>+ {t("common:languagesNativeLanguage.finnish")}</span>;

    return (
        <ChangeLessonNameWrapper inputWidth={`${Math.ceil(Math.max(props.name.length, 20) * 8)}px`}>
            {svLocale() ?
                <>
                    {inputSv}
                    {inputEn}
                    {inputFi}
                </>
                : fiLocale() ?
                    <>
                        {inputFi}
                        {inputSv}
                        {inputEn}
                    </>
                    :
                    <>
                        {inputEn}
                        {inputSv}
                        {inputFi}
                    </>
            }
            <div className="buttons">
                <SaveButton onClick={() => onDone()} text={t('common:buttons.done')} />
                <label onClick={() => props.onClose()} className="cancel">{t('common:buttons.cancel')}</label>
            </div>
        </ChangeLessonNameWrapper>
    )
}

interface ChangeCourseNameProps {
    nameSv: string;
    nameEn: string;
    nameFi: string;
    onChangeNames: (names: CourseName[]) => void;
    onClose: () => void;
}

const ChangeCourseName = (props: ChangeCourseNameProps) => {
    const { onChangeNames, onClose } = props;
    const [newSv, setNewSv] = useState(props.nameSv);
    const [newEn, setNewEn] = useState(props.nameEn);
    const [newFi, setNewFi] = useState(props.nameFi);
    const { t } = useTranslation();
    const onTypingSv = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewSv(target.value);
    }
    const onTypingEn = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewEn(target.value);
    }
    const onTypingFi = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        setNewFi(target.value);
    }
    const onDone = useCallback(() => {
        onChangeNames([{ Language: "sv-SE", Value: newSv }, { Language: "en-US", Value: newEn }, { Language: "fi-FI", Value: newFi }]);
        onClose();
    }, [newSv, newEn, newFi, onClose, onChangeNames]);

    useEffect(() => {
        const keyPress = (e: KeyboardEvent) => {
            e.key === "Escape" && onClose();
            e.key === "Enter" && onDone();
        }
        document.addEventListener("keydown", keyPress);
        return () => document.removeEventListener("keydown", keyPress);
    }, [newSv, newEn, onClose, onDone]);
    return (
        <Group align="flex-end" spacing="lg">
            <Group spacing="xs">
                <TextInput label="Svenska" autoFocus value={newSv} onChange={onTypingSv} />
                <TextInput label="Engelska" value={newEn} onChange={onTypingEn} />
                <TextInput label="Finska" value={newFi} onChange={onTypingFi} />
            </Group>
            <Group mb={6} spacing="xs">
                <MantineButton compact size="sm" onClick={() => onDone()}>{t('common:buttons.done')}</MantineButton>
                <MantineButton compact size="sm" onClick={() => onClose()} variant="subtle">{t('common:buttons.cancel')}</MantineButton>
            </Group>
        </Group>
    )
}

interface NoLessonsEmptyStateProps {
    isDragging?: boolean;
}

export const NoLessonsEmptyState = (props: NoLessonsEmptyStateProps) => {
    const ref: MutableRefObject<any> = useRef(null);
    const { t } = useTranslation();
    return (
        <NoLessonsEmptyStateWrapper>
            <h4 data-tip={t("courseEditor.lessons.emptyState.tooltip")}>{t("courseEditor.lessons.emptyState.subTitle")}</h4>
            <CSSTransition timeout={300} nodeRef={ref} unmountOnExit in={props.isDragging} classNames="fadeZoom">
                <h1 ref={ref}>{t("courseEditor.lessons.emptyState.title")}</h1>
            </CSSTransition>
        </NoLessonsEmptyStateWrapper>
    );
}

export const SkillsterToolTip = () =>
    <ReactTooltip
        effect="solid"
        backgroundColor={teacherTheme.colors.primary.normal}
        textColor="white"
    />

interface MiniStatsProps {
    lessonsCount: number;
    exercisesCount: number;
    idealCompletionTime: number;
}

const MiniStats = (props: MiniStatsProps) => {
    const { t } = useTranslation();
    return (
        <MiniStatsWrapper>
            <div className="item">
                <IconLessons className="icon" />
                <div className="data">
                    <h5>{t("courseEditor.miniStats.lessons.title")}</h5>
                    <label>{t("courseEditor.miniStats.lessons.data", { lessonCount: props.lessonsCount })}</label>
                </div>
            </div>
            <div className="item">
                <IconExercises className="icon" />
                <div className="data">
                    <h5>{t("courseEditor.miniStats.exercises.title")}</h5>
                    <label>{t("courseEditor.miniStats.exercises.data", { exerciseCount: props.exercisesCount })}</label>
                </div>
            </div>
            <div className="item">
                <IconRuntime className="icon" />
                <div className="data">
                    <h5>{t("courseEditor.miniStats.runtime.title")}</h5>
                    <label>{convertSecondsToReadable(props.idealCompletionTime)}</label>
                </div>
            </div>
        </MiniStatsWrapper>
    );
}

interface AddLessonProps {
    onClick: () => void;
}

export const AddLesson = (props: AddLessonProps) => {
    const { t } = useTranslation();
    return (
        <AddNewLessonWrapper onClick={props.onClick}>
            <div className="inner">
                <RoundIconBase className="plus">
                    <IconPlusBlue />
                </RoundIconBase>
                <label>{t("courseEditor.lessons.add.title")}</label>
            </div>
        </AddNewLessonWrapper>
    );
}

interface CourseEditorHeaderProps {
    showAllExercisesInEditor?: boolean;
    title: string;
    draftIsEdited: boolean;
    vehiclesIsActive?: boolean;
    created: string;
    createdBy: User;
    lastSaved?: Date;
    isPublishing?: boolean;
    isUpdating?: boolean;
    kioskClosed?: boolean;
    onChooseKiosk: (vehicles: boolean) => void;
    onSave: () => void;
    onExit: () => void;
    onSaveCopy: () => void;
    onCompare: () => void;
}

export const CourseEditorHeader = (props: CourseEditorHeaderProps) => {
    const { t } = useTranslation();
    return (
        <CourseEditorHeaderWrapper>
            <CourseEditorBlueBarWrapper>
                <div className="inner">
                    <div className="title-wrapper">
                        <IconSkillsterSymbolWhite />
                        <h3>{t("courseEditor.header.title")}</h3> <h3 className="course-name">{props.title}</h3>
                    </div>
                    <div className="buttons">
                        <SavedStatus
                            draftIsEdited={props.draftIsEdited}
                            lastSaved={props.lastSaved}
                            isPublishing={props.isPublishing}
                            onCompare={props.onCompare}
                        />
                        <MantineButton
                            leftIcon={<IconLogout />}
                            onClick={props.onExit}
                            disabled={props.isUpdating}
                            variant="subtle"
                            sx={(theme) => ({
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "rgba(255,255,255,.05)"
                                },
                            })}
                        >
                            {t("common:buttons.exit")}
                        </MantineButton>
                        <MantineButton
                            onClick={props.onSave}
                            leftIcon={<IconCloudUpload />}
                            disabled={props.isUpdating || !props.draftIsEdited}
                            sx={() => ({
                                backgroundColor: teacherTheme.colors.primary.dark,
                                '&:hover': {
                                    backgroundColor: teacherTheme.colors.primary.darker
                                },
                            })}
                        >
                            {t("common:buttons.save")}
                        </MantineButton>
                    </div>
                </div>
            </CourseEditorBlueBarWrapper>
            {props.showAllExercisesInEditor &&
            <CourseEditorSubMenu
                vehiclesIsActive={props.vehiclesIsActive}
                onChooseKiosk={props.onChooseKiosk}
                created={props.created}
                createdBy={props.createdBy}
                kioskClosed={props.kioskClosed}
            />
            }
        </CourseEditorHeaderWrapper>
    );
}

interface SavedStatusProps {
    draftIsEdited: boolean;
    lastSaved?: Date;
    isPublishing?: boolean;
    onCompare: () => void;
}

const SavedStatus = (props: SavedStatusProps) => {
    const { t } = useTranslation();
    if (props.isPublishing)
        return <SavedStatusWrapper className="fadeInEntrance" key={1}><SmallSpinner className="spinner" color="white" /> <label>{t("courseEditor.header.savedStatus.saving")}</label></SavedStatusWrapper>;
    if (props.draftIsEdited && !props.lastSaved)
        return <SavedStatusWrapper className="fadeInEntrance" key={2}><label>{t("courseEditor.header.savedStatus.unsaved")} (<strong className="showCompare" onClick={props.onCompare}>{t("courseEditor.header.savedStatus.show")}</strong>)</label></SavedStatusWrapper>;
    if (props.lastSaved)
        return <SavedStatusWrapper className="fadeInEntrance" key={3}><IconSmallCheck className="check" /><label>{t("courseEditor.header.savedStatus.lastSaved")} {format(props.lastSaved, "p", { locale: svLocale() ? sv : enGB })}</label></SavedStatusWrapper>;
    return null;
}

interface CourseEditorSubMenuProps {
    onChooseKiosk: (vehicles: boolean) => void;
    vehiclesIsActive?: boolean;
    created: string;
    createdBy: User;
    kioskClosed?: boolean;
}

const CourseEditorSubMenu = (props: CourseEditorSubMenuProps) => {
    const { t } = useTranslation();
    return (
        <CourseEditorSubMenuWrapper>
            {!props.kioskClosed &&
                <div className="kioskButtons">
                    <div
                        className={`item ${props.vehiclesIsActive ? "isActive" : ""}`}
                        onClick={() => props.onChooseKiosk(true)}
                    >
                        <IconCourseEditorVehicles className="icon" />
                        <label>{t("courseEditor.subMenu.exercises.title")}</label>
                    </div>
                    <div
                        className={`item ${!props.vehiclesIsActive ? "isActive" : ""}`}
                        onClick={() => props.onChooseKiosk(false)}
                    >
                        <IconCourseEditorSkillster className="icon" />
                        <label>{t("courseEditor.subMenu.courses.title")}</label>
                    </div>
                </div>
            }
            <CreatedBy user={props.createdBy} created={props.created} />
        </CourseEditorSubMenuWrapper>
    );
}

interface CreatedByProps {
    user: User;
    created: string;
}

const CreatedBy = (props: CreatedByProps) => {
    const { t } = useTranslation();
    if (!props.user) return null;
    return (
        <CreatedByWrapper>
            <InitialsBadge noDot user={props.user} className="badge" />
            <div className="text">
                <label>{props.user.FirstName} {props.user.LastName}</label>
                <label className="date">{t("courseEditor.subMenu.createdBy", { date: dateObjectToReadable(new Date(props.created)) })}</label>
            </div>
        </CreatedByWrapper>
    )
}

interface UnpublishedDraftOnServerProps {
    isOpen: boolean;
    uuid: string;
    onDiscardUnsavedDraft: (draftUUID: string) => void;
    onGetUnsavedDraft: (draftUUID: string) => void;
}

export const UnpublishedDraftOnServer = (props: UnpublishedDraftOnServerProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={props.isOpen}
            title={t("courseEditor.draftFound.title")}
            noPadding
        >
            <ExitWarningModalWrapper>
                <div className="description">
                    <span>{t("courseEditor.draftFound.description")}</span>
                </div>
                <div className="buttons">
                    <span className="textButton warning" onClick={() => props.onDiscardUnsavedDraft(props.uuid)}>{t("courseEditor.draftFound.buttons.discard")}</span>
                    <Button onClick={() => props.onGetUnsavedDraft(props.uuid)}>{t("courseEditor.draftFound.buttons.download")}</Button>
                </div>
            </ExitWarningModalWrapper>
        </Modal>
    );
}

interface CourseIsLockedProps {
    onClose: () => void;
}

export const CourseIsLocked = (props: CourseIsLockedProps) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen title={t("courseEditor.courseIsLocked.title")}>
            <ExitWarningModalWrapper>
                <div className="description">
                    <span>{t("courseEditor.courseIsLocked.description")}</span>
                </div>
                <div className="buttons">
                    <Button onClick={props.onClose}>{t("common:buttons.back")}</Button>
                </div>
            </ExitWarningModalWrapper>
        </Modal>
    );
}

interface ExitWarningProps {
    isOpen: boolean;
    onCancelExit: () => void;
    onConfirmExit: () => void;
    onExitAndSave: () => void;
}

export const ExitWarning = (props: ExitWarningProps) => {
    const { t } = useTranslation();
    return (
        <Modal noPadding isOpen={props.isOpen} title={t("courseEditor.exitWarning.title")} onClose={props.onCancelExit}>
            <ExitWarningModalWrapper>
                <div className="description">
                    <span>{t("courseEditor.exitWarning.description")}</span>
                </div>
                <div className="buttons">
                    <span className="textButton warning" onClick={props.onConfirmExit}>{t("courseEditor.exitWarning.buttons.discard")}</span>
                    <Button onClick={props.onExitAndSave}>{t("courseEditor.exitWarning.buttons.saveAndExit")}</Button>
                </div>
            </ExitWarningModalWrapper>
        </Modal>
    );
}

interface LockTimeoutWarningProps {
    isOpen?: boolean;
    secondsLeft?: number;
    onKeepLock: () => void;
}

export const LockTimeoutWarning = (props: LockTimeoutWarningProps) => {
    const { t } = useTranslation();
    if (!props.secondsLeft) return null;
    return (
        <Modal noPadding isOpen={props.isOpen} title={t("courseEditor.lockOutWarning.title")}>
            <ExitWarningModalWrapper>
                <div className="description">
                    <span>{t("courseEditor.lockOutWarning.description")}</span>
                </div>
                <strong>{convertSecondsToReadable(props.secondsLeft)}</strong>
                <div className="buttons">
                    <Button onClick={props.onKeepLock}>{t("courseEditor.lockOutWarning.buttons.keepAlive")}</Button>
                </div>
            </ExitWarningModalWrapper>
        </Modal>
    );
}

interface VehicleDropdownProps {
    vehicleOptions: ExerciseOption[];
    selected: ExerciseOption;
    onChange: (option: ExerciseOption) => void;
    className?: string;
    label?: string;
}

const VehicleDropdown = (props: VehicleDropdownProps) => {
    if (!props.vehicleOptions) return null;
    return (
        <DropDownStringValue
            options={props.vehicleOptions.map((v: ExerciseOption) => (
                { Name: v.Name, Value: v.Value }
            ))}
            selected={props.selected ?? new ExerciseOption()}
            onChange={props.onChange}
            className={props.className}
            label={props.label}
            showThumbs
            allowFiltering
        />
    );
}

interface CourseComparisonPreviewProps {
    initial: CourseDraft;
    draft: CourseDraft;
    onClose: () => void;
    isOpen?: boolean;
    weatherOptions: ExerciseOption[];
    trafficOptions: ExerciseOption[];
    difficultyOptions: Option[];
    exercisesMovedWithinLesson: string[];
    lessonsMoved: string[];
}

export const CourseComparisonPreview = (props: CourseComparisonPreviewProps) => {
    const { t } = useTranslation();
    const mergedDrafts = mergeDrafts(props.draft, props.initial);
    const exercisesMovedBetweenLessons: { [uuid: string]: { from: { uuid: string, index: number }, to: { uuid: string, index: number } } } = {};

    const updatedExercises: {
        [uuid: string]: {
            weather: { from?: string, to?: string },
            traffic: { from?: string, to?: string },
            difficulty: { from?: number, to?: number },
        }
    } = {};

    props.draft.Lessons.forEach((lesson: Lesson, index: number) =>
        lesson.Exercises.forEach((exercise: Exercise) => {
            const from = exerciseWasMovedFrom(props.initial, exercise, lesson.PublicUUID);
            if (from) exercisesMovedBetweenLessons[exercise.PublicUUID] = {
                from: { uuid: from.publicLessonUUID, index: from.index },
                to: { uuid: lesson.PublicUUID, index }
            }
        }))

    props.draft.Lessons.forEach((lesson: Lesson) => {
        lesson.Exercises.forEach((exercise: Exercise) => {
            const initial = getInitialExercise(props.initial, exercise);
            if (initial) {
                if (!exercisesAreEqual(initial, exercise)) {

                    updatedExercises[exercise.PublicUUID] = {
                        weather: { from: initial.WeatherUUID, to: exercise.WeatherUUID },
                        traffic: { from: initial.TrafficUUID, to: exercise.TrafficUUID },
                        difficulty: { from: initial.Difficulty, to: exercise.Difficulty }
                    }
                }
            }
        })
    });

    return (

        <Modal wide isOpen={props.isOpen} title={t("courseEditor.diff.title")} onClose={props.onClose}>
            <SkillsterToolTip />
            <p className="description">{t("courseEditor.diff.description")}</p>
            <UpdatedCourseImage initialImageUUID={props.initial.ImageUUID} draftImageUUID={props.draft.ImageUUID} />
            <UpdatedCourseType initialIsLinear={props.initial.IsLinear} draftIsLinear={props.draft.IsLinear} />
            <ComparisonWrapper>
                <div className="course-name">
                    <h2 className="current-name">{props.draft.Name}</h2>
                    {props.initial.Name !== props.draft.Name && <h4 className="old-name">(ändrat från "{props.initial.Name}")</h4>}
                </div>
                <div className="lessons">
                    {mergedDrafts.Lessons.map((lesson: Lesson) => {
                        const lessonNew = isNewLesson(props.initial, lesson.PublicUUID);
                        const lessonNameChanged = lessonNameIsChanged(props.initial, lesson);
                        const lessonDeleted = isDeletedLesson(props.initial, props.draft, lesson.PublicUUID);
                        const lessonMoved = props.lessonsMoved.some((publicUUID: string) => publicUUID === lesson.PublicUUID);
                        return (
                            <div className={`lesson ${lessonDeleted ? "deleted" : ""}`} key={lesson.UUID}>
                                <div className="lesson-name">
                                    <LessonName
                                        isDeleted={lessonDeleted}
                                        names={lesson.Names}
                                        index={lessonDeleted ? props.initial.Lessons.findIndex(l => l.PublicUUID === lesson.PublicUUID) : props.draft.Lessons.findIndex(l => l.PublicUUID === lesson.PublicUUID)}
                                    />
                                    <div className="badges">
                                        {lessonMoved && <Badge className="moved">Flyttad lektion</Badge>}
                                        {lessonNew && <Badge className="new">Ny lektion</Badge>}
                                        {lessonDeleted && <Badge className="deleted">Borttagen lektion</Badge>}
                                        {lessonNameChanged && <Badge className="moved">Ändrat namn</Badge>}
                                    </div>
                                </div>
                                {lesson.Exercises.length ? lesson.Exercises.map((exercise: Exercise) => {
                                    const isNew = isNewExercise(props.initial, props.draft, exercise, lesson.PublicUUID);
                                    const isDeleted = isDeletedExercise(props.initial, props.draft, exercise, lesson.PublicUUID);
                                    const isMovedWithinItsLesson = props.exercisesMovedWithinLesson.some((uuid: string) => uuid === exercise.PublicUUID);
                                    const movedDetails = exercisesMovedBetweenLessons[exercise.PublicUUID];
                                    return (
                                        <div className="exercise" key={exercise.PublicUUID}>
                                            <span className={`name ${isDeleted ? movedDetails ? "moved" : "deleted" : ""}`}>{exercise.Name}</span>
                                            <div className="badges">
                                                {updatedExercises[exercise.PublicUUID] &&
                                                    <UpdatedSettings
                                                        changes={updatedExercises[exercise.PublicUUID]}
                                                        weatherOptions={props.weatherOptions}
                                                        trafficOptions={props.trafficOptions}
                                                        difficultyOptions={props.difficultyOptions}
                                                    />
                                                }
                                                {(isMovedWithinItsLesson && !movedDetails) && <Badge className="moved">Flyttad inom lektionen</Badge>}
                                                {isNew &&
                                                    <>
                                                        {movedDetails ? <Badge className="moved new">{t("courseEditor.diff.badges.movedFrom", { lesson: getLessonName(props.initial, movedDetails.from.uuid, movedDetails.from.index) })}</Badge> :
                                                            <Badge className="new">{t("courseEditor.diff.badges.new")}</Badge>
                                                        }
                                                    </>
                                                }
                                                {isDeleted &&
                                                    <>
                                                        {movedDetails ? <Badge className="moved">{t("courseEditor.diff.badges.movedTo", { lesson: getLessonName(props.draft, movedDetails.to.uuid, movedDetails.to.index) })}</Badge> :
                                                            <Badge className="deleted">{t("courseEditor.diff.badges.deleted")}</Badge>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    );
                                }) :
                                    <div>
                                        <label>{t("courseEditor.diff.emptyState")}</label>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </ComparisonWrapper>
        </Modal>
    )
}

interface BadgeProps {
    children: ReactNode;
    className?: string;
}

const Badge = (props: BadgeProps) =>
    <BadgeWrapper className={`badge ${props.className ?? ""}`}>{props.children}</BadgeWrapper>

interface UpdatedCourseImageProps {
    initialImageUUID: string;
    draftImageUUID: string;
}

const UpdatedCourseImage = (props: UpdatedCourseImageProps) => {
    const { t } = useTranslation();
    if (props.initialImageUUID === props.draftImageUUID) return null;
    return (
        <>
            <h4>{t("courseEditor.diff.updatedCourseImage.title")}</h4>
            <UpdatedCourseImageWrapper>
                {props.initialImageUUID &&
                <div className="initial">
                        <CourseImage imageUUID={props.initialImageUUID} width={172} alt="Vehicle" />
                </div>
                }
                <div className={`draft ${props.initialImageUUID ? "offsetLeft" : ""}`}>
                    <Badge className="badge new">{t("courseEditor.diff.updatedCourseImage.badges.new")}</Badge>
                    <CourseImage imageUUID={props.draftImageUUID} width={172} alt="Vehicle" />
                </div>
            </UpdatedCourseImageWrapper>
        </>
    )
}

interface UpdatedCourseTypeProps {
    initialIsLinear: boolean;
    draftIsLinear: boolean;
}

const UpdatedCourseType = (props: UpdatedCourseTypeProps) => {
    const { t } = useTranslation();
    if (props.initialIsLinear === props.draftIsLinear) return null;
    return (
        <UpdatedCourseTypeWrapper>
            <h4>{t("courseEditor.diff.updatedCourseType.title")}</h4>
            <div className="description">
                <div className="changedTo">{t("courseEditor.diff.updatedCourseType.changedTo")}</div> <CourseTypeIcon isLinear={props.draftIsLinear} showText />
            </div>
        </UpdatedCourseTypeWrapper>
    );
}

interface UpdatedSettingsProps {
    changes: {
        weather: { from?: string, to?: string },
        traffic: { from?: string, to?: string },
        difficulty: { from?: number, to?: number },
    };
    weatherOptions: ExerciseOption[];
    trafficOptions: ExerciseOption[];
    difficultyOptions: Option[];
}

const UpdatedSettings = (props: UpdatedSettingsProps) => {
    const { t } = useTranslation();
    const getWeatherSettingName = (uuid?: string) => props.weatherOptions.find((o: ExerciseOption) => o.Value === uuid)?.Name;
    const getTrafficSettingName = (uuid?: string) => props.trafficOptions.find((o: ExerciseOption) => o.Value === uuid)?.Name;
    const getDifficultySettingName = (value?: number) => props.difficultyOptions.find((o: Option) => o.value === value)?.label;
    return (
        <>
            {!isEqual(props.changes.weather.from, props.changes.weather.to) &&
                <Badge data-tip={t("courseEditor.diff.updatedSettings.fromTo", { from: getWeatherSettingName(props.changes.weather.from), to: getWeatherSettingName(props.changes.weather.to) })}>
                    {t("courseEditor.diff.updatedSettings.weather")}
            </Badge>
            }
            {!isEqual(props.changes.traffic.from, props.changes.traffic.to) &&
                <Badge data-tip={t("courseEditor.diff.updatedSettings.fromTo", { from: getTrafficSettingName(props.changes.traffic.from), to: getTrafficSettingName(props.changes.traffic.to) })}>
                    {t("courseEditor.diff.updatedSettings.traffic")}
            </Badge>
            }
            {!isEqual(props.changes.difficulty.from, props.changes.difficulty.to) &&
                <Badge data-tip={t("courseEditor.diff.updatedSettings.fromTo", { from: getDifficultySettingName(props.changes.difficulty.from), to: getDifficultySettingName(props.changes.difficulty.to) })}>
                    {t("courseEditor.diff.updatedSettings.difficulty")}
            </Badge>
            }
        </>
    );
}

interface LessonHeaderProps {
    index: number;
    lessonName: string;
    lessonNames: { Language: string, Value: string }[];
    isCollapsed: boolean;
    showChangeLessonName: boolean;
    onSetShowChangeLessonName: (status: boolean) => void;
    onSetCollapsed: (collapsed: boolean) => void;
    onNameChange: (nameSv: string, nameFi: string, nameEn: string) => void;
}

const LessonHeader = (props: LessonHeaderProps) => {
    const { t } = useTranslation();
    return (
        <div className="collapse">
            <CollapseChevron isCollapsed={props.isCollapsed} className="chevronWrapper" onClick={() => props.onSetCollapsed(!props.isCollapsed)} />
            <div className="lessonName">
                {props.showChangeLessonName ?
                    <ChangeLessonName
                        onNameChange={props.onNameChange}
                        onClose={() => props.onSetShowChangeLessonName(false)}
                        name={props.lessonName}
                        names={props.lessonNames}
                    /> :
                    <>
                        <LessonName
                            index={props.index}
                            names={props.lessonNames}
                            onClick={() => props.onSetCollapsed(!props.isCollapsed)}
                        />
                        <EditButton text={t("courseEditor.lessonName.buttons.edit")} onClick={() => props.onSetShowChangeLessonName(true)} className="editButton" />
                    </>
                }
            </div>
        </div>
    );
}

interface LessonActionsProps {
    lessonName: string;
    toggleShowConfirm: (show: boolean) => void;
    onDuplicateLesson: () => void;
}

const LessonActions = (props: LessonActionsProps) => {
    const { t } = useTranslation();
    return (
        <LessonActionsWrapper>
            <div
                className="duplicate"
                onClick={props.onDuplicateLesson}
                data-tip={t("courseEditor.lessonActions.tooltip")}
            >
                <IconDuplicate />
                <label>{t("courseEditor.lessonActions.duplicate")}</label>
            </div>
            <div className="delete" onClick={() => props.toggleShowConfirm(true)}>
                <IconDelete />
                <label>{t("courseEditor.lessonActions.delete")}</label>
            </div>
        </LessonActionsWrapper>
    );
}

interface DeleteConfirmationProps {
    show?: boolean;
    courseName: string;
    onDelete: () => void;
    onCancel: () => void;
    column?: boolean;
}

const DeleteConfirmation = (props: DeleteConfirmationProps) => {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    useOutsideAlerter(nodeRef, props.onCancel);
    return (
        <CSSTransition unmountOnExit in={props.show} nodeRef={nodeRef} timeout={300} classNames="fadeZoom">
            <DeleteConfirmationWrapper ref={nodeRef} className={`${props.column ? "column" : ""}`}>
                <h4 className="title">{t("courseEditor.deleteConfirmation.title", { courseName: props.courseName })}</h4>
                <Button className="delete" onClick={props.onDelete}>{t("common:buttons.delete")}</Button>
                <Button className="cancel" onClick={props.onCancel}>{t("common:buttons.cancel")}</Button>
            </DeleteConfirmationWrapper>
        </CSSTransition>
    );
}

interface GroupedExerciseVariantsKioskProps {
    exerciseVariants: ExerciseVariant[];
    getScreenshotUUIDfromExerciseVariant: (ev: ExerciseVariant) => string | undefined;
    openExerciseModal: (e: Exercise | ExerciseVariant) => void;
}

const GroupedExerciseVariantsKiosk = (props: GroupedExerciseVariantsKioskProps) => {
    const { exerciseVariants } = props;
    const [isCollapsed, setIsCollapsed] = useState(false);
    const courseEditorState = useSelector((state: RootState) => state.courseEditor);
    return (
        <DraggableLessonWrapper className="no-hover">
            <KioskLessonInnerWrapper className={`${isCollapsed ? "collapsed" : ""}`}>
                <div className={`header`}>
                    <CollapseChevron isCollapsed={isCollapsed} className="chevronWrapper" onClick={() => setIsCollapsed(!isCollapsed)} />
                    <h3 className="title" onClick={() => setIsCollapsed(!isCollapsed)}>{exerciseVariants[0].TemplateName}</h3>
                </div>
                {!isCollapsed &&
                    <Droppable
                        droppableId={`${KIOSK_IDENTIFIER}${exerciseVariants[0].ExerciseTemplateUUID}`}
                        type="exercise"
                        isDropDisabled
                        renderClone={getRenderExerciseVariant(exerciseVariants, props.getScreenshotUUIDfromExerciseVariant)}
                    >
                        {(provided, snapshot) => {
                            return (
                                <DroppableArea
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={`kiosk ${snapshot.isDraggingOver ? "draggingOver" : ""}`}
                                >
                                    {exerciseVariants.map((ev: ExerciseVariant, evIndex: number) => {
                                        const shouldRenderClone = ev.ID.toString() === snapshot.draggingFromThisWith;
                                        const screenshotUUID = props.getScreenshotUUIDfromExerciseVariant(ev);
                                        const estimatedRuntime = ev.EstimatedRuntimePerVehicle[courseEditorState.selectedVehicleOption.Value] ?? 0;
                                        return (
                                            <Fragment key={ev.ID.toString()}>
                                                {shouldRenderClone ? (
                                                    <DraggableExerciseCard className="card react-beautiful-dnd-copy">
                                                        <div className="inner">
                                                            <ExerciseCardName
                                                                name={ev.Name.replace(/([A-Z0-9])/g, ' $1').trim().replace(/(K M)/g, 'km')}
                                                                screenshotUUID={screenshotUUID}
                                                                completionTime={estimatedRuntime}
                                                            />
                                                            <InfoSymbol onClick={() => { }} />
                                                        </div>
                                                    </DraggableExerciseCard>
                                                ) : (
                                                    <Draggable key={ev.ID.toString()} draggableId={ev.ID.toString()} index={evIndex}>
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <DraggableExerciseCard
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={`card inverted ${snapshot.isDragging ? "dragging" : ""}`}
                                                                >
                                                                    <div className="inner">
                                                                        <ExerciseCardName
                                                                            name={ev.Name.replace(/([A-Z0-9])/g, ' $1').trim().replace(/(K M)/g, 'km')}
                                                                            screenshotUUID={screenshotUUID}
                                                                            completionTime={estimatedRuntime}
                                                                        />
                                                                        <InfoSymbol inverted onClick={() => props.openExerciseModal(ev)} />
                                                                    </div>
                                                                </DraggableExerciseCard>
                                                            );
                                                        }}
                                                    </Draggable>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                    {provided.placeholder}
                                </DroppableArea>
                            )
                        }}
                    </Droppable>
                }
            </KioskLessonInnerWrapper>
        </DraggableLessonWrapper>
    );
}

interface ExerciseCardNameProps {
    name: string;
    screenshotUUID?: string;
    completionTime: number;
}

const ExerciseCardName = (props: ExerciseCardNameProps) => {
    return (
        <ExerciseCardNameWrapper>
            {props.screenshotUUID && <div className="image"><ExerciseTemplateVariantImage uuid={props.screenshotUUID} height={45} alt={props.name} /></div>}
            <div className="name-and-runtime">
                <strong>{props.name}</strong>
                <label className="smallText">{convertSecondsToReadable(props.completionTime, undefined, undefined, true)}</label>
            </div>
        </ExerciseCardNameWrapper>
    );
}

interface SkillsterCoursesKioskProps {
    lessons: Lesson[];
    getScreenshotUUIDFromExercise: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    getVehicleGUIDfromExercise: (exercise: Exercise) => string | null;
    getIdealCompletionTimeFromExercise: (exercise: Exercise) => number
    onOpenExerciseModal: (exercise: Exercise) => void;
}

const SkillsterCoursesKiosk = (props: SkillsterCoursesKioskProps) => {
    return (
        <Droppable
            droppableId={KIOSK_EXISTING_LESSONS_IDENTIFIER}
            type="lesson"
            isDropDisabled
            renderClone={getRenderLesson(props.lessons, props.getScreenshotUUIDFromExercise, props.getIdealCompletionTimeFromExercise)}
        >
            {(provided, snapshot) => {
                return (
                    <DroppableArea
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="kioskLessonsDroppable"
                    >
                        {props.lessons.map((l: Lesson, index) => {
                            const shouldRenderClone = l.PublicUUID === snapshot.draggingFromThisWith;
                            return (
                                <Fragment key={l.PublicUUID}>
                                    {shouldRenderClone ? (
                                        <DraggableLessonWrapper className="react-beautiful-dnd-copy">
                                            <KioskLessonInner
                                                lesson={l}
                                                index={index}
                                                getScreenshotUUID={props.getScreenshotUUIDFromExercise}
                                                getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                                            />
                                        </DraggableLessonWrapper>
                                    ) : (
                                            <Draggable key={l.PublicUUID} draggableId={l.PublicUUID} index={index}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <DraggableLessonWrapper
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={l.PublicUUID}
                                                        className={`card ${snapshot.isDragging ? "dragging" : ""}`}
                                                    >
                                                        <KioskLessonInner
                                                            lesson={l}
                                                            index={index}
                                                            getScreenshotUUID={props.getScreenshotUUIDFromExercise}
                                                            getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                                                            onOpenExerciseModal={props.onOpenExerciseModal}
                                                        />
                                                    </DraggableLessonWrapper>
                                                );
                                            }}
                                        </Draggable>
                                    )}
                                </Fragment>
                            )
                        })}
                        {props.lessons.length === 0 &&
                            <NoLessonsEmptyState isDragging={false} />
                        }
                        {provided.placeholder}
                    </DroppableArea>
                );
            }}
        </Droppable>
    );
}

interface DraftHeaderProps {
    name: string;
    names: CourseName[];
    lessonsCount: number;
    exercisesCount: number;
    idealCompletionTime: number;
    onChangeCourseNames: (names: CourseName[]) => void;
    onChangeCourseType: (option: Option) => void;
    isLinear?: boolean;
    imageUUID?: string;
}

export const DraftHeader = (props: DraftHeaderProps) => {
    const { t } = useTranslation();
    const [editCourseName, setEditCourseName] = useState(false);
    const ref = useRef(null);
    return (
        <SwitchTransition>
            <CSSTransition
                key={editCourseName ? "edit" : "noEdit"}
                classNames="fadeZoom"
                timeout={200}
                nodeRef={ref}
            >
                <DraftHeaderWrapper ref={ref}>
                    {editCourseName ?
                        <ChangeCourseName
                            nameSv={props.names.find((name: CourseName) => name.Language === "sv-SE")?.Value ?? ""}
                            nameEn={props.names.find((name: CourseName) => name.Language === "en-US")?.Value ?? ""}
                            nameFi={props.names.find((name: CourseName) => name.Language === "fi-FI")?.Value ?? ""}
                            onClose={() => setEditCourseName(false)}
                            onChangeNames={props.onChangeCourseNames}
                        />
                        :
                        <div className="inner">
                            <div className="courseName">
                                <HeaderCourseImage imageUUID={props.imageUUID} />
                                <h2>{props.name}</h2>
                                <EditButton text={t("courseEditor.editCourseName")} onClick={() => setEditCourseName(true)} className="editButton inverted" />
                            </div>
                            <div className="info">
                                <MiniStats
                                    lessonsCount={props.lessonsCount}
                                    exercisesCount={props.exercisesCount}
                                    idealCompletionTime={props.idealCompletionTime}
                                />
                                <div className="courseType">
                                    <DropDown
                                        selected={props.isLinear ? COURSE_TYPE_OPTIONS[0] : COURSE_TYPE_OPTIONS[1]}
                                        options={COURSE_TYPE_OPTIONS}
                                        onChange={props.onChangeCourseType}
                                        selectedClassName="selected-course-type"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </DraftHeaderWrapper>
            </CSSTransition>
        </SwitchTransition>
    );
}

interface HeaderCourseImageProps {
    imageUUID?: string;
}

const HeaderCourseImage = (props: HeaderCourseImageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <CourseImageThumbnailWrapper onClick={() => dispatch(actions.modals.setIsOpen("courseImageSelector", true))} >
            {props.imageUUID ? <CourseDefaultImage imageUUID={props.imageUUID} className="vehicle" /> : <IconImage className="icon" />}
            <div className="change">
                <label>{t("courseEditor.courseImage.hoverText")}</label>
            </div>
        </CourseImageThumbnailWrapper>
    );
}

interface CourseDefaultImageProps {
    imageUUID: string;
    className?: string;
    onClick?: () => void;
}

const CourseDefaultImage = (props: CourseDefaultImageProps) =>
    <img
        src={getCloudinaryVehicleUrl(props.imageUUID, 80)}
        alt={"Course"}
        className={props.className}
        onClick={props.onClick}
    />

interface CourseImageSelectorModalProps {
    isOpen?: boolean;
    imageUUIDs: string[];
    selectedImageUUID: string;
    onUpdateCourseImage: (imageUUID: string) => void;
}

export const CourseImageSelectorModal = (props: CourseImageSelectorModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <Modal isOpen={props.isOpen} title={t("courseEditor.courseImage.selector.title")} onClose={() => dispatch(actions.modals.setIsOpen("courseImageSelector", false))}>
            <CourseImageSelectorWrapper>
                <span>{t("courseEditor.courseImage.selector.description")}</span>
                <div className="images">
                    <div
                        className={`option ${props.selectedImageUUID === "" ? "selected" : ""}`}
                        onClick={() => props.onUpdateCourseImage("")}
                    >
                        <label>{t("courseEditor.courseImage.selector.auto")}</label>
                    </div>
                    {props.imageUUIDs.map((imageUUID: string) =>
                        <div
                            className={`option ${props.selectedImageUUID === imageUUID ? "selected" : ""}`}
                            key={imageUUID}
                        >
                            <CourseDefaultImage
                                imageUUID={imageUUID}
                                onClick={() => props.onUpdateCourseImage(imageUUID)}
                            />
                        </div>
                    )}
                </div>
            </CourseImageSelectorWrapper>
        </Modal>
    );
}

interface KioskProps {
    vehiclesIsActive?: boolean;
    showAllExercisesInEditor?: boolean;
    vehicleOptions: ExerciseOption[];
    selectedVehicleOption: ExerciseOption;
    filterExercises: string;
    groupedExerciseVariants: ExerciseVariant[][];
    courseList: Course[];
    selectedCourseImageUUID: string;
    selectedCoursePublicUUID: string;
    selectedCourseName: string;
    filterSkillsterCourses: string;
    skillsterLessons: Lesson[];
    handleSearchBarExercises: (event: FormEvent) => void;
    onChangeVehicle: (option: ExerciseOption) => void;
    getScreenshotUUIDfromExerciseVariant: (ev: ExerciseVariant) => string | undefined;
    onOpenExerciseModal: (e: Exercise | ExerciseVariant) => void;
    onOpenSkillsterCourseModal: () => void;
    handleSearchBarSkillsterCourses: (event: FormEvent) => void;
    getScreenshotUUIDFromExercise: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    getVehicleGUIDfromExercise: (e: Exercise) => string | null;
    getIdealCompletionTimeFromExercise: (e: Exercise) => number;
    onToggle: () => void;
}

export const Kiosk = (props: KioskProps) => {
    const ref = useRef(null);
    if (!props.showAllExercisesInEditor)
        return (
            <KioskWrapper>
                <div className="minimize" onClick={props.onToggle}>
                    <IconRoundChevron className="chevron" />
                </div>
                <CoursesKiosk
                    courseList={props.courseList}
                    filterSkillsterCourses={props.filterSkillsterCourses}
                    skillsterLessons={props.skillsterLessons}
                    handleSearchBarSkillsterCourses={props.handleSearchBarSkillsterCourses}
                    onOpenExerciseModal={props.onOpenExerciseModal}
                    getScreenshotUUIDFromExercise={props.getScreenshotUUIDFromExercise}
                    getVehicleGUIDfromExercise={props.getVehicleGUIDfromExercise}
                    getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                    onOpenSkillsterCourseModal={props.onOpenSkillsterCourseModal}
                    selectedCourseImageUUID={props.selectedCourseImageUUID}
                    selectedCourseName={props.selectedCourseName}
                    selectedCoursePublicUUID={props.selectedCoursePublicUUID}
                />
            </KioskWrapper>
        );

    return (
        <KioskWrapper>
            <div className="minimize" onClick={props.onToggle}>
                <IconRoundChevron className="chevron" />
            </div>
            <SwitchTransition>
                <CSSTransition
                    key={props.vehiclesIsActive ? "exercises" : "courses"}
                    timeout={100}
                    classNames="fade"
                    nodeRef={ref}
                >
                    <div ref={ref} className=" fast ">
                        {props.vehiclesIsActive ?
                            <ExercisesKiosk
                                vehiclesIsActive={props.vehiclesIsActive}
                                VehicleOptions={props.vehicleOptions}
                                onChangeVehicle={props.onChangeVehicle}
                                selectedVehicleOption={props.selectedVehicleOption}
                                searchBarValue={props.filterExercises}
                                handleSearchBar={props.handleSearchBarExercises}
                                groupedExerciseVariants={props.groupedExerciseVariants}
                                getScreenshotUUIDfromExerciseVariant={props.getScreenshotUUIDfromExerciseVariant}
                                onOpenExerciseModal={props.onOpenExerciseModal}
                            />
                            :
                            <CoursesKiosk
                                courseList={props.courseList}
                                filterSkillsterCourses={props.filterSkillsterCourses}
                                skillsterLessons={props.skillsterLessons}
                                handleSearchBarSkillsterCourses={props.handleSearchBarSkillsterCourses}
                                onOpenExerciseModal={props.onOpenExerciseModal}
                                getScreenshotUUIDFromExercise={props.getScreenshotUUIDFromExercise}
                                getVehicleGUIDfromExercise={props.getVehicleGUIDfromExercise}
                                getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                                onOpenSkillsterCourseModal={props.onOpenSkillsterCourseModal}
                                selectedCourseImageUUID={props.selectedCourseImageUUID}
                                selectedCourseName={props.selectedCourseName}
                                selectedCoursePublicUUID={props.selectedCoursePublicUUID}
                            />
                        }
                    </div>

                </CSSTransition>
            </SwitchTransition>
        </KioskWrapper>
    )
}

interface KioskClosedProps {
    onToggle: () => void;
}

export const KioskClosed = (props: KioskClosedProps) => {
    return (
        <>
            <SkillsterToolTip />
            <KioskClosedWrapper onClick={props.onToggle} data-tip="Öppna sidofältet">
                <IconRoundChevron className="chevron" />
        </KioskClosedWrapper>
        </>

    )
}

interface ExercisesKioskProps {
    vehiclesIsActive?: boolean;
    VehicleOptions: ExerciseOption[];
    onChangeVehicle: (option: ExerciseOption) => void;
    selectedVehicleOption: ExerciseOption;
    searchBarValue: string;
    handleSearchBar: (event: FormEvent) => void;
    groupedExerciseVariants: ExerciseVariant[][];
    getScreenshotUUIDfromExerciseVariant: (ev: ExerciseVariant) => string | undefined;
    onOpenExerciseModal: (e: Exercise | ExerciseVariant) => void;
}

const ExercisesKiosk = (props: ExercisesKioskProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <>
            <h3>{t("courseEditor.kiosk.exercises.title")}</h3>
            <div className="description">{t("courseEditor.kiosk.exercises.description")}</div>
            <VehicleDropdown
                vehicleOptions={props.VehicleOptions}
                onChange={props.onChangeVehicle}
                selected={props.selectedVehicleOption}
                className="dropdown"
                label={t("courseEditor.kiosk.exercises.dropdown.label")}
            />
            <SearchBar
                invertColors
                searchBarValue={props.searchBarValue}
                handleSearchBar={props.handleSearchBar}
                className="searchBar"
                debounceMs={400}
                onClear={() => dispatch(actions.filter.clearFilter("kioskExerciseVariants"))}
            />
            <SearchingFor filter={props.searchBarValue} />
            {props.groupedExerciseVariants.map((exerciseVariants: ExerciseVariant[]) =>
                <GroupedExerciseVariantsKiosk
                    exerciseVariants={exerciseVariants}
                    key={exerciseVariants[0].VariantUUID}
                    getScreenshotUUIDfromExerciseVariant={props.getScreenshotUUIDfromExerciseVariant}
                    openExerciseModal={props.onOpenExerciseModal}
                />
            )}
        </>
    );
}

interface CoursesKioskProps {
    courseList: Course[];
    selectedCourseName: string;
    selectedCourseImageUUID: string;
    selectedCoursePublicUUID: string;
    filterSkillsterCourses: string;
    skillsterLessons: Lesson[];
    handleSearchBarSkillsterCourses: (event: FormEvent) => void;
    onOpenExerciseModal: (e: Exercise | ExerciseVariant) => void;
    getScreenshotUUIDFromExercise: (templateVariantUUID: string, vehicleUUID: string) => string | undefined;
    getVehicleGUIDfromExercise: (e: Exercise) => string | null;
    getIdealCompletionTimeFromExercise: (e: Exercise) => number;
    onOpenSkillsterCourseModal: () => void;
}

const CoursesKiosk = (props: CoursesKioskProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <>
            <h3>{t("courseEditor.kiosk.courses.title")}</h3>
            <div className="description">{t("courseEditor.kiosk.courses.description")}</div>
            <KioskSelectedCourse
                selectedCourseName={props.selectedCourseName}
                selectedCourseImageUUID={props.selectedCourseImageUUID}
                onChange={props.onOpenSkillsterCourseModal}
            />
            <SearchBar
                invertColors
                searchBarValue={props.filterSkillsterCourses}
                handleSearchBar={props.handleSearchBarSkillsterCourses}
                className="searchBar"
                debounceMs={400}
                onClear={() => dispatch(actions.filter.clearFilter("kioskSkillsterCoursesExercises"))}
            />
            <SearchingFor filter={props.filterSkillsterCourses} />
            <SkillsterCoursesKiosk
                lessons={props.skillsterLessons}
                getScreenshotUUIDFromExercise={props.getScreenshotUUIDFromExercise}
                getVehicleGUIDfromExercise={props.getVehicleGUIDfromExercise}
                getIdealCompletionTimeFromExercise={props.getIdealCompletionTimeFromExercise}
                onOpenExerciseModal={props.onOpenExerciseModal}
            />
        </>
    );
}

interface KioskSelectedCourseProps {
    selectedCourseName: string;
    selectedCourseImageUUID: string;
    onChange: () => void;
}

const KioskSelectedCourse = (props: KioskSelectedCourseProps) => {
    const { t } = useTranslation();
    return (
        <>
            <label>{t("courseEditor.kiosk.courses.dropdown.label")}</label>
            <KioskSelectedCourseWrapper>
                <div className="selected">
                    <DropdownVehicleThumbWrapper>
                        <CourseImage imageUUID={props.selectedCourseImageUUID} width={145} alt={props.selectedCourseName} />
                    </DropdownVehicleThumbWrapper>
                    <h5>{props.selectedCourseName}</h5>
                </div>
                <EditButton text={t("courseEditor.kiosk.courses.change")} onClick={props.onChange} />
            </KioskSelectedCourseWrapper>
        </>
    );
}
interface LessonNameProps {
    index: number;
    names: { Language: string, Value: string }[];
    name?: string;
    onClick?: () => void;
    className?: string;
    isDeleted?: boolean;
}
const LessonName = (props: LessonNameProps) => {
    const { t } = useTranslation();
    const name = (props.name && props.name !== "") ? props.name : props.names?.find(name => name.Language === fetchLang())?.Value;
    return (
        <LessonNameWrapper onClick={props.onClick} className={`${props.isDeleted ? "deleted" : ""} ${props.className ?? ""}`}>
            <h3>{name ? name : `${t('common:course.lesson')} ${props.index + 1}`}</h3>
        </LessonNameWrapper>
    );
};

interface ExerciseModalProps {
    exercise?: Exercise | ExerciseVariant;
    screenshotUUID?: string;
    isOpen: boolean;
    onClose: () => void;
}

export const ExerciseModal = (props: ExerciseModalProps) => {
    const { t } = useTranslation();
    if (!props.exercise) return null;
    return (
        <Modal isOpen={props.isOpen} title={props.exercise.Name} onClose={props.onClose}>
            <ExerciseModalInnerWrapper>
                {props.screenshotUUID &&
                    <ExerciseTemplateVariantImage uuid={props.screenshotUUID} width={425} alt={props.exercise.Name} className="exercise-image" />
                }
                <div className="section small">
                    <h3>{t('user.courses.modal.title')}</h3>
                </div>
                <p className="description">
                    {props.exercise.Description}
                </p>
            </ExerciseModalInnerWrapper>
        </Modal>
    );
}
