import {
  Lecture,
  Segment,
  ServerLecture,
  ServerSegment,
} from "../../../../../types/lecture.types";

export function convertServerLectureToLecture(source: ServerLecture): Lecture {
  const Names: Record<string, string> = {};

  source.Names?.forEach((n) => {
    Names[n.Language] = n.Data;
  });
  const Descriptions: Record<string, string> = {};

  source.Descriptions?.forEach((n) => {
    Descriptions[n.Language] = n.Data;
  });

  //   const Segments: Segment[] = source.Segments.map((s) =>
  //     convertServerSegmentToSegment(s)
  //   );

  const segmentsList: Segment[] = source.Segments.map((s) =>
    convertServerSegmentToSegment(s)
  );
  const segmentsMap: Record<string, Segment> = {};
  source.Segments?.forEach((s) => {
    segmentsMap[s.ID] = convertServerSegmentToSegment(s);
  });

  return {
    ...source,
    Names,
    Descriptions,
    Segments: {
      list: segmentsList,
      map: segmentsMap,
    },
  };
}

function convertServerSegmentToSegment(source: ServerSegment): Segment {
  const Names: Record<string, string> = {};
  const Images: Record<string, string> = {};

  source.Names?.forEach((n) => {
    Names[n.Language] = n.Data;
  });

  source.Images?.forEach((i) => {
    Images[i.Language] = i.Data;
  });

  return {
    ...source,
    Names,
    Images,
  };
}

export function convertLectureToServerLecture(source: Lecture): ServerLecture {
  const Names = Object.keys(source.Names)?.map((key) => ({
    Language: key,
    Data: source.Names[key],
  }));

  const Descriptions = Object.keys(source.Descriptions)?.map((key) => ({
    Language: key,
    Data: source.Descriptions[key],
  }));

  const Segments = source.Segments.list.map((s) =>
    convertSegmentToServerSegment(s)
  );

  return {
    ...source,
    Names,
    Descriptions,
    Segments,
  };
}

function convertSegmentToServerSegment(source: Segment): ServerSegment {
  const Names = Object.keys(source.Names)?.map((key) => ({
    Language: key,
    Data: source.Names[key],
  }));

  const Images = Object.keys(source.Images)?.map((key) => ({
    Language: key,
    Data: source.Images[key],
  }));

  return {
    ...source,
    Names,
    Images,
  };
}
