import { Option } from "../types/option.types";
import { fiLocale, svLocale } from "./universal.helper";
import { enGB, sv, fi } from "date-fns/locale";
import courseFree from "../components/global/Misc/resources/icons/course-free.svg";
import courseLinear from "../components/global/Misc/resources/icons/course-linear.svg";
import { addDays, addMonths, format, startOfWeek, startOfYear } from "date-fns";

export const COURSE_TYPE_OPTIONS: Option[] = [{ label: svLocale() ? "Linjär" : fiLocale() ? "Lineaarinen" : "Linear", value: 1, icon: () => <img src={courseLinear} alt="Linjär" className="course-type-icon" /> }, { label: svLocale() ? "Fri" : fiLocale() ? "Vapaa" : "Open", value: 2, icon: () => <img src={courseFree} alt="Fri" className="course-type-icon" /> }];
export const ROLES = () => svLocale() ? ["", "Admin", "Lärare", "Elev"] : fiLocale() ? ["", "Admin", "Opettaja", "Oppilas"] : ["", "Admin", "Teacher", "Student"];
export const ROLES_SELECT_ITEMS = () => [{ label: ROLES()[2], value: "2" }, { label: ROLES()[3], value: "3" }];
export const ADMIN_ROLES_SELECT_ITEMS = () => [{ label: ROLES()[1], value: "1" }, { label: ROLES()[2], value: "2" }, { label: ROLES()[3], value: "3" }];

export const ROLES_OPTIONS_V2 = () => [{ label: ROLES()[2], value: "2" }, { label: ROLES()[3], value: "3" }];
export const ADMIN_ROLES_OPTIONS_V2 = () => [{ label: ROLES()[1], value: "1" }, { label: ROLES()[2], value: "2" }, { label: ROLES()[3], value: "3" }];

export const SKILLSTER_ORGANIZATION_ID = 1;
export const SKILLSTER_DEMO_ORGANIZATION_ID = 62;
export const SKILLSTER_DEMO_ORGANIZATION_ID_FINLAND = 193;
export const SKILLSTER_ORGANIZATION_IDS = [SKILLSTER_ORGANIZATION_ID, SKILLSTER_DEMO_ORGANIZATION_ID, SKILLSTER_DEMO_ORGANIZATION_ID_FINLAND];

const WEEKDAYS = (short?: boolean) => {
    const locale: Locale = svLocale() ? sv : fiLocale() ? fi : enGB;
    const first = startOfWeek(new Date())
    return Array.from(Array(7)).map((_, i) => format(addDays(first, i), short ? 'EEEEEE' : 'EEEE', { locale }));
}

const MONTHS = (short?: boolean) => {
    const locale: Locale = svLocale() ? sv : fiLocale() ? fi : enGB;
    const first = startOfYear(new Date());
    return Array.from(Array(12)).map((_, i) => format(addMonths(first, i), short ? 'LLL' : 'LLLL', { locale }));
}

export const MONTHS_LONG = () => MONTHS();
export const MONTHS_SHORT = () => MONTHS(true);
export const WEEKDAYS_LONG = () => WEEKDAYS();
export const WEEKDAYS_SHORT = () => WEEKDAYS(true);

export const MANUAL_PDF_URL = () => svLocale() ?
    "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057604/L%C3%A4rarverktyget/Manualer/skillster-manual-sv_zczljx.pdf" :
    fiLocale() ?
        "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057605/L%C3%A4rarverktyget/Manualer/skillster-manual-fi_lgwv81.pdf" :
        "https://res.cloudinary.com/dy7i8hekz/image/upload/v1683057597/L%C3%A4rarverktyget/Manualer/skillster-manual-en_l9crgp.pdf";

export const KIOSK_IDENTIFIER = "skillster_kiosk_lesson_identifier_";
export const KIOSK_EXISTING_EXERCISES_IDENTIFIER = "skillster_kiosk_existing_exercises_identifier_";
export const KIOSK_EXISTING_LESSONS_IDENTIFIER = "skillster_kiosk_existing_lessons_identifier_";

export const VIEW_HEIGHT = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

export const PERMISSION_CODE_PERMISSIONS_READ = "permissions:read";
export const PERMISSION_CODE_PERMISSIONS_WRITE = "permissions:write";
export const PERMISSION_CODE_COURSE_EDITOR_READ = "courseeditor:read";
export const PERMISSION_CODE_COURSE_EDITOR_WRITE = "courseeditor:write";
export const PERMISSION_CODE_COMMUNICATION_MESSAGES_READ = "communicationmessages:read";
export const PERMISSION_CODE_COMMUNICATION_MESSAGES_WRITE = "communicationmessages:write";
