import { useMutation } from "react-query";
import { apiPost } from "../../../../../../../helpers/requests.helper";

type AddCategoryToCourseMutation = {
  sourceUuid: string;
  targetSchoolId: number;
};

export function useCopyCourseToSchoolMutation() {
  return useMutation("add-category-to-course", (mutation: AddCategoryToCourseMutation) => {
    return apiPost(`/editor/duplicate`, { SourceUUID: mutation.sourceUuid, TargetSchool: mutation.targetSchoolId });
  });
}
