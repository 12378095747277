export const GET_COURSES = "/courses";
export const GET_LESSONS = "/lessons";
export const GET_EXERCISES = "/exercises";
export const GET_EXERCISE_VARIANTS = "/exercise-variants";
export const GET_SESSION_USER = "/get-session-user";
export const GET_USERS_FROM_SCHOOL = "/users-from-school";
export const GET_ALL_SCHOOLS = "/all-schools";
export const GET_SCHOOL_CLASSES = "/school-classes";

export const GET_CLASS_EXTRA_DATA = (classId: number) =>
  `/class/${classId}/extra-data`;
export const GET_USERS_CLASS = "/users-class";

export const GET_CLASS_COURSES = "/class-courses";

export const GET_ALL_ORGANIZATIONS = "/all-organizations";
export const GET_ORGANIZATION_SCHOOL_ID = "/organization-school-id";
export const GET_TEACHER_SCHOOLS = "/teacher-schools";

export const GET_EXERCISE_OPTIONS = "/editor/exercise-options";
export const GET_COURSE_IMAGES = "/image/courseimage";
export const GET_CLASS_PRESENCE = "/class-presence-date-range";
export const GET_ALL_ASSESSMENTS = "/get-all-assessments";

export const POST_SET_CLASS_COURSES = "/set-class-courses";
export const POST_DELETE_CLASS = "/delete-class";
export const POST_DELETE_USER = "/delete-user";

export const PUT_UPDATE_WELCOME = "/update-welcome";

export const GET_STATS_LOGGED_IN_USERS = "/logged-in-users";
export const GET_STATS_LOGGED_IN_USERS_PER_DAY = "/logged-in-users-per-day";

export const DELETE_DELETE_ORGANIZATION = "/admin/delete-organization";
export const POST_REACTIVATE_ORGANIZATION = "/admin/reactivate-organization";
export const DELETE_DELETE_SCHOOL = "/delete-school";
export const POST_REACTIVATE_SCHOOL = "/reactivate-school";

export const PUT_ADMIN_UPDATE_USER = "/admin/update-user";
export const POST_ADMIN_REACTIVATE_USER = "/admin/reactivate-user";
export const GET_ADMIN_USER_PERMISSIONS = "/admin/get-user-permissions";
export const GET_PERMISSIONS = "/admin/get-permissions";
export const POST_ADMIN_SET_USER_PERMISSIONS = "/admin/set-user-permissions";
