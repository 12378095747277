import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SubBar } from "../components/global/Misc/Misc.components";
import PageNotFound from "../components/pages/PageNotFound/PageNotFound";
import { AdminRoute } from "./AdminRoute";
import { lazyWithRetry } from "./Routes.helpers";

const Login = lazyWithRetry(() => import("../components/pages/Login/Login"));
const Start = lazyWithRetry(
  () => import("../components/pages/Home/Start/Start")
);
const HomeStats = lazyWithRetry(
  () => import("../components/pages/Home/HomeStats")
);
const Members = lazyWithRetry(() => import("../components/pages/Users/Users"));
const SchoolClass = lazyWithRetry(
  () => import("../components/pages/SchoolClass/SchoolClass")
);

const SchoolClasses = lazyWithRetry(
  () => import("../components/pages/SchoolClasses/SchoolClasses")
);
const User = lazyWithRetry(() => import("../components/pages/User/User"));
const Courses = lazyWithRetry(
  () => import("../components/pages/Courses/Courses")
);
const Course = lazyWithRetry(() => import("../components/pages/Course/Course"));
const ImportUsersPage = lazyWithRetry(
  () => import("../components/pages/ImportUsers/ImportUsers")
);
const LessonDetails = lazyWithRetry(
  () => import("../components/pages/LessonDetails/LessonDetails")
);
const Simulators = lazyWithRetry(
  () => import("../components/pages/Simulators/Simulators")
);
const ExerciseDetails = lazyWithRetry(
  () => import("../components/pages/Exercise/Exercise")
);
const CreateUser = lazyWithRetry(
  () => import("../components/pages/CreateUser/CreateUser")
);
const CreateSchoolClass = lazyWithRetry(
  () => import("../components/pages/CreateSchoolClass/CreateSchoolClass")
);
const Support = lazyWithRetry(
  () => import("../components/pages/Support/Support")
);
const InstallationGuide = lazyWithRetry(
  () =>
    import("../components/pages/Support/InstallationGuideNew/InstallationGuide")
);
const Stats = lazyWithRetry(
  () => import("../components/pages/Admin/Admin/Admin")
);
const AdminTeacherLectures = lazyWithRetry(
  () => import("../components/pages/Admin/TeacherLectures/TeacherLectures")
);
const AdminTeacherLectureStats = lazyWithRetry(
  () =>
    import(
      "../components/pages/Admin/TeacherLecturesStats/TeacherLecturesStats"
    )
);
const AdminTeacherLectureStatsSchool = lazyWithRetry(
  () =>
    import(
      "../components/pages/Admin/TeacherLecturesStats/LectureStatsSchool/LectureStatsSchool"
    )
);
const AdminTeacherLectureStatsUser = lazyWithRetry(
  () =>
    import(
      "../components/pages/Admin/TeacherLecturesStats/LectureStatsUser/LectureStatsUser"
    )
);
const AdminBetaSchools = lazyWithRetry(
  () => import("../components/pages/Admin/BetaSchools/BetaSchools")
);
const AdminContests = lazyWithRetry(
  () => import("../components/pages/Admin/Contests")
);
const AdminCreateContest = lazyWithRetry(
  () => import("../components/pages/Admin/Contests/CreateContest")
);
const AdminContest = lazyWithRetry(
  () => import("../components/pages/Admin/Contests/ContestDetails")
);
const AdminBilling = lazyWithRetry(
  () => import("../components/pages/Admin/Billing")
);
const AdminFortnoxCallback = lazyWithRetry(
  () => import("../components/pages/Admin/Billing/components/FortnoxCallback")
);
const AdminTachographExercises = lazyWithRetry(
  () =>
    import("../components/pages/Admin/TachographExercises/TachographExercises")
);
const AdminEditTachographExercise = lazyWithRetry(
  () =>
    import(
      "../components/pages/Admin/EditTachographExercise/EditTachographExercise"
    )
);

const EditLecture = lazyWithRetry(
  () => import("../components/pages/Admin/TeacherLectures/Edit/EditLecture")
);
const AllSimulatorsNew = lazyWithRetry(
  () => import("../components/pages/Admin/AllSimulators/AllSimulators")
);
const HardwareDistribution = lazyWithRetry(
  () =>
    import(
      "../components/pages/Admin/AllSimulators/HardwareDistribution/HardwareDistribution"
    )
);
const GlobalUsers = lazyWithRetry(
  () => import("../components/pages/Admin/GlobalUsers/GlobalUsers")
);
const Schools = lazyWithRetry(
  () => import("../components/pages/Admin/Schools")
);
const Organizations = lazyWithRetry(
  () => import("../components/pages/Admin/Organizations/Organizations")
);
const OrganizationDetails = lazyWithRetry(
  () =>
    import("../components/pages/Admin/OrganizationDetails/OrganizationDetails")
);
const SchoolDetails = lazyWithRetry(
  () => import("../components/pages/Admin/SchoolDetails/SchoolDetails")
);
const Tickets = lazyWithRetry(() => import("../components/pages/Admin/Tickets/Tickets"));
const Ticket = lazyWithRetry(() => import("../components/pages/Admin/TicketDetails/TicketDetails"));
const GithubCallback = lazyWithRetry(
  () => import("../components/pages/Admin/TicketDetails/GithubCallback")
);
const Map = lazyWithRetry(() => import("../components/pages/Admin/Map/Map"));
const GlobalToplist = lazyWithRetry(
  () => import("../components/pages/Admin/GlobalToplist/GlobalToplist")
);
const RunData = lazyWithRetry(
  () => import("../components/pages/Admin/RunData/Level1")
);
const RunDataLevel2 = lazyWithRetry(
  () => import("../components/pages/Admin/RunData/Level2")
);
const RunDataLevel3 = lazyWithRetry(
  () => import("../components/pages/Admin/RunData/Level3")
);
const CourseEditorStats = lazyWithRetry(
  () => import("../components/pages/Admin/CourseEditorStats/CourseEditorStats")
);
const Academy = lazyWithRetry(
  () => import("../components/pages/Home/Academy/Academy")
);
const Youtube = lazyWithRetry(
  () => import("../components/pages/Home/Youtube/Youtube")
);
const News = lazyWithRetry(() => import("../components/pages/Home/News/News"));
const Newsletter = lazyWithRetry(
  () => import("../components/pages/Home/Newsletter/Newsletter")
);
const SimulatorsActivation = lazyWithRetry(
  () =>
    import("../components/pages/Admin/AllSimulators/components/ActivatedTable")
);
const AdminExerciseComments = lazyWithRetry(
  () => import("../components/pages/Admin/ExerciseComments")
);

export const USER_DRIVING_RECORD_SUBPATH = "drivingrecord";
export const USER_PROFILE_SUBPATH = "profile";
export const TACHOGRAPH_SUBPATH = "tachograph";
export const SCHOOL_CLASS_HISTORY_SUBPATH = "history";

export const MY_SKILLSTER_URL = "https://my.skillster.se";

export const ACTIVATE_PATH = "/activate";
export const LOGIN_PATH = "/login";
export const HOME_PATH = "/home";
export const START_PATH = `${HOME_PATH}/home`;
export const HOME_STATS_PATH = `${HOME_PATH}/stats`;
export const HOME_NEWS_PATH = `${HOME_PATH}/news`;
export const HOME_NEWSLETTER_PATH = `${HOME_PATH}/newsletter`;
export const HOME_YOUTUBE_PATH = `${HOME_PATH}/youtube`;
export const COURSES_PATH = "/courses";
export const COURSES_CUSTOM_PATH = `${COURSES_PATH}/custom`;
export const COURSE_EDITOR_PATH = `/course-editor`;
export const DRAFT_PATH = `${COURSE_EDITOR_PATH}/:publicUUID`;
export const SUPPORT_PATH = "/support";
const COURSE_PATH = `${COURSES_PATH}/:PublicCourseUUID`;
export const LESSON_BASE_PATH = "/lesson";
const LESSON_PATH = `${LESSON_BASE_PATH}/:PublicExerciseUUID`;
export const EXERCISES_PATH = `/exercises`;
const EXERCISE_PATH = `/exercises/:publicExerciseUUID`;
export const USERS_PATH = "/users";
export const USER_PATH = `${USERS_PATH}/:userID`;
export const CREATE_USER_PATH = `${USERS_PATH}/create-user`;
export const IMPORT_USERS_PATH = `${USERS_PATH}/import-users`;
export const USER_PROFILE_PATH = `${USER_PATH}/${USER_PROFILE_SUBPATH}`;
export const USER_DRIVING_RECORD_PATH = `${USER_PATH}/${USER_DRIVING_RECORD_SUBPATH}`;
export const USER_TACHOGRAPH_PATH = `${USER_PATH}/${TACHOGRAPH_SUBPATH}`;
export const SCHOOL_CLASSES_PATH = "/school-classes";
export const CREATE_SCHOOL_CLASS_PATH = `${SCHOOL_CLASSES_PATH}/create-class`;
export const SIMULATORS_PATH = "/simulators";
const CLASS_PATH = `${SCHOOL_CLASSES_PATH}/:classID`;
export const CLASS_TACHOGRAPH_PATH = `${CLASS_PATH}/${TACHOGRAPH_SUBPATH}`;
export const CLASS_HISTORY_PATH = `${CLASS_PATH}/${SCHOOL_CLASS_HISTORY_SUBPATH}`;
const EDIT_CLASS_PATH = `${CLASS_PATH}/edit`;
export const EDIT_CLASS_COURSES_PATH = `${EDIT_CLASS_PATH}/courses`;
export const EDIT_CLASS_USERS_PATH = `${EDIT_CLASS_PATH}/users`;
export const EDIT_CLASS_SETTINGS_PATH = `${EDIT_CLASS_PATH}/settings`;
export const INSTALLATION_PATH = `${SUPPORT_PATH}/installation`;
export const ADMIN_PATH = "/admin";
export const STATS_SIMULATORS_PATH = `${ADMIN_PATH}/simulators`;
export const EXERCISE_COMMENTS_PATH = `${ADMIN_PATH}/exercise-comments`;
export const STATS_SIMULATORS_ACTIVATION_PATH = `${ADMIN_PATH}/simulators-activation`;
export const BETA_SCHOOLS_PATH = `${ADMIN_PATH}/beta-schools`;
export const GLOBAL_USERS_PATH = `${ADMIN_PATH}/users`;
export const ORGANIZATIONS_PATH = `${ADMIN_PATH}/organizations`;
export const SCHOOLS_PATH = `${ADMIN_PATH}/schools`;
const ORGANIZATION_DETAILS_PATH = `${ORGANIZATIONS_PATH}/:organizationID`;
const SCHOOL_DETAILS_PATH = `${ORGANIZATION_DETAILS_PATH}/:schoolID`;
export const TICKETS_PATH = `${ADMIN_PATH}/tickets`;
export const TICKET_PATH = `${TICKETS_PATH}/:ticketId`;
export const GITHUB_CALLBACK = `/oauth/github/callback`
export const MAP_PATH = `${ADMIN_PATH}/map`;
export const GLOBAL_TOPLIST_PATH = `${ADMIN_PATH}/toplist`;
export const AGGREGATED_RUN_STATS_PATH = `${ADMIN_PATH}/runstats`;
export const RUN_DATA_PATH = `${ADMIN_PATH}/rundata`;
export const RUN_DATA_LEVEL_2_PATH = `${RUN_DATA_PATH}/:key1/:key1Id`;
export const RUN_DATA_LEVEL_3_PATH = `${RUN_DATA_PATH}/:key1/:key1Id/:key2/:key2Id`;
export const COURSE_EDITOR_STATS_PATH = `${ADMIN_PATH}/courseeditorstats`;
export const ADMIN_TEACHER_LECTURES_PATH = `${ADMIN_PATH}/teacher-lectures`;
export const ADMIN_TEACHER_LECTURES_STATS_PATH = `${ADMIN_TEACHER_LECTURES_PATH}-stats`;
export const ADMIN_TEACHER_LECTURES_STATS_PATH_SCHOOL = `${ADMIN_TEACHER_LECTURES_STATS_PATH}/:lectureId`;
export const ADMIN_TEACHER_LECTURES_STATS_PATH_USER = `${ADMIN_TEACHER_LECTURES_STATS_PATH}/:lectureId/:statsSchoolId`;
export const ADMIN_CONTESTS_PATH = `${ADMIN_PATH}/contests`;
export const ADMIN_CONTEST_PATH = `${ADMIN_CONTESTS_PATH}/:id`;
export const ADMIN_CREATE_CONTEST_PATH = `${ADMIN_PATH}/contests/create`;
export const ADMIN_BILLING_PATH = `${ADMIN_PATH}/billing`;
export const ADMIN_TACHOGRAPH_EXERCISES = `${ADMIN_PATH}/tachograph-exercises/`;
export const ADMIN_EDIT_TACHOGRAPH_EXERCISE_PATH = `${ADMIN_PATH}/edit-tachograph-exercise/:id`;
export const ADMIN_FORTNOX_CALLBACK = "/oauth/fortnox/callback";
export const EDIT_LECTURE_BASEPATH = `${ADMIN_TEACHER_LECTURES_PATH}/edit`;
export const EDIT_LECTURE_PATH = `${EDIT_LECTURE_BASEPATH}/:id`;
export const ACADEMY_PATH = `/academy`;
export const ACADEMY_DETAILS_PATH = `${ACADEMY_PATH}/:lectureId/:segmentId`;
export const HARDWARE_DISTRIBUTION_PATH = `${STATS_SIMULATORS_PATH}/hardware`;

export const goToTopLevelRoute = (path: string, history: any) => {
  if (
    path === USERS_PATH ||
    path === SCHOOL_CLASSES_PATH ||
    path === COURSES_PATH ||
    path === SIMULATORS_PATH ||
    path === INSTALLATION_PATH ||
    path === SUPPORT_PATH
  )
    return;
  else if (path.startsWith(USERS_PATH)) history.push(USERS_PATH);
  else if (path.startsWith(SCHOOL_CLASSES_PATH))
    history.push(SCHOOL_CLASSES_PATH);
  else if (path.startsWith(COURSES_PATH)) history.push(COURSES_PATH);
  else if (path.startsWith(SIMULATORS_PATH)) history.push(SIMULATORS_PATH);
  else if (path.startsWith(INSTALLATION_PATH)) history.push(INSTALLATION_PATH);
  else if (path.startsWith(SUPPORT_PATH)) history.push(SUPPORT_PATH);
  else history.push(HOME_PATH);
};

const Routes = () => (
  <Suspense fallback={<SubBar />}>
    <Switch>
      <Route exact path={LOGIN_PATH} component={Login} />
      <Route exact path={SCHOOL_CLASSES_PATH} component={SchoolClasses} />
      <Route
        exact
        path={CREATE_SCHOOL_CLASS_PATH}
        component={CreateSchoolClass}
      />
      <Route exact path={HOME_PATH} component={Start} />
      <Route exact path={HOME_STATS_PATH} component={HomeStats} />
      <Route exact path={HOME_NEWS_PATH} component={News} />
      <Route exact path={HOME_NEWSLETTER_PATH} component={Newsletter} />
      <Route exact path={HOME_YOUTUBE_PATH} component={Youtube} />
      <Route exact path={CLASS_PATH} component={SchoolClass} />
      <Route exact path={CLASS_TACHOGRAPH_PATH} component={SchoolClass} />
      <Route exact path={CLASS_HISTORY_PATH} component={SchoolClass} />
      <Route exact path={EDIT_CLASS_PATH} component={SchoolClass} />
      <Route exact path={EDIT_CLASS_COURSES_PATH} component={SchoolClass} />
      <Route exact path={EDIT_CLASS_USERS_PATH} component={SchoolClass} />
      <Route exact path={EDIT_CLASS_SETTINGS_PATH} component={SchoolClass} />
      <Route exact path={COURSES_PATH} component={Courses} />
      <Route exact path={COURSES_CUSTOM_PATH} component={Courses} />
      <Route exact path={COURSE_PATH} component={Course} />
      <Route exact path={LESSON_PATH} component={LessonDetails} />
      <Route exact path={EXERCISE_PATH} component={ExerciseDetails} />
      <Route exact path={USERS_PATH} component={Members} />
      <Route exact path={CREATE_USER_PATH} component={CreateUser} />
      <Route exact path={IMPORT_USERS_PATH} component={ImportUsersPage} />
      <Route exact path={USER_PATH} component={User} />
      <Route exact path={USER_DRIVING_RECORD_PATH} component={User} />
      <Route exact path={USER_TACHOGRAPH_PATH} component={User} />
      <Route exact path={USER_PROFILE_PATH} component={User} />
      <Route exact path={SIMULATORS_PATH} component={Simulators} />
      <Route exact path={SUPPORT_PATH} component={Support} />
      <Route exact path={INSTALLATION_PATH} component={InstallationGuide} />
      <Route exact path={ACADEMY_PATH} component={Academy} />

      <AdminRoute exact path={ADMIN_PATH} component={Stats} />
      <AdminRoute
        exact
        path={STATS_SIMULATORS_PATH}
        component={AllSimulatorsNew}
      />
      <AdminRoute exact path={GLOBAL_USERS_PATH} component={GlobalUsers} />
      <AdminRoute exact path={ORGANIZATIONS_PATH} component={Organizations} />
      <AdminRoute exact path={SCHOOLS_PATH} component={Schools} />
      <AdminRoute
        exact
        path={ORGANIZATION_DETAILS_PATH}
        component={OrganizationDetails}
      />
      <AdminRoute exact path={SCHOOL_DETAILS_PATH} component={SchoolDetails} />
      <AdminRoute exact path={TICKETS_PATH} component={Tickets} />
      <AdminRoute exact path={TICKET_PATH} component={Ticket} />
      <AdminRoute exact path={GITHUB_CALLBACK} component={GithubCallback} />
      <AdminRoute exact path={MAP_PATH} component={Map} />
      <AdminRoute exact path={GLOBAL_TOPLIST_PATH} component={GlobalToplist} />
      <AdminRoute exact path={RUN_DATA_PATH} component={RunData} />
      <AdminRoute
        exact
        path={RUN_DATA_LEVEL_2_PATH}
        component={RunDataLevel2}
      />
      <AdminRoute
        exact
        path={RUN_DATA_LEVEL_3_PATH}
        component={RunDataLevel3}
      />
      <AdminRoute
        exact
        path={COURSE_EDITOR_STATS_PATH}
        component={CourseEditorStats}
      />
      <AdminRoute
        exact
        path={ADMIN_TEACHER_LECTURES_PATH}
        component={AdminTeacherLectures}
      />
      <AdminRoute exact path={EDIT_LECTURE_PATH} component={EditLecture} />
      <AdminRoute
        exact
        path={ADMIN_TEACHER_LECTURES_STATS_PATH}
        component={AdminTeacherLectureStats}
      />
      <AdminRoute
        exact
        path={ADMIN_TEACHER_LECTURES_STATS_PATH_SCHOOL}
        component={AdminTeacherLectureStatsSchool}
      />
      <AdminRoute
        exact
        path={ADMIN_TEACHER_LECTURES_STATS_PATH_USER}
        component={AdminTeacherLectureStatsUser}
      />
      <AdminRoute
        exact
        path={HARDWARE_DISTRIBUTION_PATH}
        component={HardwareDistribution}
      />
      <AdminRoute
        exact
        path={ADMIN_CREATE_CONTEST_PATH}
        component={AdminCreateContest}
      />
      <AdminRoute exact path={ADMIN_CONTESTS_PATH} component={AdminContests} />
      <AdminRoute exact path={ADMIN_CONTEST_PATH} component={AdminContest} />
      <AdminRoute
        exact
        path={STATS_SIMULATORS_ACTIVATION_PATH}
        component={SimulatorsActivation}
      />
      <AdminRoute exact path={BETA_SCHOOLS_PATH} component={AdminBetaSchools} />
      <AdminRoute
        exact
        path={EXERCISE_COMMENTS_PATH}
        component={AdminExerciseComments}
      />
      <AdminRoute exact path={ADMIN_BILLING_PATH} component={AdminBilling} />
      <AdminRoute
        exact
        path={ADMIN_FORTNOX_CALLBACK}
        component={AdminFortnoxCallback}
      />
      <AdminRoute
        exact
        path={ADMIN_TACHOGRAPH_EXERCISES}
        component={AdminTachographExercises}
      />
      <AdminRoute
        exact
        path={ADMIN_EDIT_TACHOGRAPH_EXERCISE_PATH}
        component={AdminEditTachographExercise}
      />

      <Route exact path={"/"}>
        <Redirect to={HOME_PATH} />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  </Suspense>
);
export default Routes;
