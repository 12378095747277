import { Box, Center, RingProgress, Title, Tooltip, useMantineTheme } from "@mantine/core";

interface LectureProgressRingProps {
    progress: number;
    size?: number;
    thickness?: number;
    hideText?: boolean;
}

function LectureProgressRing(props: LectureProgressRingProps) {

    const theme = useMantineTheme();
    const SIZE = props.size ?? 44;
    const PADDING = 5;

    return (
        <Tooltip
            openDelay={400}
            width={200}
            multiline
            label={`Du har läst ${props.progress} % av innehållet i detta avsnitt`}
        >
            <Box
                sx={{
                    position: "relative",
                }}
                p={PADDING}
            >
                <Box
                    sx={{
                        backgroundColor: "white",
                        position: "absolute",
                        width: SIZE,
                        height: SIZE,
                        top: 2 * PADDING,
                        left: 2 * PADDING,
                        borderRadius: "100%",
                    }}
                />
                <RingProgress
                    size={SIZE + 2 * PADDING}
                    thickness={props.thickness ?? 4}
                    label={
                        !props.hideText &&
                        <Center>
                            <Title order={6} color="bluegray.5">{props.progress}%</Title>
                        </Center>
                    }
                    sections={[
                        { value: props.progress, color: theme.colors.green[5] },
                    ]}
                />
            </Box>
        </Tooltip>
    )
}

export default LectureProgressRing;