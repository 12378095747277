export const FIREBASE_CONFIG_ADMIN = {
  apiKey: "AIzaSyCXXgRJHXBedQUofGIUfws0MN18In5iuVY",
  authDomain: "messages-admin-bbcaa.firebaseapp.com",
  projectId: "messages-admin-bbcaa",
  storageBucket: "messages-admin-bbcaa.appspot.com",
  messagingSenderId: "63156397733",
  appId: "1:63156397733:web:4cca2df82d0ee116570f4c",
  measurementId: "G-87FE3XEZ4K",
};

export const FIREBASE_CONFIG_DEV = {
  apiKey: "AIzaSyCW-ETWZhgk9QpWrQqdOUBY2Ncl0KZHaTQ",
  authDomain: "skillster-messages.firebaseapp.com",
  projectId: "skillster-messages",
  storageBucket: "skillster-messages.appspot.com",
  messagingSenderId: "768544995202",
  appId: "1:768544995202:web:18ce7aa6c2aa97964e419b",
};
