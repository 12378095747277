import { Group, Stack } from "@mantine/core";
import { ReactNode } from "react";
import { LanguageSelector } from "../../global/Misc/Misc.components";
import { LogoDark } from "../../global/Misc/Symbols.components";
import { LogoWrapper, SelectLanguageDesktopWrapper, SelectLanguageMobileWrapper } from "./Login.styled.components";

export const Logo = () => <LogoWrapper><LogoDark /></LogoWrapper>;

export const SelectLanguageDesktop = () => <SelectLanguageDesktopWrapper><LanguageSelector /></SelectLanguageDesktopWrapper>;
export const SelectLanguageMobile = () => <SelectLanguageMobileWrapper><LanguageSelector /></SelectLanguageMobileWrapper>;

interface ButtonsWrapperProps {
    children: ReactNode;
    desktop?: boolean;
}

export function ButtonsWrapper(props: ButtonsWrapperProps) {

    if (props.desktop)
        return (
            <Group>
                {props.children}
            </Group>
        );

    return (
        <Stack>
            {props.children}
        </Stack>
    );

}