import { Page } from "../../global/Containers/Containers.styled.components";
import { IllustrationSomethingWrong } from "../../global/Misc/Symbols.components";

function NotAllowed() {
    return (
        <Page>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 50,
                justifyContent: "center",
                height: "80vh"
            }}>
                <IllustrationSomethingWrong />
                <h1 className="huge">Insufficient permissions</h1>
            </div>
        </Page>
    )
}

export default NotAllowed;