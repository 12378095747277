import { Email, Message, MessageTypeReply, Ticket } from "./TicketDetails.api";

const citationPattern = /(Den.*(?:skrev.*|.*skrev):)|(On.*wrote:)/i;

export const removeJunkFromBody = (name?: string) => {
  if (!name) return "";
  return name.replace(/\d{1,2} [a-zA-Z]* \d{1,4} [\s\S]*/, "");
};

export const hasRespondToAddress = (ticket?: Ticket) => {
  return (
    ticket?.Emails?.some(
      (x) => x.Value === ticket.RespondTo && x.Label === "RespondTo"
    ) ?? false
  );
};

export const findReplyEmail = (emails: Email[]) => {
  return emails.find((x) => x.Label === "UserEmail") ?? emails[0];
};

export const parseMessageBody = (htmlRaw: string) => {
  const messageElement = document.createElement("div");
  messageElement.innerHTML = htmlRaw;
  let blockquote = messageElement.querySelector("blockquote");
  if (blockquote) {
    // if citationline is outside of blockquote, move it inside
    const prevNode = blockquote.previousElementSibling;
    if (prevNode && citationPattern.test(prevNode.textContent ?? "")) {
      const parentNode = prevNode.parentNode;
      if (parentNode) {
        const citationBlockQuote = document.createElement("blockquote");
        citationBlockQuote.appendChild(prevNode.cloneNode(true));
        citationBlockQuote.appendChild(blockquote);
        parentNode.insertBefore(citationBlockQuote, prevNode);
        blockquote = citationBlockQuote;
        prevNode.remove();
      }
    }
    const fullMessageElement = messageElement.cloneNode(true) as HTMLElement;
    blockquote?.remove();

    return {
      html: messageElement.innerHTML,
      full_html: fullMessageElement.innerHTML,
    };
  }
  return { html: messageElement.innerHTML };
};

export const buildResponsePreviousThread = (ticket: Ticket) => {
  const messages = ticket.Conversation;
  if (!messages.length) {
    return buildFromTicketDescription(ticket).outerHTML;
  }

  let thread = buildFromTicketDescription(ticket);
  for (let i = 0; i < messages.length; i++) {
    const msg = messages[i];

    if (msg.Type === MessageTypeReply) {
      //set username if needed
      if (!msg.Agent.Name && ticket.User.Email === msg.Agent.Email) {
        msg.Agent.Name = `${ticket.User.FirstName} ${ticket.User.LastName}`;
      }

      const citationElement = document.createElement("p");
      citationElement.innerHTML = createCitationLine(
        msg.CreationTime,
        msg.Agent.Name,
        msg.Agent.Email
      );

      const threadMessageElement = document.createElement("div");
      threadMessageElement.innerHTML = parseMessageForReplyThread(msg);

      const tmpThread = document.createElement("blockquote");
      tmpThread.appendChild(citationElement);
      tmpThread.appendChild(document.createElement("br"));
      tmpThread.appendChild(threadMessageElement);
      tmpThread.appendChild(thread);
      thread = tmpThread;
    }
  }

  return thread.outerHTML;
};

const buildFromTicketDescription = (ticket: Ticket) => {
  let thread = document.createElement("blockquote");
  const userName = `${ticket.User.FirstName} ${ticket.User.LastName}`;
  const userEmail =
    ticket.Emails.find((x) => x.Label === "UserEmail")?.Value ?? "";

  const citationElement = document.createElement("p");
  citationElement.innerHTML = createCitationLine(
    ticket.CreationTime,
    userName,
    userEmail
  );

  const threadMessageElement = document.createElement("div");
  threadMessageElement.innerHTML = ticket.DescriptionHtml;
  thread.appendChild(citationElement);
  thread.appendChild(document.createElement("br"));
  thread.appendChild(threadMessageElement);
  return thread;
};

const parseMessageForReplyThread = (message: Message) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = message.BodyHtml;

  // remove already existing thread, which can be included in external
  // responses
  const blockquote = tempDiv.querySelectorAll("blockquote");
  blockquote?.forEach((x) => x.remove());

  // sometimes previous threads have a line with timestamp of last reply
  // outside of the blockquote, if so, it needs to be removed
  const elements = tempDiv.getElementsByTagName("*");
  for (let i = 0; i < elements.length; i++) {
    citationPattern.test(elements[i].textContent ?? "") && elements[i].remove();
  }

  return tempDiv.innerHTML;
};

const createCitationLine = (
  creationTime: string,
  name: string,
  email: string
) => {
  const creationDate = new Date(creationTime);
  const userEmail = email ? `&lt${email}&gt; ` : "";
  const timeZoneOffset = creationDate.getTimezoneOffset() / -60;
  const timeZoneFormatted =
    (timeZoneOffset >= 0 ? "+" : "-") +
    String(Math.abs(timeZoneOffset)).padStart(2, "0") +
    ":00";

  // Format the date and time
  const formattedDate = creationDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const formattedTime = creationDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `On ${formattedDate} at ${formattedTime} ${timeZoneFormatted}, ${name} ${userEmail} wrote:`;
};
