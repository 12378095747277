import { DropResult } from "react-beautiful-dnd";
import actions, { fetchCourses, fetchExercises, fetchLessons, setError, setLoading, setSuccess } from ".";
import { apiDelete, apiGet, apiPost, apiPut } from "../helpers/requests.helper";
import { downloadObjectAsJson, fetchLang} from "../helpers/universal.helper";
import { GET_EXERCISE_OPTIONS, GET_COURSE_IMAGES } from "../navigation/endpoints";
import store, { DispatchFunction } from "../store";
import { Course, CourseDraft, CourseName, Exercise, ExerciseVariant, Lesson } from "../types/course.types";
import { v4 as uuidv4 } from 'uuid';
import { ExerciseOption } from "../types/option.types";
import { 
    COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST, 
    COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST, 
    COURSE_EDITOR_CLEAR_DRAFT, 
    COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON, 
    COURSE_EDITOR_CLEAR_LAST_SAVED, 
    COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS, 
    COURSE_EDITOR_CLEAR_UUID, 
    COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK, 
    COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK, 
    COURSE_EDITOR_CREATE_NEW_LESSON, 
    COURSE_EDITOR_DELETE_LESSON, 
    COURSE_EDITOR_DUPLICATE_EXERCISE, 
    COURSE_EDITOR_DUPLICATE_LESSON, 
    COURSE_EDITOR_MOVE_EXERCISE, 
    COURSE_EDITOR_MOVE_LESSON, 
    COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST, 
    COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST, 
    COURSE_EDITOR_REMOVE_EXERCISE, 
    COURSE_EDITOR_SET_COURSE_IMAGE, 
    COURSE_EDITOR_SET_COURSE_NAMES, 
    COURSE_EDITOR_SET_DRAFT, 
    COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION, 
    COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID, 
    COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID, 
    COURSE_EDITOR_SET_DUPLICATE_UUID, 
    COURSE_EDITOR_SET_EXERCISE_OPTIONS, 
    COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON, 
    COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE, 
    COURSE_EDITOR_SET_INITIAL_DRAFT, 
    COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE, 
    COURSE_EDITOR_SET_IS_DRAGGING_LESSON, 
    COURSE_EDITOR_SET_IS_SAVED, 
    COURSE_EDITOR_SET_LAST_SAVED, 
    COURSE_EDITOR_SET_MOVED_LESSONS, 
    COURSE_EDITOR_SET_NEW_UUID, 
    COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED, 
    COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE, 
    COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION, 
    COURSE_EDITOR_SET_SHOW_EXIT_WARNING, 
    COURSE_EDITOR_SET_VEHICLE_IMAGES, 
    COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE, 
    COURSE_EDITOR_UPDATE_COURSE_TYPE, 
    COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS, 
    COURSE_EDITOR_UPDATE_LESSON_NAME 
} from "../types/redux.types";
import { Lock } from "../types/editor.types";

const courseEditorActions = {
    courseEditor: {
        setIsSaved: (status: boolean) => ({
            type: COURSE_EDITOR_SET_IS_SAVED,
            status
        }),
        setCourseImage: (imageUUID: string) => ({
            type: COURSE_EDITOR_SET_COURSE_IMAGE,
            imageUUID
        }),
        setVehicleImages: (imageUUIDs: string[]) => ({
            type: COURSE_EDITOR_SET_VEHICLE_IMAGES,
            imageUUIDs
        }),
        setDuplicatedCourseUUID: (courseUUID: string) => ({
            type: COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID,
            courseUUID
        }),
        clearUuid: () => ({
            type: COURSE_EDITOR_CLEAR_UUID
        }),
        clearDuplicateUUID: () => ({
            type: COURSE_EDITOR_SET_DUPLICATE_UUID,
            uuid: ""
        }),
        clearDraft: () => ({
            type: COURSE_EDITOR_CLEAR_DRAFT
        }),
        clearLastSaved: () => ({
            type: COURSE_EDITOR_CLEAR_LAST_SAVED
        }),
        clearDuplicatedCourseUUID: () => ({
            type: COURSE_EDITOR_SET_DUPLICATED_COURSE_UUID,
            courseID: undefined
        }),
        showExitWarning: (status: boolean) => ({
            type: COURSE_EDITOR_SET_SHOW_EXIT_WARNING,
            status
        }),
        setSelectedVehicle: (option: ExerciseOption) => ({
            type: COURSE_EDITOR_SET_SELECTED_VEHICLE_OPTION,
            option
        }),
        setExerciseVariantsSelectedVehicle: (exerciseVariants: ExerciseVariant[]) => ({
            type: COURSE_EDITOR_SET_EXERCISE_VARIANTS_FOR_SELECTED_VEHICLE,
            exerciseVariants
        }),
        setDraftAndInitialDraftIntersection: () => ({
            type: COURSE_EDITOR_SET_DRAFT_AND_INITIAL_DRAFT_INTERSECTION
        }),
        setExerciseUuidsMovesWithinLesson: (result: DropResult) => ({
            type: COURSE_EDITOR_SET_EXERCISE_UUIDS_MOVED_WITHIN_LESSON,
            result
        }),
        setLessonsMoved: (result: DropResult) => ({
            type: COURSE_EDITOR_SET_MOVED_LESSONS,
            result
        }),
        clearExerciseUuidsMovesWithinLesson: () => ({
            type: COURSE_EDITOR_CLEAR_EXERCISE_UUIDS_MOVED_WITHIN_LESSON
        }),
        setInitialDraft: (draft: CourseDraft) => ({
            type: COURSE_EDITOR_SET_INITIAL_DRAFT,
            draft
        }),
        setPublishAndLeaveConfirmed: (status: boolean) => ({
            type: COURSE_EDITOR_SET_PUBLISH_AND_LEAVE_CONFIRMED,
            status
        })
    },
    kiosk: {
        setVehicleIsActive: (status: boolean) => ({
            type: COURSE_EDITOR_SET_VEHICLE_KIOSK_IS_ACTIVE,
            status
        }),
        clearSelectedOptions: () => ({
            type: COURSE_EDITOR_CLEAR_SELECTED_KIOSK_OPTIONS
        }),
        setSelectedCourse: (publicCourseUUID: string, imageUUID: string, courseName: string) => ({
            type: COURSE_EDITOR_SET_SELECTED_KIOSK_COURSE,
            publicCourseUUID,
            imageUUID,
            courseName
        })
    },
    lesson: {
        delete: (index: number) => ({
            type: COURSE_EDITOR_DELETE_LESSON,
            index
        }),
        changeName: (board: string, nameSv: string, nameFi: string, nameEn: string) => ({
            type: COURSE_EDITOR_UPDATE_LESSON_NAME,
            board,
            nameSv,
            nameFi,
            nameEn
        }),
        dragStart: () => ({
            type: COURSE_EDITOR_SET_IS_DRAGGING_LESSON,
            isDragging: true
        }),
        dragEnd: () => ({
            type: COURSE_EDITOR_SET_IS_DRAGGING_LESSON,
            isDragging: false
        }),
    },
    exercise: {
        dragStart: () => ({
            type: COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE,
            isDragging: true
        }),
        dragEnd: () => ({
            type: COURSE_EDITOR_SET_IS_DRAGGING_EXERCISE,
            isDragging: false
        }),
    }
};

export function acquireLockCourse(publicUUID: string) {
    return async (dispatch: DispatchFunction) => {
        const ResourceType = "COURSE";
        const ResourceKey = publicUUID;
        try {
            dispatch(setLoading("ACQUIRE_LOCK"));
            const lock = await apiPost("/locking/acquire", { 
                language: fetchLang(), 
                ResourceType, 
                ResourceKey
            });
            dispatch(actions.locks.addLock(lock));
        } catch (error: any) {
            console.warn(error)
            if (error.StatusCode === 409) {
                dispatch(setError("ACQUIRE_LOCK"));
            } else {
                throw error;
            }
        }
        dispatch(setSuccess("ACQUIRE_LOCK"));
    }
}

function releaseLockCourse(publicUUID: string) {
  return async () => {
    try {
      const lockUUID = store
        .getState()
        .locks.list.find((lock: Lock) => lock.ResourceKey === publicUUID)?.UUID;
      if (!lockUUID) throw Error;
      apiDelete("/locking/release", {
        language: fetchLang(),
        UUID: lockUUID,
      });
    } catch (error) {
      console.warn(error);
    }
  };
}

export function createCourseDraftWithName(name: string, SourceUuid?: string) {
    return async (dispatch: DispatchFunction) => {
        const statusId = "COURSE_EDITOR_DRAFT_WITH_NAME";
        try {
            dispatch(setLoading(statusId));
            const { CourseUuid } = await apiPost("/editor/create-course-draft", { language: fetchLang() });
            const sourceCourse = SourceUuid ? store.getState().courses.list.find((c: Course) => c.UUID === SourceUuid) : undefined;
            await dispatch(getCourseDraft(CourseUuid, true));
            const publicUUID = store.getState().courseEditor.draft.PublicUUID;
            await dispatch(acquireLockCourse(publicUUID));
            if (sourceCourse) {
                const lessons = store.getState().lessons.list.filter((l: Lesson) => l.PublicCourseUUID === sourceCourse.PublicUUID).map(
                    (lesson: Lesson) => {
                        const lessonUUID = uuidv4();
                        return {
                            ...lesson,
                            UUID: lessonUUID,
                            PublicUUID: lessonUUID,
                            Exercises: lesson.Exercises.map((exercise: Exercise) => {
                                    const exerciseUUID = uuidv4();
                                    return {...exercise, UUID: exerciseUUID, PublicUUID: exerciseUUID}
                                })
                            }
                        }
                );
                await dispatch(updateLessons(CourseUuid, lessons))
            } else {
                dispatch(addEmptyLesson());
            }
            
            await dispatch(updateCourseNames([
                {Language: "en-US", Value: name}, 
                {Language: "fi-FI", Value: name}, 
                {Language: "sv-SE", Value: name}
            ]));

            dispatch({type: COURSE_EDITOR_SET_NEW_UUID, uuid: CourseUuid});
            dispatch(setSuccess(statusId));
        } catch (error: any) {
            if (error.StatusCode === 409) {
                dispatch({type: COURSE_EDITOR_SET_DUPLICATE_UUID, uuid: error.Response.Data.Uuid});
            }
            dispatch(setError(statusId));
        }
    };
}



export function createAndGetCourseDraft(SourceUuid: string, publicUUID: string) {
    return async (dispatch: DispatchFunction) => {
        const statusGetDraft = "CREATE_AND_GET_COURSE_DRAFT";
        try {
            dispatch(setLoading(statusGetDraft));
            await dispatch(acquireLockCourse(publicUUID));
            const newUuid = await apiPost("/editor/create-course-draft", { SourceUuid, language: fetchLang() });
            dispatch(courseEditorActions.courseEditor.clearDuplicateUUID());
            await dispatch(getCourseDraft(newUuid.CourseUuid, true));
            dispatch(setSuccess(statusGetDraft));
        } catch (error: any) {
            if (error.StatusCode === 409) {
                dispatch({type: COURSE_EDITOR_SET_DUPLICATE_UUID, uuid: error.Response.Data.Uuid});
                dispatch({type: COURSE_EDITOR_SET_DUPLICATED_PUBLIC_COURSE_UUID, publicUUID});
            }
            dispatch(setError(statusGetDraft));
        }
    }
}

function clearCourseEditor() {
  return (dispatch: DispatchFunction) => {
    dispatch(courseEditorActions.courseEditor.setIsSaved(false));
    dispatch(courseEditorActions.courseEditor.clearDraft());
    dispatch(courseEditorActions.courseEditor.clearUuid());
    dispatch(courseEditorActions.courseEditor.clearDuplicateUUID());
    dispatch(courseEditorActions.courseEditor.clearLastSaved());
    dispatch(courseEditorActions.courseEditor.clearDuplicatedCourseUUID());
    dispatch(
      courseEditorActions.courseEditor.clearExerciseUuidsMovesWithinLesson()
    );
    dispatch(courseEditorActions.courseEditor.showExitWarning(false));
    dispatch(
      courseEditorActions.courseEditor.setPublishAndLeaveConfirmed(false)
    );
  };
}

export function deleteCourseDraft(isExistingOnServer?: boolean) {
    return async (dispatch: DispatchFunction) => {
        const statusMessage = `DELETE_COURSE_DRAFT`;
        try {
            dispatch(setLoading(statusMessage));
            const state = store.getState().courseEditor;
            const UUID = isExistingOnServer ? state.existingDraftUUID : state.draft.UUID;
            if (!store.getState().courseEditor.publishAndLeaveConfirmed) await apiDelete("/editor/delete-draft", { UUID, language: fetchLang() });
            !isExistingOnServer && await dispatch(releaseLockCourse(store.getState().courseEditor.draft.PublicUUID));
            dispatch(clearCourseEditor());
            dispatch(setSuccess(statusMessage));
        } catch (e) {
            dispatch(setError(statusMessage));
        }
    };
}


export function publishCourseDraft(publishAsNew: boolean, draftUUID: string, publicUUID: string, onExit?: boolean) {
    return async (dispatch: DispatchFunction) => {
        const statusMessage = `PUBLISH_COURSE_DRAFT`;
        try {
            dispatch(setLoading(statusMessage));
            dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
            await apiPost("/editor/publish", { uuid: draftUUID, asNew: publishAsNew, language: fetchLang() });
            await dispatch(fetchCourses());
            dispatch({type: COURSE_EDITOR_SET_LAST_SAVED, publicUUID});
            !onExit && dispatch(courseEditorActions.courseEditor.setIsSaved(true));
            onExit && dispatch(clearCourseEditor());
            dispatch(setSuccess(statusMessage));
        } catch (e) {
            dispatch(setError(statusMessage));
        }
    };
}

export function updateLesson(nameSv: string, nameFi: string, nameEn: string, uuid?: string) {
    return async (dispatch: DispatchFunction) => {
        try {
            if (!uuid) throw Error;
            const courseUUID = store.getState().courseEditor.draft.UUID;
            const publicUUID = store.getState().courseEditor.draft.PublicUUID;
            dispatch(courseEditorActions.lesson.changeName(uuid, nameSv, nameFi, nameEn))
            dispatch(setLoading(`UPDATING_LESSON`));
            dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
            await apiPut("/editor/update-lesson", {
                CourseUUID: courseUUID,
                UUID: uuid,
                Names: [{
                        Language: "en-US",
                        Value: nameEn
                    },
                    {
                        Language: "sv-SE",
                        Value: nameSv
                    },
                    {
                        Language: "fi-FI",
                        Value: nameFi
                    }
                ]
            })
            dispatch(setSuccess(`UPDATING_LESSON`));
            dispatch(acquireLockCourse(publicUUID));
        }
        catch (e) {
            dispatch(setError(`UPDATING_LESSON`));   
        }
    }
}

function updateLessonExercises(lessons: Lesson[]) {
  return async (dispatch: DispatchFunction) => {
    try {
      const { PublicUUID, UUID, ExercisesMovedWithinLesson } =
        store.getState().courseEditor.draft;
      dispatch(setLoading(`UPDATING_LESSON_EXERCISES`));
      dispatch({ type: COURSE_EDITOR_CLEAR_LAST_SAVED });
      await apiPut("/editor/update-lesson-exercises", {
        CourseUuid: UUID,
        Lessons: lessons,
        ExercisesMovedWithinLesson,
        language: fetchLang(),
      });
      await dispatch(getCourseDraft(UUID));
      dispatch(setSuccess(`UPDATING_LESSON_EXERCISES`));
      dispatch(acquireLockCourse(PublicUUID));
    } catch (e) {
      dispatch(setError(`UPDATING_LESSON_EXERCISES`));
    }
  };
}

function updateLessons(courseUUID?: string, lessons?: Lesson[]) {
  return async (dispatch: DispatchFunction) => {
    try {
      const publicUUID = store.getState().courseEditor.draft.PublicUUID;
      dispatch(setLoading(`UPDATING_LESSONS_ORDER`));
      dispatch({ type: COURSE_EDITOR_CLEAR_LAST_SAVED });
      await apiPut("/editor/update-course-lessons", {
        CourseUuid: courseUUID ?? store.getState().courseEditor.draft.UUID,
        Lessons: lessons ?? store.getState().courseEditor.draft.Lessons,
        LessonsMoved: store.getState().courseEditor.draft.LessonsMoved,
        language: fetchLang(),
      });
      await dispatch(
        getCourseDraft(courseUUID ?? store.getState().courseEditor.draft.UUID)
      );
      dispatch(setSuccess(`UPDATING_LESSONS_ORDER`));
      dispatch(acquireLockCourse(publicUUID));
    } catch (e) {
      dispatch(setError(`UPDATING_LESSONS_ORDER`));
    }
  };
}

function updateCourseNames(Names: CourseName[]) {
  return async (dispatch: DispatchFunction) => {
    try {
      const Uuid = store.getState().courseEditor.draft.UUID;
      const publicUUID = store.getState().courseEditor.draft.PublicUUID;
      dispatch(setLoading("UPDATE_COURSE"));
      dispatch({ type: COURSE_EDITOR_CLEAR_LAST_SAVED });
      await apiPut("/editor/update-course-translations", {
        Uuid,
        Names,
      });
      await dispatch(getCourseDraft(Uuid));
      dispatch(setSuccess("UPDATE_COURSE"));
      dispatch(acquireLockCourse(publicUUID));
    } catch (e) {
      dispatch(setError("UPDATE_COURSE"));
    }
  };
}

export function updateCourse(IsLinear: boolean, ImageUuid: string) {
    return async (dispatch: DispatchFunction) => {
        try {
            const Uuid = store.getState().courseEditor.draft.UUID;
            const publicUUID = store.getState().courseEditor.draft.PublicUUID;
            dispatch(setLoading("UPDATE_COURSE"));
            dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
            await apiPut("/editor/update-course", {
                Uuid,
                IsLinear,
                ImageUuid
            })
            await dispatch(getCourseDraft(Uuid));
            dispatch(setSuccess("UPDATE_COURSE"));
            dispatch(acquireLockCourse(publicUUID));
        } catch (e) {
            dispatch(setError("UPDATE_COURSE"));
        } 
    }
}

export function updateExercise(exerciseUUID: string, WeatherUuid: string, TrafficUuid: string, Difficulty: 0 | 1 | 2 ) {
    return async (dispatch: DispatchFunction) => {
        try {
            const publicUUID = store.getState().courseEditor.draft.PublicUUID;
            dispatch(setLoading(`UPDATING_EXERCISE_${exerciseUUID}`));
            dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
            dispatch({
                type: COURSE_EDITOR_UPDATE_EXERCISE_SETTINGS,
                exerciseUUID,
                WeatherUuid,
                TrafficUuid,
	            Difficulty
            })
            await apiPut("/editor/update-exercise", {
                Uuid: exerciseUUID,
                WeatherUuid,
                TrafficUuid,
	            Difficulty
            });
            await dispatch(getCourseDraft(store.getState().courseEditor.draft.UUID));
            dispatch(setSuccess(`UPDATING_EXERCISE_${exerciseUUID}`));
            dispatch(acquireLockCourse(publicUUID));
        } catch (e) {
            dispatch(setError(`UPDATING_EXERCISE_${exerciseUUID}`));
        }
    }
}

export function deleteLesson(index: number) {
    return async (dispatch: DispatchFunction) => {
        try {
            dispatch(setLoading(`DELETE_LESSON`));
            dispatch(courseEditorActions.lesson.delete(index));
            dispatch(updateLessons());
            dispatch(setSuccess(`DELETE_LESSON`));
        } catch (e) {
            dispatch(setError(`DELETE_LESSON`));
        }
    }
}

export function cloneLessonFromKiosk(
    result: DropResult,
    lesson: Lesson
) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_CLONE_LESSON_FROM_KIOSK,
            to: result.destination?.droppableId,
            toIndex: result.destination?.index,
            lesson,
            sourceUUID: lesson.UUID
        });
        dispatch(updateLessons());
    }
}

export function moveLesson(result: DropResult) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_MOVE_LESSON,
            from: result.source.droppableId,
            to: result.destination?.droppableId,
            fromIndex: result.source.index,
            toIndex: result.destination?.index,
        });
        dispatch(courseEditorActions.courseEditor.setLessonsMoved(result));
        dispatch(updateLessons());
    }
}

export function cloneExerciseFromKiosk(
    result: DropResult, 
    variantUUID: string,
    vehicleUUID: string,
    weatherUUID: string,
    trafficUUID: string,
    difficulty: number
    ) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK,
            to: result.destination?.droppableId,
            toIndex: result.destination?.index,
            variantUUID,
            vehicleUUID,
            weatherUUID,
            trafficUUID,
            difficulty
        });
        dispatch(updateLessonExercises(store.getState().courseEditor.draft.Lessons));
    }
}

export function cloneExerciseFromExisting(result: DropResult, e: Exercise) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_CLONE_EXERCISE_FROM_KIOSK,
            to: result.destination?.droppableId,
            toIndex: result.destination?.index,
            variantUUID: e.TemplateVariantUUID,
            vehicleUUID: e.VehicleUUID,
            weatherUUID: e.WeatherUUID,
            trafficUUID: e.TrafficUUID,
            difficulty: e.Difficulty,
            name: e.Name
        });
        dispatch(updateLessonExercises(store.getState().courseEditor.draft.Lessons));
    }
}

export function moveExercise(result: DropResult) {
    return (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_MOVE_EXERCISE,
            from: result.source.droppableId,
            to: result.destination?.droppableId,
            fromIndex: result.source.index,
            toIndex: result.destination?.index,
        });
        dispatch(courseEditorActions.courseEditor.setDraftAndInitialDraftIntersection());
        dispatch(courseEditorActions.courseEditor.setExerciseUuidsMovesWithinLesson(result));
        dispatch(updateLessonExercises(store.getState().courseEditor.draft.Lessons));
    }
}

export function duplicateExercise(lessonUuid: string, index: number) {
    return async (dispatch: DispatchFunction) => {
        const uuid = uuidv4();
        dispatch({
            type: COURSE_EDITOR_DUPLICATE_EXERCISE,
            board: lessonUuid,
            index,
            uuid
        });
        dispatch({
            type: COURSE_EDITOR_ADD_DUPLICATED_EXERCISE_TO_DUPLICATED_LIST,
            uuid
        });
        setTimeout(() => dispatch({
            type: COURSE_EDITOR_REMOVE_DUPLICATED_EXERCISE_FROM_DUPLICATED_LIST,
            uuid
        }), 2500);
        dispatch(updateLessonExercises(store.getState().courseEditor.draft.Lessons));
    }
}

export function addEmptyLesson() {
    return async (dispatch: DispatchFunction) => {
        dispatch({ type: COURSE_EDITOR_CREATE_NEW_LESSON});
        dispatch(updateLessons());
    }
}

export function duplicateLesson(lessonUuid: string, index: number) {
    return async (dispatch: DispatchFunction) => {
        const uuid = uuidv4();
        dispatch({
            type: COURSE_EDITOR_DUPLICATE_LESSON,
            board: lessonUuid,
            uuid,
            index
        });
        dispatch({
            type: COURSE_EDITOR_ADD_DUPLICATED_LESSON_TO_DUPLICATED_LIST,
            uuid
        });
        setTimeout(() => dispatch({
            type: COURSE_EDITOR_REMOVE_DUPLICATED_LESSON_FROM_DUPLICATED_LIST,
            uuid
        }), 2500);
        dispatch(updateLessons());
    }
}

export function changeCourseType(isLinear: boolean) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_UPDATE_COURSE_TYPE,
            isLinear
        })
        dispatch(updateCourse(isLinear, store.getState().courseEditor.draft.ImageUUID));
    }
}

export function changeCourseNames(names: CourseName[]) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_SET_COURSE_NAMES,
            names
        })
        await dispatch(updateCourseNames(names));
    }
}

export function removeExercise(lessonUuid: string, index: number) {
    return async (dispatch: DispatchFunction) => {
        dispatch({
            type: COURSE_EDITOR_REMOVE_EXERCISE,
            board: lessonUuid,
            index
        })
        dispatch(updateLessonExercises(store.getState().courseEditor.draft.Lessons));
    }
}

export function getCourseDraft(uuid: string, setInitial?: boolean) {
    return async (dispatch: DispatchFunction) => {
        try {
            dispatch(setLoading("GET_COURSE_DRAFT"));
            const json: CourseDraft = await apiGet(`/editor/draft-with-content`, {uuid, language: fetchLang()});
            const draft: CourseDraft = {
                ...new CourseDraft(),
                ...json,
                Lessons: json.Lessons.map((l: Lesson) => ({
                    ...new Lesson(),
                    ...l,
                    Exercises: l.Exercises.map((e: Exercise) => ({
                        ...new Exercise(),
                        ...e
                    }))
                })) 
            }
            dispatch({ type: COURSE_EDITOR_SET_DRAFT, draft })
            if (setInitial) dispatch({ type: COURSE_EDITOR_SET_INITIAL_DRAFT, draft });
            dispatch(setSuccess("GET_COURSE_DRAFT"));
        } catch (e) {
            dispatch(setError("GET_COURSE_DRAFT"));
        }
    };
}

export function duplicateCourse(uuid: string, publicUUID: string) {
    return async (dispatch: DispatchFunction) => {
        try {
            dispatch(setLoading("DUPLICATE_COURSE"));
            await dispatch(acquireLockCourse(publicUUID));
            dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
            const json = await apiPost(`/editor/duplicate`, {SourceUUID: uuid, language: fetchLang()});
            const { CourseUuid } = json;
            dispatch(releaseLockCourse(publicUUID));
            dispatch(courseEditorActions.courseEditor.setDuplicatedCourseUUID(CourseUuid));
            setTimeout(() => dispatch(courseEditorActions.courseEditor.clearDuplicatedCourseUUID()), 2000);
            dispatch(fetchCourses());
            dispatch(fetchLessons());
            dispatch(fetchExercises());
            dispatch(setSuccess("DUPLICATE_COURSE"));
        } catch (e) {
            dispatch(setError("DUPLICATE_COURSE"));
        }
    };
}

export function exportCourse(uuid: string) {
    return async () => {
        try {
            const json = await apiGet(`/course/export/${uuid}`, {language: fetchLang()});
            downloadObjectAsJson(json, uuid);
        } catch (e) {
            console.log(e)
        }
    }
}

export function getExerciseOptions() {
    return async (dispatch: DispatchFunction) => {
        try {
            const json = await apiGet(GET_EXERCISE_OPTIONS, { language: fetchLang() });
            dispatch({ type: COURSE_EDITOR_SET_EXERCISE_OPTIONS, options: json });
        } catch (e) {
            console.log(`Uncaught exception while getting exercise options: ${e}`);
        }
    }
}

export function getVehicleImages() {
    return async (dispatch: DispatchFunction) => {
        try {
            const json = await apiGet(GET_COURSE_IMAGES, { language: fetchLang() });
            dispatch({ type: COURSE_EDITOR_SET_VEHICLE_IMAGES, imageUUIDs: json })
        } catch (e) {
            console.warn(`Uncaught exception while getting vehicle images: ${e}`);
        }
    }
}

export default courseEditorActions;