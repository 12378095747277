import { useEffect } from "react";
import { ModalsProvider } from "@mantine/modals";
import { GlobalStyles } from "./resources/styles/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./reducers";
import Routes, {
  ACADEMY_DETAILS_PATH,
  ACADEMY_PATH,
  ACTIVATE_PATH,
  ADMIN_PATH,
  COURSES_PATH,
  DRAFT_PATH,
  GLOBAL_TOPLIST_PATH,
  GLOBAL_USERS_PATH,
  HOME_PATH,
  MAP_PATH,
  ORGANIZATIONS_PATH,
  SCHOOL_CLASSES_PATH,
  SIMULATORS_PATH,
  STATS_SIMULATORS_PATH,
  SUPPORT_PATH,
  USERS_PATH,
  COURSE_EDITOR_STATS_PATH,
  RUN_DATA_PATH,
  TICKETS_PATH,
  TICKET_PATH,
} from "./navigation/Routes";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import actions, {
  checkUserSession,
  fetchAll,
  getOrganizationsAndSchools,
} from "./actions";
import {
  CookiesModal,
  EmptyState,
  FeedbackModal,
  LicenseAgreementModal,
  PageLoader,
} from "./components/global/Misc/Misc.components";
import Header from "./components/global/Header/Header";
import Login from "./components/pages/Login/Login";
import {
  MobileMenu,
  PrintLogo,
} from "./components/global/Header/Header.components";
import { useTranslation } from "react-i18next";
import Footer from "./components/global/Footer/Footer";
import { AppWrapper } from "./components/global/Containers/Containers.styled.components";
import ActivateAcccount from "./components/pages/ActivateAccount/ActivateAccount";
import Welcome from "./components/pages/Welcome/Welcome";
import ReactGA from "react-ga";
import CourseEditor from "./components/pages/CourseEditor/CourseEditor";
import {
  fetchLang,
  isAdminUser,
  isDevel,
  PageView,
  searchString,
} from "./helpers/universal.helper";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { get } from "lodash";
import { Messages } from "./App.components";
import { IllustrationSomethingWrong } from "./components/global/Misc/Symbols.components";
import { ContactPerson } from "./components/global/Footer/Footer.components";
import TicketDetails from "./components/pages/Admin/TicketDetails/TicketDetails";
import { useQueryClient } from "react-query";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { localStorageGet } from "./helpers/localstorage.helper";
import { SpotlightAction, SpotlightProvider } from "@mantine/spotlight";
import Presentation from "./components/pages/Home/Academy/components/Presentation";
import { skillsterMantineTheme } from "./theme/theme";
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";
import { onAuthStateChanged } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { FIREBASE_CONFIG_ADMIN, FIREBASE_CONFIG_DEV } from "./firebase.config";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-169044839-1");
  Sentry.init({
    dsn: "https://4c23de986ca64ecd98cec42e47e5da70@o1043637.ingest.sentry.io/6025082",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    beforeSend(event) {
      const regex = new RegExp(
        /(token|school_id|user_id|organization_id)=([0-9a-z]+)(&|(?="))/gm
      );
      let string = JSON.stringify(event.breadcrumbs);
      event.breadcrumbs = JSON.parse(string.replace(regex, "$1=&"));
      return event;
    },
  });
}

export const app = initializeApp(
  isDevel() ? FIREBASE_CONFIG_DEV : FIREBASE_CONFIG_ADMIN
);
export const db = getFirestore(app);
export const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (!isAdminUser()) return;

  if (user) {
    // console.log(user.getIdTokenResult().then((idTokenResult) => { console.log(idTokenResult.claims) }))
    user.getIdTokenResult().then((idTokenResult) => {
      if (
        Number(idTokenResult?.claims?.user_id) !==
        Number(localStorage.getItem("user_id"))
      ) {
        localStorage.removeItem("authentication_token");
        localStorage.setItem("status", "logged out");
      }
    });
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    // const uid = user.uid;
  }
});

function App() {
  const { ready } = useTranslation("common");
  const { t } = useTranslation();
  const authState = useSelector((state: RootState) => state.auth);
  const organizationState = useSelector(
    (state: RootState) => state.organization
  );
  const modalsState = useSelector((state: RootState) => state.modals);
  const mobileMenuState = useSelector((state: RootState) => state.mobileMenu);
  const successState = useSelector((state: RootState) => state.success);
  const messagesState = useSelector((state: RootState) => state.messages);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const cookiesIsOpen = get(modalsState, "cookies", false);
  const feedbackIsOpen = get(modalsState, "feedback", false);
  const licenceAgreementIsOpen = get(modalsState, "licenceAgreement", false);

  const feedbackSuccess = get(successState, "POST_TO_FRESHDESK", false);

  const closeFeedbackModal = () =>
    dispatch(actions.modals.setIsOpen("feedback", false));
  const closeLicenceAgreementModal = () =>
    dispatch(actions.modals.setIsOpen("licenceAgreement", false));
  const onLogOut = () => dispatch(actions.auth.logout());
  const closeMobileMenu = () => dispatch(actions.mobileMenu.setIsOpen(false));
  const closeCookiesModal = () =>
    dispatch(actions.modals.setIsOpen("cookies", false));
  const isActivationPage = window.location.pathname === ACTIVATE_PATH;
  const isExerciseDetailsPage = window.location.pathname.startsWith("/lesson");
  const isEditCourse = window.location.pathname.includes("course-editor");
  const isTicket = /^.*\/admin\/tickets\/\d*$/.test(window.location.pathname);
  const isAcademyDetailsPath = window.location.pathname.includes(
    `${ACADEMY_PATH}/`
  );
  const queryClient = useQueryClient();
  const schoolId = localStorageGet("school_id");
  const lang = fetchLang();

  if (process.env.REACT_APP_API_URI === "https://devel.skillster.se") {
    document.title = "[Labs] Lärarverktyget";
  }

  useEffect(() => queryClient.clear(), [schoolId, lang]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (feedbackSuccess) dispatch(actions.modals.setIsOpen("feedback", false));
  }, [dispatch, feedbackSuccess]);

  useEffect(() => {
    if (authState.isLoggedIn) {
      dispatch(fetchAll());
      dispatch(
        getOrganizationsAndSchools(
          Number(localStorage.getItem("organization_id"))
        )
      );
    }
  }, [authState.isLoggedIn, dispatch]);

  useEffect(() => {
    function clearFilters() {
      dispatch(actions.filter.clearFilters());
    }
    if (!isActivationPage) dispatch(checkUserSession());
    !isExerciseDetailsPage && window.scrollTo(0, 0);
    PageView();
    return () => clearFilters();
  }, [location, dispatch, isActivationPage, isExerciseDetailsPage]);

  const spotlightActions: SpotlightAction[] = [
    {
      title: "Hem",
      // description: "Gå till startsidan",
      onTrigger: () => history.push(HOME_PATH),
    },
    {
      title: "Grupper",
      onTrigger: () => history.push(SCHOOL_CLASSES_PATH),
    },
    {
      title: "Användare",
      onTrigger: () => history.push(USERS_PATH),
    },
    {
      title: "Kurser",
      onTrigger: () => history.push(COURSES_PATH),
    },
    {
      title: "Simulatorer",
      onTrigger: () => history.push(SIMULATORS_PATH),
    },
    {
      title: "Support",
      onTrigger: () => history.push(SUPPORT_PATH),
    },
    {
      title: "Statistik",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(ADMIN_PATH),
    },
    {
      title: "Simulatorer",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(STATS_SIMULATORS_PATH),
    },
    {
      title: "Användare",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(GLOBAL_USERS_PATH),
    },
    {
      title: "Organisationer",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(ORGANIZATIONS_PATH),
    },
    {
      title: "Kartan",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(MAP_PATH),
    },
    {
      title: "Topplistan",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(GLOBAL_TOPLIST_PATH),
    },
    {
      title: "Ärenden",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(TICKETS_PATH),
    },
    {
      title: "Kördata",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(RUN_DATA_PATH),
    },
    {
      title: "Skapade kurser",
      keywords: ["admin"],
      description: "Admin only",
      onTrigger: () => history.push(COURSE_EDITOR_STATS_PATH),
    },
  ];

  const showWelcome = !authState.hasSeenWelcomeScreen;
  if (
    (!isActivationPage &&
      !authState.isLoggedIn &&
      authState.isCheckingSession) ||
    !ready
  )
    return <PageLoader />;

  return (
    <MantineProvider theme={skillsterMantineTheme}>
      <SpotlightProvider
        actions={isAdminUser() ? spotlightActions : []}
        searchPlaceholder="Sök..."
        shortcut={isAdminUser() ? "mod + k" : null}
        nothingFoundMessage="Hittade inget"
        highlightQuery
        disabled={false}
        filter={(query, actions) =>
          actions.filter((action) =>
            searchString(`${action.title} ${action.description}`, query)
          )
        }
      >
        <NotificationsProvider position="top-center">
          <ModalsProvider>
            <GlobalStyles isLoginScreen={!authState.isLoggedIn} />
            <CookiesModal isOpen={cookiesIsOpen} onClose={closeCookiesModal} />
            <LicenseAgreementModal
              isOpen={licenceAgreementIsOpen}
              onClose={closeLicenceAgreementModal}
            />
            <Messages messages={messagesState.messages} />
            {isEditCourse ? (
              <Switch>
                <Route exact path={DRAFT_PATH} component={CourseEditor} />
              </Switch>
            ) : isActivationPage ? (
              <Route component={ActivateAcccount} />
            ) : authState.isLoggedIn ? (
              isTicket ? (
                <Route path={TICKET_PATH} component={TicketDetails} />
              ) : showWelcome ? (
                <Route component={Welcome} />
              ) : isAcademyDetailsPath ? (
                <Switch>
                  <Route path={ACADEMY_DETAILS_PATH} component={Presentation} />
                </Switch>
              ) : (
                <>
                  <AppWrapper>
                    <section className="appHeader">
                      <Header />
                      <PrintLogo />
                    </section>
                    <main>
                      {organizationState.noSchoolsInOrganization ? (
                        <EmptyState className="empty-state">
                          <IllustrationSomethingWrong />
                          <div className="description">
                            <h1 className="huge">
                              {t("noSchoolInOrganization.title")}
                            </h1>
                            <h2 className="subtitle">
                              {t("noSchoolInOrganization.description")}
                            </h2>
                          </div>
                          <ContactPerson className="contact" />
                        </EmptyState>
                      ) : (
                        <Routes />
                      )}
                    </main>
                    <section className="appFooter">
                      <Footer />
                    </section>
                  </AppWrapper>
                  <FeedbackModal
                    isOpen={feedbackIsOpen}
                    onClose={closeFeedbackModal}
                  />
                  <MobileMenu
                    isOpen={mobileMenuState.isOpen}
                    onLogOut={onLogOut}
                    closeMobileMenu={closeMobileMenu}
                  />
                </>
              )
            ) : (
              <Route component={Login} />
            )}
          </ModalsProvider>
        </NotificationsProvider>
      </SpotlightProvider>
    </MantineProvider>
  );
}

export default App;
