import { ContactPersonWrapper } from "./Footer.styled.components";
import per from "./resources/img/per.png";
import ari from "./resources/img/ari.png";
import { useTranslation } from 'react-i18next';
import { fiLocale } from "../../../helpers/universal.helper";

interface ContactPersonProps {
    className?: string;
}
export const ContactPerson = (props: ContactPersonProps) => {
    const { t } = useTranslation();
    return fiLocale() ? (
        <ContactPersonWrapper className={props.className}>
            <img src={ari} alt="Ari" />
            <div className="contactInfo">
                <strong className="notranslate">{t('footer.contactPerson.name')}</strong>
                <span className="phone">{t('footer.contactPerson.phone')}</span>
                <a href={`mailto:${t('footer.contactPerson.email')}`}>{t('footer.contactPerson.email')}</a>
            </div>
        </ContactPersonWrapper>
    ) : (
        <ContactPersonWrapper className={props.className}>
            <img src={per} alt="Per" />
            <div className="contactInfo">
                <strong className="notranslate">{t('footer.contactPer.name')}</strong>
                <span className="phone">{t('footer.contactPer.phone')}</span>
                <a href={`mailto:${t('footer.contactPer.email')}`}>{t('footer.contactPer.email')}</a>
            </div>
        </ContactPersonWrapper>
    )
        ;
}