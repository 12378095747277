import { useMutation } from "react-query";
import { apiPost } from "../../../helpers/requests.helper";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "../../../App";

export function signInWithJwt(token: string) {
  signInWithCustomToken(auth, token)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log(user);
    })
    .catch((error) => {
      //   const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
    });
}

interface LoginMutation {
  email: string;
  password: string;
}

export function useLogin() {
  return useMutation(
    (mutation: LoginMutation) => {
      return apiPost("/authenticate", { email: mutation.email, password: mutation.password });
    },
    {
      onSuccess: async (data) => {
        if (!data.TwoFactorEnabled) {
          await setLoginUserData(data)
        }
      },
    }
  );
}

interface LoginTwoFactorMutation {
  passcode: string;
  token: string;
}

export function useTwoFactorLogin() {
  return useMutation(
    (mutation: LoginTwoFactorMutation) => {
      return apiPost("/authenticate/two-factor", { Passcode: mutation.passcode, Token: mutation.token });
    },
    {
      onSuccess: async (data) => {
        if (!data.TwoFactorEnabled) {
          await setLoginUserData(data)
        }
      },
    }
  );
}

async function setLoginUserData(data: any) {
  localStorage.setItem("status", "logged in");
  localStorage.setItem("authentication_token", data.Token);
  localStorage.setItem("school_id", data.User.SchoolID);
  localStorage.setItem("user_id", data.User.ID);
  localStorage.setItem("schoolclass_name", data.User.SchoolClass);
  localStorage.setItem("first_name", data.User.FirstName);
  localStorage.setItem("last_name", data.User.LastName);
  localStorage.setItem("email", data.User.Email);
  localStorage.setItem("role", data.User.Role);
  localStorage.setItem("organization_id", data.User.OrganizationID);
  if (data.User.Role === 1) {
    try {
      const json = await apiPost("/firebase/token", {});
      signInWithJwt(json.jwt);
    } catch (e) {
      console.error("Error while asking for firebase token:", e);
    }
  }
}


interface ForgotMutation {
    email: string;
}

export function useForgot() {
    return useMutation((mutation: ForgotMutation) => {
        return apiPost("/forgot-password", { email: mutation.email });
    });
}