import { useMutation, useQuery } from "react-query";
import { apiGet, apiPost } from "../../helpers/requests.helper";
import { useLocation } from "react-router-dom";

export function useGithubAuthMutation() {
  const location = useLocation();
  return useMutation(
    ["github-auth"],
    async () => {
      const args = process.env.REACT_APP_IS_LOCAL_HOST ? { redirect_host: "http://localhost:3000" } : null;
      return await apiGet("/oauth/github/connect", args);
    },
    {
      onSuccess: (data) => {
        const stateHash: string | undefined = data?.StateHash;
        if (stateHash) localStorage.setItem("gh_stateHash", stateHash);
        localStorage.setItem("gh_oauth_location", location.pathname);
      },
    }
  );
}

type OAuthError = string;

interface GithubCallbackResponse {
  RedirectTo: string
}

export function useGithubCallback(code?: string | null, state?: string | null) {
  return useQuery<GithubCallbackResponse, OAuthError>(
    ["github-callback"],
    async () => {
      const data = await apiPost(`/oauth/github/callback`, undefined, {
        code,
        state,
        state_hash: localStorage.getItem("gh_stateHash"),
        redirect_host: process.env.REACT_APP_IS_LOCAL_HOST ? "http://localhost:3000" : "",
      });
      const result = { ...data, RedirectTo: localStorage.getItem("gh_oauth_location") }
      localStorage.removeItem("gh_stateHash");
      localStorage.removeItem("gh_oauth_location");
      return result;
    },
    {
      retry: false,
      enabled: !!code && !!state,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
}

export function useRevokeGithubAuth(onSuccess:() => void) {
  return useMutation(
    ["github-revoke"],
    async () => {
      return await apiPost("/oauth/github/revoke", null);
    },
    {
      onSuccess: onSuccess,
    }
  );
}