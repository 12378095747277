import { ExerciseOption } from "./option.types";
import { DetailedProgress } from "./user.types";

class Achievement {
  ID: number = 0;
  Code: string = "";
  Name: string = "";
}
export class Exercise {
  ID: number = 0;
  SortOrder: number = 0;
  UUID: string = "";
  PublicUUID: string = "";
  SourceUUID?: string = "";
  PublicCourseUUID: string = "";
  Name: string = "";
  Description: string = "";
  CourseName: string = "";
  CourseID: number = 0;
  LessonID: number = 0;
  WeatherUUID?: string = "";
  TrafficUUID?: string = "";
  Difficulty?: 0 | 1 | 2 = 0;
  TemplateVariantUUID: string = "";
  TemplateName: string = "";
  ScreenshotName: string = "";
  VehicleUUID: string = "";
  IsValid?: boolean;
  Achievements: Achievement[] = [];
  PublicLessonUUID: string = "";
  EstimatedRuntime: number = 0;
}

export class ExerciseVariant {
  ID: number = 0;
  VariantUUID: string = "";
  ExerciseTemplateUUID: string = "";
  AllowedWeatherOptions: ExerciseOption[] = [];
  AllowedTrafficOptions: ExerciseOption[] = [];
  Description: string = "";
  VehicleUUID: string = "";
  TemplateName: string = "";
  Name: string = "";
  Vehicles: { [guid: string]: string } = {};
  MappedUUID?: string;
  Screenshots: { [uuid: string]: string } = {};
  IdealCompletionTime: number = 0;
  EstimatedRuntimePerVehicle: Record<string, number> = {};
}

export class Lesson {
  ID: number = 0;
  CourseID?: number = 0;
  PublicCourseUUID: string = "";
  PublicUUID: string = "";
  SortOrder: number = 0;
  Name: string = "";
  Exercises: Exercise[] = [];
  UUID: string = "";
  SourceUUID?: string = "";
  DescriptionKey: number = 0;
  IsValid: boolean = false;
  Names: { Language: string; Value: string }[] = [];
  EstimatedRuntime: number = 0;
}

export class Course {
  ID = 0;
  SortOrder = 0;
  Name = "";
  Names: CourseName[] = [];
  UUID = "";
  PublicUUID = "";
  LessonCount = 0;
  IsLinear = true;
  Exercises?: Exercise[];
  RestrictedAccess: boolean = false;
  SourceUUID: string = "";
  OwnerId: number = 0;
  IsDraft: boolean = false;
  Vehicles: string[] = [];
  PublishedBy: number = 0;
  Published: string = "";
  Native: boolean = false;
  ImageUUID: string = "";
  Individual: boolean = false;
}

export class UserExercisesByCourse {
  courseId: number = 0;
  exercisesTotal?: number;
  exercises: DetailedProgress[] = [];
}

export class CourseName {
  Language: string = "";
  Value: string = "";
}

export class CourseDraft {
  Created: string = "";
  CreatedBy: number = 0;
  SourceUUID: string = "";
  OwnerId: number = 0;
  ID: number = 0;
  IsDraft: boolean = true;
  IsLinear: boolean = true;
  IsPublished: boolean = false;
  IsValid: boolean = true;
  Lessons: Lesson[] = [];
  Name: string = "";
  Published: string = "";
  PublishedBy: number = 0;
  RestrictedAccess: boolean = false;
  SortOrder: number = 0;
  UUID: string = "";
  PublicUUID: string = "";
  Names: CourseName[] = [];
  Vehicles: string[] = [];
  ImageUUID: string = "";
  Native?: boolean;
  LessonsMoved: string[] = [];
  ExercisesMovedWithinLesson: string[] = [];
  EstimatedRuntime: number = 0;
}

export interface CourseAccessClipboard {
    allowedOrganiatons: Set<number>;
    originCoursePublicUuid: string;
}