import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { fetchLang } from "../../../helpers/universal.helper";

const styleParagraph = (paragraph: string) => {
    if (paragraph.startsWith('###'))
        return <h3>{paragraph.substr(3)}</h3>;

    if (paragraph.startsWith('##'))
        return <h2>{paragraph.substr(2)}</h2>;

    if (paragraph.startsWith('#'))
        return; // <H1 style={styles.h1}>{paragraph.substr(1)}</H1>;

    if (paragraph.startsWith('**')) {
        paragraph = paragraph.substr(2);
        const boldEndIndex = paragraph.indexOf('*');
        const paragraphNumber = paragraph.substr(0, boldEndIndex);
        const paragraphText = paragraph.substr(boldEndIndex + 2);
        return <span><span className="num">{paragraphNumber}</span>{paragraphText}</span>
    }

    return <span>{paragraph}</span>
}

const styleText = (text: string) => {
    const paragraphs = text.split('\n\n')
    return paragraphs.map((p, index) => <section key={index}>{styleParagraph(p)}</section>)
}


const LicenseAgreement = withRouter((props) => {
    const [licenseText, setLicenseText] = useState("");

    const fetchLicense = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URI}/eula/${fetchLang()}`, { method: "GET" });
        if (response.ok)
            setLicenseText(await response.text());
        // else
        // setLicenseText(licenseFailedToLoadAgreement);
    };

    useEffect(() => { fetchLicense(); });
    return <div>{styleText(licenseText)}</div>;

});

export default LicenseAgreement;
