import { AppShell, BackgroundImage, Button, Center, Container, createStyles, Group, Header, Loader, Modal, Stack, Text, Transition, useMantineTheme } from "@mantine/core";
import { useFullscreen, useHotkeys, useIdle, useScrollLock } from "@mantine/hooks";
import { IconPhoto } from "@tabler/icons";
import { useEffect, useState } from "react";
import { ChevronLeft, Minimize } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { getCloudinaryLectureUrl } from "../../../../../../helpers/strings.helper";
import { fetchLang } from "../../../../../../helpers/universal.helper";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { ACADEMY_PATH, HOME_PATH } from "../../../../../../navigation/Routes";
import { Lecture, Segment } from "../../../../../../types/lecture.types";
import { useLectureProgress, useLectures, useSegmentIsCompleted } from "../../../../Admin/TeacherLectures/TeacherLectures.api";
import Steppers from "../../../components/Steppers";
import AcademyAside from "../../AcademyAside";
import CompletedModal from "../CompletedModal";
import PresentationHeader from "../PresentationHeader";
import usePreloadImages from "./hooks/usePreloadImages";

const useStyles = createStyles((theme, _params, getRef) => ({
    button: {
        backgroundColor: theme.colors.bluegray[0],
        color: theme.colors.bluegray[5],
        height: 40,
        ':hover': {
            backgroundColor: theme.colors.bluegray[1],
        }
    }
}));

interface LocationState {
    fromStartPage?: boolean;
}

function Presentation() {

    const { t } = useTranslation();
    const { lectureId, segmentId } = useParams<Record<string, string>>();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const lecturesQuery = useLectures();
    const isCompleted = useSegmentIsCompleted(Number(lectureId), Number(segmentId));
    const mobile = useIsMobile();

    const progressQuery = useLectureProgress();
    const [suppressModal, setSuppressModal] = useState(false);
    const [showCompletedModal, setShowCompletedModal] = useState(false);

    usePreloadImages(lectureId);

    const HEADER_HEIGHT = 51;
    const ASIDE_WIDTH = 400;
    const { classes } = useStyles();

    const [showControls, setShowControls] = useState(true);
    const idle = useIdle(2000);

    const lecture: Lecture | undefined = lecturesQuery.data?.map[lectureId];
    const segment: Segment | undefined = lecture?.Segments.map[segmentId];
    const imgSelectedLang = segment?.Images[fetchLang()];
    const segmentImageUrl = (lecture && imgSelectedLang) ? getCloudinaryLectureUrl(lecture.UUID, imgSelectedLang) : undefined;

    const currentIndex = lecture?.Segments.list.findIndex(s => s.ID === Number(segmentId));
    const isFirst = currentIndex === 0;
    const isLast = lecture ? currentIndex === lecture?.Segments?.list.length - 1 : false;

    function goToNext() {
        if (typeof currentIndex === "undefined" || !lecture || isLast)
            return;
        history.push(`${ACADEMY_PATH}/${lecture.ID}/${lecture.Segments.list[currentIndex + 1].ID}`);
    }

    function goToPrevious() {
        if (typeof currentIndex === "undefined" || !lecture || isFirst)
            return;
        history.push(`${ACADEMY_PATH}/${lecture.ID}/${lecture.Segments.list[currentIndex - 1].ID}`);
    }

    useEffect(() => {
        if (lecture && progressQuery.isSuccess) {
            const allCompleted = lecture.Segments.list.every(segment => progressQuery.data?.[segment.LectureID]?.some(i => i.LectureSegmentID === segment.ID))
            setShowCompletedModal(allCompleted);
        }
    }, [lecture, progressQuery]);

    useHotkeys([
        ['ArrowUp', goToPrevious],
        ['ArrowLeft', goToPrevious],
        ['ArrowDown', goToNext],
        ['ArrowRight', goToNext],
    ]);

    const { toggle, fullscreen } = useFullscreen();

    const [, setScrollLocked] = useScrollLock();

    useEffect(() => {
        setScrollLocked(true);
        return () => setScrollLocked(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const theme = useMantineTheme();

    if (lecturesQuery.isLoading || lecturesQuery.isIdle)
        return (
            <Loader />
        )

    if (lecturesQuery.isError || !lecture)
        return (
            <>Error</>
        );

    if (mobile)
        return (


            <Container
                fluid
                p="xs"
                sx={{
                    backgroundColor: fullscreen ? theme.colors.bluegray[9] : theme.colors.bluegray[0],
                    position: "relative",
                    height: "100%",
                }}
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
            >


                <Group
                    style={{
                        position: "absolute",
                        top: 0,
                        zIndex: 2,
                        left: 0,
                        width: "100vw"
                    }}
                    px="md"
                    pt="md"
                    position="apart"
                >
                    <Button
                        component={Link}
                        to={ACADEMY_PATH}
                        variant="light"
                        className={classes.button}
                        leftIcon={<ChevronLeft color={theme.colors.bluegray[5]} />}
                        sx={{ flexGrow: 0 }}
                    >
                        {t("common:buttons.back")}
                    </Button>
                    <Steppers
                        onClickNext={goToNext}
                        onClickPrevious={goToPrevious}
                        hidePrevious={isFirst}
                        hideNext={isLast}
                        currentIsCompleted={isCompleted}
                        segmentId={Number(segmentId)}
                    />
                </Group>

                {segmentImageUrl ? (
                    <BackgroundImage
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: !fullscreen ? 0 : undefined,
                        }}
                        src={segmentImageUrl}
                        radius="sm"
                    />
                ) : (
                    <Center sx={{ height: "100%" }}>
                        <Stack align="center" spacing="xs">
                            <IconPhoto color={theme.colors.bluegray[4]} />
                                <Text color="bluegray.5">{t("common:misc.imageMissing")}</Text>
                        </Stack>
                    </Center>
                )}
            </Container>
        )

    return (
        <AppShell
            padding={0}
            hidden={fullscreen}
            fixed
            header={
                <Header
                    height={HEADER_HEIGHT}
                    withBorder={false}
                    px={"xs"}
                >
                    <PresentationHeader
                        backButtonLink={location.state?.fromStartPage ? HOME_PATH : ACADEMY_PATH}
                        toggleFullscreen={toggle}
                    />
                </Header>
            }

            aside={
                <AcademyAside
                    width={ASIDE_WIDTH}
                    lecture={lecture}
                    currentId={Number(segmentId)}
                />
            }
        >
            <Modal
                opened={showCompletedModal && !suppressModal}
                withCloseButton={false}
                centered
                size="xs"
                onClose={() => setSuppressModal(true)}
            >
                <CompletedModal onClose={() => setSuppressModal(true)} />
            </Modal>


            <Container
                fluid
                p="xs"
                sx={{
                    backgroundColor: fullscreen ? theme.colors.bluegray[9] : theme.colors.bluegray[0],
                    position: "relative",
                    height: "100%",
                }}
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
            >
                <Transition
                    mounted={!mobile && (showControls || (fullscreen && !idle))}
                    transition="fade"
                    duration={400}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <Group
                            position={"center"}
                            px="sm"
                            sx={{
                                width: "100%"
                            }}
                            style={{
                                ...styles,
                                position: "absolute",
                                bottom: 25,
                                left: 0,
                                zIndex: 2
                            }}
                        >
                            <Steppers
                                onClickNext={goToNext}
                                onClickPrevious={goToPrevious}
                                hidePrevious={isFirst}
                                hideNext={isLast}
                                currentIsCompleted={isCompleted}
                                segmentId={Number(segmentId)}
                            />
                        </Group>
                    )}
                </Transition>
                <Transition
                    mounted={fullscreen && (!idle || mobile)}
                    transition="fade"
                    duration={400}
                    timingFunction="ease"
                >
                    {(styles) => (
                        <Group
                            style={{
                                ...styles,
                                position: "absolute",
                                top: 25,
                                right: theme.spacing.sm,
                                zIndex: 2
                            }}
                        >
                            <Button
                                onClick={toggle}
                                className={classes.button}
                                pl={15}
                                leftIcon={<Minimize color={theme.colors.bluegray[5]} width={18} strokeWidth={2} />}
                            >
                                {t("academy.presentation.fullscreen.exit")}
                            </Button>
                        </Group>
                    )}
                </Transition>
                {segmentImageUrl ? (
                    <BackgroundImage
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPositionY: !fullscreen ? 0 : undefined,
                        }}
                        src={segmentImageUrl}
                        radius="sm"
                    />
                ) : (
                    <Center sx={{ height: "100%" }}>
                        <Stack align="center" spacing="xs">
                            <IconPhoto color={theme.colors.bluegray[4]} />
                                <Text color="bluegray.5">{t("common:misc.imageMissing")}</Text>
                        </Stack>
                    </Center>
                )}
            </Container>

        </AppShell>
    );
}

export default Presentation;
