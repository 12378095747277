import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import actions from "../../../actions";
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut,
} from "../../../helpers/requests.helper";
import { CourseName } from "../../../types/course.types";

export type CourseCategoryData = {
  Courses: string[];
  ID: number;
  Names: CourseName[];
  SortOrder: number;
};

export function useGetCourseCategories() {
  return useQuery<{
    list: CourseCategoryData[];
    map: Record<number, CourseCategoryData>;
  }>(["get-course-categories"], async () => {
    const list: CourseCategoryData[] = await apiGet("/get-course-categories");
    const map: Record<number, CourseCategoryData> = {};
    list.forEach((d) => (map[d.ID] = d));
    return { list, map };
  });
}

type CreateCategoryMutation = {
  names: {
    Language: "sv-SE" | "en-US" | "fi-FI";
    Value: string;
  }[];
};

export function useCreateCategoryMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    "create-course-category",
    (mutation: CreateCategoryMutation) => {
      return apiPost("/create-category", {
        SortOrder: 10,
        Names: mutation.names,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-course-categories"),
    }
  );
}

type AddCategoryToCourseMutation = {
  id: number;
  publicCourseUuid: string;
};

export function useAddCategoryToCourseMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    "add-category-to-course",
    (mutation: AddCategoryToCourseMutation) => {
      return apiPost("/add-category-course", {
        ID: mutation.id,
        PublicCourseUUID: mutation.publicCourseUuid,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-course-categories"),
    }
  );
}

type RemoveCategoryFromCourseMutation = {
  id: number;
  publicCourseUuid: string;
};

export function useRemoveCategoryFromCourseMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    "add-category-to-course",
    (mutation: RemoveCategoryFromCourseMutation) => {
      return apiDelete("/remove-category-course", {
        ID: mutation.id,
        PublicCourseUUID: mutation.publicCourseUuid,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-course-categories"),
    }
  );
}

type RemoveCategoryMutation = {
  id: number;
};

export function useRemoveCategoryMutation() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(
    "remove-course-category",
    (mutation: RemoveCategoryMutation) => {
      return apiDelete("/remove-category", {
        ID: mutation.id,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries("get-course-categories");
        dispatch(actions.courseCategories.setSelectedId(-1));
      },
    }
  );
}

type UpdateCategoryMutation = {
  id: number;
  sortOrder: number;
  names: { Language: string; Value: string }[];
};

export function useUpdateCategoryMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    "create-course-category",
    (mutation: UpdateCategoryMutation) => {
      return apiPut("/update-category", {
        ID: mutation.id,
        SortOrder: mutation.sortOrder,
        Names: mutation.names,
      });
    },
    {
      onSuccess: () => queryClient.refetchQueries("get-course-categories"),
    }
  );
}
