import { useQuery } from "react-query";
import { apiGet } from "../helpers/requests.helper";
import { fetchLang } from "../helpers/universal.helper";
import { User } from "../types/user.types";

export function useGetUsersFromSchool(schoolId?: number) {
  return useQuery(
    ["users-from-school", schoolId],
    async () => {
      const data = await apiGet("/users-from-school", {
        language: fetchLang(),
        school_id: schoolId ?? undefined,
      });
      const list: User[] = data.Users ?? [];
      const map: Record<string, User> = {};
      list.forEach((u) => (map[u.ID] = u));
      return { list, map };
    },
    {
      staleTime: 20 * 1000,
    }
  );
}
