import styled from "styled-components";

export const LogoWrapper = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
`;

export const SelectLanguageDesktopWrapper = styled.div`
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
    }
`;

export const SelectLanguageMobileWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;