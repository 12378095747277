import { Page } from '../../global/Containers/Containers.styled.components';
import { NotFound } from './PageNotFound.components';

function PageNotFound() {

    return (
        <Page>
            <NotFound />
        </Page>
    )
}

export default PageNotFound;