import { Button, Center, Container, Group, Image, PasswordInput, SimpleGrid, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { FormEvent } from "react";
import { Trans, useTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Link } from "react-router-dom";
import { getCloudinaryVehicleUrl } from "../../../helpers/strings.helper";
import useIsMobile from "../../../hooks/useIsMobile";
import { LOGIN_PATH, MY_SKILLSTER_URL } from "../../../navigation/Routes";
import teacherTheme from "../../../resources/themes/teacher";
import { ContactPerson } from "../../global/Footer/Footer.components";
import { LanguageSelector } from "../../global/Misc/Misc.components";
import logoDark from "../../global/Misc/resources/icons/skillster-logo-dark.svg";
import { IconSmallCheckGreen } from "../../global/Misc/Symbols.components";
import { Logo, SelectLanguageDesktop } from "../Login/Login.components";
import { Checks, VehicleWrapper } from "./ActivateAccount.styled.components";

interface SelectPasswordProps {
    onSubmit: (event: FormEvent) => void;
    setPasswordScore: (score: number) => void;
    onClickLicenseAgreement: () => void;
    onChangePassword: (event: FormEvent) => void;
    password: string;
    score: number;
    name: string;
    title: string;
    buttonText: string;
    buttonTextLoading: string;
    loading: boolean;
}
export const SelectPassword = (props: SelectPasswordProps) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const desktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const mobile = useIsMobile();
    const form = (
        <form onSubmit={props.onSubmit}>
            <Stack spacing="xs">
                <PasswordInput
                    label={t('common:inputLabels.password')}
                    value={props.password}
                    onChange={props.onChangePassword}
                />
                <PasswordStrengthBar
                    password={props.password}
                    shortScoreWord={t('common:passwordScores.tooShort')}
                    onChangeScore={(score) => props.setPasswordScore(score)}
                    barColors={['#ddd', '#ef4836', '#f6b44d', teacherTheme.colors.primary.normal, teacherTheme.colors.primary.normal]}
                    scoreWords={[t('common:passwordScores.one'), t('common:passwordScores.two'), t('common:passwordScores.three'), t('common:passwordScores.four'), t('common:passwordScores.five')]}
                />
                <Text size="sm">
                    <Trans i18nKey="activateAccount.licenseAgreement">
                        text <span onClick={props.onClickLicenseAgreement} className="link underscore"> link</span> text
                    </Trans>
                </Text>
                <Button
                    type="submit"
                    disabled={props.score < 2}
                    loading={props.loading}
                >
                    {props.buttonText}
                </Button>
            </Stack>
        </form>
    );
    if (!desktop)
        return (
            <Stack p="sm">
                <Group align="center" position="apart">
                    <Image src={logoDark} width={150} />
                    <LanguageSelector />
                </Group>
                <Title order={3}>{props.title}</Title>
                {form}
            </Stack>
        );

    return (
        <div style={{ width: "100vw", height: "100vh" }}>
            <Logo />
            <SelectLanguageDesktop />
            <Center style={{ height: "100%" }}>
                <Container size="lg" >
                    <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                        <Stack spacing="md" justify="center">
                            <Text weight="bold" color="bluegray.5">{t('activateAccount.selectPassword.greeting', { name: props.name })}</Text>
                            <Title size={45}>{props.title}</Title>
                            {form}
                        </Stack>
                        {desktop && <Vehicle />}
                    </SimpleGrid>
                </Container>
            </Center>
        </div>
    );
}

const Vehicle = () =>
    <VehicleWrapper>
        <Center style={{ height: "100%" }}>
            <img src={getCloudinaryVehicleUrl("ab238dc6babc21d4088d35212042a51f", 570)} alt="Skillster" />
        </Center>
    </VehicleWrapper>


export const ConfirmationPasswordResetTeacher = () => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    return (
        <>
            <Logo />
            <SelectLanguageDesktop />
            <Container size="lg">
                <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                    <Stack spacing="md" justify="center">
                        <Title size={45}>{t('activateAccount.confirmationResetTeacher.title')}</Title>
                        <Text>{t('activateAccount.confirmationResetTeacher.description')}</Text>
                        <Link to={LOGIN_PATH}><Button>{t('activateAccount.confirmationResetTeacher.button')}</Button></Link>
                        <ContactPerson className="contactPerson" />
                    </Stack>
                    <Vehicle />
                </SimpleGrid>
            </Container>
        </>
    );
}

interface ConfirmationPasswordResetStudentProps {
    name: string;
}

export const ConfirmationPasswordResetStudent = (props: ConfirmationPasswordResetStudentProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    return (
        <>
            <Logo />
            <SelectLanguageDesktop />
            <Container size="lg">
                <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                    <Stack spacing="md" justify="center">
                        <Title size={45}>{t('activateAccount.confirmationResetStudent.title')}</Title>
                        <Text>{t('activateAccount.confirmationResetStudent.description')}</Text>
                        <a href={MY_SKILLSTER_URL}><Button>{t('activateAccount.confirmationResetStudent.button')}</Button></a>
                    </Stack>
                    <Vehicle />
                </SimpleGrid>
            </Container>
        </>
    );
}

interface ConfirmationActivationTeacherProps {
    name: string;
}

export const ConfirmationActivationTeacher = (props: ConfirmationActivationTeacherProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    return (
        <>
            <Logo />
            <SelectLanguageDesktop />

            <Container size="lg">
                <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                    <Stack spacing="md" justify="center">
                        <Title size={45}>{t('activateAccount.confirmationActivationTeacher.greeting', { name: props.name })}</Title>
                        <Text>{t('activateAccount.confirmationActivationTeacher.description')}</Text>
                        <Checks>
                            <div className="check">
                                <IconSmallCheckGreen /> {t('activateAccount.confirmationActivationTeacher.checks.one')}
                            </div>
                            <div className="check">
                                <IconSmallCheckGreen /> {t('activateAccount.confirmationActivationTeacher.checks.two')}
                            </div>
                            <div className="check">
                                <IconSmallCheckGreen /> {t('activateAccount.confirmationActivationTeacher.checks.three')}
                            </div>
                        </Checks>
                        <Link to={LOGIN_PATH}><Button>{t('activateAccount.confirmationActivationTeacher.button')}</Button></Link>
                        <ContactPerson className="contactPerson" />
                    </Stack>
                    <Vehicle />
                </SimpleGrid>
            </Container>
        </>
    );
}

interface ConfirmationActivationStudentProps {
    name: string;
}

export const ConfirmationActivationStudent = (props: ConfirmationActivationStudentProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    return (
        <>
            <Logo />
            <SelectLanguageDesktop />
            <Container size="lg">
                <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                    <Stack spacing="md" justify="center">
                        <Title size={45}>{t('activateAccount.confirmationActivationStudent.greeting', { name: props.name })}</Title>
                        <Text>{t('activateAccount.confirmationActivationStudent.description')}</Text>
                        <a href={MY_SKILLSTER_URL}><Button>{t('activateAccount.confirmationActivationStudent.button')}</Button></a>
                    </Stack>
                    <Vehicle />
                </SimpleGrid>
            </Container>
        </>
    );
}

export const InvalidToken = () => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    return (
        <>
            <Logo />
            <SelectLanguageDesktop />
            <Container size="lg">
                <SimpleGrid cols={mobile ? 1 : 2} mih="100vh" pt={mobile ? 70 : 0}>
                    <Stack spacing="md" justify="center">
                        <Title size={45}>{t('activateAccount.invalidToken.title')}</Title>
                        <Text>{t('activateAccount.invalidToken.helperText')}</Text>
                        <Link to={{
                            pathname: LOGIN_PATH,
                            state: {
                                forgot: true
                            }
                        }}><Button>{t('activateAccount.invalidToken.button')}</Button></Link>
                        <ContactPerson className="contactPerson" />
                    </Stack>
                    <Vehicle />
                </SimpleGrid>
            </Container>
        </>
    );
}
