import styled, { css } from "styled-components";
import { animateListCards, animateProgressBar, animateSquareCards, flipLoader, skeletonSwoosh, spin } from "../../../resources/styles/animations";
import { Page } from "../Containers/Containers.styled.components";
import { getNthChildStylingGrid, getNthChildStylingList } from "../../../helpers/universal.helper";

interface SmallSpinnerProps {
    color?: string;
}
export const SmallSpinner = styled.div<SmallSpinnerProps>`
    margin-left: 10px;
    width: 1em;
    height: 1em;
    position: relative;
    color: ${props => props.color ?? props.theme.colors.primary.normal};
    display: inline-block;
    box-sizing: content-box;
    transform-origin: 50% 50%; transform-box: fill-box;
    animation: ${spin} 1s infinite linear;
    :after {
        content: " ";
        display: block;
        width: 2em;
        height: 2em;
        box-sizing: border-box;
        transform-origin: 0em 0em;
        transform: translateZ(0) scale(0.5);
        backface-visibility: hidden;
        border-radius: 50%;
        border: 0.3em solid currentColor;
        border-left-color: transparent;
    }
`;

export const FlipLoaderContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* height: 700px; */
    img {
        width: 80px;
    }
`;

export const FlipLoader = styled.img`
    animation: ${flipLoader} 1s infinite linear;
`;

export const PageLoaderWrapper = styled.div`
    width: 100%;
    height: 50vh;
`;

export const ProgressBarWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .text {
        width: 65px;
        height: 26px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        .inner {
            display: flex;
            align-items: center;
            margin-right: 10px;
            padding: 0 7px;
            width: auto;
            text-align: right;
        }
    }
    &.quota {
        .text {
            width: auto;
            flex-grow: 1;
        }
    }
    &.outlier {
        .text .inner {
            background-color: ${props => props.theme.colors.comp1.lighter};
            border-radius: ${props => props.theme.border.radius.medium};
            color: ${props => props.theme.colors.comp1.dark};
            font-weight: 700;
            margin-left: 7px;
            em {
                color: ${props => props.theme.colors.comp1.dark};
                font-weight: 700;
            }
        }
    }
`;

interface BarProps {
    percent: number;
    small?: boolean;
}

export const Bar = styled.div<BarProps>`
    position: relative;
    display: flex;
    height: ${props => props.small ? "5px" : "8px"};
    width: 100%;
    background-color: ${props => props.theme.colors.comp4.light};
    border-radius: ${props => props.theme.border.radius.large};
    overflow: hidden;
    div {
        border-top-right-radius: ${props => props.theme.border.radius.large};
        border-bottom-right-radius: ${props => props.theme.border.radius.large};
        width: ${props => Math.floor(props.percent * 100)}%;
        height: 100%;
        background-color: ${props => props.theme.colors.primary.normal};
    }
    &:not(.disable-animation) {
        div {
            animation: ${animateProgressBar} 900ms ease-out;
        }
    }
    &.outlier div {
        background-color: ${props => props.theme.colors.comp1.normal};
    }
`;

interface InitialsBadgeWrapperProps {
    isOnline?: boolean;
    showHover?: boolean;
}

export const InitialsBadgeWrapper = styled.div<InitialsBadgeWrapperProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.comp4.lighter};
    &.inverted {
        background-color: white;
    }
    font-family: "Open Sans", "sans-serif";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: ${props => props.theme.colors.comp4.dark};
    cursor: default;
    ${props => props.showHover && css`
        cursor: pointer;
        &:hover {
            border: 2px solid ${props => props.theme.colors.primary.normal};
        }
    `}
    .dot {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 10px;
        height: 10px;
        background-color: ${props => props.isOnline ? props.theme.colors.secondary.normal : props.theme.colors.comp4.mid};
        border-radius: 50%;
    }
`;

export const SubBarWrapper = styled.div`
    display: flex;
    background-color: white;
    height: 40px;
    .badge { display: none; }
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        .badge { display: flex; }
        height: 56px;
        /* margin-bottom: 20px; */
    }
    h2 {
        margin: 0;
    }
    .content {
        width: 100%;
        display: flex;
        align-items: center;
        .userTitle {
            display: flex;
            align-items: center;
            >:first-child {
                margin-right: 15px;
            }
        }
        .box {
            flex: 1;
            display: flex;
            justify-content: center;
            @media (max-width: ${props => props.theme.breakpoints.mobile}) {
                :last-child {
                    flex-grow: 0;
                }
            }
            &:first-child > * { margin-right: auto; }
            &:last-child > * { margin-left: auto; }

            &.search {
                display: none;
                width: 0;
                @media (min-width: ${props => props.theme.breakpoints.mobile}) {
                    display: initial;
                    width: auto;
                    flex-grow: 0;
                }
            }
        }
    }
`;

export const MobileSubBarSearchBar = styled.div`
    margin: 5px 0 10px 0;
    display: flex;
    position: relative;
    > div {
        flex-grow: 1;
    }
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        display: none;
    }
`;

export const NoSearchResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    h2 {
        margin: 20px 0;
    }
    img {
        width: 150px;
        height: auto;
    }
`;

export const DropDownWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &.is-open-height {
        height: 380px;
    }
    label {
        margin-bottom: 4px;
    }
`;

export const DropDownStringValueWrapper = styled(DropDownWrapper)`
    width: 200px;
`;

interface DropDownBtnProps {
    isOpen?: boolean;
}
export const DropDownBtn = styled.div<DropDownBtnProps>`
    position: relative;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    user-select: none;
    .spinner {
        margin-left: auto;
    }
    .course-type-icon {
        margin-right: 10px;
    }
    padding: 0 10px;
    border-radius: ${props => props.theme.border.radius.medium};
    box-shadow: 0 0 0 2px ${props => props.theme.colors.comp4.mid};
    ${props => !props.isOpen && css`
        :hover:not(.disabled) {
            box-shadow: 0 0 0 2px ${props => props.theme.colors.comp4.normal};
        }
    `}
    background-color: white;
    .selected {
        color: ${props => props.theme.colors.comp4.dark};
    }
    cursor: pointer;
    span {
        margin-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .chevron {
        position: absolute;
        user-select: none;
        top: 50%;
        right: 15px;
        transform: ${props => props.isOpen ? "rotate(180deg) translateY(50%)" : "rotate(0deg) translateY(-30%)"};
        transition: transform 200ms ease-out;
    }
    ${props => props.isOpen && css`
        box-shadow: 0 0 0 2px ${props.theme.colors.primary.normal} !important;
    `}
    &.disabled {
        cursor: default;
        color: rgba(0,0,0,.4);
    }
    :not(.disabled) {
        font-weight: 600;
        color: ${props => props.theme.colors.comp4.dark};
    }
    &.inverted {
        background-color: ${props => props.theme.colors.comp4.lighter};
        box-shadow: none;
        ${props => !props.isOpen && css`
            :hover:not(.inverted) {
                box-shadow: none;
                background-color: ${props => props.theme.colors.primary.lighter};
            }
        `}
    }
    &.loading {
        cursor: default;
        color: rgba(0,0,0,.4);
    }
`;

interface DropDownItemsWrapperProps {
    hasLabel?: boolean;
}

export const DropDownItemsWrapper = styled.div<DropDownItemsWrapperProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: ${props => props.hasLabel ? "70px" : "50px"};
    width: 100%;
    overflow: hidden;
    max-height: 300px;
    background-color: white;
    z-index: 9;
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.theme.border.radius.medium};
    .empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.colors.comp4.lighter};
        min-height: 200px;
    }
    .filter-wrapper {
        display: flex;
        top: 0;
        padding: 10px;
        .input-filter {
            flex-grow: 1;
            height: 30px;
            background-color: ${props => props.theme.colors.comp4.lighter};
            border-radius: 50px;
            box-shadow: 0;
        }
    }
    .list {
        overflow-y: auto;
        flex: 1;
    }
    li {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 10px;
        user-select: none;
        cursor: pointer;
        img {
            margin-right: 10px;
        }
        :hover {
            background-color: ${props => props.theme.colors.comp4.lighter};
        }
        label {
            font-family: "Open Sans";
            font-weight: 600;
            color: ${props => props.theme.colors.comp4.darker};
        }
        &.selected {
            background-color: ${props => props.theme.colors.primary.lighter};
            label {
                color: ${props => props.theme.colors.primary.normal};
                font-weight: bold;
            }
        }
    }
`;

export const DropdownVehicleThumbWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: ${props => props.theme.colors.comp4.light};
    border-radius: 50%;
    img {
        height: 90%;
    }
`;

export const MessageWrapper = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background: rgba(0,0,0,.4);
    .window {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 250px;
        max-width: 250px;
        max-height: 95vh;
        overflow-y: auto;
        background-color: white;
        border-radius: ${props => props.theme.border.radius.medium};
        box-shadow: ${props => props.theme.boxShadow};
        padding: 15px;
        .title {
            color: ${props => props.theme.colors.comp4.dark};
            margin-bottom: 10px;
            text-align: center;
        }
        .message {
            color: ${props => props.theme.colors.comp4.normal};
            text-align: center;
        }
        .content {
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            .illustration {
                width: 50%;
                margin: 15px 0 20px 0;
            }
        }
        .buttons {
            display: flex;
            > div {
                flex-grow: 1;
            }
            width: 100%;
            margin-top: 30px;
        }
        button { width: 100%; }
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            padding: 20px;
        }
    }
`;

export const ModalWrapper = styled.div`
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background: rgba(0,0,0,.4);
    :not(.allow-overflow) {
        .window {
            overflow-y: auto;
        }
    }
    .window {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 500px;
        max-height: 95vh;
        overflow-y: scroll;
        margin-top: 40px;
        background-color: white;
        border-radius: ${props => props.theme.border.radius.medium};
        box-shadow: ${props => props.theme.boxShadow};
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            min-height: 200px;
        }
        button.mobile {
            width: 100%;
            margin-top: 30px;
        }
        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 25px;
            padding: 20px 20px 0 20px;
            @media (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 40px 40px 0 40px;
            }
            .close {
                cursor: pointer;
                margin-left: 20px;
                text-decoration: underline;
                display: flex;
                align-items: center;
                height: 33px;
            }
        }
        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 0 20px 20px 20px;
            @media (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 0 40px 40px 40px;
            }
            img {
                max-width: 100%;
                height: auto;
                border-radius: ${props => props.theme.border.radius.medium};
            }
        }
        .buttons {
            width: 100%;
            height: 100px;
            flex-shrink: 0;
            background-color: ${props => props.theme.colors.comp4.lighter};
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 20px;
            margin-top: auto;
            @media (min-width: ${props => props.theme.breakpoints.mobile}) {
                padding: 0 40px;
            }
            .cancel {
                cursor: pointer;
                :hover {
                    color: black;
                }
            }
            button {
                margin-left: 20px;
            }
        }
    }
    &.no-padding {
            .window {
                .content {
                    padding: 0;
                }
            }
        }
    &.wide {
        .window {
            width: 1200px;
        }
    }
    &.tall {
        .window {
            max-height: 90vh;
            @media (min-width: ${props => props.theme.breakpoints.medium}) {
                min-height: 70vh;
            }
            .content {
                flex-grow: 1;
            }
        }
    }
`;

export const SubMenuWrapper = styled.div`
    display: flex;
    background-color: rgb(231, 237, 245);
    min-height: 50px;
    margin-bottom: 15px;
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        margin-bottom: 20px;
        /* margin-top: -20px; */
        padding: 0;
    }
`;

export const SubMenuContent = styled(Page)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 100%;
    height: 100%;
    .arrow {
        margin: 0 12px;
    }
    .breadcrumb {
        label {
            flex-shrink: 0;
        }
    }
    a {
        display: flex;
        align-items: center;
        :not(:last-child, .breadcrumb) { margin-right: 30px; }
        @media (max-width: ${props => props.theme.breakpoints.mobile}) {
            /* margin: 10px; */
        }
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            flex-grow: 0;
            flex-shrink: 0;
        }
        svg {
            margin-bottom: -2px;
            margin-right: 7px;
        }
    }
    .school {
        width: 18px;
        margin-top: -2px;
    }
    .organization {
        width: 16px;
        margin-top: -3px;
    }
    .bugs {
        width: 16px;
        margin-top: -2px;
    }
    .person {
        width: 10px;
        margin-bottom: 1px;
        margin-right: 10px;
    }
    .email {
        width: 16px;
        margin-top: 0px;
    }
    .icon {
        .fill {
            fill: ${props => props.theme.colors.comp4.normal};
        }
        &.active {
            .fill { 
                fill: ${props => props.theme.colors.primary.normal};
            }
        }
    }
    .active label {
        color: ${props => props.theme.colors.primary.normal};
    }
    .fill {
        fill: ${props => props.theme.colors.comp4.normal};
        transition: fill 300ms ease-in-out;
    }
    .active {
        label {
            color: ${props => props.theme.colors.primary.normal};
        }
        .fill {
            fill: ${props => props.theme.colors.primary.normal};
        }
    }
`;

export const SkillsterBadgeWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    flex-grow: 0;
    flex-shrink: 0;
    background: ${props => props.theme.colors.comp4.dark};
    border-radius: 100%;
    img {
        width: 50%;
    }
`;

export const AnimatedList = styled.div`
    .animate-in:not(.disable-animation) > div {
        animation: ${animateListCards} 150ms ease-in-out;
        animation-fill-mode: backwards;
    }
    .animate-in:not(.disable-animation) {
        ${getNthChildStylingList()}
    }
`;

export const AnimatedGridNew = styled.div`
    .simple-grid {
        > div, > a {
            animation: ${animateSquareCards} 150ms ease-in-out;
            animation-fill-mode: backwards;
            ${getNthChildStylingGrid()}
        }
    }    
`;

export const CookiesModalContentWrapper = styled.div`
    > section:not(:last-child) {
        margin-bottom: 20px;
    }
    .subParts {
        margin-top: 20px;
        > section:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;

export const LicenseAgreementModalContentWrapper = styled.div`
    section:not(:last-child) {
        margin-bottom: 20px;
    }
    .num {
        font-weight: 600;
        flex-shrink: 0;
        font-size: 13px;
        font-weight: bold;
        color: ${props => props.theme.colors.comp4.dark};
        border-radius: 50%;
        margin-right: 5px;
    }
`;

export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    img {
        margin-bottom: 30px;
    }
    button {
        margin-top: 10px;
    }
`;

export const ButtonWrapper = styled.div`
    .spinner {
        margin-left: 0;
    }
    .icon {
        fill: white;
        margin-right: 10px;
    }
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    label {
        user-select: none;
    }
    &.disabled {
        opacity: .5;
        cursor: default;
    }
    .checkbox {
        margin-right: 10px;
        .background {
            transition: fill 50ms ease-in-out, stroke 50ms ease-in-out;
        }
        .background {
            fill: white;
            stroke: ${props => props.theme.colors.comp5.mid};
        }
        .check {
            fill: white;
        }
    }
    &.checked {
        label {
            color: ${props => props.theme.colors.comp4.darker};
        }
        .checkbox {
            .background {
                fill: ${props => props.theme.colors.primary.normal};
                stroke: ${props => props.theme.colors.primary.normal};
            }
        }
    }
`;

export const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    label {
        user-select: none;
    }
    .radio {
        margin-right: 10px;
        .background {
            fill: white;
            stroke: ${props => props.theme.colors.comp5.mid};
        }
        .fill {
            fill: white;
        }
    }
    &.selected {
        .background {
            fill: ${props => props.theme.colors.primary.normal};
            stroke: ${props => props.theme.colors.primary.normal};
        }
        .fill {
            fill: white;
        }
    }
`;

export const SkeletonBlocksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    &:not(.no-margin-bottom) {
        > div {
            margin-bottom: 6px;
        }
    }
`;
interface SkeletonBlockWrapperProps {
    height: string;
    randomWidth?: boolean;
}

export const SkeletonBlockWrapper = styled.div<SkeletonBlockWrapperProps>`
    border-radius: ${props => props.theme.border.radius.medium};
    background-color: ${props => props.theme.colors.comp4.lighter};
    height: ${props => props.height};
    position: relative;
    overflow: hidden;
    ${props => props.randomWidth && css`
    width: ${Math.random() * (90 - 50) + 50}%;
    `}
    ::before {
        content: '';
        display: block;
        position: absolute;
        left: -100%;
        top: 0;
        height: 100%;
        width: 50%;
        background: linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(255,255,255,.6) 50%, rgba(255,255,255,0) 100%);
        animation: ${skeletonSwoosh} 1.2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
    &.inverted {
        background-color: ${props => props.theme.colors.comp4.light};
        ::before {
            background: linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(255,255,255,.3) 50%, rgba(255,255,255,0) 100%);
        }
    }
`;

export const LoadingScreenWrapper = styled.div`
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SelectCourseModalInnerWrapper = styled.div`
    position: absolute;
    margin-top: 40px;
    background: white;
    padding: 30px 40px;
    width: 1000px;
    max-width: 90%;
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    border-radius: ${props => props.theme.border.radius.large};
    box-shadow: ${props => props.theme.boxShadow};
`;

export const ModalCourseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: ${props => props.theme.border.radius.large};
    background-color: white;
    cursor: pointer;
    &.selected {
        background-color: ${props => props.theme.colors.primary.lighter};
        box-shadow: 0 0 0 2px ${props => props.theme.colors.primary.normal};
    }
    :hover {
        background-color: ${props => props.theme.colors.comp4.light};
        .course-image {
            transform: scale(1.05);
        }
    }
    .course-image {
        transition: transform 100ms ease-in-out;
        width: 100%;
    }
`;

export const SelectCourseModalGridWrapper = styled.div`
    margin-bottom: 20px;
    .title {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    }
    padding: 20px;
    border-radius: ${props => props.theme.border.radius.large};
    background-color: ${props => props.theme.colors.comp4.lighter};
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
        gap: 10px;
    }
`;

export const SelectCourseModalTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .text {
        display: flex;
        padding-top: 3px;
        flex-direction: column;
        gap: 0px;
    }
    .close {
        margin-left: auto;
        cursor: pointer;
    }
`;

