import { flatten } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { Exercise, Lesson } from "../types/course.types";
import {
  Assessment,
  Assessments,
  AssessmentWithName,
  DetailedProgress,
  ExerciseRunAssessment,
  ExerciseRunResult,
  RunTemp,
} from "../types/user.types";
import { localStorageGet } from "./localstorage.helper";

export function useGetLoggedInUser() {
  const loggedInUserID = Number(localStorageGet("user_id"));
  const firstName = localStorageGet("first_name") ?? "";
  const lastName = localStorageGet("last_name") ?? "";
  return { firstName, lastName, loggedInUserID };
}

export function mapToArray(map: Record<string, number>) {
  if (!map) return null;
  return Object.keys(map).map((key) => ({ id: Number(key), count: map[key] }));
}

export function useGetResultText(result?: ExerciseRunResult) {
  const { t } = useTranslation();
  return result === ExerciseRunResult.Completed
    ? t("common:exerciseRunStatuses.completed")
    : result === ExerciseRunResult.Aborted
    ? t("common:exerciseRunStatuses.aborted")
    : t("common:exerciseRunStatuses.failed");
}

const assessmentIsWarning = (assessment: Assessment) =>
  assessment.Severity === 1 || assessment.Severity === 2;

function useGetWarningsAssessments() {
  const assessmentsState = useSelector((state: RootState) => state.assessments);
  return assessmentsState.assessments?.filter((assessment: Assessment) =>
    assessmentIsWarning(assessment)
  );
}

function useGetPraiseAssessments() {
  const assessmentsState = useSelector((state: RootState) => state.assessments);
  return assessmentsState.assessments?.filter(
    (assessment: Assessment) => assessment.Severity === 3
  );
}

export function useCountWarningsForRun(run: RunTemp) {
  const warningAssessments = useGetWarningsAssessments();
  const warningsCount = run?.Assessments?.List?.filter(
    (exerciseRunAssessment: ExerciseRunAssessment) =>
      warningAssessments?.some(
        (wa) => wa.ID === exerciseRunAssessment.AssessmentID
      )
  ).length;
  return warningsCount;
}

export function useCountWarningsForDetailedProgress(
  detailedProgress: DetailedProgress
) {
  const warningAssessments = useGetWarningsAssessments();
  const warningsCount = flatten(
    flatten(detailedProgress.Runs).map((run: RunTemp) => run.Assessments.List)
  )?.filter((exerciseRunAssessment: ExerciseRunAssessment) =>
    warningAssessments.some(
      (wa) => wa.ID === exerciseRunAssessment.AssessmentID
    )
  ).length;
  return warningsCount;
}

export function useGetPraiseList(run?: RunTemp) {
  const praiseAssessments = useGetPraiseAssessments();
  if (!run) return undefined;
  return run.Assessments.List?.filter(
    (exerciseRunAssessment: ExerciseRunAssessment) =>
      praiseAssessments.some(
        (pa) => pa.ID === exerciseRunAssessment.AssessmentID
      )
  )
    .map((exerciseRunAssessment: ExerciseRunAssessment) =>
      praiseAssessments.find(
        (pa: Assessment) => pa.ID === exerciseRunAssessment.AssessmentID
      )
    )
    .filter((item: Assessment | undefined) => typeof item !== "undefined");
}

export function useGetWarningCountForRun(run: RunTemp) {
  const warningAssessments = useGetWarningsAssessments();
  return run.Assessments.List?.filter(
    (exerciseRunAssessment: ExerciseRunAssessment) =>
      warningAssessments.some(
        (wa) => wa.ID === exerciseRunAssessment.AssessmentID
      )
  ).length;
}

export function useGetSuccessfulAttempts(detailedProgress: DetailedProgress) {
  const successfulCount = detailedProgress.Runs?.reduce(
    (acc: number, curr: RunTemp) =>
      acc + (curr.Result === ExerciseRunResult.Completed ? 1 : 0),
    0
  );
  const totalAttempts = detailedProgress.Runs?.length;
  return { successfulCount, totalAttempts };
}

export function useGetDetailedProgressForCourse() {
  function getDetailedProgress(
    lessons?: Lesson[],
    detailedProgressList?: DetailedProgress[]
  ) {
    const detailedProgressSelectedCourse: Record<string, DetailedProgress[]> =
      {};

    lessons?.forEach((lesson: Lesson) => {
      const p =
        detailedProgressList?.filter((detailedProgress: DetailedProgress) =>
          lesson.Exercises?.some(
            (exercise: Exercise) =>
              exercise.PublicUUID === detailedProgress.PublicExerciseUUID
          )
        ) ?? [];

      detailedProgressSelectedCourse[lesson.PublicUUID] = p;
    });
    return detailedProgressSelectedCourse;
  }

  return getDetailedProgress;
}

export function useGetWarningsFromAssessmentObject(
  runAssessments?: Assessments
) {
  const assessmentsState = useSelector((state: RootState) => state.assessments);
  const warnings: AssessmentWithName[] = [];
  runAssessments?.List?.forEach((assessment: ExerciseRunAssessment) => {
    const source = assessmentsState.assessments.find(
      (a: Assessment) => a.ID === Number(assessment.AssessmentID)
    );
    if (source && assessmentIsWarning(source)) {
      warnings.push({
        id: assessment.AssessmentID,
        name: source.Name,
        timestamp: assessment.Timestamp,
        location: assessment.Location,
        uuid: source.UUID,
      });
    }
  });
  return warnings;
}

export function useFormatHoursToReadable() {
  function format(hours: number) {
    const flooredHours = Math.floor(hours);
    const decimals = hours - flooredHours;
    const flooredMinutes = Math.floor(decimals * 60);
    if (flooredHours === 0) return `${flooredMinutes} min`;
    if (flooredMinutes === 0) return `${flooredHours} tim`;
    return `${flooredHours} tim ${flooredMinutes} min`;
  }
  return format;
}

export const useMessageIsOpen = () => {
  const messagesState = useSelector((state: RootState) => state.messages);
  return messagesState.messages.length > 0;
};
