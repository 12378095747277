import { DispatchFunction, GetStateFunction, AppAction } from "../store";
import { ActionCreator } from "redux";

function generateListActionNames(listName: string) {
    const listNameUpper = listName.toUpperCase();

    const actionStart = `${listNameUpper}_FETCH_START`;
    const actionComplete = `${listNameUpper}_FETCH_COMPLETE`;
    const actionFailed = `${listNameUpper}_FETCH_FAILED`;
    const actionInvalidate = `${listNameUpper}_INVALIDATE`;

    return { actionStart, actionComplete, actionFailed, actionInvalidate }
}

export function generateListActions<Type>(listName: string, getter: (dispatch: DispatchFunction, extraArgs?: unknown) => Promise<Type[]>): ActionCreator<AppAction>[]{
    const { actionStart, actionComplete, actionFailed, actionInvalidate } = generateListActionNames(listName);

    function fetchList(extraArgs?: any) {
        return async (dispatch: DispatchFunction, getState: GetStateFunction) => {
            const rootState: any = getState();
            const state = rootState[listName];
            if (state.isFetching)
                return;

            dispatch({ type: actionStart });

            try {
                const list = await getter(dispatch, extraArgs);
                dispatch({ type: actionComplete, list });
            } catch (error) {
                dispatch({ type: actionFailed, error });
            }
        }
    }

    function invalidateList() {
        return (dispatch: DispatchFunction) => dispatch({ type: actionInvalidate });
    }

    return [ fetchList, invalidateList ];
}

type Map<Type> = { [ID: number | string]: Type };

class ListState<Type> {
  hasData: boolean = false;
  isEmpty: boolean = true;
  isFetching: boolean = false;
  list: Type[] = [];
  map: Map<Type> = {};
};

export function generateListReducer<Type extends {ID: number; PublicUUID?: string;}>(listName: string, type: {new(): Type}) {
    const { actionStart, actionComplete, actionFailed, actionInvalidate } = generateListActionNames(listName);

    function reduceList(state = new ListState<Type>(), action: any): ListState<Type> {
        switch (action.type) {
            case actionStart:
                return {
                    ...state,
                    isFetching: true
                };

            case actionComplete:
                const items = action.list.map((item: Type) => ({...new type(), ...item}));
                const map: Map<Type> = {}
                items.forEach((item: Type) => map[item.PublicUUID ?? item.ID] = item);
                const isEmpty = items.length === 0;
                return {
                    ...state,
                    list: items,
                    map: map,
                    isFetching: false,
                    hasData: true,
                    isEmpty
                }

            case actionFailed:
                return {
                    ...state,
                    isFetching: false,
                }

            case actionInvalidate:
                return {
                    ...state,
                    list: [],
                    map: {},
                    hasData: false,
                    isEmpty: true
                }
        }

        return state;
    }

    return reduceList;
}

export {};