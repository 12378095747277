import { SchoolClassExtraData } from "../components/pages/SchoolClass/SchoolClass.api";
import { Course, Lesson } from "../types/course.types";
import { DrivingRecordOption } from "../types/drivingrecord.types";
import {
  EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES,
  NEW_SCHOOL_CLASS_CLEAR_STATE,
  NEW_SCHOOL_CLASS_SET_CLASS_ID,
  NEW_SCHOOL_CLASS_SET_EXTRA_DATA,
  NEW_SCHOOL_CLASS_SET_LOADING,
  NEW_SCHOOL_CLASS_SET_NAME,
  SELECTED_SCHOOLCLASS_CLEAR_STATE,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION,
  SELECTED_SCHOOLCLASS_SET_CLASS_ID,
  SELECTED_SCHOOLCLASS_SET_COURSE_LIST,
  SELECTED_SCHOOLCLASS_SET_EXTRA_DATA,
  SELECTED_SCHOOLCLASS_SET_MEMBER_LIST,
  SELECTED_SCHOOLCLASS_SET_MINI_STATS,
  SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE,
  SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS,
  SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES,
} from "../types/redux.types";
import {
  DriversLicense,
  EducationType,
  SchoolClassMiniStats,
} from "../types/school.types";
import { User } from "../types/user.types";

class ExtraDataValuesState {
  educationTypes?: EducationType[];
  driversLicenses?: DriversLicense[];
}

export function extraDataValuesReducer(
  state = new ExtraDataValuesState(),
  action: any
): ExtraDataValuesState {
  switch (action.type) {
    case EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES:
      return {
        ...state,
        educationTypes: action.educationTypes,
        driversLicenses: action.driversLicenses,
      };
  }
  return state;
}

class NewSchoolClassState {
  name: string = "";
  isLoading?: boolean = false;
  classID: number = 0;
  extraData?: SchoolClassExtraData;
}

export function newSchoolClassReducer(
  state = new NewSchoolClassState(),
  action: any
): NewSchoolClassState {
  switch (action.type) {
    case NEW_SCHOOL_CLASS_SET_NAME:
      return {
        ...state,
        name: action.name,
      };
    case NEW_SCHOOL_CLASS_SET_LOADING:
      return {
        ...state,
        isLoading: action.status,
      };
    case NEW_SCHOOL_CLASS_SET_CLASS_ID:
      return {
        ...state,
        classID: action.classID,
      };
    case NEW_SCHOOL_CLASS_SET_EXTRA_DATA:
      return {
        ...state,
        extraData: action.extraData,
      };
    case NEW_SCHOOL_CLASS_CLEAR_STATE:
      return new NewSchoolClassState();
  }
  return state;
}

const today = new Date();
const monday = new Date();
monday.setDate(
  monday.getDate() - (today.getDay() === 0 ? 6 : today.getDay() - 1)
);
const firstDayInMonth = new Date();
firstDayInMonth.setDate(1);

class SelectedSchoolClassState {
  classID: number = 0;
  extraData: SchoolClassExtraData | null = null;
  members: User[] = [];
  courses: Course[] = [];
  lessons: Lesson[][] = [];
  miniStats: SchoolClassMiniStats = new SchoolClassMiniStats();
  selectedCourseID: number = 0;
  selectAllCourses = true;
  presencePickerVisible = false;
  presenceRange: { from: Date; to?: Date } = { from: new Date() };
  presenceUsers: User[] = [];
  presenceSelectedOption: "all" | "today" | "week" | "month" | "custom" = "all";
  presenceOptions: DrivingRecordOption[] = [];
}

export function selectedSchoolClassReducer(
  state = new SelectedSchoolClassState(),
  action: any
): SelectedSchoolClassState {
  switch (action.type) {
    case SELECTED_SCHOOLCLASS_SET_CLASS_ID:
      return {
        ...state,
        classID: action.classID,
      };
    case SELECTED_SCHOOLCLASS_SET_COURSE_LIST:
      return {
        ...state,
        courses: action.courses,
      };
    case SELECTED_SCHOOLCLASS_SET_MEMBER_LIST:
      return {
        ...state,
        members: action.members,
      };
    case SELECTED_SCHOOLCLASS_SET_MINI_STATS:
      return {
        ...state,
        miniStats: action.miniStats,
      };
    case SELECTED_SCHOOLCLASS_SET_EXTRA_DATA:
      return {
        ...state,
        extraData: action.data,
      };
    case SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES:
      return {
        ...state,
        selectAllCourses: action.status,
      };
    case SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS:
      return {
        ...state,
        presenceUsers: action.users,
      };
    case SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE:
      return {
        ...state,
        presenceRange: action.range,
      };
    case SELECTED_SCHOOLCLASS_CLEAR_STATE:
      return {
        ...new SelectedSchoolClassState(),
        selectedCourseID: state.selectedCourseID,
      };
    case SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS:
      return {
        ...state,
        presenceOptions: action.options,
      };
    case SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION:
      return {
        ...state,
        presenceSelectedOption: action.selected,
      };
    case SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY:
      return {
        ...state,
        presencePickerVisible: action.status,
      };
  }
  return state;
}
