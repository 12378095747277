import { useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { apiGet } from "../helpers/requests.helper";
import { fetchLang } from "../helpers/universal.helper";
import { SIMULATORS_PATH } from "../navigation/Routes";
import { useGetUsersFromSchool } from "./usersFromSchool";

export function useGetOnlineUsersFromSchool() {
  const usersFromSchoolQuery = useGetUsersFromSchool();
  const comparison = useRef({ curr: 0, prev: 0 });
  const queryClient = useQueryClient();
  return useQuery(
    ["get-school-online-users"],
    async () => {
      const data = await apiGet("/get-school-online-users", {
        language: fetchLang(),
      });
      if (usersFromSchoolQuery.isSuccess) {
        comparison.current.prev = comparison.current.curr;
        comparison.current.curr = data.length;
        return data.map((id: string) => usersFromSchoolQuery.data.map[id]);
      }
      throw Error();
    },
    {
      enabled: !!usersFromSchoolQuery.data,
      refetchInterval: 10 * 1000,
      onSuccess: (data) => {
        if (data.length !== comparison.current.prev && window.location.pathname.includes(SIMULATORS_PATH)) {
          queryClient.refetchQueries("simulators");
        }
      },
    }
  );
}
