import {
  FormEvent,
  useEffect,
  useState,
  useCallback,
  useRef,
  MutableRefObject,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../actions";
import userActions from "../../../actions/user";
import { ErrorResponse } from "../../../helpers/requests.helper";
import { RootState } from "../../../reducers";
import { Redirect, useLocation } from "react-router-dom";
import { HOME_PATH } from "../../../navigation/Routes";
import {
  IconSkillsterSymbolDark,
  IllustrationLoginGroupPeopleMobile,
} from "../../global/Misc/Symbols.components";
import { Trans, useTranslation } from "react-i18next";
import {
  Container,
  Group,
  Text,
  TextInput,
  Title,
  Image,
  useMantineTheme,
  Button as MantineButton,
  Anchor,
  Alert,
  PasswordInput,
  Stack,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import logoMediumDark from "../../../components/global/Misc/resources/icons/skillster-logo-medium-dark.svg";
import illustrationGroupOfPeople from "../../../components/global/Misc/resources/illustrations/login-group-people-mobile.svg";
import { useForgot, useLogin, useTwoFactorLogin } from "./Login.api";
import { LanguageSelector } from "../../global/Misc/Misc.components";
import { ButtonsWrapper } from "./Login.components";

function Login() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const desktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const openCookiesModal = () =>
    dispatch(actions.modals.setIsOpen("cookies", true));
  const openLicenseAgreementModal = () =>
    dispatch(actions.modals.setIsOpen("licenceAgreement", true));
  const location = useLocation<locationState>();
  const loginMutation = useLogin();
  const twoFactorMutation = useTwoFactorLogin();
  const forgotMutation = useForgot();
  const loginInputRef: MutableRefObject<any> = useRef(null);
  const forgotInputRef: MutableRefObject<any> = useRef(null);
  const twoFactorInputRef: MutableRefObject<any> = useRef(null);

  const [wrongCredentials, setWrongCredentials] = useState(false);

  const screenForgot = "forgot";
  const screenTwoFactor = "twoFactor";
  const screenLogin = "login";
  const [screen, setScreen] = useState(screenLogin);
  interface locationState {
    forgot?: boolean;
    twoFactor?: boolean;
  }

  const isForgot = screen === screenForgot;
  const isTwoFactor = screen === screenTwoFactor;

  const [emailEmptyError, setEmailEmptyError] = useState(false);
  const [passwordEmptyError, setPasswordEmptyError] = useState(false);
  const [twoFactorToken, setTwoFactorToken] = useState("");
  const [passcode, setPasscode] = useState("");

  useEffect(() => {
    window.history.replaceState({}, document.title);
    if (location.state && location.state?.forgot) setScreen(screenForgot);
  }, [location.state]);

  const handleEmailChange = (event: FormEvent<Element>) => {
    const target = event.target as HTMLInputElement;
    loginMutation.reset();
    setEmailEmptyError(false);
    dispatch(actions.auth.setFirstName(target.value));
  };

  const handlePasswordChange = (event: FormEvent<Element>) => {
    const target = event.target as HTMLInputElement;
    loginMutation.reset();
    setPasswordEmptyError(false);
    dispatch(actions.auth.setPassword(target.value));
  };

  const handlePasscodeChange = (event: FormEvent<Element>) => {
    twoFactorMutation.reset();
    const target = event.target as HTMLInputElement;
    setPasscode(target.value);
    if (target.value.length >= 6) {
      twoFactorMutation.mutate({
        passcode: target.value,
        token: twoFactorToken,
      });
    }
  };

  const initUser = useCallback(
    (data: any) => {
      dispatch(actions.auth.setHasSeenWelcomeScreen(data.HasSeen));
      dispatch(userActions.user.setUser(data.User));
      dispatch(userActions.user.editUserSetUser(data.User));
      dispatch(actions.contactForms.feedback.setFrom(data.User.Email));
      dispatch(actions.auth.login(data.Token, data.User));
      dispatch(actions.permissions.setLoggedInUserViewConfig(data.ViewConfig));
    },
    [dispatch]
  );

  useEffect(() => {
    if (loginMutation.isSuccess) {
      if (loginMutation.data.TwoFactorEnabled) {
        loginMutation.reset();
        setTwoFactorToken(loginMutation.data.Token);
        setScreen(screenTwoFactor);
      } else {
        initUser(loginMutation.data);
      }
    }
  }, [dispatch, initUser, loginMutation]);

  useEffect(() => {
    if (twoFactorMutation.isSuccess) {
      initUser(twoFactorMutation.data);
    }
    if (twoFactorMutation.isError) {
      setPasscode("");
    }
  }, [dispatch, initUser, twoFactorMutation]);

  useEffect(() => {
    if (loginMutation.error instanceof ErrorResponse) {
      setWrongCredentials(
        loginMutation.error.Response.Reason === "credentials"
      );
    }
  }, [loginMutation.error]);

  useEffect(() => {
    switch (screen) {
      case "login":
        loginInputRef.current?.focus();
        break;
      case "forgot":
        forgotInputRef.current?.focus();
        break;
      case "twoFactor":
        twoFactorInputRef.current.focus();
        break;
    }
  }, [screen]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (authState.email.length === 0) {
      setEmailEmptyError(true);
    }
    if (authState.password.length === 0) {
      setPasswordEmptyError(true);
    }
    if (authState.email.length > 0 && authState.password.length > 0) {
      loginMutation.mutate({
        email: authState.email,
        password: authState.password,
      });
    }
  };

  const handleForgot = async (event: FormEvent) => {
    event.preventDefault();
    forgotMutation.mutate({ email: authState.email });
  };

  const handleTwoFactorSubmit = async (event: FormEvent) => {
    event.preventDefault();
    twoFactorMutation.mutate({ passcode: passcode, token: twoFactorToken });
  };

  const handleTwoFactorGoBack = () => {
    setPasscode("");
    setTwoFactorToken("");
    setScreen(screenLogin);
  };

  if (authState.isLoggedIn) return <Redirect to={HOME_PATH} />;

  const loginForm = (
    <>
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            autoFocus
            ref={loginInputRef}
            type="email"
            label={t("login.inputLabels.email")}
            value={authState.email}
            error={
              emailEmptyError ? t("login.emptyFieldsErrors.email") : undefined
            }
            onChange={handleEmailChange}
          />
          <PasswordInput
            label={t("login.inputLabels.password")}
            value={authState.password}
            error={
              passwordEmptyError
                ? t("login.emptyFieldsErrors.password")
                : undefined
            }
            onChange={handlePasswordChange}
          />
          <ButtonsWrapper desktop={desktop}>
            <MantineButton
              type="submit"
              formNoValidate
              loading={loginMutation.isLoading}
            >
              {loginMutation.isLoading
                ? t("common:buttons.loggingIn")
                : t("common:buttons.logIn")}
            </MantineButton>
            <MantineButton
              variant="subtle"
              onClick={() => setScreen(screenForgot)}
            >
              {t("login.forgotPassword.title")}
            </MantineButton>
          </ButtonsWrapper>
        </Stack>
      </form>
      {loginMutation.isError ? (
        <Alert variant="light">
          {wrongCredentials ? t("login.error") : t("login.updatingServer")}
        </Alert>
      ) : null}

      <Stack>
        <Group
          position={desktop ? undefined : "center"}
          sx={{ order: desktop ? 1 : undefined }}
        >
          <Image
            src={logoMediumDark}
            width={138}
            mt={desktop ? "xl" : undefined}
          />
        </Group>
        <Text
          px={desktop ? 0 : "xl"}
          mt="md"
          size="sm"
          weight={600}
          sx={{
            textAlign: desktop ? undefined : "center",
            order: desktop ? 0 : undefined,
            color: theme.colors.bluegray[5],
          }}
        >
          <Trans i18nKey="login.cookies">
            text
            <Anchor size="sm" onClick={openCookiesModal}>
              cookies
            </Anchor>
            <Anchor size="sm" onClick={openLicenseAgreementModal}>
              terms
            </Anchor>
            .
          </Trans>
        </Text>
      </Stack>
    </>
  );

  const twoFactorForm = (
    <>
      <form onSubmit={handleTwoFactorSubmit}>
        <Stack>
          <TextInput
            ref={twoFactorInputRef}
            autoFocus
            type="text"
            label={t("login.twoFactor.vericationInputLabel")}
            placeholder="XXXXXX"
            value={passcode}
            onChange={handlePasscodeChange}
            disabled={twoFactorMutation.isLoading}
          />
          <ButtonsWrapper desktop={desktop}>
            <MantineButton
              type="submit"
              formNoValidate
              loading={twoFactorMutation.isLoading}
            >
              {t("login.twoFactor.verifyButton")}
            </MantineButton>
            <MantineButton variant="subtle" onClick={handleTwoFactorGoBack}>
              {t("common:buttons.back")}
            </MantineButton>
          </ButtonsWrapper>
        </Stack>
      </form>
      {twoFactorMutation.isError ? (
        <Alert variant="light">{t("login.twoFactor.authFailedAlert")}</Alert>
      ) : null}
      <Stack>
        <Group position={desktop ? undefined : "center"} sx={{ order: 1 }}>
          <Image
            src={logoMediumDark}
            width={138}
            mt={desktop ? "xl" : undefined}
          />
        </Group>
        <Text
          px={desktop ? 0 : "xl"}
          mt="md"
          size="sm"
          weight={600}
          sx={{
            textAlign: desktop ? undefined : "center",
            order: desktop ? 0 : undefined,
            color: theme.colors.bluegray[5],
          }}
        >
          {t("login.twoFactor.description")}
        </Text>
      </Stack>
    </>
  );

  const forgotPasswordForm = (
    <form onSubmit={handleForgot}>
      <Stack>
        <TextInput
          autoFocus
          ref={forgotInputRef}
          label={t("login.inputLabels.email")}
          value={authState.email}
          onChange={handleEmailChange}
        />
        {!desktop ? <Text>{t("login.forgotPassword.description")}</Text> : null}
        <ButtonsWrapper desktop={desktop}>
          <MantineButton type="submit" loading={forgotMutation.isLoading}>
            {forgotMutation.isLoading
              ? t("common:buttons.sending")
              : t("common:buttons.send")}
          </MantineButton>
          <MantineButton
            variant="subtle"
            onClick={() => {
              setScreen(screenLogin);
              forgotMutation.reset();
            }}
          >
            {t("common:buttons.cancel")}
          </MantineButton>
        </ButtonsWrapper>
        {forgotMutation.isSuccess ? (
          <Alert>
            {t("user.edit.activationLink.success", { name: authState.email })}
          </Alert>
        ) : forgotMutation.isError ? (
          <Alert>{t("common:toasts.genericError")}</Alert>
        ) : null}
      </Stack>
    </form>
  );

  if (desktop)
    return (
      <Container
        fluid
        sx={{
          position: "relative",
          minHeight: "calc(100vh - 250px)",
          height: "calc(100% - 250px)",
        }}
      >
        <Group position="right" sx={{ padding: 50 }}>
          <LanguageSelector />
        </Group>
        <Group align="center" sx={{ height: "100%" }}>
          <Container size="lg" sx={{ width: "100%" }}>
            <Group position="apart" align="center" grow>
              <Group grow>
                <Stack sx={{ maxWidth: 360 }}>
                  <Group spacing="xs">
                    {isForgot ? (
                      <>
                        <Title order={1}>
                          {t("login.forgotPassword.title")}
                        </Title>
                        <Text>{t("login.forgotPassword.description")}</Text>
                      </>
                    ) : (
                      <>
                        <IconSkillsterSymbolDark />
                        <Title order={1}>{t("login.title")}</Title>
                        {isTwoFactor ? (
                          <Title order={3} mt="md">
                            {t("login.twoFactor.title")}
                          </Title>
                        ) : null}
                      </>
                    )}
                  </Group>
                  {isForgot
                    ? forgotPasswordForm
                    : isTwoFactor
                    ? twoFactorForm
                    : loginForm}
                </Stack>
              </Group>

              <Group grow position="left">
                <Image
                  src={illustrationGroupOfPeople}
                  alt="Log in"
                  sx={{ maxWidth: 500, marginTop: -100 }}
                />
              </Group>
            </Group>
          </Container>
        </Group>
      </Container>
    );

  return (
    <Container py="lg">
      <Stack spacing="sm">
        <Group position="apart">
          <Group spacing="xs">
            {isForgot ? (
              <Title order={2}>{t("login.forgotPassword.title")}</Title>
            ) : (
              <>
                <IconSkillsterSymbolDark />
                <Title order={2}>{t("login.title")}</Title>
              </>
            )}
          </Group>
          <LanguageSelector />
        </Group>
        <IllustrationLoginGroupPeopleMobile />
        {isTwoFactor ? (
          <Title order={3} mt="md">
            {t("login.twoFactor.title")}
          </Title>
        ) : null}
        {isForgot
          ? forgotPasswordForm
          : isTwoFactor
          ? twoFactorForm
          : loginForm}
      </Stack>
    </Container>
  );
}

export default Login;
