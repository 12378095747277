import { ReactNode } from "react";

export class Option {
  value: number = -1;
  label: string = "";
  icon?: () => ReactNode;
}

export class ExerciseOption {
  Value: string = "";
  Name: string = "";
}
