import { useMutation, useQuery } from "react-query";
import {
  apiGet,
  apiPost,
  apiPut,
  ErrorResponse,
} from "../../../../helpers/requests.helper";
import { User } from "../../../../types/user.types";
import { Course, Exercise, Lesson } from "../../../../types/course.types";

export const MessageTypeNotePrivate = "note:private";
export const MessageTypeNotePublic = "note:public";
export const MessageTypeReply = "reply";

export interface Email {
  Label: string;
  Value: string;
}

export interface Issue {
  ID: number;
  Title: string;
  URL: string;
}

export interface Ticket {
  ID: number;
  User: User;

  Subject: string;
  Description: string;
  DescriptionHtml: string;
  Status: number;
  Priority: number;

  CreationTime: string;
  LastUpdateTime: string;
  Tags: string[];

  Conversation: Message[];
  Emails: Email[];
  Issues: Issue[];

  //Bug report specific fields:
  RespondTo: string;

  BugID: string;
  BugNumber: number;
  AppVersion: string;
  ExerciseName: string;
  PublicExerciseUUID: string;
  CourseName: string;
  PublicCourseUUID: string;
  HasReplay: boolean;
  SystemInfo: string;
}

export interface Message {
  ID: number;
  Body: string;
  BodyHtml: string;
  Type: string;
  Private: boolean;
  Source: number;
  CreationTime: string;
  Agent: Agent;
}

export interface Agent {
  Internal: boolean;
  Name: string;
  Email: string;
}

export function useShowTicket(ticketId: number, onSuccessCallback: any) {
  return useQuery<Ticket, Error>(
    ["show-ticket", ticketId],
    async () => {
      return await apiGet("/tickets/show-ticket", { ticket_id: ticketId });
    },
    {
      onSuccess: onSuccessCallback,
    }
  );
}

interface CreateMessageMutation {
  TicketID: number;
  Type: string;
  Message: string;
  CcEmails: string[];
}

export function useCreateMessage(onSuccess: () => void) {
  return useMutation(
    ["create-message"],
    (mutation: CreateMessageMutation) => {
      return apiPost("/tickets/create-message", {
        TicketID: mutation.TicketID,
        Type: mutation.Type,
        Message: mutation.Message,
        CcEmails: mutation.CcEmails,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
}

interface UpdateTicketMutation {
  ID: number;
  Status: number;
  Priority: number;
}

export function useUpdateTicket() {
  return useMutation(["update-ticket"], (mutation: UpdateTicketMutation) => {
    return apiPut("/tickets/update-ticket", {
      ID: mutation.ID,
      Status: mutation.Status,
      Priority: mutation.Priority,
    });
  });
}

interface LastSeenReplyMutation {
  TicketID: number;
  MessageID: number;
}

export function useUpdateLastSeenReply() {
  return useMutation(
    ["update-last-reply-seen"],
    (mutation: LastSeenReplyMutation) => {
      return apiPut("/tickets/update-last-reply-seen", {
        TicketID: mutation.TicketID,
        MessageID: mutation.MessageID,
      });
    }
  );
}

export function useBugReplay(hasReplay?: boolean, bugId?: string) {
  return useQuery(
    ["get-bug-replay", bugId],
    async () => {
      const data = await apiGet("/admin/get-bug-replay", {
        bugid: bugId,
      });
      return data;
    },
    { enabled: (hasReplay && !!bugId) || false }
  );
}

export function useBugScreenshot(bugId?: string) {
  return useQuery(
    ["get-bug-screenshot", bugId],
    async () => {
      const data = await apiGet("/admin/get-bug-screenshot", {
        bugid: bugId,
      });
      return data;
    },
    {
      enabled: !!bugId,
    }
  );
}

export function useBugConsole(bugId?: string) {
  return useQuery<string, Error>(
    ["get-bug-console", bugId],
    async () => {
      const data = await apiGet("/admin/get-bug-console", {
        bugid: bugId,
      });
      return data;
    },
    {
      enabled: !!bugId,
    }
  );
}

export function usePrevLog(bugId?: string) {
  return useQuery<string, Error>(
    ["prevlog", bugId],
    async () => {
      const data = await apiGet("/admin/get-bug-player-prev-log", {
        bugid: bugId,
      });
      return data;
    },
    {
      enabled: !!bugId,
    }
  );
}

export class SyllabusLesson extends Lesson {
  LessonIndex: number = 0;
}

export class SyllabusExercise extends Exercise {
  ExerciseIndex: number = 0;
}

type BugSyllabusResponse = {
  Course: Course;
  Lesson: SyllabusLesson;
  Exercise: SyllabusExercise;
};

export function useBugSyllabusInfo(publicExerciseUuid?: string) {
  return useQuery<BugSyllabusResponse, Error>(
    ["get-bug-syllabus-info", publicExerciseUuid],
    async () => {
      const data = await apiGet("/admin/get-bug-syllabus-info", {
        publicexerciseuuid: publicExerciseUuid,
      });
      return data;
    },
    {
      enabled: !!publicExerciseUuid,
    }
  );
}

interface ListReposProps {
  Enabled: boolean;
  OnSuccess?: () => void;
  OnError?: (error: ErrorResponse) => void;
}

export interface Repo {
  Id: number;
  Name: string;
}

interface ListReposResponse {
  Repos: Repo[];
}

export function useListRepos(props: ListReposProps) {
  return useQuery<ListReposResponse, ErrorResponse>(
    ["list-repos"],
    async () => {
      const data = await apiGet("/tickets/list-repos");
      return data;
    },
    {
      retry: false,
      enabled: props.Enabled,
      onSuccess: props.OnSuccess,
      onError: props.OnError,
    }
  );
}

export interface CreateIssueMutation {
  TicketID: number;
  Repo: String;
  Title: String;
  Description: String;
}

export function useCreateIssue(onSuccess: any) {
  return useMutation(
    ["create-issue"],
    (mutation: CreateIssueMutation) => {
      return apiPost("/tickets/create-issue", {
        TicketID: mutation.TicketID,
        Repo: mutation.Repo,
        Title: mutation.Title,
        Description: mutation.Description,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
}

interface UnlinkIssueMutation {
  TicketID: number;
  IssueID: number;
}

export function useUnlinkIssue(onSuccess: any) {
  return useMutation(
    ["unlink-issue"],
    (mutation: UnlinkIssueMutation) => {
      return apiPost("/tickets/unlink-issue", {
        TicketID: mutation.TicketID,
        IssueID: mutation.IssueID,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
}

interface LinkIssueMutation {
  Repo: string;
  TicketID: number;
  IssueID: number;
}

export function useLinkIssue(onSuccess: any) {
  return useMutation(
    ["link-issue"],
    (mutation: LinkIssueMutation) => {
      return apiPost("/tickets/link-issue", {
        TicketID: mutation.TicketID,
        Repo: mutation.Repo,
        IssueID: mutation.IssueID,
      });
    },
    {
      onSuccess: onSuccess,
    }
  );
}
