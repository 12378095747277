import * as Sentry from "@sentry/react";
import i18next from "i18next";
import actions, { addMessage, fetchUsers, setError, setLoading, setSuccess } from ".";
import { localStorageGet } from "../helpers/localstorage.helper";
import { apiPost } from "../helpers/requests.helper";
import { POST_ADMIN_REACTIVATE_USER, POST_DELETE_USER } from "../navigation/endpoints";
import { DispatchFunction } from "../store";
import { Course, UserExercisesByCourse } from "../types/course.types";
import { DrivingRecordOption } from "../types/drivingrecord.types";
import {
  DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY,
  DRIVING_RECORD_SET_DAYS_WITH_RUNS,
  DRIVING_RECORD_SET_FROM_DATE,
  DRIVING_RECORD_SET_IS_LOADING,
  DRIVING_RECORD_SET_OPTIONS,
  DRIVING_RECORD_SET_RUNS,
  DRIVING_RECORD_SET_SELECTED_OPTION,
  DRIVING_RECORD_SET_TO_DATE,
  EDIT_USER_SET_EMAIL,
  EDIT_USER_SET_FIRST_NAME,
  EDIT_USER_SET_IS_SAVING,
  EDIT_USER_SET_LAST_NAME,
  EDIT_USER_SET_PASSWORD,
  EDIT_USER_SET_SAVE_ERROR,
  EDIT_USER_SET_SAVE_SUCCESS,
  EDIT_USER_SET_USER,
  NEW_USER_CLEAR_STATE,
  NEW_USER_EMPTY_FIELDS,
  NEW_USER_SET_DUPLICATE_USER,
  NEW_USER_SET_EMAIL,
  NEW_USER_SET_FIRST_NAME,
  NEW_USER_SET_LAST_NAME,
  NEW_USER_SET_ROLE,
  NEW_USER_SET_SCHOOLCLASS_ID,
  SELECTED_USER_CLEAR_STATE,
  SELECTED_USER_SET_DETAILED_PROGRESS,
  SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA,
  SELECTED_USER_SET_IS_DELETED,
  USER_SET_COURSES,
  USER_SET_EXERCISES,
  USER_SET_EXERCISES_BY_COURSE,
  USER_SET_IS_FETCHING_SIMULATOR_DATA,
  USER_SET_TOTAL_FUEL_CONSUMPTION,
  USER_SET_TOTAL_TRAVEL_DISTANCE,
  USER_SET_USER,
} from "../types/redux.types";
import { DetailedProgress, DrivingRecordChartEntry, RunTemp, User } from "../types/user.types";
import schoolClassActions from "./school-class";

const userActions = {
  user: {
    setUser: (user: User) => ({
      type: USER_SET_USER,
      user,
    }),
    setCourses: (courses: Course[]) => ({
      type: USER_SET_COURSES,
      courses,
    }),
    setExercises: (exercises: DetailedProgress[]) => ({
      type: USER_SET_EXERCISES,
      exercises,
    }),
    setExercisesByCourse: (exercisesByCourse: UserExercisesByCourse[]) => ({
      type: USER_SET_EXERCISES_BY_COURSE,
      exercisesByCourse,
    }),
    // setChartData: (chartData: any) => ({
    //     type: USER_SET_CHART_DATA,
    //     chartData
    // }),
    setTotalFuelConsumption: (fuel: number) => ({
      type: USER_SET_TOTAL_FUEL_CONSUMPTION,
      fuel,
    }),
    setTotalTravelDistance: (distance: number) => ({
      type: USER_SET_TOTAL_TRAVEL_DISTANCE,
      distance,
    }),
    setIsFetchingSimulatorData: (status: boolean) => ({
      type: USER_SET_IS_FETCHING_SIMULATOR_DATA,
      status,
    }),
    editUserSetUser: (user: User) => ({
      type: EDIT_USER_SET_USER,
      user,
    }),
    editUserSetFirstName: (firstName: string) => ({
      type: EDIT_USER_SET_FIRST_NAME,
      firstName,
    }),
    editUserSetLastName: (lastName: string) => ({
      type: EDIT_USER_SET_LAST_NAME,
      lastName,
    }),
    editUserSetEmail: (email: string) => ({
      type: EDIT_USER_SET_EMAIL,
      email,
    }),
    editUserSetPassword: (password: string) => ({
      type: EDIT_USER_SET_PASSWORD,
      password,
    }),
    editUserSetIsSaving: (status: boolean) => ({
      type: EDIT_USER_SET_IS_SAVING,
      status,
    }),
    editUserSetSaveSuccess: (status: boolean) => ({
      type: EDIT_USER_SET_SAVE_SUCCESS,
      status,
    }),
    editUserSetSaveError: (status: boolean) => ({
      type: EDIT_USER_SET_SAVE_ERROR,
      status,
    }),
  },
  drivingRecord: {
    setRuns: (runs: RunTemp[]) => ({
      type: DRIVING_RECORD_SET_RUNS,
      runs,
    }),
    setDaysWithRuns: (days: Date[]) => ({
      type: DRIVING_RECORD_SET_DAYS_WITH_RUNS,
      days,
    }),
    setFromDate: (date: Date) => ({
      type: DRIVING_RECORD_SET_FROM_DATE,
      date,
    }),
    setToDate: (date?: Date) => ({
      type: DRIVING_RECORD_SET_TO_DATE,
      date,
    }),
    setPickerVisible: (status: boolean) => ({
      type: DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY,
      status,
    }),
    setIsLoading: (status: boolean) => ({
      type: DRIVING_RECORD_SET_IS_LOADING,
      status,
    }),
    setOptions: (options: DrivingRecordOption[]) => ({
      type: DRIVING_RECORD_SET_OPTIONS,
      options,
    }),
    setSelectedOption: (selected: string) => ({
      type: DRIVING_RECORD_SET_SELECTED_OPTION,
      selected,
    }),
  },
  newUser: {
    setFirstName: (firstName: string) => ({
      type: NEW_USER_SET_FIRST_NAME,
      firstName,
    }),
    setLastName: (lastName: string) => ({
      type: NEW_USER_SET_LAST_NAME,
      lastName,
    }),
    setEmail: (email: string) => ({
      type: NEW_USER_SET_EMAIL,
      email,
    }),
    setSchoolClassID: (schoolClassID: number) => ({
      type: NEW_USER_SET_SCHOOLCLASS_ID,
      schoolClassID,
    }),
    setRole: (role: number) => ({
      type: NEW_USER_SET_ROLE,
      role,
    }),
    setDuplicateUser: (user: User, error: string | null) => ({
      type: NEW_USER_SET_DUPLICATE_USER,
      user,
      error,
    }),
    emptyFields: () => ({
      type: NEW_USER_EMPTY_FIELDS,
    }),
    clear: () => ({
      type: NEW_USER_CLEAR_STATE,
    }),
  },
  selectedUser: {
    setDetailedProgress: (detailedProgress: DetailedProgress[]) => ({
      type: SELECTED_USER_SET_DETAILED_PROGRESS,
      detailedProgress,
    }),
    setDrivingRecordChartData: (chartData: DrivingRecordChartEntry[]) => ({
      type: SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA,
      chartData,
    }),
    setIsDeleted: (status: boolean) => ({
      type: SELECTED_USER_SET_IS_DELETED,
      status,
    }),
    clear: () => ({
      type: SELECTED_USER_CLEAR_STATE,
    }),
  },
};

// export function adminUpdateUserDetails(
//   ID: number,
//   OrganizationID: number,
//   SchoolID: number,
//   SchoolClassID: number,
//   FirstName: string,
//   LastName: string,
//   Email: string,
//   Role: number,
//   Permissions?: number[]
// ) {
//   return async (dispatch: DispatchFunction) => {
//     const statusId = "ADMIN_UPDATE_USER";
//     dispatch(setLoading(statusId));

//     try {
//       await apiPut(PUT_ADMIN_UPDATE_USER, {
//         ID,
//         OrganizationID,
//         SchoolID,
//         SchoolClassID,
//         FirstName,
//         LastName,
//         Email,
//         Role,
//       });

//       if (Permissions)
//         await apiPost(POST_ADMIN_SET_USER_PERMISSIONS, {
//           userID: ID,
//           Permissions,
//         });

//       dispatch(
//         addMessage("Användaren uppdaterad.", "Fort var det gjort!", "success")
//       );
//       dispatch(setSuccess(statusId));
//     } catch (error: any) {
//       Sentry.captureException(error);
//       if (error?.Response?.Error?.role) {
//         dispatch(
//           addMessage(
//             "Något gick fel",
//             "De nya behörigheterna kunde inte uppdateras.",
//             "error"
//           )
//         );
//       } else {
//         dispatch(
//           addMessage(
//             "Något gick fel.",
//             "Användaren kunde inte uppdateras. Kontrollera uppgifterna.",
//             "error"
//           )
//         );
//       }
//       dispatch(setError(statusId));
//       console.warn(`Update user failed: ${error}`);
//     }
//   };
// }

export function createUsers(users: User[]) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "CREATE_USERS";
    try {
      dispatch(setLoading(statusId));
      const data = {
        Users: users,
        CreatedBy: Number(localStorageGet("user_id")),
        OrganizationID: Number(localStorageGet("organization_id")),
        SchoolID: Number(localStorageGet("school_id")),
      };
      const json = await apiPost("/create-user", data);
      dispatch(actions.importUsers.setResult(json));
      dispatch(userActions.newUser.clear());
      dispatch(setSuccess(statusId));
      dispatch(
        addMessage(i18next.t("user.add.create.success.title"), i18next.t("user.add.create.success.description"), "success")
      );
      dispatch(fetchUsers());
    } catch (error) {
      dispatch(addMessage(i18next.t("user.add.create.error"), "", "error"));
      Sentry.captureException(error);
      dispatch(setError(statusId));
      console.warn(error);
      dispatch(fetchUsers());
    }
  };
}

export function deleteUser(userID: number) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "DELETE_USER";
    try {
      dispatch(setLoading(statusId));
      await apiPost(POST_DELETE_USER, { ID: userID });
      dispatch(addMessage("Användaren borttagen.", "Tack för den här tiden!", "success"));
      dispatch(fetchUsers());
      dispatch(setSuccess(statusId));
    } catch (error) {
      dispatch(setError(statusId));
      dispatch(addMessage("Något gick fel.", "Användaren kunde inte tas bort. Prova igen.", "error"));
      Sentry.captureException(error);
      console.warn(error);
    }
  };
}

export function selectCourse(publicCourseUUID: string) {
  return async function (dispatch: DispatchFunction) {
    dispatch(actions.selectedCourse.setPublicUuid(publicCourseUUID));
    dispatch(schoolClassActions.selectedSchoolClass.setSelectAllCourses(false));
  };
}

export function reactivateUser(ID: number, Email?: string) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "REACTIVATE_USER";
    try {
      dispatch(setLoading(statusId));
      await apiPost(POST_ADMIN_REACTIVATE_USER, { ID, Email });
      dispatch(setSuccess(statusId));
      dispatch(addMessage("Användaren återaktiverad", "Välkommen tillbaka in i värmen!", "success"));
    } catch (error: any) {
      dispatch(setError(statusId));
      if (error.StatusCode === 422) {
        dispatch(setError("REACTIVATE_USER_EMAIL_EXISTS"));
      } else {
        dispatch(addMessage("Användaren kunde inte aktiveras", "Kontakta Skillster Support", "error"));
      }
    }
  };
}

export default userActions;
