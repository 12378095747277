import { fetchLang, fiLocale, svLocale } from "./universal.helper";
import { MONTHS_SHORT } from "./constants";
import { enGB, sv, fi } from "date-fns/locale";
import {
  formatDistanceToNowStrict,
  format,
  differenceInCalendarDays,
  differenceInCalendarYears,
  Locale,
} from "date-fns";
import {
  CompetitionData,
  CompetitionStatus,
} from "../components/pages/Admin/Contests/api/useCompetitions";

export const dateObjectToReadable = (date: Date) =>
  `${date.getDate()} ${MONTHS_SHORT()[date.getMonth()]}`;
export const dateObjectToReadableWithYear = (date: Date) =>
  `${dateObjectToReadable(date)} ${date.getFullYear()}`;

const replaceAt = (string: string, index: number, replacement: string) => {
  if (index >= string.length) return string;
  return string.substring(0, index) + replacement + string.substring(index + 1);
};

export const safariDateFix = (timestamp: string) =>
  timestamp.charAt(10) === " " ? replaceAt(timestamp, 10, "T") : timestamp;

export function formatTimeDistanceStrict(timestamp: string) {
  if (timestamp === "") return "";
  const ts = safariDateFix(timestamp);
  return differenceInCalendarDays(new Date(), new Date(ts)) > 0
    ? differenceInCalendarYears(new Date(), new Date(ts)) > 0
      ? format(new Date(ts), "d MMM yyyy", {
          locale: svLocale() ? sv : fiLocale() ? fi : enGB,
        })
      : format(new Date(ts), "d MMM p", {
          locale: svLocale() ? sv : fiLocale() ? fi : enGB,
        })
    : formatDistanceToNowStrict(new Date(ts), {
        addSuffix: true,
        locale: svLocale() ? sv : fiLocale() ? fi : enGB,
      });
}

export function formatRuntime(secondsWithDecimals: number) {
  const m = Math.floor((secondsWithDecimals % 3600) / 60);
  const s = Math.floor((secondsWithDecimals % 3600) % 60);
  const ms = (secondsWithDecimals - Math.floor(secondsWithDecimals))
    .toString()
    .slice(2, 4);

  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;
  const msDisplay = ms;

  return `${mDisplay}:${sDisplay}.${msDisplay}`;
}

export const getDifferenceInDays = (date1: any, date2: any) =>
  Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));

export function convertDateToYYYMMDD(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function convertSecondsToReadable(
  seconds: number,
  floorToHours?: boolean,
  decimalMode?: boolean,
  doNotShowHoursIfUnderOneHour?: boolean,
  showSeconds?: boolean
): string {
  if (!seconds) return "0 min";
  const hours = svLocale() ? "tim" : "h";
  const h = Math.floor(seconds / 3600);
  const m =
    h < 1
      ? Math.ceil((seconds % 3600) / 60)
      : Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const hDisplay = `${Number(h).toLocaleString(fetchLang())} ${hours} `;
  const mDisplay = m + " min ";
  const sDisplay = s > 0 ? s + " s" : "";
  if (floorToHours) return hDisplay;
  if (decimalMode) return `${h}${m >= 30 ? ".5" : ""} ${hours}`;
  if (h < 1 && doNotShowHoursIfUnderOneHour)
    return mDisplay + (showSeconds ? sDisplay : "");
  return hDisplay + mDisplay + (showSeconds ? sDisplay : "");
}

export const datesAreOnSameDay = (date1?: Date, date2?: Date) =>
  date1 &&
  date2 &&
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export function truncateDate(d: Date) {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function dateRangeThisYear(from: Date, to?: Date) {
  const today = new Date();
  if (!to) return today.getFullYear() === from.getFullYear();
  return (
    today.getFullYear() === from.getFullYear() &&
    today.getFullYear() === to.getFullYear()
  );
}

export const dateFormat = (from: Date, to?: Date) =>
  dateRangeThisYear(from, to) ? "do MMM" : "do MMM y";

export function formatDateRange(from: Date, to?: Date) {
  return !to || datesAreOnSameDay(from, to)
    ? `${format(new Date(from), dateFormat(from, to), {
        locale: svLocale() ? sv : fiLocale() ? fi : enGB,
      })}`
    : `${format(new Date(from), dateFormat(from, to), {
        locale: svLocale() ? sv : fiLocale() ? fi : enGB,
      })} – ${format(new Date(to), dateFormat(from, to), {
        locale: svLocale() ? sv : fiLocale() ? fi : enGB,
      })}`;
}

const date1 = new Date();
date1.setMonth(0);
date1.setDate(1);
export const jan1stThisYear = date1;

const date2 = new Date();
date2.setMonth(0);
date2.setDate(1);
date2.setFullYear(date2.getFullYear() - 1);
export const jan1stPrevYear = date2;

const date3 = new Date();
date3.setMonth(11);
date3.setDate(31);
date3.setFullYear(date3.getFullYear() - 1);
export const dec31stPrevYear = date3;

export function formatHighscoreRuntime(secondsWithDecimals: number) {
  const h = Math.floor(secondsWithDecimals / 3600);
  const m = Math.floor((secondsWithDecimals % 3600) / 60);
  const s = Math.floor((secondsWithDecimals % 3600) % 60);

  const hDisplay = h < 1 ? "" : h < 10 ? "0" + h : h;
  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;

  return `${hDisplay ? `${hDisplay}:` : ""}${mDisplay}:${sDisplay}`;
}

export function formatHighscoreRuntimeWithMs(secondsWithDecimals: number) {
  const m = Math.floor((secondsWithDecimals % 3600) / 60);
  const s = Math.floor((secondsWithDecimals % 3600) % 60);
  const ms = (secondsWithDecimals - Math.floor(secondsWithDecimals))
    .toString()
    .slice(2, 4);

  const mDisplay = m < 10 ? "0" + m : m;
  const sDisplay = s < 10 ? "0" + s : s;
  const msDisplay = ms;

  return `${mDisplay}:${sDisplay}.${msDisplay}`;
}

export function formatFirebaseTimestamp(date: Date) {
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  return format(date, `dd MMM ${!isThisYear ? "yyyy" : ""}, kk:mm`, {
    locale: svLocale() ? sv : fiLocale() ? fi : enGB,
  });
}

export const currentLocale = (): Locale =>
  svLocale() ? sv : fiLocale() ? fi : enGB;

export function formatDateToReadable(
  date?: Date,
  dateFormat: string = "EEEE dd LLL 'kl.' hh:mm"
) {
  if (!date) return "null";
  return format(date, dateFormat, { locale: sv });
}

export function getCompetitionStatus(
  competition: CompetitionData
): CompetitionStatus {
  const now = new Date();
  const start = new Date(competition.StartTime);
  const end = new Date(competition.EndTime);

  if (now.getTime() > start.getTime() && now.getTime() < end.getTime())
    return "active";

  if (now.getTime() > end.getTime()) return "past";

  return "coming";
}

export const dateDifferenceInMs = (dateInitial: Date, dateFinal: Date) =>
  dateFinal.getTime() - dateInitial.getTime();
