import { DrivingRecordOption } from "../types/drivingrecord.types";
import { 
    DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY,
    DRIVING_RECORD_SET_DAYS_WITH_RUNS,
    DRIVING_RECORD_SET_FROM_DATE,
    DRIVING_RECORD_SET_IS_LOADING,
    DRIVING_RECORD_SET_OPTIONS,
    DRIVING_RECORD_SET_RUNS,
    DRIVING_RECORD_SET_SELECTED_OPTION,
    DRIVING_RECORD_SET_TO_DATE,
    NEW_USER_CLEAR_STATE, 
    NEW_USER_EMPTY_FIELDS, 
    NEW_USER_SET_DUPLICATE_USER, 
    NEW_USER_SET_EMAIL, 
    NEW_USER_SET_FIRST_NAME, 
    NEW_USER_SET_LAST_NAME, 
    NEW_USER_SET_ROLE, 
    NEW_USER_SET_SCHOOLCLASS_ID, 
    SELECTED_USER_CLEAR_STATE, 
    SELECTED_USER_SET_DETAILED_PROGRESS, 
    SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA, 
    SELECTED_USER_SET_IS_DELETED,
} from "../types/redux.types";
import { DetailedProgress, DrivingRecordChartEntry, User, RunTemp } from "../types/user.types";

const d = new Date();
d.setDate(1);

class DrivingRecordState {
    from = d;
    to? = new Date();
    isLoading = false;
    runs?: RunTemp[];
    daysWithRuns: Date[] = [];
    pickerVisible = false;
    options: DrivingRecordOption[] = [];
    selectedOption: "all" | "today" | "week" | "month" | "custom" = "month";
}

export const drivingRecordReducer = function(state = new DrivingRecordState(), action: any): DrivingRecordState {
    switch (action.type) {
        case DRIVING_RECORD_SET_FROM_DATE:
            return {
                ...state,
                from: action.date
            }
        case DRIVING_RECORD_SET_TO_DATE:
            return {
                ...state,
                to: action.date
            }
        case DRIVING_RECORD_SET_RUNS:
            return {
                ...state,
                runs: action.runs
            }
        case DRIVING_RECORD_SET_DAYS_WITH_RUNS:
            return {
                ...state,
                daysWithRuns: action.days
            }
        case DRIVING_RECORD_SET_OPTIONS:
            return {
                ...state,
                options: action.options
            }
        case DRIVING_RECORD_SET_DATE_PICKER_VISIBILITY:
            return {
                ...state,
                pickerVisible: action.status
            }
        case DRIVING_RECORD_SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.status
            }
        case DRIVING_RECORD_SET_SELECTED_OPTION:
            return {
                ...state,
                selectedOption: action.selected
            }
    }
    return state;
}

class NewUserState {
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    schoolClassID: number = 0;
    role: number = 3;
    errorMsg: string | null = null;
    duplicateUser: User = new User();
}

export function newUserReducer(state = new NewUserState(), action: any): NewUserState {
    switch (action.type) {
        case NEW_USER_SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.firstName
            }
        case NEW_USER_SET_LAST_NAME:
            return {
                ...state,
                lastName: action.lastName
            }
        case NEW_USER_SET_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case NEW_USER_SET_SCHOOLCLASS_ID:
            return {
                ...state,
                schoolClassID: action.schoolClassID
            }
        case NEW_USER_SET_ROLE:
            return {
                ...state,
                role: action.role
            }
        case NEW_USER_SET_DUPLICATE_USER:
            return {
                ...state,
                duplicateUser: action.user,
                errorMsg: action.error
            }
        case NEW_USER_EMPTY_FIELDS:
            return {
                ...state,
                firstName: "",
                lastName: "",
                email: "",
                role: 3
            }
        case NEW_USER_CLEAR_STATE:
            return new NewUserState();
    }
    return state;
}

class SelectedUserState {
    detailedProgress: DetailedProgress[] = [];
    chartData?: DrivingRecordChartEntry[];
    isDeleted?: boolean = false;
}

export function selectedUserReducer(state = new SelectedUserState(), action: any): SelectedUserState {
    switch (action.type) {
        case SELECTED_USER_SET_DETAILED_PROGRESS:
            return {
                ...state,
                detailedProgress: action.detailedProgress
            }
        case SELECTED_USER_SET_DRIVING_RECORD_CHART_DATA:
            return {
                ...state,
                chartData: action.chartData
            }
        case SELECTED_USER_SET_IS_DELETED:
            return {
                ...state,
                isDeleted: action.status
            }
        case SELECTED_USER_CLEAR_STATE:
            return new SelectedUserState();
    }
    return state;
}