import { Group, Stack, Text, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { Clock } from "react-feather";
import { useTranslation } from "react-i18next";
import { convertSecondsToReadable } from "../../../../../helpers/time.helper";
import { dropdownTransition } from "../../../../../resources/styles/animations";

interface EstimatedRuntimeCourseProps {
    runtime?: number;
}

function EstimatedRuntimeCourse(props: EstimatedRuntimeCourseProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    if (!props.runtime)
        return null;

    return (
        <Group
            position="center"
            spacing={5}
        >
            <Tooltip
                label={
                    <Stack p="sm" spacing={5}>
                        <Group spacing={5}>
                            <Clock strokeWidth={3} width={15} color={theme.colors.bluegray[9]} />
                            <Title order={6}>{convertSecondsToReadable(props.runtime, undefined, undefined, true)}</Title>
                        </Group>
                        <Text size="sm" color={theme.colors.bluegray[6]}>{t("common:estimatedRuntime.course")}</Text>
                    </Stack>
                }
                width={250}
                position="bottom"
                transition={dropdownTransition}
                openDelay={500}
                withinPortal
                multiline
            >
                <Text
                    size="sm"
                    weight={600}
                    sx={(theme) => ({
                        color: theme.colors.bluegray[5],
                    })}
                >
                    {convertSecondsToReadable(props.runtime, undefined, undefined, true)}
                </Text>
            </Tooltip>
        </Group>
    )
}

export default EstimatedRuntimeCourse;
