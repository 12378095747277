import { Box, Title, CSSObject, MantineTheme } from "@mantine/core";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { firstChar } from "../../../../helpers/strings.helper";
import { User } from "../../../../types/user.types";

type Props = {
  user: User;
  className?: string;
  noDot?: boolean;
  inverted?: boolean;
  to?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

const UserBadge = forwardRef<HTMLDivElement, Props>((props, ref) => {
  if (!props.user) return null;

  const boxStyles = (theme: MantineTheme): CSSObject => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    flexShrink: 0,
    borderRadius: "100%",
    backgroundColor: props.inverted ? theme.white : theme.colors.bluegray[1],
    border: props.to ? `2px solid transparent` : undefined,
    ":hover": {
      border: props.to ? `2px solid ${theme.colors.blue[5]}` : undefined,
    },
  });

  const content = (
    <>
      {props.noDot ? null : (
        <Box
          sx={(theme) => ({
            width: 10,
            height: 10,
            borderRadius: "100%",
            position: "absolute",
            top: props.to ? -2 : 0,
            right: props.to ? -2 : 0,
            backgroundColor: props.user.IsOnline
              ? theme.colors.green[5]
              : theme.colors.bluegray[4],
          })}
        />
      )}
      <Title order={6}>
        {firstChar(props.user.FirstName).toUpperCase()}
        {firstChar(props.user.LastName).toUpperCase()}
      </Title>
    </>
  );

  return (
    <Box
      ref={ref}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      className="notranslate"
      sx={boxStyles}
    >
      {props.to ? <Link to={props.to}>{content}</Link> : content}
    </Box>
  );
});

export default UserBadge;
