import { useQuery } from "react-query";

import { apiGet } from "../../../../helpers/requests.helper";
import { Organization } from "../../../../types/organization.types";
import { isAdminUser, searchString } from "../../../../helpers/universal.helper";

export function useGetAllOrganizations(
  permissionIds?: number[],
  include_active = true,
  include_inactive = false,
  filter?: string
) {
  return useQuery<{ list: Organization[]; map: Record<number, Organization> }, Error>(
    ["get-admin-organizations", permissionIds, include_active, include_inactive, filter],
    async () => {
      const response = await apiGet("/all-organizations", {
        permissionIds: permissionIds ?? [],
        include_active,
        include_inactive,
      });

      const organizations = response.Organizations;
      const list: Organization[] = filter?.length
        ? organizations.filter((o: Organization) => searchString(o.Name, filter))
        : organizations;

      const map: Record<number, Organization> = {};
      list.forEach((org) => {
        map[org.ID] = org;
      });
      return { list, map };
    },
    {
      enabled: isAdminUser(),
    }
  );
}
