import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import actions from "../actions";
import { apiDelete, apiGet, apiPost, apiPut } from "../helpers/requests.helper";
import { fetchLang } from "../helpers/universal.helper";
import store from "../store";
import { Course } from "../types/course.types";
import { Lock } from "../types/editor.types";
import { useGetUsersFromSchool } from "./usersFromSchool";

export function useGetCourses() {
    const usersFromSchool = useGetUsersFromSchool();
    return useQuery(
        ["courses"],
        async () => {
        const data = await apiGet("/courses", { language: fetchLang() });
            const list: Course[] = data.Courses;
        const map: Record<string, Course> = {};
        list.forEach((c) => (map[c.PublicUUID] = c));
        return { list, map };
        },
        {
            staleTime: 20 * 1000,
            enabled: !!usersFromSchool.data,
        }
    );
}

interface DuplicateCourseMutation {
    publicUuid: string;
    uuid: string;
}

export function useDuplicateCourseMutation() {
    const dispatch = useDispatch();
    const authDuplicateCourseMutation = useAuthDuplicateCourseMutation();

    return useMutation(
        "aquire-lock",
        (mutation: DuplicateCourseMutation) => {
            return apiPost("/locking/acquire", {
                ResourceType: "COURSE",
                ResourceKey: mutation.publicUuid,
            });
        },
        {
            onSuccess: (response, mutation) => {
                dispatch(actions.locks.addLock(response));
                authDuplicateCourseMutation.mutate({
                    uuid: mutation.uuid,
                    publicUuid: mutation.publicUuid
                });
            },
        }
    );
}

interface AuthDuplicateCourseMutation {
    uuid: string;
    publicUuid: string;
}

// dispatch(setLoading("DUPLICATE_COURSE"));
// await dispatch(acquireLockCourse(publicUUID));
// dispatch({type: COURSE_EDITOR_CLEAR_LAST_SAVED});
// const json = await apiPost(`/editor/duplicate`, {SourceUUID: uuid, language: fetchLang()});
// const { CourseUuid } = json;
// dispatch(releaseLockCourse(publicUUID));
// dispatch(courseEditorActions.courseEditor.setDuplicatedCourseUUID(CourseUuid));
// setTimeout(() => dispatch(courseEditorActions.courseEditor.clearDuplicatedCourseUUID()), 2000);
// dispatch(fetchCourses());
// dispatch(fetchLessons());
// dispatch(fetchExercises());
// dispatch(setSuccess("DUPLICATE_COURSE"));

function useAuthDuplicateCourseMutation() {
    const queryClient = useQueryClient();
    const releaseLockMutation = useReleaseLockMutation();
    return useMutation(
        "duplicate-course", (mutation: AuthDuplicateCourseMutation) => {
            return apiPost("/editor/duplicate", {
                SourceUUID: mutation.uuid,
                language: fetchLang()
            });
        }, {
        onSuccess: (_, mutation) => {
            queryClient.invalidateQueries("courses");
            const lockUUID = store
                .getState()
                .locks.list.find(
                    (lock: Lock) => lock.ResourceKey === mutation.publicUuid
                )?.UUID;
            if (!lockUUID) throw Error;
            // showNotification({
            //     title: 'Kopierad',
            //     message: "Du har kopierat kursen.",
            //     icon: <IconCheck size={15} strokeWidth={4} />,
            // });
            releaseLockMutation.mutate({ publicUuid: mutation.publicUuid });
        }
    }
    )
}
interface DeactivateCourseMutation {
    uuid: string;
    publicUuid: string;
}

function useDeactivateCourseMutation() {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const releaseLockMutation = useReleaseLockMutation();
    return useMutation(
        "deactivate-course",
        (mutation: DeactivateCourseMutation) => {
            return apiPut("/course/deactivate", {
                UUID: mutation.uuid,
            });
        },
        {
            onSuccess: (response, mutation) => {
                queryClient.invalidateQueries("courses");
                const lockUUID = store
                    .getState()
                    .locks.list.find(
                        (lock: Lock) => lock.ResourceKey === mutation.publicUuid
                    )?.UUID;
                if (!lockUUID) throw Error;
                showNotification({
                    title: t('common:notifications.courseRemoved.title'),
                    message: t('common:notifications.courseRemoved.description'),
                    icon: <IconCheck size={15} strokeWidth={4} />,
                });
                releaseLockMutation.mutate({ publicUuid: mutation.publicUuid });
            },
        }
    );
}

interface DeleteCourseMutation {
    uuid: string;
    publicUuid: string;
}

export function useDeleteCourseMutation() {
    const dispatch = useDispatch();
    const deactivateCourseMutation = useDeactivateCourseMutation();

    return useMutation(
        "deactivate-course",
        (mutation: DeleteCourseMutation) => {
            return apiPost("/locking/acquire", {
                ResourceType: "COURSE",
                ResourceKey: mutation.publicUuid,
            });
        },
        {
            onSuccess: (response, mutation) => {
                dispatch(actions.locks.addLock(response));
                deactivateCourseMutation.mutate({
                    uuid: mutation.uuid,
                    publicUuid: mutation.publicUuid,
                });
            },
        }
    );
}


interface ReleaseLockMutation {
    publicUuid: string;
}

function useReleaseLockMutation() {
    return useMutation("release-lock", (mutation: ReleaseLockMutation) => {
        const lockUuid = store
            .getState()
            .locks.list.find(
                (lock: Lock) => lock.ResourceKey === mutation.publicUuid
            )?.UUID;
        return apiDelete("/locking/release", {
            language: fetchLang(),
            UUID: lockUuid,
        });
    });
}
