import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";
import {
  ALL_SIMULATORS_SET_SIMULATORS,
  ASSESSMENTS_SET,
  CHANGE_ORGANIZATION,
  CHANGE_SCHOOL,
  CLEAR_CLASSES_FETCH_CONTROLLER,
  CLEAR_COURSES_FETCH_CONTROLLER,
  CLEAR_EXERCISES_FETCH_CONTROLLER,
  CLEAR_FILTER,
  CLEAR_FILTERS,
  CLEAR_LESSONS_FETCH_CONTROLLER,
  CLEAR_SIMULATORS_FETCH_CONTROLLER,
  CLEAR_USERS_FETCH_CONTROLLER,
  CONTACT_FORM_SET_FEEDBACK_FROM,
  CONTACT_FORM_SET_FEEDBACK_MESSAGE,
  COURSE_ACCESS_ADD_TO_CLIPBOARD,
  COURSE_ACCESS_CLEAR_CLIPBOARD,
  COURSE_ACCESS_CLEAR_MODAL,
  COURSE_ACCESS_SET_ACCESSES,
  COURSE_ACCESS_SET_ACCESS_MODAL,
  COURSE_CATEGORIES_SET_SELECTED_CATEGORY,
  EMAILS_SET_ACTIVATION_TRANSLATIONS,
  EMAILS_SET_PASSWORD_RESET_TRANSLATIONS,
  EXERCISE_NUMBERING_SET,
  EXERCISE_VARIANTS_SET_VARIANTS,
  GLOBAL_USERS_SET_META_DATA,
  GLOBAL_USERS_SET_ORDER_BY,
  HOME_PAGE_CLEAR,
  HOME_PAGE_SET_ACTIVE_PRESET,
  HOME_PAGE_SET_DATE_RANGE,
  HOME_PAGE_SET_DATE_RANGE_ON_DATA,
  HOME_PAGE_SET_FROM_DATE,
  HOME_PAGE_SET_LATEST_EXERCISES,
  HOME_PAGE_SET_LATEST_RUNS,
  HOME_PAGE_SET_OUTLIERS,
  HOME_PAGE_SET_SCHOOL_MINI_STATS,
  HOME_PAGE_SET_SCHOOL_RUNTIME,
  HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME,
  HOME_PAGE_SET_TO_DATE,
  IMPORT_USERS_ADD_NEW,
  IMPORT_USERS_CLEAR,
  IMPORT_USERS_DELETE_USER,
  IMPORT_USERS_SET_RESULT,
  IMPORT_USERS_SET_USERS,
  IMPORT_USERS_UPDATE_USER,
  LATEST_APPLICATION_VERSION,
  LOCKS_ADD_LOCK,
  LOCKS_SET_LOCKS,
  LOGIN,
  LOGIN_SET_EMAIL,
  LOGIN_SET_PASSWORD,
  LOGOUT,
  MOBILE_MENU_SET_IS_OPEN,
  MODAL_CLEAR_ALL,
  MODAL_SET_OPEN,
  PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG,
  PERMISSIONS_SET_SELECTED_USER_PERMISSIONS,
  PREVIOUS_SEARCHES_ADD_ENTRY,
  PREVIOUS_SEARCHES_REMOVE_ENTRY,
  PREVIOUS_SEARCHES_SET_ALL,
  REVERSE_ORDER_SCHOOL_CLASSES,
  REVERSE_ORDER_USERS,
  RUN_STATS_SET_DATA,
  RUN_STATS_SET_FROM_DATE,
  RUN_STATS_SET_SELECTED_CALENDAR_MONTH,
  RUN_STATS_SET_TO_DATE,
  SELECTED_COURSE_SET_PUBLIC_UUID,
  SET_FILTER,
  SET_HAS_SEEN_WELCOME_SCREEN,
  SET_IS_CHECKING_SESSION,
  SET_NO_SCHOOLS_IN_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_ORGANIZATIONS_IS_OPEN,
  SET_RELEASE_NOTES,
  SET_SCHOOLS,
  SET_SCHOOLS_IS_OPEN,
  SET_SELECTED_ORGANIZATION,
  SET_SELECTED_SCHOOL,
  SIMULATORS_INITIAL_ORDER_CLEAR,
  SIMULATORS_INITIAL_ORDER_SET,
  SORT_CLASS_HISTORY,
  SORT_DRIVING_RECORD,
  SORT_ORGANIZATIONS,
  SORT_SCHOOL_CLASSES,
  SORT_USERS,
  STATS_SET_USERS,
  STATS_SET_USERS_PER_DAY,
  STAY_LOGGED_IN,
} from "../types/redux.types";
import {
  Assessment,
  ImportResult,
  ImportUser,
  Outlier,
  Permission,
  RunTemp,
  User,
  ViewConfig,
} from "../types/user.types";

import * as Sentry from "@sentry/react";
import { sortBy } from "lodash";
import { AggregationList } from "../components/pages/Admin/RunData/api/RunData.api";
import { findOutliers } from "../components/pages/Home/Home.helpers";
import { PreviousSearchEntry } from "../components/pages/Home/Start/components/ResultCardBase";
import { SKILLSTER_ORGANIZATION_ID } from "../helpers/constants";
import { localStorageGet } from "../helpers/localstorage.helper";
import { apiDelete, apiGet, apiPost, apiPut } from "../helpers/requests.helper";
import { parseReleaseNotes } from "../helpers/strings.helper";
import {
  convertDateToYYYMMDD,
  dec31stPrevYear,
  jan1stPrevYear,
  jan1stThisYear,
} from "../helpers/time.helper";
import { encode, fetchLang } from "../helpers/universal.helper";
import {
  DELETE_DELETE_ORGANIZATION,
  DELETE_DELETE_SCHOOL,
  GET_ALL_ASSESSMENTS,
  GET_ALL_ORGANIZATIONS,
  GET_ALL_SCHOOLS,
  GET_COURSES,
  GET_EXERCISES,
  GET_EXERCISE_VARIANTS,
  GET_LESSONS,
  GET_ORGANIZATION_SCHOOL_ID,
  GET_SCHOOL_CLASSES,
  GET_SESSION_USER,
  GET_STATS_LOGGED_IN_USERS,
  GET_STATS_LOGGED_IN_USERS_PER_DAY,
  GET_TEACHER_SCHOOLS,
  GET_USERS_FROM_SCHOOL,
  POST_REACTIVATE_ORGANIZATION,
  POST_REACTIVATE_SCHOOL,
} from "../navigation/endpoints";
import { TranslatedEmails } from "../reducers";
import store, { DispatchFunction } from "../store";
import {
  Course,
  CourseAccessClipboard,
  Exercise,
  ExerciseVariant,
  Lesson,
} from "../types/course.types";
import { Lock } from "../types/editor.types";
import { Language, Organization } from "../types/organization.types";
import {
  AccessModal,
  CourseAccess,
  School,
  SchoolClass,
} from "../types/school.types";
import { Simulator } from "../types/simulator.types";
import { OrderBy, SortOrder } from "../types/sort.types";
import { generateListActions } from "./generate-list-actions";

const actions = {
  auth: {
    login: (token: string, user: User) => ({
      type: LOGIN,
      token,
      user,
    }),
    stayLoggedIn: (user: User, token: string) => ({
      type: STAY_LOGGED_IN,
      user,
      token,
    }),
    logout: () => ({
      type: LOGOUT,
    }),
    setFirstName: (email: string) => ({
      type: LOGIN_SET_EMAIL,
      email,
    }),
    setPassword: (password: string) => ({
      type: LOGIN_SET_PASSWORD,
      password,
    }),
    setIsCheckingSession: (status: boolean) => ({
      type: SET_IS_CHECKING_SESSION,
      status,
    }),
    setHasSeenWelcomeScreen: (status: boolean) => ({
      type: SET_HAS_SEEN_WELCOME_SCREEN,
      status,
    }),
  },
  modals: {
    setIsOpen: (key: string, value: boolean) => ({
      type: MODAL_SET_OPEN,
      key,
      value,
    }),
    clear: () => ({
      type: MODAL_CLEAR_ALL,
    }),
  },
  contactForms: {
    feedback: {
      setFrom: (from: string) => ({
        type: CONTACT_FORM_SET_FEEDBACK_FROM,
        from,
      }),
      setMessage: (message: string) => ({
        type: CONTACT_FORM_SET_FEEDBACK_MESSAGE,
        message,
      }),
    },
  },
  organizations: {
    setOrganizations: (organizations: Organization[]) => ({
      type: SET_ORGANIZATIONS,
      organizations,
    }),
    setSelectedOrganization: (organization: Organization) => ({
      type: SET_SELECTED_ORGANIZATION,
      organization,
    }),
    changeSchool: (schoolID: number) => ({
      type: CHANGE_SCHOOL,
      schoolID,
    }),
    setSelectedSchool: (school: School) => ({
      type: SET_SELECTED_SCHOOL,
      school,
    }),
    changeOrganization: (organizationID: string) => ({
      type: CHANGE_ORGANIZATION,
      organizationID,
    }),
    setOrganizationsIsOpen: (status: boolean) => ({
      type: SET_ORGANIZATIONS_IS_OPEN,
      status,
    }),
    setSchoolsIsOpen: (status: boolean) => ({
      type: SET_SCHOOLS_IS_OPEN,
      status,
    }),
    setNoSchoolsInOrganization: (status: boolean) => ({
      type: SET_NO_SCHOOLS_IN_ORGANIZATION,
      status,
    }),
  },
  schools: {
    setSchools: (schools: School[]) => ({
      type: SET_SCHOOLS,
      schools,
    }),
    setSelectedSchool: (school: School) => ({
      type: SET_SELECTED_SCHOOL,
      school,
    }),
  },
  filter: {
    setFilter: (key: string, value: string) => ({
      type: SET_FILTER,
      key,
      value,
    }),
    clearFilter: (key: string) => ({
      type: CLEAR_FILTER,
      key,
    }),
    clearFilters: () => ({
      type: CLEAR_FILTERS,
    }),
  },
  exerciseNumbering: {
    set: (
      publicCourseUUID: string,
      numbering: { [publicCourseUUID: string]: number }
    ) => ({
      type: EXERCISE_NUMBERING_SET,
      publicUUID: publicCourseUUID,
      numbering,
    }),
  },
  sort: {
    sortSchoolClassList: (sortOrder: OrderBy[]) => ({
      type: SORT_SCHOOL_CLASSES,
      sortOrder,
    }),
    reverseOrderSchoolClasses: () => ({
      type: REVERSE_ORDER_SCHOOL_CLASSES,
    }),
    sortUserList: (sortOrder: OrderBy[]) => ({
      type: SORT_USERS,
      sortOrder,
    }),
    reverseOrderUsers: () => ({
      type: REVERSE_ORDER_USERS,
    }),
    sortDrivingRecord: (sortOrder: OrderBy[]) => ({
      type: SORT_DRIVING_RECORD,
      sortOrder,
    }),
    sortClassHistory: (sortOrder: OrderBy[]) => ({
      type: SORT_CLASS_HISTORY,
      sortOrder,
    }),
    sortOrganizations: (sortOrder: OrderBy[]) => ({
      type: SORT_ORGANIZATIONS,
      sortOrder,
    }),
  },
  fetchController: {
    clearCoursesController: () => ({
      type: CLEAR_COURSES_FETCH_CONTROLLER,
    }),
    clearClassesController: () => ({
      type: CLEAR_CLASSES_FETCH_CONTROLLER,
    }),
    clearUserController: () => ({
      type: CLEAR_USERS_FETCH_CONTROLLER,
    }),
    clearSimulatorsController: () => ({
      type: CLEAR_SIMULATORS_FETCH_CONTROLLER,
    }),
    clearLessonsController: () => ({
      type: CLEAR_LESSONS_FETCH_CONTROLLER,
    }),
    clearExercisesController: () => ({
      type: CLEAR_EXERCISES_FETCH_CONTROLLER,
    }),
  },
  mobileMenu: {
    setIsOpen: (status: boolean) => ({
      type: MOBILE_MENU_SET_IS_OPEN,
      status,
    }),
  },
  simulatorsInitialOrder: {
    setOrder: (order: { InstallationID: string }[]) => ({
      type: SIMULATORS_INITIAL_ORDER_SET,
      order,
    }),
    clearOrder: () => ({
      type: SIMULATORS_INITIAL_ORDER_CLEAR,
    }),
  },
  locks: {
    setLocks: (locks: Lock[]) => ({
      type: LOCKS_SET_LOCKS,
      locks,
    }),
    addLock: (lock: Lock) => ({
      type: LOCKS_ADD_LOCK,
      lock,
    }),
  },
  allSimulators: {
    setSimulators: (simulators: Simulator[]) => ({
      type: ALL_SIMULATORS_SET_SIMULATORS,
      simulators,
    }),
  },
  courseAccess: {
    setAccessModal: (accessModal: AccessModal) => ({
      type: COURSE_ACCESS_SET_ACCESS_MODAL,
      accessModal,
    }),
    setCourseAccesses: (courseAccesses: {
      [publicCourseUUID: string]: number[];
    }) => ({
      type: COURSE_ACCESS_SET_ACCESSES,
      courseAccesses,
    }),
    clearModal: () => ({
      type: COURSE_ACCESS_CLEAR_MODAL,
    }),
    addToClipboard: (clipboard: CourseAccessClipboard) => ({
      type: COURSE_ACCESS_ADD_TO_CLIPBOARD,
      clipboard,
    }),
    clearClipboard: () => ({
      type: COURSE_ACCESS_CLEAR_CLIPBOARD,
    }),
  },
  releaseNotes: {
    set: (releaseNotes: JSX.Element) => ({
      type: SET_RELEASE_NOTES,
      releaseNotes,
    }),
  },
  homePage: {
    setSchoolRuntime: (
      schoolID: number,
      runtime: { day: Date; runtime: number }[]
    ) => ({
      type: HOME_PAGE_SET_SCHOOL_RUNTIME,
      schoolID,
      runtime,
    }),
    setDateRangeRequested: (dateRange: { from: Date; to: Date }) => ({
      type: HOME_PAGE_SET_DATE_RANGE,
      dateRange,
    }),
    setDateRangeOnData: (dateRange: { from: Date; to: Date }) => ({
      type: HOME_PAGE_SET_DATE_RANGE_ON_DATA,
      dateRange,
    }),
    setOutliers: (outliers: Outlier[]) => ({
      type: HOME_PAGE_SET_OUTLIERS,
      outliers,
    }),
    setFromDate: (from: Date) => ({
      type: HOME_PAGE_SET_FROM_DATE,
      from,
    }),
    setToDate: (to?: Date) => ({
      type: HOME_PAGE_SET_TO_DATE,
      to,
    }),
    setActivePreset: (preset: 1 | 2 | 3 | undefined) => ({
      type: HOME_PAGE_SET_ACTIVE_PRESET,
      preset,
    }),
    setSchoolMiniStats: (
      totalDistance: number,
      totalFuel: number,
      totalRuntime: number
    ) => ({
      type: HOME_PAGE_SET_SCHOOL_MINI_STATS,
      totalRuntime,
      totalDistance,
      totalFuel,
    }),
    setSchoolClassesRuntime: (
      data: { schoolClassID: number; runtime: number }[]
    ) => ({
      type: HOME_PAGE_SET_SCOOL_CLASSES_RUNTIME,
      data,
    }),
    setLatestRuns: (latestRuns: RunTemp[]) => ({
      type: HOME_PAGE_SET_LATEST_RUNS,
      latestRuns,
    }),
    setLatestExercises: (exercises: Exercise[]) => ({
      type: HOME_PAGE_SET_LATEST_EXERCISES,
      exercises,
    }),
    clearHomePage: () => ({
      type: HOME_PAGE_CLEAR,
    }),
  },
  stats: {
    setUsers: (
      timeSpan: "lastDay" | "lastWeek" | "lastMonth" | "lastYear",
      users: number
    ) => ({
      type: STATS_SET_USERS,
      timeSpan,
      users,
    }),
    setUsersPerDay: (usersPerDay: any) => ({
      type: STATS_SET_USERS_PER_DAY,
      usersPerDay,
    }),
  },
  importUsers: {
    setUsers: (users: ImportUser[]) => ({
      type: IMPORT_USERS_SET_USERS,
      users,
    }),
    updateUser: (updatedUser: ImportUser, index: number) => ({
      type: IMPORT_USERS_UPDATE_USER,
      updatedUser,
      index,
    }),
    deleteUser: (index: number) => ({
      type: IMPORT_USERS_DELETE_USER,
      index,
    }),
    addEmptyUser: () => ({
      type: IMPORT_USERS_ADD_NEW,
    }),
    setResult: (result: ImportResult) => ({
      type: IMPORT_USERS_SET_RESULT,
      result,
    }),
    clear: () => ({
      type: IMPORT_USERS_CLEAR,
    }),
  },
  globalUsersMetaData: {
    set: (metaData: any) => ({
      type: GLOBAL_USERS_SET_META_DATA,
      metaData,
    }),
    setOrderBy: (orderBy: OrderBy) => ({
      type: GLOBAL_USERS_SET_ORDER_BY,
      orderBy,
    }),
  },
  permissions: {
    setLoggedInUserViewConfig: (viewConfig: ViewConfig) => ({
      type: PERMISSIONS_SET_LOGGED_IN_USER_VIEW_CONFIG,
      viewConfig,
    }),
    setSelectedUserPermissions: (
      userID: number,
      permissions: Permission[]
    ) => ({
      type: PERMISSIONS_SET_SELECTED_USER_PERMISSIONS,
      userID,
      permissions,
    }),
  },
  assessments: {
    set: (assessments: Assessment[]) => ({
      type: ASSESSMENTS_SET,
      assessments,
    }),
  },
  emails: {
    setActivationTranslations: (activationTranslations: TranslatedEmails) => ({
      type: EMAILS_SET_ACTIVATION_TRANSLATIONS,
      activationTranslations,
    }),
    setPasswordResetTranslations: (
      passwordResetTranslations: TranslatedEmails
    ) => ({
      type: EMAILS_SET_PASSWORD_RESET_TRANSLATIONS,
      passwordResetTranslations,
    }),
  },
  selectedCourse: {
    setPublicUuid: (publicUuid: string) => ({
      type: SELECTED_COURSE_SET_PUBLIC_UUID,
      publicUuid,
    }),
  },
  runStats: {
    setData: (data: AggregationList[]) => ({
      type: RUN_STATS_SET_DATA,
      data,
    }),
    setFromDate: (date: string) => ({
      type: RUN_STATS_SET_FROM_DATE,
      date,
    }),
    setToDate: (date: string) => ({
      type: RUN_STATS_SET_TO_DATE,
      date,
    }),
    setSelectedCalendarMonth: (month: number | undefined) => ({
      type: RUN_STATS_SET_SELECTED_CALENDAR_MONTH,
      month,
    }),
    setSelectedOrganizations: (data: string[] | undefined) => ({
      type: "RUN_STATS_SET_SELECTED_ORGANIZATIONS",
      data,
    }),
    setSelectedOrganizationsBehaviour: (data: "include" | "exclude") => ({
      type: "RUN_STATS_SET_SELECTED_ORGANIZATIONS_BEHAVIOUR",
      data,
    }),
  },
  courseCategories: {
    setSelectedId: (id: number) => ({
      type: COURSE_CATEGORIES_SET_SELECTED_CATEGORY,
      id,
    }),
  },
  previousSearches: {
    addEntry: (entry: Omit<PreviousSearchEntry, "clickCount">) => ({
      type: PREVIOUS_SEARCHES_ADD_ENTRY,
      entry,
      userId: localStorageGet("user_id"),
    }),
    removeEntry: (path: string) => ({
      type: PREVIOUS_SEARCHES_REMOVE_ENTRY,
      path,
      userId: localStorageGet("user_id"),
    }),
    setAll: (entries: Record<string, PreviousSearchEntry>) => ({
      type: PREVIOUS_SEARCHES_SET_ALL,
      entries,
      userId: localStorageGet("user_id"),
    }),
  },
};

export function fetchLocks() {
  return async (dispatch: DispatchFunction) => {
    try {
      const locks = await apiGet("/locking/course-locks");
      dispatch(actions.locks.setLocks(locks));
    } catch (error) {
      console.warn(error);
    }
  };
}

export function checkUserSession() {
  return async (dispatch: DispatchFunction) => {
    dispatch(actions.auth.setIsCheckingSession(true));
    try {
      const json = await apiGet(GET_SESSION_USER);
      if (Number(json.User.Role) > 2) throw Error;
      dispatch(actions.permissions.setLoggedInUserViewConfig(json.ViewConfig));
      dispatch(actions.auth.stayLoggedIn(json.User, json.Token));
      dispatch(actions.contactForms.feedback.setFrom(json.User.Email));
    } catch (error) {
      dispatch(actions.auth.logout());
    }
  };
}

export function postToFreshDesk(
  email: string,
  message: string,
  fromPage: string,
  tags: string[],
  fromSupportPage?: boolean
) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "POST_TO_FRESHDESK";
    dispatch(setLoading(statusId));
    try {
      await apiPost("/tickets/create-feedback", {
        Subject: "Feedback",
        Message: message,
        Source: fromPage,
        Tags: tags,
        RespondTo: email,
      });

      const title = fromSupportPage ? "FAQ-formulär" : "Feedback";
      const response2 = await fetch("/form", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": title,
          subject: `${title} [Teacher]: ${localStorageGet(
            "first_name"
          )} ${localStorageGet("last_name")}`,
          userid: localStorageGet("user_id") ?? "",
          name: `${localStorageGet("first_name")} ${localStorageGet(
            "last_name"
          )}`,
          email: email,
          message: message,
          fromPage: fromPage,
        }),
      });

      if (!response2.ok) throw Error();
      dispatch(
        addMessage("", i18next.t("support.contactForm.success"), "success")
      );
      dispatch(setSuccess(statusId));
    } catch (error) {
      dispatch(
        addMessage(
          "Något gick fel",
          "Försök skicka igen, om det misslyckas ber vi dig ringa oss.",
          "error"
        )
      );
      dispatch(setError(statusId));
    }
  };
}

export function getOrganizationsAndSchools(organizationID: number) {
  return async (dispatch: DispatchFunction) => {
    await dispatch(getOrganizations(organizationID));
    dispatch(getSchools());
  };
}

export function getOrganizations(
  organizationID: number,
  include_inactive?: boolean
) {
  return async (dispatch: DispatchFunction) => {
    try {
      const json = await apiGet(GET_ALL_ORGANIZATIONS, {
        include_inactive: include_inactive ?? true,
      });
      if (!json.Organizations) throw Error;
      dispatch(actions.organizations.setOrganizations(json.Organizations));
      dispatch(
        actions.organizations.setSelectedOrganization(
          json.Organizations.find(
            (o: Organization) => o.ID === organizationID
          ) || new Organization()
        )
      );
    } catch (error) {
      // Sentry.captureException(error);
    }
  };
}

function changeSchoolID(organizationID: number) {
  return async (dispatch: DispatchFunction) => {
    try {
      const json = await apiGet(GET_ORGANIZATION_SCHOOL_ID, {
        organization_id: organizationID,
      });
      if (json.SchoolID > 0)
        dispatch(actions.organizations.changeSchool(json.SchoolID));
      dispatch(getSchools());
    } catch (error) {
      dispatch(actions.organizations.setNoSchoolsInOrganization(true));
      dispatch(actions.schools.setSchools([]));
      Sentry.captureException(error);
      console.warn(error);
    }
  };
}

export function setDateRangePreset1() {
  return async (dispatch: DispatchFunction) => {
    try {
      const json = await apiGet("/get-organization-creation-time", {
        organization_id: Number(localStorageGet("organization_id")),
      });
      dispatch(actions.homePage.setFromDate(new Date(json.CreationTime)));
      dispatch(actions.homePage.setToDate(new Date()));
      dispatch(actions.homePage.setActivePreset(1));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("SET_ORGANZATION_CREATION_TIME"));
    }
  };
}

export function setDateRangePreset2() {
  return async (dispatch: DispatchFunction) => {
    dispatch(actions.homePage.setFromDate(jan1stPrevYear));
    dispatch(actions.homePage.setToDate(dec31stPrevYear));
    dispatch(actions.homePage.setActivePreset(2));
  };
}

export function setDateRangePreset3() {
  return async (dispatch: DispatchFunction) => {
    dispatch(actions.homePage.setFromDate(jan1stThisYear));
    dispatch(actions.homePage.setToDate(new Date()));
    dispatch(actions.homePage.setActivePreset(3));
  };
}

function getSchools() {
  return async (dispatch: DispatchFunction) => {
    try {
      const organizationID = Number(localStorageGet("organization_id"));
      const json = await apiGet(GET_TEACHER_SCHOOLS, {
        organization_id: organizationID,
      });
      if (!json.Schools) throw Error;
      dispatch(
        actions.schools.setSelectedSchool(
          json.Schools.find(
            (school: School) =>
              school.ID === Number(localStorageGet("school_id")) || new School()
          )
        )
      );
      dispatch(actions.schools.setSchools(json.Schools));
      dispatch(actions.organizations.setNoSchoolsInOrganization(false));
    } catch (error) {
      dispatch(actions.organizations.setNoSchoolsInOrganization(true));
      dispatch(actions.schools.setSchools([]));
      Sentry.captureException(error);
      console.warn(`getSchools failed: ${error}`);
    }
  };
}

export function getOutliers(
  users: User[],
  courses: Course[],
  schoolClasses: SchoolClass[]
) {
  return async (dispatch: DispatchFunction) => {
    const outliers = await findOutliers(users, courses, schoolClasses);
    dispatch(actions.homePage.setOutliers(outliers));
    dispatch(setSuccess("GET_OUTLIERS"));
  };
}

export function sortList(
  list: OrderBy[],
  tagName: string,
  sorter: (sortOrder: OrderBy[]) => {
    type: string;
    sortOrder: OrderBy[];
  },
  order?: SortOrder
) {
  return async (dispatch: DispatchFunction) => {
    const l = [...list];
    if (l[0].tag === tagName) {
      l[0].order = order
        ? order
        : l[0].order === SortOrder.Ascending
        ? SortOrder.Descending
        : SortOrder.Ascending;
      dispatch(sorter(l));
    } else {
      dispatch(sorter(sortBy(l, ({ tag }) => (tag === tagName ? 0 : 1))));
    }
  };
}

export function changeOrganization(o: Organization, schoolId?: number) {
  return async (dispatch: DispatchFunction) => {
    await dispatch(abortAll());
    dispatch(actions.organizations.setSelectedOrganization(o));
    dispatch(actions.organizations.setOrganizationsIsOpen(false));
    dispatch(actions.filter.clearFilter("organizations"));
    dispatch(actions.filter.clearFilter("schools"));
    if (schoolId) {
      dispatch(actions.organizations.changeSchool(schoolId));
      dispatch(getSchools());
    } else {
      await dispatch(changeSchoolID(o.ID));
    }

    dispatch(clearSchoolData());
    dispatch(fetchAll());
  };
}

export function changeSchool(s: School) {
  return async (dispatch: DispatchFunction) => {
    await dispatch(abortAll());
    dispatch(actions.organizations.changeSchool(s.ID));
    dispatch(actions.organizations.setSchoolsIsOpen(false));
    dispatch(actions.filter.clearFilter("organizations"));
    dispatch(actions.filter.clearFilter("schools"));
    dispatch(clearSchoolData());
    dispatch(fetchAll());
    dispatch(actions.organizations.setSelectedSchool(s));
  };
}

export function getAllSimulators() {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_ALL_SIMULATORS"));
      const json = await apiGet("/all-simulators", { language: fetchLang() });
      dispatch(actions.allSimulators.setSimulators(json.Simulators));
      dispatch(setSuccess("GET_ALL_SIMULATORS"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_ALL_SIMULATORS"));
      console.warn(`getAllSimulators failed: ${error}`);
    }
  };
}

export function getReleaseNotes() {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_RELEASE_NOTES"));

      const response = await fetch(
        `https://update.skillster.se/updates/Simulator/news-${fetchLang().substr(
          0,
          2
        )}?${Date.now()}`,
        { method: "GET" }
      );
      const text = await response.text();

      dispatch(actions.releaseNotes.set(parseReleaseNotes(text)));

      dispatch(setSuccess("GET_RELEASE_NOTES"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_RELEASE_NOTES"));
      console.warn(`getAllSimulators failed: ${error}`);
    }
  };
}

export function getSchoolMiniStats(fromDate: Date, toDate: Date) {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_SCHOOL_MINI_STATS"));
      const json = await apiGet("/school-stats", {
        fromDate: convertDateToYYYMMDD(fromDate),
        toDate: convertDateToYYYMMDD(toDate),
      });
      dispatch(
        actions.homePage.setSchoolMiniStats(
          json.TravelDistance,
          json.FuelConsumption,
          json.RunTime
        )
      );
      dispatch(setSuccess("GET_SCHOOL_MINI_STATS"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_SCHOOL_MINI_STATS"));
      console.warn(`get school classes total runtime failed: ${error}`);
    }
  };
}

export function getLatestExercises(count: number) {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_LATEST_EXERCISES"));
      const json = await apiGet("/latest-exercises", {
        language: fetchLang(),
        count,
      });
      dispatch(actions.homePage.setLatestExercises(json));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_LATEST_EXERCISES"));
      console.warn(`get latest exercises failed: ${error}`);
    }
  };
}

function getSchoolClassesRuntime() {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_SCHOOL_CLASSES_RUNTIME"));
      const json = await apiGet("/total-runtime-per-schoolclass");
      dispatch(
        actions.homePage.setSchoolClassesRuntime(
          json.map((entry: { SchoolClassID: number; RunTime: number }) => ({
            schoolClassID: entry.SchoolClassID,
            runtime: entry.RunTime,
          }))
        )
      );
      dispatch(setSuccess("GET_SCHOOL_CLASSES_RUNTIME"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_SCHOOL_CLASSES_RUNTIME"));
      console.warn(`getSchoolClassesRuntime failed: ${error}`);
    }
  };
}

function getSchoolLatestRuns() {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_SCHOOL_LATEST_RUNS"));
      const json = await apiGet("/latest-runs", { language: fetchLang() });
      dispatch(actions.homePage.setLatestRuns(json));
      dispatch(setSuccess("GET_SCHOOL_LATEST_RUNS"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_SCHOOL_LATEST_RUNS"));
      console.warn(`getSchoolLatestRuns failed: ${error}`);
    }
  };
}

export function getStatsLoggedInUsers() {
  return async (dispatch: DispatchFunction) => {
    dispatch(setLoading("GET_STATS_USERS"));
    try {
      const usersLastDay = await apiGet(GET_STATS_LOGGED_IN_USERS, {
        daysBack: 1,
      });
      if (usersLastDay.status === 404) throw Error;
      dispatch(actions.stats.setUsers("lastDay", usersLastDay.Users));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(error);
    }
    try {
      const usersLastWeek = await apiGet(GET_STATS_LOGGED_IN_USERS, {
        daysBack: 7,
      });
      if (usersLastWeek.status === 404) throw Error;
      dispatch(actions.stats.setUsers("lastWeek", usersLastWeek.Users));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(error);
    }
    try {
      const usersLastMonth = await apiGet(GET_STATS_LOGGED_IN_USERS, {
        daysBack: 30,
      });
      if (usersLastMonth.status === 404) throw Error;
      dispatch(actions.stats.setUsers("lastMonth", usersLastMonth.Users));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(error);
    }
    try {
      const usersLastYear = await apiGet(GET_STATS_LOGGED_IN_USERS, {
        daysBack: 365,
      });
      if (usersLastYear.status === 404) throw Error;
      dispatch(actions.stats.setUsers("lastYear", usersLastYear.Users));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(error);
    }
    dispatch(setSuccess("GET_STATS_USERS"));
  };
}

export function getActiveUsersPerDayAllSchools(daysBack: number) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "GET_ACTIVE_USERS_PER_DAY_ALL_SCHOOLS";
    try {
      dispatch(setLoading(statusId));
      const usersPerDay = await apiGet(GET_STATS_LOGGED_IN_USERS_PER_DAY, {
        daysBack: daysBack,
      });
      if (usersPerDay.status === 404) throw Error;
      dispatch(actions.stats.setUsersPerDay(usersPerDay.UsersPerDay));
      dispatch(setSuccess(statusId));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(error);
      dispatch(setError(statusId));
    }
  };
}

function getAllAssessments() {
  return async (dispatch: DispatchFunction) => {
    const statusId = GET_ALL_ASSESSMENTS;
    try {
      dispatch(setLoading(statusId));
      const response = await apiGet(GET_ALL_ASSESSMENTS, {
        language: fetchLang(),
      });
      dispatch(actions.assessments.set(response));
      dispatch(setSuccess(statusId));
    } catch (error) {
      dispatch(setError(statusId));
    }
  };
}

export function deleteOrganization(ID: number) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "DELETE_ORGANIZATION";
    try {
      dispatch(setLoading(statusId));
      await apiDelete(DELETE_DELETE_ORGANIZATION, { ID });
      dispatch(setSuccess(statusId));
      dispatch(
        addMessage(
          "Organisation borttagen",
          "Adjö, välkommen åter 👋",
          "success"
        )
      );
      dispatch(getOrganizations(Number(localStorageGet("organization_id"))));
    } catch (error) {
      dispatch(
        addMessage(
          "Något gick fel",
          "Organisationen kunde inte tas bort.",
          "error"
        )
      );
      dispatch(setError(statusId));
    }
  };
}

export function reactivateOrganization(
  ID: number,
  conflictingName: string,
  newName?: string
) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "REACTIVATE_ORGANIZATION";
    try {
      dispatch(setLoading(statusId));
      await apiPost(POST_REACTIVATE_ORGANIZATION, { ID, Name: newName });
      dispatch(setSuccess(statusId));
      dispatch(
        addMessage(
          "Organisation återaktiverad",
          "Nu kan de tuta och köra",
          "success"
        )
      );
      dispatch(getOrganizations(Number(localStorageGet("organization_id"))));
    } catch (error: any) {
      if (error?.Response?.Error?.name) {
        dispatch(
          addMessage(
            "Namndubblett",
            `Det finns redan en organisation med namnet ${conflictingName}.`,
            "error"
          )
        );
        dispatch(setError(`${statusId}_NAME_CONFLICT`));
      } else {
        dispatch(
          addMessage(
            "Något gick fel",
            "Organisationen kunde inte återaktiveras.",
            "error"
          )
        );
      }
      dispatch(setError(statusId));
    }
  };
}

export function deleteSchool(ID: number) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "DELETE_SCHOOL";
    try {
      dispatch(setLoading(statusId));
      await apiDelete(DELETE_DELETE_SCHOOL, { ID });
      dispatch(setSuccess(statusId));
      dispatch(addMessage("Skola borttagen", "På återseende 👋", "success"));
      dispatch(fetchAllSchools());
    } catch (error) {
      dispatch(
        addMessage("Något gick fel", "Skolan kunde inte tas bort.", "error")
      );
      dispatch(setError(statusId));
    }
  };
}

export function reactivateSchool(
  ID: number,
  conflictingName: string,
  newName?: string
) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "REACTIVATE_SCHOOL";
    try {
      dispatch(setLoading(statusId));
      await apiPost(POST_REACTIVATE_SCHOOL, { ID, Name: newName });
      dispatch(setSuccess(statusId));
      dispatch(addMessage("Skolan återaktiverad", "Suveränt!", "success"));
      dispatch(fetchAllSchools());
    } catch (error: any) {
      if (error?.Response?.Error?.name) {
        dispatch(
          addMessage(
            "Namndubblett",
            `Det finns redan en skola med namnet ${conflictingName}.`,
            "error"
          )
        );
        dispatch(setError(`${statusId}_NAME_CONFLICT`));
      } else {
        dispatch(
          addMessage(
            "Något gick fel",
            "Skolan kunde inte återaktiveras.",
            "error"
          )
        );
      }
      dispatch(setError(statusId));
    }
  };
}

const stripMarkup = (s: string) => s.replace(/<\/?[a-zA-Z0-9]*>/g, "");

export const [fetchCourses, invalidateCourses] = generateListActions<Course>(
  "courses",
  async () => {
    const signal = store.getState().fetchController.courses.signal;
    const json = await apiGet(GET_COURSES, { language: fetchLang() }, signal);
    return json.Courses;
  }
);

export const [fetchLessons, invalidateLessons] = generateListActions<Lesson>(
  "lessons",
  async () => {
    const signal = store.getState().fetchController.lessons.signal;
    const json = await apiGet(GET_LESSONS, { language: fetchLang() }, signal);
    const lessons = json.Lessons.map((lesson: Lesson) => ({
      ...lesson,
      Exercises: lesson.Exercises.map((e: Exercise) => ({
        ...e,
        Description: stripMarkup(e.Description),
      })),
    }));
    return lessons;
  }
);

export const [fetchExercises, invalidateExercises] =
  generateListActions<Exercise>("exercises", async () => {
    const signal = store.getState().fetchController.exercises.signal;
    const json = await apiGet(GET_EXERCISES, { language: fetchLang() }, signal);
    const exercises = json.Exercises.map((e: Exercise) => ({
      ...e,
      Description: stripMarkup(e.Description),
    }));
    return exercises;
  });

export const [fetchUsers, invalidateUsers] = generateListActions<User>(
  "users",
  async () => {
    const signal = store.getState().fetchController.users.signal;
    const json = await apiGet(GET_USERS_FROM_SCHOOL, undefined, signal);
    return json.Users;
  }
);

export const [fetchAllSchools, invalidateAllSchools] =
  generateListActions<School>("allSchools", async () => {
    const json = await apiGet(GET_ALL_SCHOOLS, { include_inactive: true });
    return json;
  });

export const [fetchClassesOptionalSchool, invalidateClassesOptionalSchool] =
  generateListActions<School>(
    "classesOptionalSchool",
    async (dispatch: DispatchFunction, extraArgs?: any) => {
      let json;
      try {
        json = await apiGet(GET_SCHOOL_CLASSES, extraArgs);
      } catch (error) {
        console.log(error);
      }
      return json.SchoolClasses;
    }
  );

export const [fetchClasses, invalidateClasses] =
  generateListActions<SchoolClass>("classes", async () => {
    const signal = store.getState().fetchController.classes.signal;
    const json = await apiGet(GET_SCHOOL_CLASSES, undefined, signal);
    return json.SchoolClasses;
  });

function fetchExerciseVariants() {
  return async (dispatch: DispatchFunction) => {
    try {
      const json = await apiGet(GET_EXERCISE_VARIANTS, {
        language: fetchLang(),
      });
      dispatch({
        type: EXERCISE_VARIANTS_SET_VARIANTS,
        variants: json.map((ev: ExerciseVariant) => ({
          ...new ExerciseVariant(),
          ...ev,
        })),
      });
    } catch (e) {
      console.log(`Uncaught exception while getting exercise variants: ${e}`);
    }
  };
}

export function getAllowedOrganizationsForCourse(courseUUID: string) {
  return async (dispatch: DispatchFunction) => {
    const stateId = `GET_ALLOWED_ORG_COURSE`;
    try {
      dispatch(setLoading(stateId));
      const json = await apiGet("/course/allowed-organizations", {
        language: fetchLang(),
        uuid: courseUUID,
      });
      dispatch(
        actions.courseAccess.setAccessModal({
          courseID: json.CourseId,
          publicCourseUUID: json.PublicCourseUUID,
          allowedOrganizations: json.Organizations,
        })
      );
      dispatch(setSuccess(stateId));
    } catch (error) {
      dispatch(setError(stateId));
    }
  };
}

export function createOrganization(
  Name: string,
  LicenseCount: number,
  DefaultLanguage: Language,
  Environment: string
) {
  return async (dispatch: DispatchFunction) => {
    const stateId = "CREATE_ORGANIZATION";
    try {
      dispatch(setLoading(stateId));
      await apiPost("/admin/create-organization", {
        Name,
        LicenseCount,
        DefaultLanguage,
        Environment,
      });
      dispatch(getOrganizations(Number(localStorageGet("organization_id"))));
      dispatch(setSuccess(stateId));
      dispatch(
        addMessage("Organisation skapad", "Då var det gjort.", "success")
      );
    } catch (error) {
      dispatch(
        addMessage(
          "Något gick fel",
          "Organisationen kunde inte skapas. Prova med ett annat namn.",
          "error"
        )
      );
      dispatch(setError(stateId));
    }
  };
}

export function updateOrganization(
  Id: number,
  Name: string,
  ContactInfo: string,
  LicenseCount: number,
  Version: number,
  DefaultLanguage: Language,
  Environment: string | null
) {
  return async (dispatch: DispatchFunction) => {
    const statusId = "UPDATE_ORGANIZATION";
    try {
      dispatch(setLoading(statusId));
      await apiPut("/admin/update-organization", {
        Id,
        Name,
        ContactInfo,
        LicenseCount,
        Version,
        DefaultLanguage,
        Environment,
      });
      dispatch(getOrganizations(Number(localStorageGet("organization_id"))));
      dispatch(setSuccess(statusId));
      dispatch(
        addMessage("Organisation uppdaterad!", "Mycket bra.", "success")
      );
    } catch (error: any) {
      if (error?.Response?.Error?.name) {
        dispatch(
          addMessage(
            "Namndubblett",
            `Det finns redan en organisation med detta namn.`,
            "error"
          )
        );
      } else {
        dispatch(
          addMessage(
            "Något gick fel!",
            "Gick inte att uppdatera. Prova igen.",
            "error"
          )
        );
      }
      dispatch(setError(statusId));
    }
  };
}

export function createSchool(
  OrganizationId: number,
  Name: string,
  CreateTeacherClass: boolean,
  ReleaseChannel: string
) {
  return async (dispatch: DispatchFunction) => {
    const stateId = "CREATE_SCHOOL";
    try {
      dispatch(setLoading(stateId));
      await apiPost("/create-school", {
        OrganizationId,
        Name,
        CreateTeacherClass,
        ReleaseChannel,
      });
      dispatch(getOrganizations(Number(localStorageGet("organization_id"))));
      dispatch(fetchAllSchools());
      dispatch(getSchools());
      dispatch(setSuccess(stateId));
      dispatch(addMessage("Skola skapad", "Då var det gjort!", "success"));
    } catch (error) {
      dispatch(setError(stateId));
      dispatch(
        addMessage(
          "Något gick fel",
          "Skolan kunde inte skapas. Prova igen.",
          "error"
        )
      );
    }
  };
}

export function updateSchool(
  Id: number,
  OrganizationId: number,
  Name: string,
  Version: number,
  ReleaseChannel: string,
  LicenseCount: number,
  Address: string,
  VrEnabled: boolean
) {
  return async (dispatch: DispatchFunction) => {
    const stateId = "UPDATE_SCHOOL";
    try {
      dispatch(setLoading(stateId));
      await apiPut("/update-school", {
        Id,
        OrganizationId,
        Name,
        Version,
        ReleaseChannel,
        LicenseCount,
        Address,
        VrEnabled,
      });
      dispatch(fetchAllSchools());
      dispatch(getSchools());
      dispatch(setSuccess(stateId));
      dispatch(addMessage("Skola uppdaterad", "Bra jobbat", "success"));
    } catch (error) {
      dispatch(
        addMessage(
          "Något gick fel",
          "Skolan kunde inte uppdateras. Kontrollera uppgifterna.",
          "error"
        )
      );
      dispatch(setError(stateId));
    }
  };
}

export function moveSchoolClass(
  SchoolClassID: number,
  OrganizationID: number,
  NewSchoolID: number
) {
  return async (dispatch: DispatchFunction) => {
    const stateId = "MOVE_SCHOOL_CLASS";
    try {
      dispatch(setLoading(stateId));
      await apiPut("/move-class", {
        ID: SchoolClassID,
        OrganizationID,
        SchoolID: NewSchoolID,
      });
      dispatch(fetchAllSchools());
      dispatch(setSuccess(stateId));
      dispatch(
        addMessage(
          "Klassen är flyttad",
          "Snyggt! Alla elever följde med också.",
          "success"
        )
      );
    } catch (error) {
      dispatch(
        addMessage(
          "Något gick fel",
          "Äsch! Klassen kunde inte flyttas. Prova igen.",
          "error"
        )
      );
      dispatch(setError(stateId));
    }
  };
}

export function getCourseAccesses() {
  return async (dispatch: DispatchFunction) => {
    const stateId = `GET_ALL_COURSE_ACCESSES`;
    try {
      dispatch(setLoading(stateId));
      const json: CourseAccess[] = await apiGet("/course/all-course-accesses");
      const map: { [publicCourseUUID: string]: number[] } = {};
      json.forEach((access: CourseAccess) => {
        if (map[access.PublicCourseUUID]) {
          map[access.PublicCourseUUID].push(access.OrganizationID);
        } else {
          map[access.PublicCourseUUID] = [access.OrganizationID];
        }
      });
      store.getState().courses.list.forEach((course: Course) => {
        if (!map[course.PublicUUID])
          map[course.PublicUUID] = [SKILLSTER_ORGANIZATION_ID];
      });
      dispatch(actions.courseAccess.setCourseAccesses(map));
      dispatch(setSuccess(stateId));
    } catch (error) {
      console.log(error);
      dispatch(setError(stateId));
    }
  };
}

export function setLoading(type: string) {
  return async (dispatch: DispatchFunction) =>
    dispatch({ type: `${type}_LOADING` });
}
export function setError(type: string) {
  return async (dispatch: DispatchFunction) =>
    dispatch({ type: `${type}_ERROR` });
}
export function setSuccess(type: string) {
  return async (dispatch: DispatchFunction) =>
    dispatch({ type: `${type}_SUCCESS` });
}
export function setReset(type: string) {
  return async (dispatch: DispatchFunction) =>
    dispatch({ type: `${type}_RESET` });
}

export function addMessage(
  title: string,
  message: string,
  sort?: "success" | "error"
) {
  return async (dispatch: DispatchFunction) => {
    dispatch({
      type: `${uuidv4()}_ADD_MESSAGE`,
      title,
      message,
      sort,
    });
  };
}
export function removeMessage(identifier: string) {
  return async (dispatch: DispatchFunction) =>
    dispatch({ type: `${identifier}_REMOVE_MESSAGE` });
}

export function getLatestApplicationVersion() {
  return async (dispatch: DispatchFunction) => {
    try {
      const json = await apiGet("/latest-application-version");
      dispatch({
        type: LATEST_APPLICATION_VERSION,
        version: json.LatestVersion,
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log(
        `Uncaught exception while getting application version: ${error}`
      );
    }
  };
}

function clearSchoolData() {
  return (dispatch: DispatchFunction) => {
    dispatch(invalidateUsers());
    dispatch(invalidateClasses());
    dispatch(actions.simulatorsInitialOrder.clearOrder());
    dispatch(actions.homePage.clearHomePage());
    dispatch(setReset("GET_SCHOOL_CLASSES_RUNTIME"));
    dispatch(setReset("GET_SCHOOL_LATEST_RUNS"));
  };
}

export const fetchAll = () => (dispatch: DispatchFunction) => {
  dispatch(fetchCourses());
  dispatch(fetchClasses());
  dispatch(fetchUsers());
  dispatch(fetchLessons());
  dispatch(fetchExercises());
  dispatch(getSchoolLatestRuns());
  dispatch(getReleaseNotes());
  dispatch(getSchoolClassesRuntime());
  dispatch(getAllAssessments());
  dispatch(fetchExerciseVariants());
  dispatch(fetchLocks());
};

export function abortAll() {
  return async (dispatch: DispatchFunction) => {
    await store.getState().fetchController.courses.abort();
    await store.getState().fetchController.classes.abort();
    await store.getState().fetchController.users.abort();
    await store.getState().fetchController.simulators.abort();
    await store.getState().fetchController.lessons.abort();
    await store.getState().fetchController.exercises.abort();
    await dispatch(actions.fetchController.clearCoursesController());
    await dispatch(actions.fetchController.clearClassesController());
    await dispatch(actions.fetchController.clearUserController());
    await dispatch(actions.fetchController.clearSimulatorsController());
    await dispatch(actions.fetchController.clearLessonsController());
    await dispatch(actions.fetchController.clearExercisesController());
  };
}

export default actions;
