export class Simulator {
  ID: number = 0;
  UserID: number = 0;
  ExerciseID: number = 0;
  PublicExerciseUUID: string = "";
  CourseID: number = 0;
  CourseName: string = "";
  OrganizationID: number = 0;
  OrganizationName: string = "";
  OrganizationVersion: number = 0;
  IsOnline: boolean = false;
  LastActiveTime: string = "";
  AppVersion: string = "";
  SimulatorNumber: number = 0;
  InstallationID: string = "";
  Name: string = "";
  IsLatest: boolean = false;
  IsActivated: number = 0;
  SchoolName?: string;
  SchoolID: number = 0;
  ActivationTime: string = "";
  DeactivationTime: string = "";
  ActivationChangedBy: string = "";
}
