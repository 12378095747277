import { localStorageGet } from "../helpers/localstorage.helper";
import { 
    SUPPORT_SET_EMAIL,
    SUPPORT_SET_MESSAGE,
    SUPPORT_SUCCESS,
} from "../types/redux.types";

class SupportState {
    email: string = localStorageGet("email") ?? "";
    message: string = "";
}

export function supportReducer(state = new SupportState(), action: any): SupportState {
    switch (action.type) {
        case SUPPORT_SET_EMAIL:
            return {
                ...state,
                email: action.email
            }
        case SUPPORT_SET_MESSAGE:
            return {
                ...state,
                message: action.message
            }
        case SUPPORT_SUCCESS:
            return {
                ...state,
                message: ""
            };
    }
    return state;
}

