import { Box, Group, } from "@mantine/core";
import { IconArrowLeft, IconArrowRight, } from "@tabler/icons";
import { useAddSegmentProgress, useRemoveSegmentProgress } from "../../Admin/TeacherLectures/TeacherLectures.api";
import Check from "./Check";
import PresentationButton from "./PresentationButton";

interface SteppersProps {
    onClickPrevious: () => void;
    onClickNext: () => void;
    hidePrevious?: boolean;
    hideNext?: boolean;
    currentIsCompleted?: boolean;
    segmentId: number;
}
function Steppers(props: SteppersProps) {
    const addProgressMutation = useAddSegmentProgress();
    const removeProgressMutation = useRemoveSegmentProgress();
    const markAsCompleted = () => addProgressMutation.mutate({ segmentID: props.segmentId });
    const markAsNotCompleted = () => removeProgressMutation.mutate({ segmentID: props.segmentId });

    return (
        <Group
            spacing={7}
            sx={{ flexGrow: 0 }}
        >
            <Box
                style={{ opacity: props.hidePrevious ? .5 : 1 }}
            >
                <PresentationButton
                    variant="square"
                    icon={<IconArrowLeft width={18} />}
                    label="Föregående"
                    onClick={props.hidePrevious ? undefined : props.onClickPrevious}
                    disabled={props.hidePrevious}
                />
            </Box>
            <Box
                style={{ opacity: props.hideNext ? .5 : 1 }}
            >
                <PresentationButton
                    variant="square"
                    icon={<IconArrowRight width={18} />}
                    label="Nästa"
                    onClick={props.hideNext ? undefined : props.onClickNext}
                    disabled={props.hideNext}
                />
            </Box>
            <PresentationButton
                variant="round"
                onClick={props.currentIsCompleted ? markAsNotCompleted : markAsCompleted}
                icon={
                    <Check
                        checked={props.currentIsCompleted ?? false}
                        disableTooltip
                        withoutLabel
                        loading={addProgressMutation.isLoading || removeProgressMutation.isLoading}
                        setChecked={() => { }}
                        active
                        index={0}
                    />
                }
                label={props.currentIsCompleted ? "Markera som ej läst" : "Markera som läst"}
            />
        </Group>
    );
}

export default Steppers;