import Lottie from 'react-lottie-player';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from '../../../actions';
import { HOME_PATH } from '../../../navigation/Routes';
import { Button } from '../../global/Misc/Misc.components';
import { PageNotFoundWrapper } from "./PageNotFound.styled.components";
import animation from './resources/lottie/73234-robot-assistant-online-manager.json';

export const NotFound = () => {

    const dispatch = useDispatch();

    const onOpenFeedbackModal = () => dispatch(actions.modals.setIsOpen("feedback", true));

    return (
        <PageNotFoundWrapper>
            <div className="animation">
                <Lottie
                    style={{ width: "100%" }}
                    play
                    loop
                    animationData={animation}
                />
            </div>
            <div className="text">
                <h1>Hmm… vad hände?</h1>
                <div className="description">
                    Vi kan inte hitta sidan du söker. Hör av dig till oss <span className="link" onClick={onOpenFeedbackModal}>här</span> om du behöver hjälp, eller prova en annan sida.
                </div>
                <Link to={HOME_PATH}><Button>Till startsidan</Button></Link>
            </div>


        </PageNotFoundWrapper>
    );
}