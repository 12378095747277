import { Paper, Loader } from "@mantine/core";
import { Link } from "react-router-dom";
import { fetchLang } from "../../../../../../helpers/universal.helper";
import { ACADEMY_PATH } from "../../../../../../navigation/Routes";
import { Segment } from "../../../../../../types/lecture.types";
import { useAddSegmentProgress, useLectureProgress, useRemoveSegmentProgress } from "../../../../Admin/TeacherLectures/TeacherLectures.api";
import Check from "../../../components/Check";

interface AsideListItemProps {
    segment: Segment;
    currentId: number;
    index: number;
}

function AsideListItem(props: AsideListItemProps) {
    const name = props.segment.Names[fetchLang()];
    const active = props.currentId === props.segment.ID;
    const progressQuery = useLectureProgress();
    const addProgressMutation = useAddSegmentProgress();
    const removeProgressMutation = useRemoveSegmentProgress();

    if (progressQuery.isLoading || progressQuery.isIdle)
        return (
            <Loader size="sm" />
        );

    if (progressQuery.isError)
        return null;

    const checked = progressQuery.data?.[props.segment.LectureID]?.some(i => i.LectureSegmentID === props.segment.ID);

    return (
        <Paper
            component={Link}
            to={`${ACADEMY_PATH}/${props.segment.LectureID}/${props.segment.ID}`}
            sx={[
                (theme) => ({
                    backgroundColor: active ? "#F2F6FF" : theme.white,
                    ':hover': {
                        backgroundColor: active ? "#F2F6FF" : theme.fn.lighten("#F2F6FF", .4),
                    }
                }),
            ]}
            p="md"
            radius={2}
            style={{ position: "relative" }}
        >
            <Check
                checked={checked}
                loading={addProgressMutation.isLoading || removeProgressMutation.isLoading}
                setChecked={() => checked ? removeProgressMutation.mutate({ segmentID: props.segment.ID }) : addProgressMutation.mutate({ segmentID: props.segment.ID })}
                label={name}
                active={active}
                index={props.index}
            />
        </Paper>
    );
}

export default AsideListItem;
