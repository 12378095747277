import { Text, Tooltip, Image, useMantineTheme } from "@mantine/core";
import { ReactNode } from "react";
import { Info } from "react-feather";
import { useTranslation } from "react-i18next";
import skillsterSymbolDark from "../../../../global/Misc/resources/icons/skillster-symbol-dark.svg";

interface SelectedSchoolsProps {
    count?: number;
    skillsterOnly?: boolean;
}

function SelectedSchools(props: SelectedSchoolsProps) {
    const { t } = useTranslation();

    if (props.skillsterOnly)
        return (
            <Wrapper skillsterOnly>
                <Text size="sm" sx={{ textAlign: "center" }}>
                    {t("courses.accessControl.restrictedCard.skillsterOnly")}
                </Text>
            </Wrapper>
        );

    if (!props.count)
        return null;

    return (
        <Wrapper>
            <Text size="sm" sx={{ textAlign: "center" }}>
                {(props.count > 1) ?
                    " Utvalda skolor" :
                    " Utvald skola"
                }{" "}
                ({props.count})
            </Text>
        </Wrapper>
    )
}

interface WrapperProps {
    children: ReactNode;
    skillsterOnly?: boolean;
}

function Wrapper(props: WrapperProps) {
    const theme = useMantineTheme();
    return (
        <Tooltip label={props.children} sx={{
            padding: 0,
            margin: 0,
            flexShrink: 0
        }}>
            {props.skillsterOnly ? (
                <Image src={skillsterSymbolDark} width={15} height={15} ml={5} />
            ) : (
                <Info color={theme.colors.bluegray[8]} strokeWidth={2.5} height={15} />
            )}
        </Tooltip>
    )
}

export default SelectedSchools;