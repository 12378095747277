import { Box, Card, Center, Group, Highlight, Image, LoadingOverlay, Overlay, Stack, Title, useMantineTheme } from "@mantine/core";
import { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetExercises } from "../../../../api/exercises";
import { getCloudinaryVehicleUrl, trimCourseName } from "../../../../helpers/strings.helper";
import { getCourseBackgroundIDfromUUID, isAdminUserOnSkillsterOrganization } from "../../../../helpers/universal.helper";
import { COURSES_PATH } from "../../../../navigation/Routes";
import lakeFree from "../../../../resources/illustrations/lake-free.svg";
import lakeLinear from "../../../../resources/illustrations/lake-linear.svg";
import { Course, Exercise } from "../../../../types/course.types";
import { Lock } from "../../../../types/editor.types";
import { CourseAccessMap } from "../../../../types/school.types";
import { CourseTypeIcon } from "../../Misc/Misc.components";
import EstimatedRuntimeCourse from "./components/EstimatedRuntime";
import SelectedSchools from "./components/SelectedSchools";
import { DropdownMenu } from "./CourseCard.components";
import bg01 from "./resources/bg01.png";
import bg02 from "./resources/bg02.png";
import bg03 from "./resources/bg03.png";
import bg04 from "./resources/bg04.png";
import bg05 from "./resources/bg05.png";
import bg06 from "./resources/bg06.png";
import { useAllCourseAccesses } from "../../../pages/Courses/Courses.api";

interface CatalogCourseCardProps {
    course: Course;
    customCourse?: boolean;
    isLoading?: boolean;
    locks: Lock[];
    courseAccesses?: CourseAccessMap;
    highlight?: string;
    disableDropdown?: boolean;
    duplicated?: boolean;
}

const CatalogCourseCard = forwardRef<HTMLDivElement, CatalogCourseCardProps>((props, ref) => {

    const { course, customCourse, isLoading, highlight, locks, courseAccesses, disableDropdown, duplicated, ...rest } = props;


    const theme = useMantineTheme();
    const exercisesQuery = useGetExercises();
    const id = getCourseBackgroundIDfromUUID(course.PublicUUID);
    const customCourseBg = id === 1 ? bg01 : id === 2 ? bg02 : id === 3 ? bg03 : id === 4 ? bg04 : id === 5 ? bg05 : bg06;
    const allCourseAccessesQuery = useAllCourseAccesses();

    const restrictedSchools = allCourseAccessesQuery.data?.filter(c => c.PublicCourseUUID === props.course.PublicUUID) ?? [];
    const restrictedSchoolCount = restrictedSchools.length;
    const isRestricted = allCourseAccessesQuery.data?.some(c => c.PublicCourseUUID === props.course.PublicUUID);
    const isSkillsterOnly = restrictedSchoolCount === 1 && restrictedSchools[0].SchoolID === 25;

    const url = getCloudinaryVehicleUrl(course.ImageUUID, 140, undefined, undefined);

    const courseImageUrl =
        course.ImageUUID ?
            url :
            undefined;

    const [hovered, setHovered] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const history = useHistory();

    if (!course || course.ID === 0)
        return null;

    const lock = locks.find((lock: Lock) => lock.ResourceKey === course.PublicUUID);

    const runtime = exercisesQuery.data?.list.filter(e => e.PublicCourseUUID === course.PublicUUID).reduce((acc: number, curr: Exercise) => acc + curr.EstimatedRuntime, 0);


    return (
        <Card
            {...rest}
            ref={ref}
            sx={(theme) => ({
                position: "relative",
                minWidth: 145,
                minHeight: 160,
                height: "100%",
                backgroundColor: isSkillsterOnly ? 'rgba(255,255,255,.4)' : 'white',
                display: "flex",
                flexDirection: "column",
                boxShadow: duplicated ? `0 0 0 8px ${theme.colors.green[3]}` : menuOpen ? `0 0 0 2px ${theme.colors.blue[5]}` : undefined,
                cursor: "pointer",
                transform: duplicated ? 'scale(1.1)' : undefined,
                transition: "transform 300ms ease-in-out",
                zIndex: duplicated ? 99 : undefined,
                '.course-image': {
                    transition: "transform 200ms ease-in-out"
                },
                '&:hover': {
                    boxShadow: !duplicated ? `0 0 0 2px ${theme.colors.blue[5]}` : undefined,
                    '.course-image': {
                        transform: "scale(1.05)"
                    }
                },
            })}
            onClick={(hovered || menuOpen) ? undefined : () => history.push(`${COURSES_PATH}/${course.PublicUUID}`)}
            p={0}
        >
            <LoadingOverlay visible={isLoading ?? false} />
            {menuOpen && <Overlay opacity={.2} color={theme.colors.blue[3]} zIndex={5} />}
            <Stack
                sx={() => ({
                    background: customCourse ? `url(${customCourseBg})` : undefined,
                    backgroundSize: "cover",
                    flexGrow: 1,
                })}
                spacing={0}
                p={8}
                pb={0}
            >
                <Group
                    sx={{
                        padding: 0,
                        flexGrow: 0,
                        flexShrink: 0
                    }}
                    position="apart"
                >
                    <CourseTypeIcon
                        isLinear={course.IsLinear}
                        showText
                        customCourse={customCourse}
                    />
                    {!disableDropdown ? (
                        <DropdownMenu
                            course={course}
                            setHover={setHovered}
                            setMenuOpen={setMenuOpen}
                            open={menuOpen}
                            lock={lock}
                        />
                    ) : null}
                </Group>
                {customCourse ? (
                    <Card.Section
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "auto",
                        }}
                        mb="md"
                    >
                        <Group spacing="xs">
                            {courseImageUrl ? (
                                <Box
                                    sx={{
                                        width: 38,
                                        height: 38,
                                        backgroundColor: "white",
                                        overflow: "hidden",
                                        borderRadius: "100%",
                                    }}
                                >
                                    <img
                                        src={courseImageUrl}
                                        alt={course.Name}
                                        style={{ transform: "translateX(-20%) translateY(12%)" }}
                                        height="80%"
                                        className="course-image"
                                    />
                                </Box>

                            ) :
                                course.Vehicles.slice(0, 2).map(guid => (
                                    <Box
                                        key={guid}
                                        sx={{
                                            width: 38,
                                            height: 38,
                                            backgroundColor: "white",
                                            overflow: "hidden",
                                            borderRadius: "100%",
                                        }}
                                    >
                                        <img
                                            src={getCloudinaryVehicleUrl(guid, 145)}
                                            alt={course.Name}
                                            style={{ transform: "translateX(-20%) translateY(12%)" }}
                                            height="80%"
                                            className="course-image"
                                        />
                                    </Box>
                                ))
                            }
                            {course.Vehicles.length > 2 ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 27,
                                        height: 27,
                                        borderRadius: "100%",
                                        backgroundColor: "white"
                                    }}
                                >
                                    <Title
                                        order={6}
                                        sx={(theme) => ({
                                            color: theme.colors.bluegray[6]
                                        })}
                                    >
                                        +{course.Vehicles.length - 2}
                                    </Title>
                                </Box>
                            ) : null}
                        </Group>
                    </Card.Section>
                ) : (
                        <Card.Section
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexGrow: 20,
                                flexShrink: 0,
                                flexBasis: 0,
                                justifyContent: "center",
                                background: `url(${course.IsLinear ? lakeLinear : lakeFree}) center center no-repeat`,
                                backgroundSize: "contain",
                            }}
                        >
                            <Center>
                                <Image
                                    src={url}
                                    alt={course.Name}
                                    className="course-image"
                                />
                            </Center>
                        </Card.Section>
                )}


            </Stack>
            <Stack
                align="right"
                spacing={0}
                pt={3}
                px={8}
                mt={6}
                mb={10}
                sx={{
                    height: 42,
                    backgroundColor: isSkillsterOnly ? 'rgba(255,255,255,.4)' : 'white',
                }}
            >
                <Group position="center" sx={{ width: "100%" }}>
                    <Stack sx={{ width: "100%" }} spacing={0}>
                        <Group spacing={0} noWrap position="center" align="center">
                            <Highlight
                                highlight={highlight?.split(" ") ?? ""}
                                component={Title}
                                order={6}
                                my={0}
                                pb={0}
                                sx={(theme) => ({
                                    fontFamily: theme.headings.fontFamily,

                                    textAlign: "center",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                })}
                            >
                                {trimCourseName(course.Name)}
                            </Highlight>

                            {(isAdminUserOnSkillsterOrganization() && isRestricted) ? (
                                <SelectedSchools count={restrictedSchoolCount} skillsterOnly={isSkillsterOnly} />
                            ) : null}
                        </Group>
                        <EstimatedRuntimeCourse runtime={runtime} />
                    </Stack>
                </Group>

            </Stack>
        </Card>
    );
});

export default CatalogCourseCard;
