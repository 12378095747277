import { cloneDeep, groupBy, isEqual } from "lodash";
import { DropResult } from "react-beautiful-dnd";
import { KIOSK_IDENTIFIER, KIOSK_EXISTING_EXERCISES_IDENTIFIER, KIOSK_EXISTING_LESSONS_IDENTIFIER } from "../../../helpers/constants";
import { localStorageGet } from "../../../helpers/localstorage.helper";
import { CourseDraft, Exercise, ExerciseVariant, Lesson } from "../../../types/course.types";
import { Lock } from "../../../types/editor.types";

export const isDrop = (result: DropResult) => result.reason === "DROP";
export const isLessonDrop = (result: DropResult) => result.type === "lesson";
export const isExerciseDrop = (result: DropResult) => result.type === "exercise";
export const isDropFromKiosk = (result: DropResult) => result.source.droppableId.includes(KIOSK_IDENTIFIER);
export const isExistingLessonDrop = (result: DropResult) => result.source.droppableId.includes(KIOSK_EXISTING_LESSONS_IDENTIFIER);
export const isExistingExerciseDrop = (result: DropResult) => result.source.droppableId.includes(KIOSK_EXISTING_EXERCISES_IDENTIFIER);
export const isDropToLessons = (result: DropResult) => result.destination?.droppableId === "lessons";

export function groupVariantsByTemplateUUID(variants: ExerciseVariant[]): ExerciseVariant[][] {
    const groupedExercises = groupBy(variants, (e: ExerciseVariant) => e.ExerciseTemplateUUID);
    return Object.entries(groupedExercises).map((k: [uuid: string, variants: ExerciseVariant[]]) => k[1]);
}

export function mergeDrafts(draft1: CourseDraft, draft2: CourseDraft): CourseDraft {
    const merged = cloneDeep(draft1);
    for (let i = 0; i < draft2.Lessons.length; i++) {
        let offset = 0;
        const lessonIndex = merged.Lessons.findIndex((l: Lesson) => l.PublicUUID === draft2.Lessons[i].PublicUUID);
        const existingLesson = lessonIndex > -1 ? merged.Lessons[lessonIndex] : undefined;
        if (existingLesson) {
            draft2.Lessons[i].Exercises.forEach((exercise: Exercise, index) => {
                const exerciseExists = existingLesson.Exercises.find((e: Exercise) => e.PublicUUID === exercise.PublicUUID);
                if (!exerciseExists) {
                    merged.Lessons[lessonIndex].Exercises.splice(index + offset, 0, exercise);
                    return;
                }
            })
        } else {
            merged.Lessons.splice(i, 0, draft2.Lessons[i])
        }
        offset++;
    }
    return merged;
}

const exerciseExistsInInitialLesson = (initialCourse: CourseDraft, draftExercise: Exercise, publicLessonUUIDdraftExercise: string) =>
    initialCourse.Lessons.some((lesson: Lesson) =>
        lesson.Exercises.some((exercise: Exercise) =>
            exercise.PublicUUID === draftExercise.PublicUUID) && lesson.PublicUUID === publicLessonUUIDdraftExercise
    );

const exerciseExistsInDraftLesson = (draftCourse: CourseDraft, initialExercise: Exercise, publicLessonUUIDinitialExercise: string) =>
    draftCourse.Lessons.some((lesson: Lesson) =>
        lesson.Exercises.some((exercise: Exercise) =>
            exercise.PublicUUID === initialExercise.PublicUUID && lesson.PublicUUID === publicLessonUUIDinitialExercise)
    );

export const exerciseWasMovedFrom = (initialCourse: CourseDraft, draftExercise: Exercise, publicLessonUUIDdraftExercise: string): { publicLessonUUID: string, index: number } | undefined => {
    let lessonIndex = -1;
    const lesson = initialCourse.Lessons.find((lesson: Lesson, index: number) => {
        if (lesson.Exercises.some((exercise: Exercise) => exercise.PublicUUID === draftExercise.PublicUUID)
            && lesson.PublicUUID !== publicLessonUUIDdraftExercise) {
            lessonIndex = index;
            return true;
        }
        return false;
    }
    );
    return lesson ? { publicLessonUUID: lesson.PublicUUID, index: lessonIndex } : undefined;
}

export const getInitialExercise = (initialCourse: CourseDraft, exercise: Exercise) => {
    const lesson = initialCourse.Lessons.find((lesson: Lesson) => lesson.Exercises.some((e: Exercise) => e.PublicUUID === exercise.PublicUUID))
    return lesson?.Exercises.find((e: Exercise) => e.PublicUUID === exercise.PublicUUID);
}

export const getLessonName = (initialCourse: CourseDraft, publicLessonUUID: string, index: number) =>
    initialCourse.Lessons.find((lesson: Lesson) => lesson.PublicUUID === publicLessonUUID) ? `Lektion ${index + 1}` : "";

export const getLessonUUIDfromPublicUUID = (publicLessonUUID: string, draft: CourseDraft) => draft.Lessons.find(lesson => lesson.PublicUUID === publicLessonUUID)?.UUID;

export const isNewLesson = (initialCourse: CourseDraft, publicLessonUUID: string) =>
    !initialCourse.Lessons.some((lesson: Lesson) => lesson.PublicUUID === publicLessonUUID);

export const lessonNameIsChanged = (initialCourse: CourseDraft, currentLesson: Lesson) => {
    const initialLesson = initialCourse.Lessons.find((lesson: Lesson) => lesson.PublicUUID === currentLesson?.PublicUUID);
    return initialLesson ? !lessonsNamesAreEqual(initialLesson, currentLesson) : false;
}

const lessonsNamesAreEqual = (lesson1: Lesson, lesson2: Lesson) => {
    const lesson1IsEmpty = lesson1?.Names === null || !lesson1?.Names || lesson1?.Names.length === 0;
    const lesson2IsEmpty = lesson2?.Names === null || !lesson2?.Names || lesson2?.Names.length === 0;
    if (lesson1IsEmpty && lesson2IsEmpty)
        return true;
    if (isEqual(lesson1.Names, lesson2.Names))
        return true;
    return false;
}

export const isDeletedLesson = (initialCourse: CourseDraft, draftCourse: CourseDraft, publicLessonUUID: string) =>
    initialCourse.Lessons.some((lesson: Lesson) => lesson.PublicUUID === publicLessonUUID) &&
    !draftCourse.Lessons.some((lesson: Lesson) => lesson.PublicUUID === publicLessonUUID);

export const isNewExercise = (initialCourse: CourseDraft, draftCourse: CourseDraft, exercise: Exercise, publicLessonUUID: string) =>
    !exerciseExistsInInitialLesson(initialCourse, exercise, publicLessonUUID) && exerciseExistsInDraftLesson(draftCourse, exercise, publicLessonUUID);

export const isDeletedExercise = (initialCourse: CourseDraft, draftCourse: CourseDraft, exercise: Exercise, publicLessonUUID: string) =>
    exerciseExistsInInitialLesson(initialCourse, exercise, publicLessonUUID) && !exerciseExistsInDraftLesson(draftCourse, exercise, publicLessonUUID);

export const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
}

export const isLockedByOther = (locks: Lock[], publicUUID: string) => locks.some((lock: Lock) =>
    lock.ResourceKey === publicUUID && lock.CreatedBy !== Number(localStorageGet("user_id")));

export const draftsAreEqual = (draft1: CourseDraft, draft2: CourseDraft) => {

    const draft1Exercises: { [publicUUID: string]: Exercise } = {};
    const draft2Exercises: { [publicUUID: string]: Exercise } = {};

    if (draft1.Name !== draft2.Name)
        return false;
    if (draft1.ImageUUID !== draft2.ImageUUID)
        return false;
    if (draft1.IsLinear !== draft2.IsLinear)
        return false;
    for (let i = 0; i < draft1.Lessons.length; i++) {
        const lesson = draft1.Lessons[i];
        const draft2Lesson = draft2.Lessons.find((l: Lesson) => l.PublicUUID === lesson.PublicUUID);

        if (lessonNameIsChanged(draft1, draft2.Lessons[i]))
            return false;
        if (!draft2Lesson)
            return false;
        if (!draft2.Lessons.some((l: Lesson) => l.PublicUUID === lesson.PublicUUID))
            return false;
        for (let j = 0; j < lesson.Exercises.length; j++) {
            if (!draft2.Lessons.some((l: Lesson) => l.Exercises.some((e: Exercise) => e.PublicUUID === lesson.Exercises[j].PublicUUID)))
                return false;
            draft1Exercises[lesson.Exercises[j].PublicUUID] = lesson.Exercises[j];
        }
        if (!exerciseListsAreEqual(lesson.Exercises, draft2.Lessons[i].Exercises, true))
            return false;
    }
    for (let i = 0; i < draft2.Lessons.length; i++) {
        const lesson = draft2.Lessons[i];
        const draft1Lesson = draft1.Lessons.find((l: Lesson) => l.PublicUUID === lesson.PublicUUID);

        if (!draft1Lesson || !lessonsNamesAreEqual(draft1Lesson, lesson))
            return false;

        if (!draft1.Lessons.some((l: Lesson) => l.PublicUUID === lesson.PublicUUID))
            return false;

        for (let j = 0; j < lesson.Exercises.length; j++) {
            if (!draft1.Lessons.some((l: Lesson) => l.Exercises.some((e: Exercise) => e.PublicUUID === lesson.Exercises[j].PublicUUID)))
                return false;
            draft2Exercises[lesson.Exercises[j].PublicUUID] = lesson.Exercises[j];
        }
    }
    for (let i = 0; i < draft1.Lessons.length; i++) {
        const lesson = draft1.Lessons[i];
        for (let j = 0; j < lesson.Exercises.length; j++) {
            const exercise = lesson.Exercises[j];
            if (!exercisesAreEqual(exercise, draft2Exercises[exercise.PublicUUID]))
                return false;
        }
    }
    for (let i = 0; i < draft2.Lessons.length; i++) {
        const lesson = draft2.Lessons[i];
        for (let j = 0; j < lesson.Exercises.length; j++) {
            const exercise = lesson.Exercises[j];
            if (!exercisesAreEqual(exercise, draft1Exercises[exercise.PublicUUID]))
                return false;
        }
    }
    return true;
}

const exerciseListsAreEqual = (exercises1: Exercise[], exercises2: Exercise[], strictOrder?: boolean) => {
    for (let i = 0; i < exercises1.length; i++) {
        if (!exercises2.some((e: Exercise) => e.PublicUUID === exercises1[i].PublicUUID))
            return false;
        if (strictOrder) {
            if (exercises1[i].PublicUUID !== exercises2[i]?.PublicUUID)
                return false;
        }
    }
    for (let i = 0; i < exercises2.length; i++) {
        if (!exercises1.some((e: Exercise) => e.PublicUUID === exercises2[i].PublicUUID))
            return false;
        if (strictOrder) {
            if (exercises2[i].PublicUUID !== exercises1[i]?.PublicUUID)
                return false;
        }
    }
    return true;
}

export const exercisesAreEqual = (exercise1?: Exercise, exercise2?: Exercise) => {
    if (!exercise1 || !exercise2)
        return false;
    if (exercise1?.TrafficUUID !== exercise2?.TrafficUUID)
        return false;
    if (exercise1?.WeatherUUID !== exercise2?.WeatherUUID)
        return false;
    if (exercise1?.Difficulty !== exercise2?.Difficulty)
        return false;
    return true;
}