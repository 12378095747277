import { Action, AnyAction, applyMiddleware, compose, createStore, ThunkDispatch } from "@reduxjs/toolkit";
import rootReducer from "../reducers";
import thunk, { ThunkAction } from "redux-thunk";

const composeEnhancers =
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    process.env.NODE_ENV === "development"
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, undefined, enhancer);

type StoreState = ReturnType<typeof rootReducer>;

export type DispatchFunction = ThunkDispatch<StoreState, undefined, AnyAction>;
export type AppAction<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  unknown,
  Action<string>
>;
export type GetStateFunction = () => StoreState;


export default store;