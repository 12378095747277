import styled, { css } from "styled-components";

export const AppWrapper = styled.div`
    min-height: calc(100% + 325px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    main {
        flex-grow: 1;
    }
    .appHeader, main, .appFooter {
        flex-shrink: 0;
    }
    .empty-state {
        height: 80vh;
        .description {
            text-align: center;
            margin: 30px 0 60px 0;
        }
        .contact {
            img {
                margin-bottom: 0;
            }
        }
    }
`;

interface PageProps {
    gap?: boolean;
}
export const Page = styled.div<PageProps>`
    ${props => props.gap && css`
        display: flex;
        flex-direction: column;
        gap: 40px;
    `}
    max-width: ${props => props.theme.page.width};
    padding: 0 6px;
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
        padding: 0 16px;
    }
    @media (min-width: ${props => props.theme.page.width}) {
        padding: 16px;
    };
    margin: auto;
`;
