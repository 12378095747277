import { Anchor, Box, Button, Container, Group, Loader, MantineTheme, Menu, Stack, Text, Title, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlignCenter, IconEye, IconLogout, IconUser } from "@tabler/icons";
import { FormEvent, forwardRef, MutableRefObject, useEffect, useRef, } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import actions from "../../../actions";
import { ROLES, SKILLSTER_ORGANIZATION_ID } from "../../../helpers/constants";
import { localStorageGet } from "../../../helpers/localstorage.helper";
import { isAdminUser } from "../../../helpers/universal.helper";
import useIsMobile from "../../../hooks/useIsMobile";
import { COURSES_PATH, HOME_PATH, SCHOOL_CLASSES_PATH, SIMULATORS_PATH, SUPPORT_PATH, USERS_PATH, } from "../../../navigation/Routes";
import { RootState } from "../../../reducers";
import teacherTheme from "../../../resources/themes/teacher";
import { Organization } from "../../../types/organization.types";
import { School } from "../../../types/school.types";
import { Simulator } from "../../../types/simulator.types";
import { User } from "../../../types/user.types";
import { useGetAllSimulators } from "../../pages/Admin/AllSimulators/AllSimulators.api";
import { SelectLanguageMobile } from "../../pages/Login/Login.components";
import { Page } from "../Containers/Containers.styled.components";
import { Logo, NoSearchResults, SearchBar } from "../Misc/Misc.components";
import { SmallSpinner } from "../Misc/Misc.styled.components";
import UserBadge from "../Misc/UserBadge/UserBadge";
import AdminMenuItems from "./components/AdminMenuItems";
import Header from "./Header";
import { HeaderCircleWrapper, MenuItemsMobileWrapper, MobileMenuWrapper, MobileOrgSchoolSelectorsWrapper, OrganizationsListWrapper, PrintLogoWrapper, SelectorWrapper } from "./Header.styled.components";
import chevron from "./resources/icons/chevron.svg";
import feedbackPlus from "./resources/icons/feedback-plus.svg";
import feedback from "./resources/icons/feedback.svg";
import user from "./resources/icons/user-online.svg";

interface MenuItemsDesktopProps {
    closeMobileMenu: () => void;
    onClick?: () => void;
}
export const MenuItemsDesktop = (props: MenuItemsDesktopProps) => {
    const theme = useMantineTheme();
    const minMd = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const minLg = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    return (
        <Group
            onClick={props.onClick}
            noWrap
            sx={(theme) => ({
                backgroundColor: "rgba(0,0,0,.2)",
                height: 53,
                borderRadius: theme.radius.sm,
            })}
            className="skillster-desktop-menu"
            spacing={
                minLg ? 18 : minMd ? 8 : 7
            }
            px="lg"
        >
            <MenuItems closeMobileMenu={props.closeMobileMenu} />
        </Group>
    );
}

interface MenuItemsMobileProps {
    onLogOut: () => void;
    closeMobileMenu: () => void;
}
const MenuItemsMobile = (props: MenuItemsMobileProps) => {
    const { t } = useTranslation("common");
    return (
        <MenuItemsMobileWrapper>
            <div className="section">
                <MenuItems closeMobileMenu={props.closeMobileMenu} />
            </div>
            <h3 onClick={props.onLogOut}>{t('buttons.logOut')}</h3>
        </MenuItemsMobileWrapper>
    );
}

interface MenuItemsProps {
    closeMobileMenu: () => void;
    footer?: boolean;
}

export const MenuItems = (props: MenuItemsProps) => {
    const { t } = useTranslation("common");
    return (
        <>
            <MenuItem
                exact={false}
                label={t('menu.home')}
                url={HOME_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
            <MenuItem
                label={t('menu.schoolClasses')}
                url={SCHOOL_CLASSES_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
            <MenuItem
                label={t('menu.users')}
                url={USERS_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
            <MenuItem
                label={t('menu.courses')}
                url={COURSES_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
            <MenuItem
                label={t('menu.simulators')}
                url={SIMULATORS_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
            <MenuItem
                label={t('menu.support')}
                url={SUPPORT_PATH}
                onClick={props.closeMobileMenu}
                footer={props.footer}
            />
        </>
    );
}

interface MenuItemProps {
    onClick?: () => void;
    label: string;
    url: string;
    footer?: boolean;
    exact?: boolean;
}

const MenuItem = (props: MenuItemProps) => {
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
    const minLg = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    return (
        <Anchor
            component={NavLink}
            exact={props.exact}
            to={props.url}
            sx={(theme) => ({
                color: "#8F9299",
                fontFamily: theme.headings.fontFamily,
                fontSize: props.footer ? 15 : mobile ? 30 : minLg ? theme.headings.sizes.h5.fontSize : 15,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderBottom: "2px solid transparent",
                '&.active': {
                    color: (mobile || props.footer) ? undefined : "white",
                    borderBottom: (mobile || props.footer) ? undefined : "2px solid white",

                },
                ':hover': {
                    textDecoration: 'none',
                    color: props.footer ? theme.colors.dark[3] : "rgba(255,255,255,.8)"
                }
            })}
            onClick={props.onClick}
        >
            {props.label}
        </Anchor>
    )
}
interface OrganizationsSelectorProps {
    selectedOrganization: Organization;
    isOpen?: boolean;
    onClick?: () => void;
    show?: boolean;
}

export const OrganizationsSelector = (props: OrganizationsSelectorProps) => {
    if (!props.show) return null;
    return <Selector
        selected={{ value: props.selectedOrganization.ID ?? 0, label: props.selectedOrganization.Name ?? "" }}
            isOpen={props.isOpen}
            onClick={props.onClick}
        loading={props.selectedOrganization?.Name === ""}
        highlightIfAdminUserOutsideSkillsterOrganization
    />
}

interface SchoolsSelectorProps {
    selectedSchool: School;
    isOpen?: boolean;
    onClick?: () => void;
}

export const SchoolsSelector = (props: SchoolsSelectorProps) =>
    <Selector
        selected={{ value: props.selectedSchool?.ID ?? 0, label: props.selectedSchool?.Name ?? "" }}
        isOpen={props.isOpen}
        onClick={props.onClick}
        loading={props.selectedSchool.ID === 0}
    />


interface SelectorProps {
    selected: { value: number; label: string; };
    isOpen?: boolean;
    onClick?: () => void;
    loading?: boolean;
    highlightIfAdminUserOutsideSkillsterOrganization?: boolean;
}

const Selector = (props: SelectorProps) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const highlight = props.highlightIfAdminUserOutsideSkillsterOrganization && isAdminUser() && localStorage.getItem("organization_id") !== SKILLSTER_ORGANIZATION_ID.toString();
    const highlightStyles = {
        outline: `2px solid ${theme.colors.blue[3]}`
    };
    return (
        <SelectorWrapper
            onClick={props.onClick}
            isOpen={props.isOpen}
            style={highlight ? highlightStyles : undefined}
        >
            {props.loading ? <>
                <SmallSpinner className="spinner" color={teacherTheme.colors.comp4.mid} />
                <span className="label">{t('common:misc.loading')}</span>
            </> :
                <>
                    <img src={chevron} alt="Välj" className="chevron" />
                    <span className="label">{props.selected.label}</span>
                </>}
        </SelectorWrapper>
    );
}

interface OnlineCounterProps {
    numOnline?: number;
    loading?: boolean;
}

export const OnlineCounter = forwardRef<HTMLDivElement, OnlineCounterProps>((props, ref) => {
    const { numOnline, loading, ...rest } = props;
    return (
        <HeaderCircleWrapper {...rest} ref={ref} online={!!numOnline && numOnline > 0}>
            <img src={user} className="onlineIcon" alt="Users online" />
            {loading ?
                <Box
                    sx={(theme) => ({
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: -6,
                        right: -6,
                        width: 20,
                        height: 20,
                        borderRadius: theme.radius.xl,
                        backgroundColor: theme.colors.bluegray[5]
                    })}
                >
                    <Loader
                        variant="dots"
                        sx={(theme) => ({
                            width: 12,
                            height: 12,
                            fill: theme.colors.bluegray[4]
                        })}
                    />
                </Box>
                : (
                    <div className="dot">{numOnline}</div>
                )}
        </HeaderCircleWrapper>
    )
});

interface FeedbackProps {
    onClick?: () => void;
}

export const Feedback = forwardRef<HTMLDivElement, FeedbackProps>((props, ref) => (
    <HeaderCircleWrapper onClick={props.onClick} {...props} ref={ref}>
        <img src={feedback} alt="Ge feedback" />
        <img src={feedbackPlus} alt="Ge feedback" className="feedbackPlus" />
    </HeaderCircleWrapper>
));
interface UserButtonProps {
    initials: string;
}

const ProfileMenuButton = forwardRef<HTMLButtonElement, UserButtonProps>(
    ({ initials, ...others }: UserButtonProps, ref) => (
        <UnstyledButton
            ref={ref}
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: theme.radius.xl,
                width: 38,
                height: 38,
                backgroundColor: theme.colors.bluegray[9],
                border: "2px solid rgba(255,255,255,.1)",
                ':hover': {
                    border: "2px solid rgba(255,255,255,.3)",
                }
            })}
            {...others}
        >
            <Title order={6} sx={{ color: "white" }}>{initials}</Title>
        </UnstyledButton>
    )
);

interface OrganizationsListProps {
    isOpen?: boolean;
    organizations: Organization[];
    onClick: (o: Organization) => void;
    handleFilter: (event: FormEvent<Element>) => void;
    onClear: () => void;
    handleClose: () => void;
    searchBarValue: string;
}
export const OrganizationsList = (props: OrganizationsListProps) => {
    const { t } = useTranslation(["", "common"]);
    const allSimulatorsQuery = useGetAllSimulators();
    const wrapperRef = useRef(null);
    useEffect(() => {
        const keyPress = (e: KeyboardEvent) => e.key === "Escape" && props.handleClose();
        props.isOpen ? document.addEventListener("keydown", keyPress) : document.removeEventListener("keydown", keyPress);
    }, [props]);
    if (!props.organizations) return null;
    return (
        <CSSTransition unmountOnExit in={props.isOpen} timeout={300} classNames="fadeZoom" nodeRef={wrapperRef}>
            <OrganizationsListWrapper ref={wrapperRef}>
                <Page className="top">
                    <div className="box title">
                        <h2>{t('organizations.title')}</h2>
                    </div>
                    <div className="box search">
                        <SearchBar autoFocus onClear={props.onClear} handleSearchBar={props.handleFilter} searchBarValue={props.searchBarValue} placeholder={t('organizations.searchBar')} />
                    </div>
                    <div className="box close">
                        <label onClick={props.handleClose} className="close">{t('common:buttons.close')}</label>
                    </div>
                </Page>
                <Container mt="md">
                    <Stack style={{ width: 500, maxWidth: "100%" }}>
                    {props.organizations.length ?
                        props.organizations.map((o: Organization, index) => {
                            const simulatorsCount = isAdminUser() ? allSimulatorsQuery.data?.list?.filter((s: Simulator) => s.OrganizationID === o.ID && s.IsActivated).length : undefined;
                            return (
                                <Button
                                    variant={o.ID === Number(localStorageGet("organization_id")) ? "filled" : "light"}
                                    tabIndex={0}
                                    key={index}
                                    onClick={() => props.onClick(o)}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                    fullWidth
                                >
                                    <span className="notranslate">{o.Name}</span>
                                    {simulatorsCount ? <Box sx={{ position: "absolute", right: 10 }}>({simulatorsCount})</Box> : null}
                                </Button>
                            );
                        }
                        ) : <NoSearchResults onClear={props.onClear} />}
                    </Stack>
                </Container>
            </OrganizationsListWrapper>
        </CSSTransition>
    );
}

interface SchoolsListProps {
    isOpen?: boolean;
    schools: School[];
    onClick: (s: School) => void;
    handleFilter: (event: FormEvent<Element>) => void;
    onClear: () => void;
    handleClose: () => void;
    searchBarValue: string;
}
export const SchoolsList = (props: SchoolsListProps) => {
    const { t } = useTranslation(["", "common"]);
    const wrapperRef = useRef(null);
    const allSimulatorsQuery = useGetAllSimulators();
    useEffect(() => {
        const keyPress = (e: KeyboardEvent) => e.key === "Escape" && props.handleClose();
        props.isOpen ? document.addEventListener("keydown", keyPress) : document.removeEventListener("keydown", keyPress);
    }, [props]);
    if (!props.schools) return null;
    return (
        <CSSTransition unmountOnExit in={props.isOpen} timeout={300} classNames="fadeZoom" nodeRef={wrapperRef}>
            <OrganizationsListWrapper ref={wrapperRef}>
                <Page className="top">
                    <div className="box title">
                        <h2>{t('schools.title')}</h2>
                    </div>
                    <div className="box search">
                        <SearchBar autoFocus onClear={props.onClear} handleSearchBar={props.handleFilter} searchBarValue={props.searchBarValue} placeholder={t('schools.searchBar')} />
                    </div>
                    <div className="box close">
                        <label onClick={props.handleClose} className="close">{t('common:buttons.close')}</label>
                    </div>
                </Page>
                <Container mt="md">
                    <Stack style={{ width: 500, maxWidth: "100%" }}>
                    {props.schools.length ?
                        props.schools.map((s: School, index) => {
                            const simulatorsCount = allSimulatorsQuery.data?.list.filter((sim: Simulator) => sim.SchoolID === s.ID && sim.IsActivated).length;
                            return (
                                <Button
                                    variant={s.ID === Number(localStorageGet("school_id")) ? "filled" : "light"}
                                    tabIndex={0}
                                    key={index}
                                    onClick={() => props.onClick(s)}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                    fullWidth
                                >
                                    <span className="notranslate">{s.Name}</span>
                                    {simulatorsCount ? <Box sx={{ position: "absolute", right: 10 }}>({simulatorsCount})</Box> : null}
                                </Button>

                            );
                        }) : <NoSearchResults onClear={props.onClear} />}
                    </Stack>
                </Container>
            </OrganizationsListWrapper>
        </CSSTransition>
    );
}

const menuStyles = (theme: MantineTheme) => ({
    dropdown: {
        backgroundColor: theme.colors.bluegray[8],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: "#40495f",
    },
    item: {
        color: theme.colors.bluegray[0],
        fontWeight: 600,
        'a': {
            color: theme.colors.bluegray[0],
        },
        ':hover': {
            backgroundColor: theme.colors.bluegray[9],
            color: theme.colors.bluegray[0],
        },
        '&.admin-item': {
            ':hover': {
                backgroundColor: theme.fn.darken(theme.colors.bluegray[9], .15),
            }
        }
    },
    divider: {
        borderColor: theme.colors.bluegray[7],
        borderWidth: 2,
    },
    label: {
        color: theme.colors.bluegray[4]
    }
});

interface UserDropDownProps {
    firstName: string;
    lastName: string;
    userID: number;
    onLogout?: () => void;
    role: number;
    handleCloseOrgSchool: () => void;
}

export const UserDropDown = (props: UserDropDownProps) => {
    const { t } = useTranslation();
    const initials = `${props.firstName.charAt(0)}${props.lastName.charAt(0)}`;
    const mobile = useIsMobile();

    return (
        <Menu
            transition="pop"
            trigger={mobile ? "click" : "hover"}
            closeOnItemClick
            transitionDuration={100}
            width={220}
            position="bottom-end"
            withinPortal
            shadow="xl"
            styles={menuStyles}
        >
            <Menu.Target>
                <ProfileMenuButton initials={initials} />
            </Menu.Target>
            <Menu.Dropdown
                onClick={props.handleCloseOrgSchool}
            >
                <Menu.Item component={Link} to={`${USERS_PATH}/${props.userID}`}>
                    <Group spacing={"sm"}>
                        <UserBadge inverted user={{ ...new User(), FirstName: props.firstName, LastName: props.lastName }} />
                        <Stack spacing={0}>
                            <Text size="sm" weight="bold" sx={(theme) => ({ color: theme.colors.bluegray[3] })}>{props.firstName} {props.lastName}</Text>
                            <Text size="sm" sx={(theme) => ({ color: theme.colors.bluegray[5] })}>{ROLES()[props.role]}</Text>
                        </Stack>
                    </Group>
                </Menu.Item>
                <Menu.Item icon={<IconEye size={14} />} component={Link} to={`${USERS_PATH}/${props.userID}`}>{t('user.subMenu.courses')}</Menu.Item>
                <Menu.Item icon={<IconAlignCenter size={14} />} component={Link} to={`${USERS_PATH}/${props.userID}/drivingrecord`}>{t('user.subMenu.drivingRecord')}</Menu.Item>
                <Menu.Item icon={<IconUser size={14} />} component={Link} to={`${USERS_PATH}/${props.userID}/profile`}>{t('user.subMenu.profileDetails')}</Menu.Item>
                <Menu.Divider />
                <Menu.Label>
                    {t('common:misc.other')}
                </Menu.Label>
                {isAdminUser() ? (
                    <AdminMenuItems />
                ) : null}
                <Menu.Item icon={<IconLogout size={14} />} onClick={props.onLogout}>{t('common:buttons.logOut')}</Menu.Item>
            </Menu.Dropdown>

        </Menu>
    )
}

interface MobileMenuProps {
    isOpen?: boolean;
    onLogOut: () => void;
    closeMobileMenu: () => void;
}

export const MobileMenu = (props: MobileMenuProps) => {
    const transitionRef: MutableRefObject<any> = useRef(null);
    const dispatch = useDispatch();
    const organizationState = useSelector((state: RootState) => state.organization);
    const openOrganizationList = () => {
        dispatch(actions.organizations.setSchoolsIsOpen(false));
        dispatch(actions.organizations.setOrganizationsIsOpen(true));
    }
    const openSchoolList = () => {
        dispatch(actions.organizations.setOrganizationsIsOpen(false));
        dispatch(actions.organizations.setSchoolsIsOpen(true));
    }
    const closeOrganizationList = () => dispatch(actions.organizations.setOrganizationsIsOpen(false));
    const closeSchoolList = () => dispatch(actions.organizations.setSchoolsIsOpen(false));
    return ReactDOM.createPortal(
        <CSSTransition nodeRef={transitionRef} unmountOnExit in={props.isOpen} timeout={300} classNames="fade">
            <MobileMenuWrapper ref={transitionRef}>
                <Header />
                <MenuItemsMobile
                    onLogOut={props.onLogOut}
                    closeMobileMenu={props.closeMobileMenu}
                />
                <MobileOrgSchoolSelectorsWrapper>
                    <OrganizationsSelector
                        show={organizationState.organizations.length > 0}
                        selectedOrganization={organizationState.selectedOrganization}
                        isOpen={organizationState.organizationsIsOpen}
                        onClick={organizationState.organizationsIsOpen ? closeOrganizationList : openOrganizationList}
                    />
                    <SchoolsSelector
                        selectedSchool={organizationState.selectedSchool}
                        isOpen={organizationState.schoolsIsOpen}
                        onClick={organizationState.schoolsIsOpen ? closeSchoolList : openSchoolList}
                    />
                </MobileOrgSchoolSelectorsWrapper>
                <SelectLanguageMobile />
            </MobileMenuWrapper>
        </CSSTransition>
        , document.getElementById("mobile-menu") as HTMLElement)
};

export const PrintLogo = () => <PrintLogoWrapper><Logo /></PrintLogoWrapper>;
