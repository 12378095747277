import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeMessage } from "./actions";
import { Message } from "./components/global/Misc/Misc.components";
import { MessageContent } from "./reducers";

interface MessagesProps {
    messages: MessageContent[];
}

export const Messages = (props: MessagesProps) => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState<MessageContent | undefined>(undefined);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const mountMessage = props.messages[0];
        if (mountMessage) {
            setMessage(props.messages[0]);
            setShow(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!show && !message && props.messages[0]) {
            setMessage(props.messages[0]);
            setShow(true);
        }
    }, [props.messages]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!show) {
            const latestMessage = props.messages[0];
            if (latestMessage) {
                setTimeout(() => {
                    setMessage(latestMessage);
                    setShow(true);
                }, 500)
            }
        }
    }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => {
        dispatch(removeMessage(message?.requestName ?? ""));
        setShow(false);
    }

    return (
        <Message
            title={message?.title ?? ""}
            message={message?.message ?? ""}
            onClose={onClose}
            isOpen={show}
            success={message?.sort === "success"}
            error={message?.sort === "error"}
            onExited={() => setMessage(undefined)}
        />
    )
}