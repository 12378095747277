import { useEffect } from "react";
import { useDispatch } from "react-redux";
import actions, { checkUserSession, fetchAll } from "../../../actions";
import { localStorageGet } from "../../../helpers/localstorage.helper";
import { apiPut } from "../../../helpers/requests.helper";
import { PUT_UPDATE_WELCOME } from "../../../navigation/endpoints";
import { WelcomeGreeting } from "./Welcome.components";

function Welcome() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkUserSession());
        dispatch(fetchAll());
    }, [dispatch])

    const onClickOK = async () => {
        try {
            await apiPut(PUT_UPDATE_WELCOME, { UserID: Number(localStorageGet("user_id")) });
            dispatch(actions.auth.setHasSeenWelcomeScreen(true));
        } catch (e) {

        }
    }

    return <WelcomeGreeting onClickOK={onClickOK} />
}

export default Welcome;