import { MantineTransition } from "@mantine/core";
import { keyframes } from "styled-components";
import teacherTheme from "../../resources/themes/teacher";

export const dropdownTransition: MantineTransition = {
  in: { opacity: 1, transform: "scaleY(1)" },
  out: { opacity: 0, transform: "scaleY(0.8)" },
  common: { transformOrigin: "top" },
  transitionProperty: "transform, opacity",
};

export const animateListCards = keyframes`
    0% {
      opacity: 0;
      transform: translateY(-10px)
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0px)
    }
`;

export const animateSquareCards = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px)
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(0px)
    }
`;

export const spin = keyframes`
    from {
        animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
        transform: rotate(0deg);
    }
    to {
       transform: rotate(360deg);
    }
`;

export const animateProgressBar = keyframes`
    0% {
        width: 0
    }
`;

export const flipLoader = keyframes`
    0% {
        animation-timing-function: cubic-bezier(0.1909,0.4373,0.4509,0.7454);
        transform: rotateY(0deg);
    }
    30% {
        animation-timing-function: cubic-bezier(0.128,0.2315,0.9704,0.8632);
        transform: rotateY(153.72deg);
    }
    50% {
        animation-timing-function: cubic-bezier(0.5788,0.3001,0.5613,0.6784);
        transform: rotateY(180deg);
    }
    55% {
        animation-timing-function: cubic-bezier(0.1545,0.4929,0.6089,0.9373);
        transform: rotateY(238.68deg);
    }
    100% {
        transform: rotateY(360deg);
    }
`;

export const shake = keyframes`
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
`;

export const zoomIn = keyframes`
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
`;

export const zoomInCard = keyframes`
    from {
        opacity: 0;
        background-color: ${teacherTheme.colors.secondary.light};
        transform: scale3d(0.8, 0.8, 0.8);
    }

    20% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        background-color: ${teacherTheme.colors.secondary.light};
    }

    100% {
        background-color: white;
    }
`;

export const slideDownCard = keyframes`
    from {
        opacity: 0;
        background-color: ${teacherTheme.colors.secondary.light};
        transform: scale3d(0.8, 0.8, 0.8) translateY(-100%);
    }

    20% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }

    40% {
        background-color: ${teacherTheme.colors.secondary.light};
    }

    60% {
        background-color: white;
    }
`;

export const slideDownCardCollapsed = keyframes`
    from {
        opacity: 0;
        background-color: ${teacherTheme.colors.secondary.light};
        transform: scale3d(0.8, 0.8, 0.8) translateY(-65px);
    }

    20% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }

    40% {
        background-color: ${teacherTheme.colors.secondary.light};
    }

    60% {
        background-color: white;
    }
`;

export const fadeInDown = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -10%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const jello = keyframes`
    from,
    11.1%,
    to {
        transform: translate3d(0, 0, 0);
    }

    22.2% {
        transform: skewX(-2.5deg) skewY(-1.5deg);
    }

    33.3% {
        transform: skewX(1.25deg) skewY(1.25deg);
    }

    44.4% {
        transform: skewX(-1.125deg) skewY(-1.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.190625deg) skewY(0.190625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
`;

export const pulse = keyframes`
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.02, 1.02, 1.02);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
`;

export const zoomInDown = keyframes`
    from {
        opacity: 0;
        transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
        opacity: 1;
        transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
`;

export const sonar = keyframes`
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
`;

export const skeletonSwoosh = keyframes`
    from {
        left: -100%;
    }
    to   {
        left: 100%;
    }
`;
