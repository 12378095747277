import styled from "styled-components";

export const PageNotFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 80px);
    h1, h2, h3, h4 {
        font-family: Rajdhani, sans-serif;
    }
    h1 {
        font-size: 3em;
        line-height: 1.2em;
    }
    .animation {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 160px;
        @media (min-width: ${props => props.theme.breakpoints.mobile}) {
            max-width: 250px;
            margin-top: 0;
        }
    }
    .text {
        display: flex;
        position: relative;
        max-width: 100%;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .description {
            width: 400px;
            max-width: 100%;
        }
    }
`;