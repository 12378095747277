import { useEffect } from "react";
import { useLectures } from "../../../../../Admin/TeacherLectures/TeacherLectures.api";
import { getCloudinaryLectureUrl } from "../../../../../../../helpers/strings.helper";
import { Lecture } from "../../../../../../../types/lecture.types";
import { fetchLang } from "../../../../../../../helpers/universal.helper";

function usePreloadImages(lectureId?: string) {
  const lecturesQuery = useLectures();
  const lecture: Lecture | undefined = lectureId ? lecturesQuery.data?.map[lectureId] : undefined;

  useEffect(() => {
    const imageUrls: string[] = [];

    if (lecture) {
      lecture.Segments.list.forEach((segment) => {
        imageUrls.push(getCloudinaryLectureUrl(lecture.UUID, segment.Images[fetchLang()]));
      });

      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
        // img.addEventListener("load", () => {
        //   console.log(`Bild ${url} laddades.`);
        // });
        // img.addEventListener("error", () => {
        //   console.error(`Det uppstod ett fel vid laddning av bild ${url}.`);
        // });
      });
    }
  }, [lecture, lectureId]);

  return null;
}

export default usePreloadImages;
