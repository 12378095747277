import { Text, Group, Tooltip, useMantineTheme, Loader, Box } from "@mantine/core";
import { useState } from "react";
import { CheckCircle } from "react-feather";
import { useTranslation } from "react-i18next";

interface CheckProps {
    checked: boolean;
    active?: boolean;
    loading?: boolean;
    index: number;
    label?: string;
    disableTooltip?: boolean;
    withoutLabel?: boolean;
    setChecked: () => void;
}
function Check(props: CheckProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [hoveringCheck, setHoveringCheck] = useState(false);

    const label = props.withoutLabel ? null : (
        <Group spacing={0} noWrap align="flex-start">
            {/* <Box sx={{ width: 28, flexShrink: 0 }}>
                <Text
                    sx={(theme) => ({
                        color: props.checked ? theme.colors.bluegray[4] : theme.colors.bluegray[5],
                        fontSize: 14,
                        fontWeight: 700,
                    })}
                >
                    {`${props.index + 1}. `}
                </Text>
            </Box> */}
            {props.checked ? (
                <Text
                    sx={(theme) => ({
                        color: theme.colors.bluegray[4],
                        fontSize: 14,
                        fontWeight: 700,
                    })}
                >
                    {props.label || `${t("common:misc.page")} ${props.index + 1}`}
                </Text>
            ) : (
                <Text
                    sx={(theme) => ({
                        color: props.active ? theme.colors.bluegray[6] : theme.colors.bluegray[5],
                        fontSize: 14,
                        fontWeight: 700,
                    })}
                >
                        {props.label || `${t("common:misc.page")} ${props.index + 1}`}
                    </Text>
            )}
        </Group>
    );

    if (props.loading)
        return (
            <Group noWrap>
                <Loader size={18} sx={{ flexShrink: 0 }} />
                {label}
            </Group>
        );

    const checkedCircle = (
            <CheckCircle
                size={18}
                radius="xl"
                onMouseEnter={() => setHoveringCheck(true)}
                onMouseLeave={() => setHoveringCheck(false)}
                onClick={props.setChecked}
                style={{ flexShrink: 0, marginTop: 2 }}
                color={
                    props.checked ?
                        theme.colors.green[6]
                        :
                        hoveringCheck ? theme.colors.blue[5] : theme.colors.bluegray[3]
                }
            />
    );
    const checkboxChecked =
        <Group noWrap align="flex-start">
            {checkedCircle}
            {label}
        </Group>

    const uncheckedCircle = (
        <Box
            onMouseEnter={() => setHoveringCheck(true)}
            onMouseLeave={() => setHoveringCheck(false)}
            onClick={props.setChecked}
            mt={2}
            sx={(theme) => ({
                width: 18,
                height: 18,
                minWidth: 18,
                minHeight: 18,
                borderRadius: "100%",
                border: `2px solid ${(props.active) ? theme.colors.blue[5] : theme.colors.bluegray[3]}`,
                ':hover': {
                    border: `2px solid ${theme.colors.blue[5]}`,
                }
            })}
        />
    );

    if (props.disableTooltip && props.checked)
        return checkedCircle;

    if (props.disableTooltip && !props.checked)
        return uncheckedCircle;

    const checkboxUnchecked =
        <Group noWrap align="flex-start">
            <Tooltip
                withArrow
                withinPortal
                label="Markera som läst"
                position="left"
                transitionDuration={0}
                offset={20}
            >
                {uncheckedCircle}
        </Tooltip>
            {label}
        </Group>

    if (props.checked)
        return checkboxChecked;

    return checkboxUnchecked;
}

export default Check;
