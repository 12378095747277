import actions, { fetchClasses, setError, setLoading, setSuccess } from ".";
import { apiGet, apiPost } from "../helpers/requests.helper";
import { convertDateToYYYMMDD } from "../helpers/time.helper";
import { GET_CLASS_PRESENCE, POST_DELETE_CLASS } from "../navigation/endpoints";
import store, { DispatchFunction } from "../store";
import { Course } from "../types/course.types";
import { DrivingRecordOption } from "../types/drivingrecord.types";
import {
  NEW_SCHOOL_CLASS_CLEAR_STATE,
  NEW_SCHOOL_CLASS_SET_CLASS_ID,
  NEW_SCHOOL_CLASS_SET_EXTRA_DATA,
  NEW_SCHOOL_CLASS_SET_LOADING,
  NEW_SCHOOL_CLASS_SET_NAME,
  EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES,
  SELECTED_SCHOOLCLASS_CLEAR_STATE,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION,
  SELECTED_SCHOOLCLASS_SET_CLASS_ID,
  SELECTED_SCHOOLCLASS_SET_COURSE_LIST,
  SELECTED_SCHOOLCLASS_SET_EXTRA_DATA,
  SELECTED_SCHOOLCLASS_SET_MEMBER_LIST,
  SELECTED_SCHOOLCLASS_SET_MINI_STATS,
  SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE,
  SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS,
  SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES,
  SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS,
} from "../types/redux.types";
import {
  DriversLicense,
  EducationType,
  SchoolClassMiniStats,
} from "../types/school.types";
import { User } from "../types/user.types";
import { fetchLang } from "../helpers/universal.helper";
import { differenceInDays } from "date-fns";
import * as Sentry from "@sentry/react";
import { SchoolClassExtraData } from "../components/pages/SchoolClass/SchoolClass.api";

const schoolClassActions = {
  selectedSchoolClass: {
    setClassID: (classID: number) => ({
      type: SELECTED_SCHOOLCLASS_SET_CLASS_ID,
      classID,
    }),
    setMemberList: (members: User[]) => ({
      type: SELECTED_SCHOOLCLASS_SET_MEMBER_LIST,
      members,
    }),
    setCourseList: (courses: Course[]) => ({
      type: SELECTED_SCHOOLCLASS_SET_COURSE_LIST,
      courses,
    }),
    setMiniStats: (miniStats: SchoolClassMiniStats) => ({
      type: SELECTED_SCHOOLCLASS_SET_MINI_STATS,
      miniStats,
    }),
    setExtraData: (data: SchoolClassExtraData) => ({
      type: SELECTED_SCHOOLCLASS_SET_EXTRA_DATA,
      data,
    }),
    setSelectAllCourses: (status: boolean) => ({
      type: SELECTED_SCHOOLCLASS_TOGGLE_SELECT_ALL_COURSES,
      status,
    }),
    clear: () => ({
      type: SELECTED_SCHOOLCLASS_CLEAR_STATE,
    }),
  },
  newSchoolClass: {
    setName: (name: string) => ({
      type: NEW_SCHOOL_CLASS_SET_NAME,
      name,
    }),
    setIsLoading: (status: boolean) => ({
      type: NEW_SCHOOL_CLASS_SET_LOADING,
      status,
    }),
    setClassID: (classID: number) => ({
      type: NEW_SCHOOL_CLASS_SET_CLASS_ID,
      classID,
    }),
    setExtraData: (extraData: SchoolClassExtraData | null) => ({
      type: NEW_SCHOOL_CLASS_SET_EXTRA_DATA,
      extraData,
    }),
    clear: () => ({
      type: NEW_SCHOOL_CLASS_CLEAR_STATE,
    }),
  },
  extraDataValues: {
    setEducationTypesAndDriversLicenses: (
      educationTypes: EducationType[],
      driversLicenses: DriversLicense[]
    ) => ({
      type: EXTRA_DATA_VALUES_SET_EDUCATION_TYPES_AND_DRIVERS_LICENSES,
      educationTypes,
      driversLicenses,
    }),
  },
  presence: {
    setUsers: (users: User[]) => ({
      type: SELECTED_SCHOOLCLASS_SET_PRESENCE_USERS,
      users,
    }),
    setRange: (range: { from: Date; to?: Date }) => ({
      type: SELECTED_SCHOOLCLASS_SET_PRESENCE_RANGE,
      range,
    }),
    setOptions: (options: DrivingRecordOption[]) => ({
      type: SELECTED_SCHOOLCLASS_PRESENCE_SET_OPTIONS,
      options,
    }),
    setSelectedOption: (selected: string) => ({
      type: SELECTED_SCHOOLCLASS_PRESENCE_SET_SELECTED_OPTION,
      selected,
    }),
    setPickerVisibility: (status: boolean) => ({
      type: SELECTED_SCHOOLCLASS_PRESENCE_SET_DATE_PICKER_VISIBILITY,
      status,
    }),
  },
};

export function deleteClass(classID: number) {
  return async (dispatch: DispatchFunction) => {
    try {
      await apiPost(POST_DELETE_CLASS, { ID: classID });
      dispatch(fetchClasses());
    } catch (error) {
      Sentry.captureException(error);
    }
  };
}

export function getClassPresenceDateRange(
  classID: number,
  from: Date,
  to: Date
) {
  return async (dispatch: DispatchFunction) => {
    try {
      const userJSON = await apiGet(GET_CLASS_PRESENCE, {
        class_id: classID,
        fromDate: convertDateToYYYMMDD(from),
        toDate: convertDateToYYYMMDD(to),
      });
      dispatch(schoolClassActions.presence.setUsers(userJSON.Users));
    } catch (error) {
      Sentry.captureException(error);
      console.warn(`getClassPresenceDateRange failed: ${error}`);
    }
  };
}

export function setSelectedRange(selected: string) {
  return async (dispatch: DispatchFunction) => {
    dispatch(schoolClassActions.presence.setSelectedOption(selected));
    const selectedOption = store
      .getState()
      .selectedSchoolClass.presenceOptions.find(
        (option: DrivingRecordOption) => option.name === selected
      );
    if (!selectedOption) return null;
    dispatch(schoolClassActions.presence.setPickerVisibility(false));
    dispatch(schoolClassActions.presence.setRange(selectedOption));
  };
}

// export function getEducationTypesAndDriversLicences() {
//     return async (dispatch: DispatchFunction) => {
//         dispatch(schoolClassActions.newSchoolClass.setExtraData(null));
//         try {
//             const json = await apiGet(GET_SCHOOL);
//             const educationTypes = json.EducationTypes;
//             const driversLicenses = json.DriversLicenses;
//             dispatch(schoolClassActions.extraDataValues.setEducationTypesAndDriversLicenses(educationTypes, driversLicenses));
//             let extraData: SchoolClassExtraData | null = null;
//             if (educationTypes !== null && educationTypes.length > 0) {
//                 extraData = {};
//                 extraData.EducationTypeID = educationTypes[0].ID;
//                 extraData.EducationType = educationTypes[0].Name;
//                 if (driversLicenses.length > 0) {
//                     extraData.DriversLicenseID = driversLicenses[0].ID;
//                     extraData.DriversLicense = driversLicenses[0].Name;
//                 }
//             }
//             dispatch(schoolClassActions.newSchoolClass.setExtraData(extraData));
//         } catch (error) {
//             Sentry.captureException(error);
//             console.warn(`getEducationTypesAndDriversLicences failed: ${error}`);
//         }
//     }
// }

function generateEmptyDaysList(from: Date, to: Date) {
  const days = Math.abs(differenceInDays(from, to));
  const map: { [day: string]: number } = {};
  const date = new Date(from);
  for (let i = 0; i < days + 1; i++) {
    map[convertDateToYYYMMDD(date)] = 0;
    date.setDate(date.getDate() + 1);
  }
  return map;
}

export function getSchoolRuntime(schoolID: number, from: Date, to: Date) {
  return async (dispatch: DispatchFunction) => {
    try {
      dispatch(setLoading("GET_SCHOOL_RUNTIME"));

      const response = await apiGet("/school-runtime-date-range", {
        language: fetchLang(),
        fromDate: convertDateToYYYMMDD(from),
        toDate: convertDateToYYYMMDD(to),
      });

      const map = generateEmptyDaysList(from, to);
      if (response.Runtime) {
        response.Runtime.forEach(
          (r: { Day: string; Duration: number }) => (map[r.Day] = r.Duration)
        );
      }

      // Remove aggregated runtime prior to May 1st 2020 from plot
      if (map["2020-05-01"]) map["2020-05-01"] = 0;

      const arrayOfObj = Object.entries(map).map((e: [string, number]) => ({
        day: new Date(e[0]),
        runtime: e[1],
      }));
      dispatch(actions.homePage.setSchoolRuntime(schoolID, arrayOfObj));
      dispatch(actions.homePage.setDateRangeOnData({ from, to }));
      dispatch(setSuccess("GET_SCHOOL_RUNTIME"));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setError("GET_SCHOOL_RUNTIME"));
      console.warn(`getSchoolRuntime failed: ${error}`);
    }
  };
}

export default schoolClassActions;
